// @flow
import React, { useState } from 'react';
import classnames from 'classnames';
import Styles from './styles.module.css';
import MusclesDataFemale from './muscle-female.json';
import MusclesDataMale from './muscle-male.json';

const getImageName = muscle => `${muscle.sex}-${muscle.view}-${muscle.group}-${muscle.name}.png`;

type SexType = "male" | "female";
type ViewType = "front" | "back";
type MuscleType = {
    sex: SexType,
    view: ViewType,
    group: string,
    name: string,
    position: {
        top: number,
        left: number
    },
    width: number,
}
type Props = {
    sex?: SexType,
    selectedGroups?: Array<string>,
    selectedSecondaryGroups?: Array<string>,
    onChangeSelectedGroups?: (primary: Array<string>, secondary: Array<string>) => any
}

export default ({
    sex = "male",
    selectedGroups = [],
    selectedSecondaryGroups = [],
    onChangeSelectedGroups = (primaryGroups: Array<string>, secondaryGroups: Array<string>) => {}
} : Props) => {

    const MusclesData = {
        male: MusclesDataMale,
        female: MusclesDataFemale
    }

    const [ groupOver: ?string, setGroupOver: (group: string) => void ] = useState(null);

    const onClickMuscle = muscle => {

        // In primary => remove from primary, add to secondary
        if (selectedGroups.indexOf(muscle.group) !== -1) {
            onChangeSelectedGroups(
                selectedGroups.filter(group => group !== muscle.group),
                [ ...selectedSecondaryGroups, muscle.group ]
            )
        // In secondary => remove from all
        } else if (selectedSecondaryGroups.indexOf(muscle.group) !== -1) {
            onChangeSelectedGroups(
                selectedGroups.filter(group => group !== muscle.group),
                selectedSecondaryGroups.filter(group => group !== muscle.group)
            )
        // Nowhere => Add to primary group
        } else {
            onChangeSelectedGroups(
                [ ...selectedGroups, muscle.group ],
                selectedSecondaryGroups
            )
        }
    }

    return (
        <div className={Styles.container}>
            <img alt={sex} src={require('./images/'+ sex +'.png')} className={Styles.imageBackground} />

            {MusclesData[sex].map((muscle: MuscleType) => muscle.sex === sex && (
                <img
                    key={getImageName(muscle)}
                    alt={getImageName(muscle)}
                    src={require(`./images/${getImageName(muscle)}`)}
                    className={classnames(Styles.imageMuscle, {
                        [Styles.imageMuscleHover]: muscle.group === groupOver,
                        [Styles.imageMuscleSelected]: selectedGroups.indexOf(muscle.group) !== -1,
                        [Styles.imageMuscleSecondarySelected]: selectedSecondaryGroups.indexOf(muscle.group) !== -1
                    })}
                    onMouseOver={e => setGroupOver(muscle.group)}
                    onMouseOut={e => setGroupOver(null)}
                    onClick={e => onClickMuscle(muscle)}
                    style={{
                        width: muscle.width + '%',
                        position: 'absolute',
                        top: muscle.position.top + "%",
                        left: muscle.position.left + '%'
                    }}
                />
            ))}
        </div>
    )

}