// @flow
export type TransformersOptions = Array<
    'int' | 'float' | 'lowercase' | 'uppercase' | 'alpha' | 'numeric' | 'alphanumeric' | 'max' | 'min' | 'nospaces' | 'cents_to_euros' | 'euros_to_cents'
>

export const transform_int = (value: any) =>parseInt(value, 10);
export const transform_float = (value: any) =>(parseFloat(value) || 0);
export const transform_lowercase = (value: any) =>value.toLowerCase();
export const transform_uppercase = (value: any) =>value.toUpperCase();
export const transform_alpha = (_value: any) =>{
    const value = _value.match(/^[a-z]*/g);
    if (!value || !value.length) {
        return '';
    }

    return value[0];
}
export const transform_numeric = (_value: any) =>{
    const value = _value.match(/^[0-9]*/g);
    if (!value || !value.length) {
        return '';
    }
    return value[0];
}
export const transform_alphanumeric = (_value: any) =>{
    const value = _value.match(/^[0-9a-z]*/g);
    if (!value || !value.length) {
        return '';
    }
    return value[0];
}


export const transform_max = (_value: any, max: number) => {
    return Math.min(_value, max);
}
export const transform_min = (_value: any, min: number) => {
    return Math.max(_value, min);
}


export const transform_nospaces = (_value: any) =>{
    return _value.replace(new RegExp(" ", "g"), "")
}

export const transform_cents_to_euros = (_value: any) =>{
    return parseInt(_value, 10) / 100;
}
transform_cents_to_euros.reverse_name = "euros_to_cents";
transform_cents_to_euros.isFloat = true;


export const transform_euros_to_cents = (_value: any) =>{
    return Math.round(_value*100);
}
transform_euros_to_cents.reverse_name = "cents_to_euros";
transform_euros_to_cents.isFloat = true;