import React from 'react';
import { Grid } from "@material-ui/core"

import SignatureCard from './Card';

export default ({ signatures, actions = [], onClick }) => {

    return (
        <Grid container spacing={10} justify="center">
            {signatures.map((signature, i) => (
                <Grid item key={i}>
                    <SignatureCard
                        signature={signature}
                        actions={actions}
                        onClick={onClick}
                    />
                </Grid>
            ))}
        </Grid>
    )
}