// @flow
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    Grid, withStyles, Paper
} from '@material-ui/core'


///// CONTAINER
export const Container = styled(Paper)`
    padding: 20px;
`


///// GRID CONTAINER
export const GridContainer = withStyles(theme => ({
  [theme.breakpoints.down('md')]: {
      root: {
        flexDirection: 'column-reverse',
      },
  }
}))(props => (
    <Grid container spacing={10} {...props} />
));



///// GRID ITEM
const _GridItem = (props) => (
    <Grid item xs={12} sm={12} lg={6} {...props} />
);
const GridItem = styled(_GridItem)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
`

export const FormContainer = (props: any) => (
    <GridItem lg={8} {...props} />
)

const VideoViewport = styled.div`
    width: 100%;
`
type RefObject = {|
  current: any,
|};
export const VideoContainer = ({ children, aspectRatio = 4/5, ...props }: { children: any, aspectRatio?: number }) => {
    const viewport: RefObject = useRef(null);
    const [ height, setHeight ] = useState(0);

    const onWindowResize = () => {
        if (!viewport.current) {
            return;
        }

        setHeight(viewport.current.offsetWidth / aspectRatio);
    }

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aspectRatio ])

    return (
        <GridItem {...props} lg={4} >
            <VideoViewport ref={viewport} style={{ ...(height === 0 ? {} : { height: height+ 'px' })}} >
                {children}
            </VideoViewport>
        </GridItem>
    )
}