import React from "react";
import { useNavigate } from 'react-router-dom'

import { useCentres, useCurrentCentre } from 'Apps/Stockizy/Store/hooks'
import { User } from '@izzistock/models';
import PageForm from 'AppCore/Components/CommonPages/PageFormWithLoader/index';
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";

import { getFormAttributes } from './helpers'

class SaveError extends Error {
    displayMessage: ""
    code: 0

    constructor({ code, message, displayMessage }) {
        super(message)
        this.code = code;
        this.displayMessage = displayMessage
    }
}
export default () => {

    const navigate = useNavigate();
    const centres = useCentres();
    const currentUser = useCurrentUser();
    const currentCentre = useCurrentCentre();

    return (
        <PageForm
            formTitle={"Nouvel utilisateur"}
            formAttributes={getFormAttributes({ centreId: currentCentre ? currentCentre.id: null, currentUser, centres, type: 'create' })}
            initialData={(new User()).clone(u => {
                u.centreId = currentCentre.id;
            })}
            saveData={async user => {
                try {
                    await user.save();
                } catch (e) {
                    if (e.code === 'auth/email-already-exists') {
                        throw new SaveError({
                            message: e.message,
                            code: e.code,
                            displayMessage: "Un utilisateur avec cet email existe déjà"
                        })
                    }
                    throw e;
                }
            }}
            onCreated={user => {
                navigate('/user/' + user.id)
            }}
        />
    )
};