import React from 'react';



export default ({ ...props }) => (
	<article>
          <ol>
            <li>Generate an email signature using <b>MySignature</b> editor.</li>
            <li>Click on <b>Copy to clipboard</b> button.</li>
            <li>Open <a target="blank" href="https://mail.yahoo.com/" rel="nofollow">Yahoo</a>.</li>
            <li>In the top right, click &nbsp; <b>Settings</b>&nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp;<b>More Settings</b>&nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp;<b>Writing emails</b></li>
            <li>Click checkbox button opposite your email and paste the signature (Ctrl+V or Command+V) into text area.</li>
          </ol>
            <b>Important:</b>
            <p>Use Chrome or Mozilla browser when you click <b>"copy to clipboard"</b> button to keep right formatting.</p>
         </article>
)