// @flow

import React from 'react';
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Paper
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { ResizableContainer } from 'AppCore/Components/MediaUploader';




export const SpacerSmall = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;
`;
export const SpacerDefault = styled.div`
    padding-bottom: 20px;
    padding-top: 20px;
`;
export const SpacerBig = styled.div`
    padding-bottom: 20px;
    padding-top: 30px;
`;

export const Spacer = ({ size, ...props }: { size: 'small' | 'big' }) => {
    switch (size) {
        case 'big':
            return <SpacerBig {...props} />;
        case 'small':
            return <SpacerSmall {...props} />;
        default:
            return <SpacerDefault {...props} />;
    }
}



export const Divider = styled(MuiDivider)`
    margin-bottom: 20px;
    margin-top: 20px;
`;
export const Typography = styled(MuiTypography)(spacing);

export const Title = (props) => (
    <Typography variant="h1" gutterBottom display="inline" {...props} />
)

export const SubTitle = (props) => (
    <Typography variant="h3" display="inline" {...props} />
)

///// CONTAINER
export const Container = styled(Paper)`
    padding: 0px;
`

///// TABS
export const TabsContainer = styled.div`
    width: 100%;
`



export const BlocMediaUpload = ({ title, children, style = {}, ...props }) => (
    <div style={{ width: '100%', ...style }} {...props}>
        <SubTitle style={{alignSelf: 'flex-start'}}>{title}</SubTitle>
        <Divider my={6} style={{width: '100%'}} />

        <ResizableContainer>
            {children}
        </ResizableContainer>
    </div>
)