import Constants from "../Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getCampaigns
*/
export const getCampaigns = () => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.getCampaigns({ organisation_id });

		dispatch({
			type: C.SET_CAMPAIGNS,
			campaigns: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createCampaign
*/
export const createCampaign = ({ campaign }) => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		await api.createCampaign({ organisation_id, campaign });
		await dispatch(getCampaigns());

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalCampaign = ({ campaign_id, campaign }) => async (dispatch, getState, { api, ws }) => {
	const campaigns = JSON.parse(JSON.stringify(getState().campaigns));

	campaigns.data.forEach((_campaign, i) => {
		if (_campaign.id === campaign_id) {
			campaigns.data[i] = {
				...campaigns.data[i],
				...campaign
			}
		}
	});

	dispatch({
		type: C.SET_CAMPAIGNS,
		campaigns
	});
}

export const updateCampaign = ({ campaign_id, campaign }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_campaigns = {
		...(getState().campaigns)
	}

	dispatch(updateLocalCampaign({ campaign_id, campaign }));

	try {


		const result = await api.updateCampaign({ campaign_id, campaign });
		dispatch(getCampaigns())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_CAMPAIGNS,
			campaigns: rollback_campaigns
		});
	}

}

export const deleteCampaign = ({ campaign_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteCampaign({ campaign_id });
		await dispatch(getCampaigns())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}