// @flow

import React, { useState } from 'react'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'

const Container = styled.div`
    position: relative;
`
const ColoredButton = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 100%;
`
const Color = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
`

const PopOver = styled.div`
    position: absolute;
    z-index: 2;
`
const Cover = styled.div`
    position: fixed;
    top: 0px; bottom: 0px; left: 0px; right: 0px;
`

export default ({ color, onChange, ...props }: { color: string, onChange: (color: string) => any }) => {
    const [ displayColorPicker, setDisplayColorPicker ] = useState(false);

    return (
        <Container {...props}>
            <ColoredButton onClick={() => setDisplayColorPicker(true)}>
                <Color style={color ? { backgroundColor: color } : {}} />
            </ColoredButton>
            {displayColorPicker ?
                <PopOver>
                    <Cover onClick={() => setDisplayColorPicker(false)} />
                    <SketchPicker color={color} onChange={color => onChange(color.hex)} />
                </PopOver> : null
            }

        </Container>
    )
}