import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components'
import queryString from 'query-string'

import ActivityIndicator from 'AppCore/Components/ActivityIndicator'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${props => props.theme.body.background};
  }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const onOpen = async () => {
    const parsed = queryString.parse(window.location.search);

    window.localStorage.setItem('ig-data', parsed.code);
    window.close();
}
export default () => {

    useEffect(() => {
        onOpen();
    }, [])

    return (
        <React.Fragment>
            <GlobalStyle />
            <Container>
                <ActivityIndicator size={40} />
            </Container>
        </React.Fragment>
    )
}