import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import styled from 'styled-components';

import Api from 'Apps/Signally/Api';
import Button from 'Apps/Signally/Components/Button'
import Preview from 'Apps/Signally/Components/Signature/V2/Preview'
import CopyBtn from 'Apps/Signally/Components/Signature//copySignatureBtn';

import SignatureData from 'Apps/Signally/Components/Signature/V2/DataObject';

import User from 'Apps/Signally/Models/User'

import FormFillInfos from './form';
import Install from './install'

import css from './index.module.css';
import { useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const PreviewContainer = styled.div`
    flex:1;
    min-width: 400px;
    @media (max-width: 1220px) {
        width: 100%;
    }
`

const ErrorContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction:column;
	justify-content: center;
	align-items: center;

	.MuiPaper-root {
		padding: 100px;
	}
`
const ErrorDisplay = ({ error }) => (
	<ErrorContainer>
		<Paper>
			<Alert severity="error">{error.message}</Alert>
		</Paper>
	</ErrorContainer>
)

const isMissingInfo = profile => {
	const isMandatory = ['first_name', 'last_name', 'position', 'phone_number'];

	for (var i = 0; i < isMandatory.length; i++) {
		if (!profile.hasOwnProperty(isMandatory[i]) || !profile[isMandatory[i]] || profile[isMandatory[i]] === '') {
			return true;
		}
	}

	return false;
}



export default () => {

	const { user_id } = useParams();

	const [ loading, setLoading ] = useState(true);
	const [ deployed, setDeployed ] = useState(false);
	const [ editInfos, setEditInfos ] = useState(false);
	const [ missingInfos, setMissingInfos ] = useState(false);

	const [ user, setUser ] = useState(new User());
	const [ signatureData, setSignatureData ] = useState(new SignatureData());
	const [ error, setError ] = useState(null);

	const {
        signatureversion
    } = queryString.parse(window.location.search) || {}


	const onSignatureCopied = async () => {
		if (!deployed) {
			await Api.setUserSignatureDeployed({ user_id, signatureversion })
			setDeployed(true);
		}
	}

	useEffect(() => {
		async function load() {

			try {

				const signatureData = await Api.getInstallSignatureData({ user_id, signatureversion })
				const signatureDataObj = new SignatureData();

				if (signatureData.signature && signatureData.signature.signatureData) {
					signatureDataObj.setData(JSON.parse(signatureData.signature.signatureData));
				}

				const user = new User();
        		user.setData(signatureData.user);

				signatureDataObj.setCompany(signatureData.company);
				signatureDataObj.setTeam(signatureData.team);
				signatureDataObj.setUser(user);

				signatureDataObj.banner.setData({
					media: {
						src : "https://api.signally.io/user/"+user.id+"/banner"
					}
				});

				setUser(user);
				setSignatureData(signatureDataObj);
				setLoading(false);

				if (isMissingInfo(signatureData.user)) {
					setMissingInfos(true);
				}

			} catch (e) {
				setError(e);
				console.error(e);
				setLoading(false);
			}
		}
		load();
	}, [ user_id, signatureversion ]);

	if (error) {
		return (
			<ErrorDisplay error={error} />
		);
	}

	if (loading) {
		return "loading";
	}

	let leftSide = null;

	const displayForm = editInfos || missingInfos;

	if (displayForm) {
		leftSide = (
			<FormFillInfos
				user_id={user.id}
				signatureversion={signatureversion}
				userData={user.export()}
				// hasCancelBtn={editInfos || (!editInfosSaved && !missingInfos)}
				onClickCancel={() => setEditInfos(false)}
				onChange={userRaw => {
					const newUser = user.clone(u => {
						u.setData(userRaw);
					});
					setUser(newUser);
					setSignatureData(signatureData.clone(s => {
						s.setUser(newUser);
					}))
				}}
				onSave={userRaw => {
					setEditInfos(false);
					setMissingInfos(isMissingInfo(userRaw));
				}}
			/>
		);
	} else {
		leftSide = <Install />
	}

	return (
		<div className={css.installContainer}>

			<div className={css.leftSide}>
				{leftSide}
			</div>

			<div className={css.previewContainer}>
				<PreviewContainer className={css.preview}>
					<Preview data={signatureData} style={{marginTop: '0px'}} />
				</PreviewContainer>

				<div className={css.actions}>
					{!displayForm && <Button onClick={() => setEditInfos(true)}>Edit Infos</Button>}
					<CopyBtn onSignatureCopied={onSignatureCopied}>Copy</CopyBtn>
				</div>
			</div>
		</div>
	)

}

















