import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 21 20" {...props}>
      <path
        d="M.5 15.28a.19.19 0 01.24-.04l4 4a.19.19 0 010 .28l-.21.21a1 1 0 01-1.41 0L.29 16.9a1 1 0 010-1.41zM11.07.46a1.51 1.51 0 012.12 0l6.36 6.37a1.51 1.51 0 010 2.12l-1.42 1.41a1.49 1.49 0 01-2.12 0l-2.5-2.51a39.84 39.84 0 00-5.66 5.66l2.51 2.51a1.49 1.49 0 010 2.12l-1.41 1.42a1.51 1.51 0 01-2.12 0L.46 13.19a1.51 1.51 0 010-2.12l1.43-1.41a1.49 1.49 0 012.12 0l2.06 2.07a43.24 43.24 0 015.66-5.66L9.66 4a1.49 1.49 0 010-2.12zm4.42-.17a1 1 0 011.41 0l2.87 2.87a1 1 0 010 1.41l-.21.21a.19.19 0 01-.28 0l-4-4a.19.19 0 010-.28z"
      />
    </svg>
  )
}

export default SvgComponent
