import React, { useState } from 'react';
import { constructFirebaseStorageUrl } from '@knxlab/utils'
import { getConfig } from 'AppCore/config'
import FileUploader from "../ReactFirebaseFileUploader";
import styled from 'styled-components';
import Button from '../Button';



export default ({ editable = true, storageRef, files = [], onFilesChange = () => {} }) => {

    const [ currentFile, setCurrentFile ] = useState(null);
    const [ currentFileProgress, setCurrentFileProgress ] = useState(0);

    const handleUploadStart = file => {
        setCurrentFile({
            ref: file.name,
            type: file.type
        });
    }
    const handleUploadSuccess = () => {
        onFilesChange([
            ...files,
            { ...currentFile }
        ]);
        setCurrentFile(null);
        setCurrentFileProgress(0);
    }
    const handleProgress = uploadProgress => {
        setCurrentFileProgress(uploadProgress);
    }

    const onDelete = index => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        onFilesChange(newFiles);
    }

    return (
        <div>

            <div>
                {files.map((file, i) => (<FileDisplay key={i} editable={editable} file={file} onDelete={() => onDelete(i)} />))}
            </div>

            {currentFile && <div>
                <FileDisplay file={currentFile} displayProgress uploadProgress={currentFileProgress} disableDelete />
            </div>}

            {editable && <div>

                <UploadBtn>
                    Selectionnez un fichier
                    <FileUploader
                        hidden
                        accept="*/*"
                        storageRef={storageRef}
                        onUploadStart={file => handleUploadStart(file)}
                        onUploadError={console.log}
                        onUploadSuccess={handleUploadSuccess}
                        onProgress={handleProgress}
                    />

                </UploadBtn>

            </div>}
        </div>
    )
}


const FileDisplayContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const FileDisplay = ({ editable = true, file, uploadProgress, displayProgress = false, disableDelete = false, onDelete = () => {} }) => {

    const { firebaseConfig: { storageBucket } } = getConfig();
    const url = constructFirebaseStorageUrl(storageBucket, `files/${file.ref}`)

    return (
        <FileDisplayContainer>

            {displayProgress ? (
                <span>{file.ref}</span>
            ) : (
                <a href={url} target="_blank" rel="noopener noreferrer">{file.ref}</a>
            ) }

            {displayProgress && uploadProgress+"%" }

            {editable && !disableDelete &&
                <Button onClick={onDelete}>Supprimer</Button>
            }
        </FileDisplayContainer>
    )
}

const UploadBtn = styled.label`
    display: inline-block;
    background-color: steelblue;
    color: white;
    padding: 10px;
    borderRadius: 4;
    cursor: pointer;
`