// @flow
import React, { useState, useEffect } from "react";

import StockTable from 'Apps/Stockizy/Components/Stocks/List'
import PageList from 'AppCore/Components/CommonPages/PageList/view'
import Button from "AppCore/Components/Button";
import { Stock } from '@izzistock/models';
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";
import { useOpenAddModal, useOpenRemoveModal } from 'Apps/Stockizy/Components/Stocks/ModalAdd'


export default () => {

    const currentCentre = useCurrentCentre();
    const openAddStockDialog = useOpenAddModal();
    const openRemoveStockDialog = useOpenRemoveModal();

    const [ stocks, setStocks ] = useState(null);
    const [ searchQuery, setSearchQuery ] = useState("");

    const loadData = async ({ centreId }: { centreId: string }) => {
        if (centreId) {
            setStocks(await Stock.getAll({ centreId }));
        }
    }

    useEffect(() => {
        if (currentCentre.id) {
            loadData({ centreId: currentCentre.id });
        }
    }, [ currentCentre.id ])

    return (
        <PageList
            hasSearch={true}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            title="Etat des stocks"
            actions={[
                <Button
                    key="remove"
                    onClick={() => {
                        if (!currentCentre.id) {
                            return;
                        }
                        openRemoveStockDialog({
                            centreId: currentCentre.id,
                            onChange: () => {
                                loadData({ centreId: currentCentre.id })
                            }
                        })
                    }}
                >Retirer du stock</Button>,
                <Button
                    key="add"
                    onClick={() => {
                        if (!currentCentre.id) {
                            return;
                        }
                        openAddStockDialog({
                            centreId: currentCentre.id,
                            onChange: () => {
                                loadData({ centreId: currentCentre.id })
                            }
                        })
                    }}
                >Ajouter du stock</Button>
            ]}
            dataList={stocks || { data: [] }}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <StockTable
                    data={data}
                    searchQuery={searchQuery}
                    actions={[]}
                />
            )}
        />
    )
}