

export const media = {
    type: "object",
    properties : {
        src: { type: "string" },
        width: { type: "integer" },
        height: { type: "integer" },
        type: { type: "string" },
        clickUrl: { type: "string" }
    }
};