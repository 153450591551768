// @flow
import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'AppCore/Components/Icons';

import type { ValidatorOptions } from './validators'
import type { TransformersOptions } from './transformers'

import InputField from './inputField';
import AutocompleteTags from "./inputFields/AutocompleteTags";
import Slider from "./inputFields/Slider";
import type { Option as OptionType } from './inputFields/AutocompleteTags'
import styled from "styled-components";

export type AttributeConfig =  {
    type: string,
    multiline?: boolean | number,
    disabled?: boolean,

    label: ?string,
    placeholder?: ?string,
    values?: Array<any>,
    options?: Array<OptionType> | Array<any>,

    validators?: ValidatorOptions,
    transformers?: TransformersOptions,
    onsave_transformers?: any,
    style?: any,

    TagComponent?: any,

    endAdornment?: any,
    startAdornment?: any
}


type Props = {
	config: AttributeConfig,
	textFieldClassName: any,

    style?: string,
    className?: string,

    name: string,
    value: string,
    hasError: boolean,
    onChange: any => any,
    onBlur: any => any
}
const FormField = ({
    config,
	textFieldClassName,

    style,
    className,

    name = "",
    value,
    hasError,
    onChange,
    onBlur,
}: Props) => {

		const {
			type,
            multiline = false, disabled = false,
            label = null, placeholder = null,

            values = [], options = [], TagComponent = null,
			endAdornment, startAdornment
		} = config;


		if (type === 'autocomplete') {
			return (
				<AutocompleteTags
					onChange={onChange}
					value={value}
					options={options}
					disabled={disabled}
					label={label || name}
					placeholder={placeholder || name}
					style={{width: '100%'}}
					TagComponent={TagComponent}
                    className={className}
				/>
			)
		}

		if (type === 'slider') {
			return (
				<Slider
					onChange={onChange}
					value={value}
					options={options}
					label={label || name}
					style={{width: '100%'}}
                    className={className}
				/>
			)
		}
        console.log("class", className)
		return (
			<InputField
				transformers={config.transformers || []}
				onsave_transformers={config.onsave_transformers || []}
				label={label || name}
				type={type}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}

				value={value}
				values={values}

				error={hasError}
				placeholder={placeholder || name}
				multiline={multiline}

                className={className}
				style={{...style, width: '100%'}}
				endAdornment={endAdornment}
				startAdornment={startAdornment}
			/>
		)
}


const ErrorContainer = styled.div`
    position: absolute;
    right: 10;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
`

export default ({
    textFieldClassName,
    classes = {},
    style,

    hasError, errorMsg,

    config,
    name,

    ...props

}: Props & Object = {}) => {

    return (
        <div className={textFieldClassName} style={{
            ...style,
            position: 'relative'
        }}>

            <Tooltip
                title={errorMsg || ""}
                open={hasError}
                placement="right"
            >
                <div style={{display: 'inline-block', width: '100%'}}>
                    <FormField
                        config={config}
                        hasError={hasError}
                        name={name}
                        {...props}
                    />
                </div>
            </Tooltip>

            {hasError &&
                <ErrorContainer>
                    <Icon name="error" color="error" />
                </ErrorContainer>
            }
        </div>
    )
}
