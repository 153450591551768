import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.other_accounts, action) => {
	switch (action.type) {
	case C.SET_OTHER_ACCOUNTS:
		return action.other_accounts || state;
	default:
		return state;
	}
}