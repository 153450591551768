import * as firebase from 'firebase/app'
import { getConfig } from './config';

export default (onAuthStateChanged = () => {}) => {
    firebase.auth().onAuthStateChanged(async (_user) => {

        let user = _user;

        if (user) {
            const { auth: authConfig } = getConfig();
            if (authConfig.userClass) {
                const userData = await authConfig.userClass.get({id: user.uid})
                user = {
                    uid: user.uid,
                    email: user.email,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    userData: userData.export()
                };
            }
        }



        onAuthStateChanged(user);
    })

}