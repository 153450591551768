import React, { useState, useRef } from 'react';

import _ from 'Apps/Signally/Trads';
import Api from 'Apps/Signally/Api';

import Form from 'AppCore/Components/Form/formFields';
import Button from 'Apps/Signally/Components/Button'

export default ({ user_id, signatureversion, userData, className = null, hasCancelBtn = false, onClickCancel = () => {}, onChange = () => {}, onSave = () => {}, ...props }) => {

	const [ loading, setLoading ] = useState(false);
	const [ saving ] = useState(false);
	const [ hasError, setHasError ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ data, setData ] = useState(userData);

	const form = useRef();

	const onClickSave = async () => {
		setLoading(true);

		try {

			await Api.setInstallSignatureData({
				user_id, signatureversion,
				user: data
			})

			setLoading(false);
			onSave(data);

		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	}

	return (
		<div>
			<h1>Fill missing information before installing new signature</h1>
			{error && <h2>{error}</h2>}
			<Form
				innerRef={form}
				config={{
					attributes: {
						first_name: { type: 'text', validators: ['not_empty'], disabled: loading || saving },
						last_name: { type: 'text', validators: ['not_empty'], disabled: loading || saving },
						position: { type: 'text', validators: ['not_empty'], disabled: loading || saving },
						phone_number: { type: 'text', label:_("mobilephone"), validators: ['not_empty', 'mobilephone'], disabled: loading || saving }
					}
				}}
				values={data}
				onChange={({values: data, hasError}) => {setData(data); setHasError(hasError); onChange(data)}}
				className={className}
			/>

			{hasCancelBtn &&
				<Button onClick={onClickCancel}>Cancel</Button>
			}
			<Button disabled={hasError} onClick={onClickSave}>Save</Button>
		</div>
	)
}