// @flow
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,

} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";


export const Card = styled(MuiCard)`
    height: 80px;
    position: relative;

    .MuiCardActions-root {
        visibility: hidden;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.05);

        .MuiCardActions-root {
            visibility: visible;
        }
    }
`


export const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
`;

export const TagsContainer = styled.div`
    & > div {
        margin-right: 10px;
    }
`
export const CardDetail = styled.div`
    flex: 1;
    padding: 10px;
`

export const Typography = styled(MuiTypography)(spacing);

export const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`