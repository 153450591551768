// @flow
import React from 'react';

import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';

import MediaUploader from 'Apps/Signally/Components/MediaUploader'

import SignatureData from '../../DataObject';

import { SectionTitle } from '../Utils/Helpers'
import ImageStyle from '../Utils/ImageStyle'

const Container = styled.div`
    padding: 10px;
`
const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`

type Props = {
    data: SignatureData,
    onlyStyle?: boolean,
    onChange: (data: SignatureData) => any
}
export default ({ data, onChange, onlyStyle }: Props) => {

    return (
        <Container>

            <SectionTitle>Logo</SectionTitle>

            {!onlyStyle && <MediaUploader
                onChange={media => {
                    onChange(data.clone(d => {
                        if (media && media.payload && media.payload.mediaObject) {
                            d.logo.mediaId = media.payload.mediaObject.id
                        }
                        d.logo.media = media;
                    }))
                }}
                initialMedia={data.logo.media || null}

                title="Uploadez votre logo"
                infos={"Uploadez votre logo"}
                btnLabel={"Selectionnez une image"}
                style={{ width: "250px", height: "300px" }}
                fileType={"image/*"}
                canCrop={true}
            />}

            <ImageStyle
                image={data.logo} onChange={image => {
                    onChange(data.clone(d => d.logo = image))
                }}
            />

            <Divider />


            <SectionTitle>Bannière</SectionTitle>

            {!onlyStyle && <MediaUploader
                onChange={media => {
                    onChange(data.clone(d => {
                        if (media && media.payload && media.payload.mediaObject) {
                            d.banner.mediaId = media.payload.mediaObject.id
                        }
                        d.banner.media = media;
                    }))
                }}
                initialMedia={data.banner.media || null}

                title="Uploadez votre bannière"
                infos={"Uploadez votre bannière"}
                btnLabel={"Selectionnez une image"}
                style={{ width: "100%", height: "auto" }}
                fileType={"image/*"}
            />}

            <ImageStyle enableSpaceStyle maxWidth={1000} image={data.banner} onChange={image => onChange(data.clone(d => d.banner = image))}/>

        </Container>
    )
}