import React, { useEffect } from "react";
import { Routes, useLocation } from "react-router-dom";

import StockizyRoutes from './routes'
import { LogEvent } from 'AppCore/Libs/Log'

import { RenderRoutes, RoutesContext, getRoutesConfig, setRoutesConfig } from '../Shared/Router'
import { useCurrentCentre } from "./Store/hooks";

export default () => {

    let location = useLocation();
    const currentCentre = useCurrentCentre();

    setRoutesConfig({
        ...StockizyRoutes,
        sidebar: currentCentre ? StockizyRoutes.sidebarCentre : StockizyRoutes.sidebar
    });

    useEffect(() => {
        console.log("ROUTE", location.pathname);
        LogEvent('navigate', { path: location.pathname })
    }, [location.pathname])

    return (
        <RoutesContext.Provider value={getRoutesConfig()}>
            <Routes>
                {RenderRoutes(getRoutesConfig())}
            </Routes>
        </RoutesContext.Provider>
    )
}