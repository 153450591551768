// @flow
import React, { useEffect, useState } from 'react';

import {
    CircularProgress
} from '@material-ui/core'

import Api from 'Apps/Signally/Api';
import MediaUploader from 'AppCore/Components/MediaUploader/v2'
import { loadImage } from 'AppCore/Components/MediaUploader/Media/helpers'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const TYPES_TO_MIME_TYPE = {
    'jpg': 'image/jpeg',
    'image/jpg': 'image/jpeg',
    'image/jpeg': 'image/jpeg',
    'png': 'image/png',
    'image/png': 'image/png',
    'gif': 'image/gif',
    'image/gif': 'image/gif'
}

export default ({
    initialMedia,
    onChange = media => {},
    canCrop = true,
    style,
    ...props
}: {
    initialMedia: any,
    canCrop?: boolean,
    style?: any,
    onChange: any => any
}) => {
    const [ media, setMedia ] = useState();
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        if (!initialMedia) {
            setLoading(false);
            return;
        }
        (async () => {
            setLoading(true);
            const image = await loadImage(initialMedia.src)

            setMedia({
                src: image.src,
                width: image.width,
                height: image.height,
                type: TYPES_TO_MIME_TYPE[initialMedia.type]
            })
            setLoading(false);

        })()
    }, [initialMedia])

    if (loading) {
        return (
            <Container style={style} >
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container style={style}>
            <MediaUploader
                {...props}
                 style={style}
                canCrop={canCrop}

                onChangeMedia={media => {
                    setMedia(media);
                    if (!media || !media.local) {
                        onChange(media)
                    }
                }}
                onClickDelete={() => {
                    setMedia(null);
                    onChange(null)
                }}
                media={media}

                onError={e => {
                    switch (e.type) {
                        case 'file_type':
                            alert("Erreur de type");
                            break;
                        default:
                            alert("Une erreur est survenue");
                            break;
                    }
                }}

                uploadMethod={async ({ file, filetype, onProgress }) => {
                    onProgress(10);
                    const fileResult = await Api.upload({ file });
                    onProgress(100);
                    return {
                        downloadURL: fileResult.path,
                        mediaObject: fileResult
                    };
                }}
            />
        </Container>
    )
}