import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.onboarding, action) => {
	switch (action.type) {

	case C.SET_ONBOARDING_SIGNATURE_DATA:
		return {
			...state,
			hasData: true,
			first_edit: action.first_edit || state.first_edit,
			signatureData: action.signatureData || state.signatureData,
			signatureName: action.signatureName || state.signatureName,
			signatureDataV2: action.signatureDataV2 || state.signatureDataV2
		};
	case C.CLEAR_ONBOARDING_SIGNATURE_DATA:
		return {
			...state,
			hasData: false,
			first_edit: initialState.first_edit,
			signatureData: initialState.onboarding.signatureData,
			signatureName: initialState.onboarding.signatureName,
			signatureDataV2: initialState.onboarding.signatureDataV2
		};
	default:
		return state;

	}
}