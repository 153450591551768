import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';

import Api from 'Apps/Signally/Api';

import Form from 'AppCore/Components/Form/formFields';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import SignatureLayout from 'Apps/Signally/Components/Signature/v2'

import Button from 'Apps/Signally/Components/Button'
import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import useLoadSignatureData from 'Apps/Signally/react-hooks/useLoadSignatureData'

import css from './index.module.css';
import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";
import SignatureData from "Apps/Signally/Components/Signature/V2/DataObject";
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";

export default () => {

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const company = useCurrentCompany()
    const { signature_id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ hasError, setHasError ] = useState(false);
    const [ data, setData ] = useState({ name: "" });
    const [ signatureData: SignatureData, setSignatureData ] = useState();
    const [ selectedTeam ] = useTeamContext();

    const currentSignatureData: SignatureData = useLoadSignatureData();

    useEffect(() => {
        (async () => {
            setLoading(true);

            if (currentSignatureData) {
                const signatureData = currentSignatureData;
                const data = {};

                if (signature_id) {
                    const template = await Api.getTemplate({ template_id: signature_id });
                    if (template) {
                        data.name = template.name;
                        if (template.signatureData) {
                            const savedSignatureDataObject = new SignatureData();
                            savedSignatureDataObject.setData(JSON.parse(template.signatureData));
                            signatureData.setLayoutData(savedSignatureDataObject);
                            signatureData.setCompany(company);
                        }
                    }
                }
                setData(data);
                setSignatureData(signatureData);
                setLoading(false);
            }

        })()
    }, [ currentSignatureData, signature_id, company ])

    const save = async () => {

        const organisation_id = company.id;
		const template = {
            ...data,
            signatureData: JSON.stringify(signatureData.getLayoutData())
        }

		if (signature_id) {
            await Api.updateTemplate({ template_id: signature_id, template })
		} else {
            if (selectedTeam) {
				await Api.createTeamSignature({ team_id: selectedTeam.id, template });
			} else {
				await Api.createTemplate({ organisation_id, template });
			}
		}
	}

    const afterSave = () => {
		navigate('/signatures');
	}

    const onClickSave = async () => {

		if (!formRef.current || formRef.current.checkAllErrors()) {
			return false;
		}

        setSaving(true);
		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await save();

			closeSnackbar(key);
			enqueueSnackbar('Saved', {variant: 'success'});

			setSaving(false)

			afterSave();

		} catch (e) {
			closeSnackbar(key);
			enqueueSnackbar('Error', {variant: 'error'});
			setSaving(false)
		}
	}

    return (

        <>

            <FlexRowSpaceBetween>
                <Title>
                    {!signature_id ? "Créer une signature" : (data.name)}
                </Title>

                <div>
                    <Button
                        disabled={loading || saving || hasError}
                        onClick={onClickSave}
                    >{"Enregistrer"}</Button>
                </div>
            </FlexRowSpaceBetween>

			<Divider />

            {loading && <ActivityIndicator />}

            {!loading && signatureData && data &&
                <>

                    <div className={css.formContainer}>
                        <Form
                            ref={formRef}
                            config={{
                                attributes: {
                                    name: { type: 'text', validators: ['not_empty'], disabled: loading || saving},
                                }
                            }}
                            values={data}
                            onChange={({values: data, hasError}) => {setData(data); setHasError(hasError)}}
                            className={css.form}
                        />
                    </div>

                    <SignatureLayout
                        onlyStyle
                        data={signatureData}
                        onChange={signatureData => {
                            setSignatureData(signatureData.clone())
                        }}
                    />
                </>
            }

        </>
    )
}