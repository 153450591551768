import React from 'react';
import classnames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Equipement } from "@nopanoga/models"

import TagComponent from 'Apps/Nopanoga/Components/Tag'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

type Props = {
    style?: any,
    className?: string,
    disabled?: boolean,

    onChange?: (selectedIds: Array<string>) => any,
    selectedIds?: Array<string>,
}

export default ({
    style = {}, className,

    selectedIds = [],
    onChange = selectedIds => {},

    disabled = false

}: Props) => {
    const classes = useStyles();

    const options = Equipement.getAll().map((tag, index) => (
        { ...tag, value: tag.id }
    ));

    const selectedValues = options.filter(sport => {
        return (selectedIds && selectedIds.indexOf && selectedIds.indexOf(sport.id) !== -1)
    })

    return (
        <div className={classnames(classes.root, className)} style={style}>
            <Autocomplete
                disabled={disabled}
                multiple
                disableCloseOnSelect
                limitTags={10}
                onChange={(e, value) => {
                    onChange(value.map(v => v.id))
                }}
                id="multiple-limit-tags"
                options={options}
                getOptionLabel={(option) => option.label}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return (
                            <TagComponent
                                tag={option}
                                variant="outlined"
                                label={option.label}
                                size="small"
                                {...getTagProps({ index })}
                            />
                        )
                    })
                }}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </React.Fragment>
                )}
                style={{ width: '100%' }}
                getOptionSelected={(option, value) => {
                    return JSON.stringify(option) === JSON.stringify(value);
                }}
                value={(selectedValues || [])}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={"Equipements nécessaires"} placeholder={"Choisissez les équipements nécessaires"} />
                )}
            />
        </div>
    );
}