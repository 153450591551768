import React, { PureComponent } from 'react';
import {useDropzone} from 'react-dropzone';
import Style from './style.module.css';
import classNames from 'classnames';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator'


const DropZone = ({ disabled = false, onClick = () => {}, onDrop = () => {}, message = null, loading = false, children, inputRef = () => {}}) => {

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: acceptedFiles => {
		onDrop(acceptedFiles);
	} });


	return (
		<div
			{...getRootProps({className: classNames(Style.dropzone, {
				[Style.dropzoneDragging]: isDragActive,
				[Style.dropzoneNoPadding]: children !== null
			})})}
			onClick={disabled ? () => {} : onClick}
		>
			<input  {...getInputProps()} disabled={disabled} ref={inputRef} />

			{loading &&
				<div className={Style.loadingContainer}>
					<ActivityIndicator />
				</div>
			}

			{children ? children :
				<div>
					{
						message !== null ?
						<p>{message}</p> :
						<p>Drag 'n' drop some files here, or click to select files</p>
					}
				</div>
			}
		</div>
	)
}


export default class UploadDropZone extends PureComponent {

	state = { imgName: null }

	constructor(props) {
		super(props);
		this.inputFile = null;
	}

	onDrop = acceptedFiles => {

		const { onFileSelected = () => {}, onReadLocalImage = null } = this.props;

		if (acceptedFiles.length === 0) {
			return;
		}

		const file = acceptedFiles[0];

		this.setState({
			imgName: file.name
		})

		onFileSelected(file);

		if (onReadLocalImage) {
			var reader = new FileReader();
			reader.onload = ({ target: { result: imgSrc } }) => {
				onReadLocalImage(imgSrc);
			}

			reader.readAsDataURL(file);
		}
	}

	triggerFileSelection = () => {
		this.inputFile.click();
	}

	render() {

		const { loading, imgSrc, disabled = false, format = { width: 100, height: 100 } } = this.props;

		return (
			<div className={classNames(Style.container, {[Style.containerNoPadding]: imgSrc !== null})} style={{width: format.width + "px", height: format.height + "px"}}>
				<DropZone disabled={disabled} onClick={this.triggerFileSelection} inputRef={ref => {this.inputFile = ref} } loading={loading} onDrop={this.onDrop}>
					{imgSrc &&
						<img id="image_" alt="uploaded" style={{maxWidth:'100%', maxHeight:'100%'}} src={imgSrc} />
					}
				</DropZone>
			</div>
		)
	}


}

