// @flow
import AbstractObject from '../AbstractObject';
import FontStyle, { schemaId as FontStyleSchemaId }  from '../FontStyle';
import SpacingStyle, { schemaId as SpacingStyleSchemaId } from '../SpacingStyle';

export const schemaId = "/Disclaimer";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        key: { type: "text" },
        text: { type: "text" },
        width: { type: "text" },
        fontStyle: { '$ref': FontStyleSchemaId },
        spacingStyle: { '$ref': SpacingStyleSchemaId }
    }
}

const NAME = "Disclaimer";
export default class Disclaimer extends AbstractObject {

    static options = {
        'green': {
            label: "Eco-responsable",
            text: "Pensez environnement ! N’imprimez cet email que si c’est nécessaire."
        },
        'confidential': {
            label: "Confidentiel",
            text: "Le présent message, ainsi que tout fichier qui y est joint, est destiné exclusivement à l’intention de son ou de ses destinataires; il peut contenir des informations confidentielles. Si vous n'êtes pas le destinataire de ce message, nous vous avisons par la présente que tout usage, reproduction ou diffusion de ce message et de tout fichier qui y est joint est strictement interdit. Si ce message vous a été transmis par erreur, veuillez en aviser sans délai l'expéditeur en lui retournant ledit message et ensuite le détruire, ainsi que tout fichier joint sans en conserver de copie. Rien dans cet avis ne peut être interprété comme allant à l’encontre de la Loi sur le droit d’auteur (L.R., 1985, ch. C-42)."
        },
    }

    key: string
    text: string
    width: number
    fontStyle: FontStyle = (new FontStyle()).clone(f => f.fontSize = 8)
    spacingStyle: SpacingStyle = new SpacingStyle()

    isEnabled() {
        return !!this.text;
    }

    getName() {
        return NAME;
    }
}
Disclaimer.register({ name: NAME, schema, __class: Disclaimer });
