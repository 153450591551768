import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useParams } from "react-router-dom";
import { Product, ProductCentreData } from '@izzistock/models';

import useForm from "AppCore/Components/Form/useForm";
import FormField from "AppCore/Components/Form/formField";
import Button from 'AppCore/Components/Button';

import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";

import { useSnackbar } from "notistack";
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";

export default () => {

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const currentCentre = useCurrentCentre();
    const [ loading, setLoading ] = useState(false)
    const [ changed, setChanged ] = useState(false)
    const [ product, setProduct ] = useState(null);
    const [ productCentreData, setProductCentreData ] = useState(null);

    const formConfig = {
        qtyTriggerOrder: {
            label: "Seuil critique en deça duquel une commande est lancée",
            placeholder: "0",
            type: 'text',
            transformers: [ 'int' ],
            validators: ['not_empty']
        },
        qtyMinInStock: {
            label: "Quantité mininum à avoir en stock",
            placeholder: "0",
            type: 'text',
            transformers: [ 'int' ],
            validators: ['not_empty']
        }
    }

    const {
        getFormFieldProps, hasError, checkAllErrors,
        setData: setFormData,
        getData: getFormData,
    } = useForm({ config: formConfig,
        onChange: () => {
            setChanged(true)
        }
    });

    const loadProductCentreData = async () => {
        const productCentreData = await ProductCentreData.get({ productId: id, centreId: currentCentre.id })
        setProductCentreData(productCentreData)
        setProduct(await Product.get({ id }))
        setFormData(productCentreData.export());
    }

    useEffect(() => {
        loadProductCentreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ])

    const onClickCancel = () => {
        setFormData(productCentreData.export());
    };
    const onClickSave = async () => {
        if (hasError() || checkAllErrors()) {
            console.log("HAS ERROR")
            return false;
        }
        setLoading(true);
        try {
            const productCentreData = await ProductCentreData.get({ productId: id, centreId: currentCentre.id })
            productCentreData.setData(getFormData())
            console.log("productCentreData", productCentreData);
            await productCentreData.save();
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setProductCentreData(productCentreData)
        } catch (e) {
            alert(e.displayMessage || "une erreur est survenue")
            console.error(e);
        }
        setLoading(false);
    }

    const updatedAt = product ? moment(product.metadata.updatedAt*1000) : null;

    return (
        <>

            <FlexRowSpaceBetween>
                <Title>
                    {product ? product.name : ""}
                </Title>

                <div>
                    <Button
                        loading={false} disabled={!changed || loading || hasError()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={loading} disabled={!changed || loading || hasError()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>
            </FlexRowSpaceBetween>

            <FlexRowSpaceBetween>
                <div/>
                {!!updatedAt && `Dernière modification: ${updatedAt.calendar()}` }
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FormField {...getFormFieldProps('qtyTriggerOrder')} />
            <FormField {...getFormFieldProps('qtyMinInStock')} />

        </>
    )
};