import React from 'react';
import { useNavigate } from 'react-router-dom'

import { ProductCategory } from '@izzistock/models'
import PageForm from 'AppCore/Components/CommonPages/PageFormWithLoader/index';

export default () => {

    const navigate = useNavigate();

    return (
        <PageForm
            formAttributes={{
                name: { type: 'text', validators: ['not_empty'] }
            }}
            initialData={new ProductCategory()}
            saveData={async productCategory => {
                await productCategory.save();
            }}
            onCreated={productCategory => {
                navigate('/product-categories/' + productCategory.id)
            }}
        />
    )
}