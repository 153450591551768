// @flow
import * as React from 'react';
import {
    View, StyleSheet
} from 'react-native';
import {
    LaunchTrainingSession
} from '@nopanoga/components'

import { TrainingSession } from '@nopanoga/models'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%',
        borderRadius: 8,
        borderWidth: 0,
        overflow: 'hidden'
    },
    listContainer: {
        flex: 1
    }
});

type Props = {
    fullScreen?: boolean,
    onPressClose?: any => any,
    trainingSession: TrainingSession
}
export default ({
    fullScreen = false,
    onPressClose = () => {},
    trainingSession
}: Props) => {

    return (
        <View style={styles.container}>
            <LaunchTrainingSession
                trainingSession={trainingSession}
                onClickClose={() => {
                    onPressClose();
                }}
            />
        </View>
    );
}
