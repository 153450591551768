import delay from 'delay';

export * from './Auth';

export const CounterIncrement = () => (dispatch, getState) => {
    dispatch({
        type: 'counter.increment'
    })
}
export const CounterDecrement = () => (dispatch, getState) => {
    dispatch({
        type: 'counter.decrement'
    })
}

export const CounterIncrementDelayed = () => async (dispatch, getState) => {
    await delay(1000);
    dispatch(CounterIncrement());
}