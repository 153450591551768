import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import { displayMobileMenu } from 'Apps/Signally/Actions';
import Icon from 'Apps/Signally/Components/Icons';
import CoreButton from 'Apps/Signally/Components/Button';

import css from './Header.module.css';

const styles = theme => ({
	button: {
		marginLeft: '10px'
	},

	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},

	headerContainer: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			height: 'auto',
			alignItems: 'flex-start'
		},
	}
})

export const Button = props => <CoreButton {...props} color="grey" />;

export const HeaderActionContainer = ({ children, ...props }) => (
	<div className={css.actionContainer}>
		{children}
	</div>
)

export default connect(
	state => ({
		mobileMenu: state.display.mobileMenu
	}),
	{ displayMobileMenu }
)(withStyles(styles)(({ title, mobileMenu, displayMobileMenu, classes, children, ...props }) => (

	<div className={classNames(css.container, classes.headerContainer)}>

		<div className={css.titleContainer}>

			<IconButton className={classes.menuButton} onClick={() => displayMobileMenu({ display: !mobileMenu })}>
				<Icon name="menu" />
			</IconButton>

			{title && <span className={css.title}>{title}</span>}
		</div>

		{children}
	</div>

)))