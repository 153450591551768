import React from 'react';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import { PageLoader } from '@knxlab/components';
import { TrainingSession } from "@nopanoga/models";

import TrainingSessionEdit from './index'

export default () => {

    return (
        <PageLoader
            loadData={async ({ id }) => {
                return await TrainingSession.get({ id })
            }}
            saveData={async (trainingSession: TrainingSession) => {
                await trainingSession.save();
                return trainingSession;
            }}

            LoadingComponent={() => (
                <ActivityIndicator material />
            )}
            Component={({ data, saveData }) => (
                <TrainingSessionEdit
                    initialTrainingSession={data}
                    saveData={saveData}
                />
            )}
        />
    )
}