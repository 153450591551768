// @flow
import { AbstractObject } from '@knxlab/models';
import CustomField, { schemaId as CustomFieldSchemaId } from './CustomField'
import FontStyle, { schemaId as FontStyleSchemaId }  from './FontStyle';
import Social, { schemaId as SocialSchemaId } from './Social'

// Image
import Image, { schemaId as ImageSchemaId } from './Image'

/// ADDONS
import SignOff, { schemaId as SignOffSchemaId } from './Addons/SignOff'
import Disclaimer, { schemaId as DisclaimerSchemaId } from './Addons/Disclaimer'
import GreenMessage, { schemaId as GreenMessageSchemaId } from './Addons/GreenMessage'
import VideoConf, { schemaId as VideoConfSchemaId } from './Addons/VideoConf'
import User from 'Apps/Signally/Models/User';


export const schema = {
    type: "object",
    properties : {
        userName: { type: "string" },
        firstName: { type: "string" },
        lastName: { type: "string" },
        companyName: { type: "string" },
        positionInCompany: { type: "string" },
        teamName: { type: "string" },
        phone: { type: "string" },
        mobile: { type: "string" },
        skype: { type: "string" },
        email: { type: "string" },
        address: { type: "string" },
        companyWebsite: { type: "string" },

        layoutKey: { type: "string" },

        customFields: {
            type: "array",
            items : { "$ref": CustomFieldSchemaId }
        },

        logo: { "$ref": ImageSchemaId },
        banner: { "$ref": ImageSchemaId },

        fontStyle: { "$ref": FontStyleSchemaId },
        social: { "$ref": SocialSchemaId },

        signoff: { "$ref": SignOffSchemaId },

        addons: {
            type: "object",
            properties: {
                disclaimer: {
                    type: "object",
                    properties: {
                        enabled: { type: 'boolean' },
                        order: { type: 'integer' },
                        data: { "$ref": DisclaimerSchemaId }
                    }
                },
                greenMessage: {
                    type: "object",
                    properties: {
                        enabled: { type: 'boolean' },
                        order: { type: 'integer' },
                        data: { "$ref": GreenMessageSchemaId }
                    }
                },
                videoConf: {
                    type: "object",
                    properties: {
                        enabled: { type: 'boolean' },
                        order: { type: 'integer' },
                        data: { "$ref": VideoConfSchemaId }
                    }
                }
            }
        }

    }
}


const defaultAddonsList = {
    disclaimer: {
        enabled: false,
        order: 0,
        data: new Disclaimer()
    },
    greenMessage: {
        enabled: false,
        order: 0,
        data: new GreenMessage()
    },
    videoConf: {
        enabled: false,
        order: 0,
        data: new VideoConf()
    }
}

type Addon = {
    enabled: boolean,
    order: number
}
const NAME = "SignatureData";
export default class SignatureData extends AbstractObject {

    static addonsOptions = {
        disclaimer: { label: 'Disclaimer' },
        greenMessage: { label: 'Message eco-responsable' },
        videoConf: { label: 'Video conférence' }
    }

    layoutKey: string = "layout1"

    userName: string
    firstName: string
    lastName: string
    companyName: string
    positionInCompany: string
    teamName: string
    phone: string
    mobile: string
    skype: string
    email: string
    address: string

    companyWebsite: string

    customFields: Array<CustomField>

    logo: Image = (new Image()).clone(i => i.urlEditable = false)
    banner: Image = new Image()

    fontStyle: FontStyle = new FontStyle()
    social: Social = new Social()

    signoff: SignOff = new SignOff()

    addons: {
        disclaimer: Addon & { data: Disclaimer },
        videoConf: Addon & { data: VideoConf },
        greenMessage: Addon & { data: GreenMessage }
    } = defaultAddonsList

    isInfosEmpty() {
        return (
            !this.firstName &&
            !this.lastName &&
            !this.companyName &&
            !this.positionInCompany &&
            !this.teamName &&
            !this.phone &&
            !this.mobile &&
            !this.email &&
            !this.address &&
            !this.companyWebsite
        )
    }

    getDisplayName() {
        if (!this.firstName && !this.lastName) {
            return "";
        }
        return `${this.firstName || ""} ${this.lastName || ""}`
    }

    getText(key: string, titleType: 'short' | 'long' ) {
        if (!this[key]) {
            return null;
        }

        switch (key) {
            case 'phone':
                return {
                    text: this.phone,
                    link: 'tel:' + this.phone,
                    title: titleType ? ({short: 't', long: 'tel'})[titleType] : null
                };
            default:
                return { text: this[key] };
        }
    }

    setUser(user: User) {
        this.email = user.email
        this.userName = user.getDisplayName();
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.positionInCompany = user.position;
        this.phone = user.phone_number
    }
    setTeam(team: Object) {
        if (team && team.name) {
            this.teamName = team.name;
        }
    }
    setCompany(company: Object) {
        this.companyName = company.name;
        this.address = company.address;

        if (company.website_url) {
            this.companyWebsite = company.website_url;
        }

        if (company.logo && company.logo.path) {
            this.logo.media = {
                src: company.logo.path
            }
            if (this.companyWebsite) {
                this.logo.url = this.companyWebsite;
                this.logo.urlEditable = false;
            }
        }

        ['facebook', 'instagram', 'linkedin', 'twitter'].forEach(iconName => {
            if (company[iconName]) {
                this.social.setIcon(iconName, company[iconName], false)
            }
        })
    }

    getCompany() {
        const company = {};
        if (this.companyName) {
            company.name = this.companyName;
        }
        if (this.address) {
            company.address = this.address;
        }
        if (this.companyWebsite) {
            company.website_url = this.companyWebsite;
        }

        if (this.logo.mediaId) {
            company.logo_id = this.logo.mediaId
        }

        ['facebook', 'instagram', 'linkedin', 'twitter'].forEach(iconName => {
            const socialIcon = this.social.getIcon(iconName);
            if (socialIcon) {
                company[iconName] = socialIcon.url
            }
        })

        return company;
    }

    setLayoutData(data: SignatureData) {
        this.customFields = data.customFields;
        this.addons = data.addons;
        this.fontStyle = data.fontStyle
        this.layoutKey = data.layoutKey;
        this.signoff = data.signoff;
        this.social = data.social;

        this.logo.setData(data.logo);
        this.banner.setData(data.banner);
    }

    getLayoutData(): Object {
        return {
            layoutKey: this.layoutKey,
            fontStyle: this.fontStyle.export(),
            customFields: (this.customFields || []).map((customField: CustomField) => (
                customField.export()
            )),

            social: this.social.export(),

            signoff: this.signoff.export(),
            addons: {
                disclaimer: {
                    ...this.addons.disclaimer,
                    data: this.addons.disclaimer.data.export()
                },
                videoConf: {
                    ...this.addons.videoConf,
                    data: this.addons.videoConf.data.export()
                },
                greenMessage: {
                    ...this.addons.greenMessage,
                    data: this.addons.greenMessage.data.export()
                },
            },

            logo: this.logo.getLayoutData(),
            banner: this.banner.getLayoutData()
        }
    }

    setData(data: Object) {
        super.setData(data);
        this.addons = {
            ...defaultAddonsList,
            ...this.addons
        }
    }

    getOrderedEnabledAddons(): Array<Addon & { data: Disclaimer | VideoConf | GreenMessage, key: string }> {
        return Object.keys(this.addons).filter(key => this.addons[key].enabled).sort((addon1Key, addon2Key) => {
            return this.addons[addon1Key].order > this.addons[addon2Key].order ? 1 : -1
        }).map(key => {
            return ({...this.addons[key], key})
        })
    }

    getAvailableAddons(): Array<Addon & { data: Disclaimer | VideoConf | GreenMessage, key: string }> {
        return Object.keys(this.addons).filter(key => !this.addons[key].enabled).map(key => ({...this.addons[key], key}))
    }

    setNewAddonOrders(newOrder: Array<number>) {
        Object.keys(this.addons).forEach(key => {
            if (!this.addons[key].enabled) {
                return;
            }
            const newIndex = newOrder.indexOf(this.addons[key].order)
            this.addons[key].order = newIndex;
        })
    }

    cleanAddonsOrder() {
        let index = 0;
        Object.keys(this.addons).map(key => {
            if (!this.addons[key].enabled) {
                this.addons[key].order = -1;
            }
            return key;
        }).sort((addon1Key, addon2Key) => {
            return this.addons[addon1Key].order > this.addons[addon2Key].order ? 1 : -1
        }).forEach(key => {
            if (this.addons[key].enabled) {
                this.addons[key].order = index;
                index += 1;
            }
        })
    }

    getName() {
        return NAME;
    }

    hasLogo() {
        return !!(this.logo && this.logo.media && this.logo.media.src)
    }
    hasBanner() {
        return !!(this.banner && this.banner.media && this.banner.media.src)
    }

    hasSocialIcons() {
        return this.social.socialIcons.length > 0;
    }

    hasAddon(type: 'disclaimer' | 'videoConf' | 'greenMessage') {
        return this.addons[type].enabled && this.addons[type].data.isEnabled()
    }
}
SignatureData.register({ name: NAME, schema, __class: SignatureData });