import { useSelector } from 'react-redux';
import { createSelector } from 'reselect'

import { User } from '@nopanoga/models'

const selectCurrentUser = createSelector(
    ({ currentUserData, Auth }) => ({
        currentUserData,
        userAuthData: (Auth && Auth.user) || {}
    }),
    ({ currentUserData, userAuthData }) => {
        const user = new User();
        user.id = userAuthData.uid;
        user.setData(userAuthData || {});
        user.setData(currentUserData || {});
        return user;
    }
)

export default () => {
    return useSelector(selectCurrentUser);
}