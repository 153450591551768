import { useSelector } from 'react-redux';
import { createSelector } from 'reselect'

import User from '../Models/User'

const selectCurrentUser = createSelector(
    state => state.current_user,
    current_user => {
        const user = new User();
        user.setData(current_user);
        // @TODO fix
        user.email = current_user.email;
        return user;
    }
)

export default () => {
    return useSelector(selectCurrentUser);
}