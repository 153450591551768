import React, { PureComponent } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import uniqid from 'uniqid';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';

import DataTable from 'AppCore/Components/DataTable';

import Api from 'Apps/Signally/Api';
import { createTeam, createUser, displayError } from 'Apps/Signally/Actions';

import Modal, { ModalContent } from 'AppCore/Components/Modal';
import Button from '@material-ui/core/Button';
import { ActivityIndicator } from 'Apps/Signally/Pages/Layout';

import UploadDropZone from 'Apps/Signally/Components/UploadDropZone'


const styles = theme => ({

	textField: {
		// width: '100%',
		width: '150px',
	}
});


class ImportUserModal extends PureComponent {

	static defaultProps = {
		onClose: () => {}
	}

	state = {
		name: "",
		success: false,
		// importedUsers: JSON.parse('[{"email":"dq@shakishaki.com","phone_number":"33616411513","first_name":"David","last_name":"Quenet","position":"CTO","__meta":{"type":"user"},"id":"jyebiamn"},{"email":"dm@shakishaki.com","phone_number":"33616411513","first_name":"Daniel","last_name":"Marhely","position":"CPO","__meta":{"type":"user"},"id":"jyebiamo"},{"email":"ac@shakishaki.com","phone_number":"33616411513","first_name":"Alexandre","last_name":"Croiseaux","position":"CEO","__meta":{"type":"user"},"id":"jyebiamp"},{"email":"elena@shakishaki.com","phone_number":"33616411513","first_name":"Elena","last_name":"Vouge","position":"CMO","__meta":{"type":"user"},"id":"jyebiamq"},{"email":"owen@shakishaki.com","phone_number":"33616411513","first_name":"Owen","last_name":"Marlaud","position":"CHEF","__meta":{"type":"user"},"id":"jyebiamr"}]')
	}

	processUploadedFile = async file => {

		const { organisation: { id: organisation_id} } = this.props;

		this.setState({ loading: true });

		const importedUsers = await Api.importCsvUser({ file, organisation_id });

		this.setState({ importedUsers: importedUsers.map(user => {user.id = uniqid(); return user;}), loading: false });
	}

	onChangeUser = newUser => {
		this.setStateUserImported(newUser);
	}

	setStateUserImported = newUser => {
		var newImportedUsers = [];
		this.state.importedUsers.forEach(user => {
			if (user.id === newUser.id) {
				newImportedUsers.push(newUser);
			} else {
				newImportedUsers.push(user);
			}
		})

		this.setState({
			importedUsers: newImportedUsers
		})
	}

	deleteUserIdFromImported = id => {
		var newImportedUsers = [];
		this.state.importedUsers.forEach(user => {
			if (user.id !== id) {
				newImportedUsers.push(user);
			}
		})

		this.setState({
			importedUsers: newImportedUsers
		})
	}

	doImportFile = async () => {

		const { organisation: { id: organisation_id }, displayError } = this.props;

		this.setState({ loading: true });

		const users = [...this.state.importedUsers];

		for (var i =  0; i < users.length; i++) {
			const user = users[i];

			this.setStateUserImported({
				...user,
				payload: {
					loading: true
				}
			});

			try {
				await this.props.createUser({ organisation_id, user });
			} catch (e) {

				e.message = "Ligne " + (i+1) + ", " + user.email + " : " + e.message;

				displayError({ error: e })

				this.setStateUserImported({
					...user,
					payload: {
						loading: false
					}
				});

				this.setState({ loading: false });
				return false;
			}

			this.deleteUserIdFromImported(user.id);
		}

		this.setState({ loading: false, success: true });
	}

	onClickDelete = async (user_id, user) => {
		this.deleteUserIdFromImported(user_id);
	}

	render() {

		const { onClose = () => {} } = this.props;
		const { loading = false } = this.state;

		return (

			<Modal
				open
				onClose={onClose}
				title="Import users"
				actions={
					this.state.success ? [
						<Button key="close" variant="text" color="primary" onClick={onClose}>
							Close
						</Button>
					] :
					[
						<Button key="cancel" disabled={this.state.loading} variant="text" color="primary" onClick={onClose}>
							Cancel
						</Button>,
						<Button key="import" variant="contained" color="primary" onClick={e => this.doImportFile()}>
							{loading ? <ActivityIndicator/> : "Import"}
						</Button>
					]
				}
			>
				<ModalContent>
					{!this.state.importedUsers &&
						<UploadDropZone
							ref={ref => this.uploader = ref}
							loading={loading}
							disabled={loading}
							onFileSelected={this.processUploadedFile}
							format={{ width: 300, height: 100 }}
						/>
					}

					{this.state.success && <div>All user imported</div>}

					{!this.state.success && this.state.importedUsers && (
						<DataTable
							data={this.state.importedUsers}
							config={{
								cells: [
									{ title: 'Email', key: 'email' },
									{ title: 'Phone', key: 'phone_number' },
									{ title: 'First Name', key: 'first_name' },
									{ title: 'Last Name', key: 'last_name' },
									{ title: 'Position', key: 'position' },
									{ title: "", key: 'loading' },
								],
								actions: [
									{ icon_name: 'delete', title: "Delete", onClick: this.onClickDelete }
								],
								actionsFirst: true
							}}
							getCell={(user, attr_name) => {

								if (attr_name === 'loading') {
									if (user.payload && user.payload.loading) {
										return <ActivityIndicator />
									} else {
										return null;
									}

								}

								const { classes } = this.props;

								return (
									<TextField
										variant="outlined"
										disabled={this.state.loading}
										className={classNames(classes.textField)}
										value={user[attr_name]}
										onChange={({target: { value }}) => {
											this.onChangeUser({
												...user,
												[attr_name]: value
											});
										}}

									/>
								);
							}}
						/>
					)}
				</ModalContent>
			</Modal>

		)
	}
}


export default connect(
	state => ({ organisation: state.organisation }),
	{ createTeam, createUser, displayError }
)(withStyles(styles)(ImportUserModal));