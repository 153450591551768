export default {

    title: "Signally",
    defaultTitle: "Signally - L'outils magique pour la gestion des signatures d'email",

    auth: {
        provider: 'custom'
    },

    reduxPersistConfig: {
        whitelist: [
            "Auth",
            "context",
            "onboarding", "user", "other_accounts", "Auth", "current_user", "organisation", "teams", "users", "templates", "campaigns"
        ]
    }
}