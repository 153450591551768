import React, { useState } from 'react';
import classnames from 'classnames'
import styled from 'styled-components'
import { Grid, Paper, IconButton } from '@material-ui/core';
import { CheckCircle, PlayArrow } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: '100%',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },

    checkedIcon: {
        position: 'absolute',
        top: -10, right: -10
    },
    playButton: {
        fontSize: '30px'
    }
});

const Img = styled.img`
    width: 100%;
    border-radius: 4px;
`

const Video = styled.video`
    width: 100%;
    border-radius: 4px;
`

const PlayContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; left :0;
    display: none;
    justify-content: center;
    align-items: center;
`

export default ({ video, isSelected = true, onClick = () => {} }) => {

    const classes = useStyles();

    const [ elevation, setElevation ] = useState(2)
    const [ mouseOver, setMouseOver ] = useState(false)
    const [ playVideo, setPlayVideo ] = useState(false);

    const onClickPlay = e => {
        e.stopPropagation();
        setPlayVideo(true)
    }

    return (
        <Grid item xs={2}>

            <Paper
                className={classnames(classes.root)}
                onMouseOver={() => {
                    setElevation(6)
                    setMouseOver(true)
                }}
                onMouseOut={() => {
                    setElevation(2)
                    setMouseOver(false)
                }}
                elevation={isSelected ? 6 : elevation}
                onClick={onClick}
            >
                {!playVideo && <PlayContainer style={mouseOver ? {display: 'flex'} : {}}>
                    <IconButton className={classes.playButton} color="primary" onClick={onClickPlay}>
                        <PlayArrow color="primary" style={{ fontSize: 80, color: "white" }}/>
                    </IconButton>
                </PlayContainer>}

                {isSelected &&
                    <CheckCircle color="primary" className={classes.checkedIcon} />
                }

                {!playVideo && <Img alt="thumbnail" width='100%' src={video.thumbnail_url} title="video" />}

                {playVideo &&
                    <Video
                        autoPlay={true}
                        controls={true}
                        loop
                        muted
                        alt="uploaded"
                        src={video.media_url}
                    />
                }

            </Paper>
        </Grid>
    )
}