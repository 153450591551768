import React, { useState } from "react";
import { useSnackbar } from 'notistack';
import Modal, { ModalContent } from 'AppCore/Components/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { ActivityIndicator } from 'Apps/Signally/Pages/Layout';
import Api from 'Apps/Signally/Api'
import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";


export default ({ template, onClose = () => {}, onSaved = () => {} }) => {

	const [ name, setName ] = useState("Copy of " + template.name)
	const company = useCurrentCompany();
	const [ loading, setLoading ] = useState(false)
	const { enqueueSnackbar } = useSnackbar();

	const saveCopyTemplate = async name => {

		const { id, ...template_origin } = template;

		const newTemplate = {
			...template_origin,
			name
		};

		setLoading(true);
		if (template.team_id) {
			await Api.createTeamSignature({ team_id: template.team_id, template: newTemplate });
		} else {
			await Api.createTemplate({ organisation_id: company.id, template: newTemplate })
		}
		await onSaved();
		setLoading(false);
		enqueueSnackbar('Copié', {variant: 'success'});
		onClose();
	}

	return (
		<Modal
			open
			onClose={onClose}
			title={"Copy " + template.name}
			actions={[
				<Button key="cancel" variant="text" color="primary" onClick={onClose}>
					Cancel
				</Button>,
				<Button key="save" variant="contained" color="primary" onClick={e => saveCopyTemplate(name)}>
					{loading ? <ActivityIndicator/> : "Save"}
				</Button>
			]}
		>
			<ModalContent>
				<TextField
					id="outlined-with-placeholder"
					label="Name of the new template"
					placeholder="Name of the new template"
					margin="normal"
					variant="outlined"
					value={name}
					style={{width: '30em'}}
					onChange={ ({target: {value: name}}) => setName(name)}
				/>
			</ModalContent>
		</Modal>
	)
}