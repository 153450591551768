// @flow
import React from "react";
import styled from 'styled-components'

import ColorPicker from 'AppCore/Components/ColorPicker';

import ButtonStyle from '../../DataObject/ButtonStyle';

import {
    LineContainer, InputLabel, InputContainer,
    Slider
} from './Helpers'

const Container = styled.div`
    width: 100%;
`

export default ({ buttonStyle, disableComponents = {} , onChange }: { buttonStyle: ButtonStyle, disableComponents?: Object, onChange: (buttonStyle: ButtonStyle) => any}) => {


    return (
        <Container>
            <Slider
                label={"Forme arrondie"}
                value={buttonStyle.radius || 8}
                min={8}
                max={30}
                step={1}
                onChange={value => onChange(buttonStyle.clone(s => s.radius = value))}
            />

            <Slider
                label={"Hauteur"}
                value={buttonStyle.height}
                min={20}
                max={60}
                step={1}
                onChange={value => onChange(buttonStyle.clone(s => s.height = value))}
            />

            {!disableComponents.backgroundColor &&
            <LineContainer>
                <InputLabel>Couleur de fond</InputLabel>
                <InputContainer>
                    <ColorPicker
                        style={{width: '30px', height: '30px'}}
                        color={buttonStyle.backgroundColor}
                        onChange={value => onChange(buttonStyle.clone(s => s.backgroundColor = value))}
                    />
                </InputContainer>
            </LineContainer>
            }

            {!disableComponents.color &&
            <LineContainer>
                <InputLabel>Couleur du texte</InputLabel>
                <InputContainer>
                    <ColorPicker
                        style={{width: '30px', height: '30px'}}
                        color={buttonStyle.color}
                        onChange={value => onChange(buttonStyle.clone(s => s.color = value))}
                    />
                </InputContainer>
            </LineContainer>
            }

        </Container>
    )
}