import React, { useRef, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
import Style from './styles.module.css';
import classNames from 'classnames';


export default React.forwardRef(
    ({
        style = {}, className = null, onDrop = () => {}, disabled = false,
        children = null,
        ...inputProps
    }, ref) => {

        const inputRef = useRef(null);

        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: acceptedFiles => {
            onDrop(acceptedFiles);
        } });

        const onClick = () => {
            inputRef.current.click();
        }

        useImperativeHandle(ref, () => ({
            triggerFileSelection: onClick
        }))

        return (
            <div
                {...getRootProps({
                    className: classNames(Style.dropzone, {
                        [Style.dropzoneDragging]: isDragActive,
                        [Style.dropzoneNoPadding]: children !== null
                    }, className)
                })}
                onClick={disabled ? () => {} : onClick}
                style={style}
            >
                <input
                    hidden {...getInputProps()}
                    ref={inputRef}
                    disabled={disabled}
                    {...inputProps}
                />

                {children}
            </div>
        )
    }
)