// @flow
import { useEffect, useState } from 'react';
import Api from 'Apps/Signally/Api';

import useCurrentCompany from './useCurrentCompany'
import useTeamContext from './useTeamContext';

export default ({ user_id = 'me', team_id }: { user_id?: string, team_id: string } = {}) => {

    const [ loading, setLoading ] = useState(true);
    const [ signatures, setSignatures ] = useState([]);
	const [ selectedTeam ] = useTeamContext();
	const company = useCurrentCompany();
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;

    const loadSignatures = async () => {
        setLoading(true);
		let result;
		if (selectedTeamId) {
			result = await Api.getTeamSignatures({ team_id: selectedTeamId })
		} else {
			result = await Api.getTemplates({ organisation_id: company.id });
		}
		setSignatures(result.data);
        setLoading(false);
	}

    useEffect(() => {
		loadSignatures();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedTeamId ])


    return [ signatures, loadSignatures, loading ];
}