import React, { useEffect, useState } from "react";
import _get from 'lodash.get';

import DataTable from 'AppCore/Components/DataTable';

import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";
import Api from "Apps/Signally/Api";
import User from "Apps/Signally/Models/User";

import DeployUserBtn from './DeployUserBtn'


export default () => {

	const [ users, setUsers ] = useState([]);
	const company = useCurrentCompany();
	const [ selectedTeam ] = useTeamContext();
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;

	const loadUsers = async () => {
		let result;
		if (selectedTeamId) {
			result = await Api.getTeamMembers({ team_id: selectedTeamId })
		} else {
			result = await Api.getOrganisationUsers({ organisation_id: company.id })
		}
		setUsers(result.data);
	}

	useEffect(() => {
		loadUsers();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedTeamId ])

	return (
		<>

			<DataTable
				data={users}
				config={{
					cells: [
						{ title: 'Email', key: 'email' },
						{ title: 'Nom', key: 'display_name' },
						{ title: 'Signature', key: 'signature' },
						...(selectedTeamId ? [] : [
                            { title: 'Team', key: 'team.name' },
                        ]),
                        { title: 'Deploy', key:'deploy_action' },
                        { title: 'Lien d\'installation', key:'installation_link' }
					]
				}}
				getCell={(user, attr_name) => {

					const userObj = new User()
					userObj.setData(user);

					if (attr_name === "display_name") {
						return userObj.getDisplayName();
					}

                    if (attr_name === 'deploy_action') {
                        return <DeployUserBtn user={user} onDeployed={loadUsers} />
                    }

					if (attr_name === 'signature') {
						return user.signature ? user.signature.name : "";
					}

					if (attr_name === 'installation_link') {
						const {
							protocol,
							host
						} = window.location;
						return user.signature ? (
							<a
								href={`${protocol}//${host}/user/${user.id}/install-email?signatureversion=${user.signature.version}`}
								target="_blank" rel="noopener noreferrer"
							>Page d'installation</a>
						) : ''
					}

					return _get(user, attr_name) || '';
				}}
			/>

		</>
	);
}