import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion as MuiAccordion, AccordionDetails, AccordionSummary,
    Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Accordion = styled(MuiAccordion)`
  &::before {
    background-color: transparent;
  }
`

export default ({ title, icon, children, ...props }) => {
  const classes = useStyles();

  return (
      <Accordion {...props} square={false}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
        >
          {icon} <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
  );
}

