import React from "react";
import { Link } from "react-router-dom";

export default () => (
    <nav>
        <Link to="/home">Tableau de bord</Link>
        <Link to="/restaurants">Exercices</Link>
        <Link to="/restaurants">Séances</Link>
        <Link to="/restaurants">Programmes</Link>
        <Link to="/restaurants">TEST</Link>
    </nav>
)