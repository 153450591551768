// @flow
import React, { useState } from 'react';
import styled from 'styled-components'

import {
    Divider as MuiDivider,
    Select, MenuItem
} from '@material-ui/core';

import TextField from 'Apps/Signally/Components/InputFields/TextField'

import FontStyleComponent from '../../Utils/FontStyle'
import SpaceStyleComponent from '../../Utils/SpaceStyle'
import {
    Slider, CheckBox,
    LineContainer, InputLabel, InputContainer
} from '../../Utils/Helpers'

import DisclaimerData from '../../../DataObject/Addons/Disclaimer';


const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`
const Container = styled.div`
    width: 100%;
`

const DisclaimerTexts = DisclaimerData.options

export default ({ disclaimerData, onChange }: { disclaimerData: DisclaimerData, onChange: (data: DisclaimerData) => any }) => {

    const [ selectedOption, setSelectedOption ] = useState(disclaimerData.key || 'custom');
    const [ customText, setCustomText ] = useState(disclaimerData.key === 'custom' ? disclaimerData.text : "");

    return (
        <Container>

            <LineContainer>
                <InputLabel>Type</InputLabel>
                <InputContainer>
                    <Select
                        style={{width: '100%'}}
                        value={selectedOption}
                        onChange={({ target: { value: key }}) => {
                            setSelectedOption(key)
                            onChange(disclaimerData.clone(s => {
                                s.text = key === 'custom' ? customText : DisclaimerTexts[key].text
                                s.key = key;
                            }))
                        }}
                    >
                        {Object.keys(DisclaimerTexts).map(disclaimerTextKey => (
                            <MenuItem key={disclaimerTextKey} value={disclaimerTextKey}>{DisclaimerTexts[disclaimerTextKey].label}</MenuItem>
                        ))}
                        <MenuItem value={"custom"}>{"Custom"}</MenuItem>
                    </Select>
                </InputContainer>
            </LineContainer>

            <TextField
                disabled={selectedOption !== 'custom'}
                value={disclaimerData.text}
                onChange={e => {
                    const text = e.target.value;
                    setCustomText(text)
                    onChange(disclaimerData.clone(s => s.text = text))
                }}
                style={{marginBottom: '10px', width: '100%'}}
                multiline
                rows={10}
            />


            <Divider />

            <CheckBox
                label={"Largeur auto"}
                value={!disclaimerData.width}
                onChange={checked => {
                    let width = 200;
                    if (checked) {
                        width = null;
                    }
                    onChange(disclaimerData.clone(d => d.width = width))
                }}
            />
            {disclaimerData.width && <Slider
                label={"Taille"}
                min={200}
                max={800}
                step={20}
                value={disclaimerData.width || 200}
                onChange={value => onChange(disclaimerData.clone(d => d.width = value))}
            />}

            <SpaceStyleComponent style={disclaimerData.spacingStyle} onChange={spacingStyle => {
                onChange(disclaimerData.clone(s => {
                    s.spacingStyle = spacingStyle
                }))
            }}/>
            <FontStyleComponent fontStyle={disclaimerData.fontStyle} onChange={fontStyle => {

                onChange(disclaimerData.clone(s => {
                    s.fontStyle = fontStyle
                }))
            }}/>
        </Container>
    )
}