// @flow
import React from 'react';
import styled from 'styled-components'

import {
    Divider as MuiDivider
} from '@material-ui/core';

import ButtonSelector from 'AppCore/Components/ButtonSelector';
import TextField from 'Apps/Signally/Components/InputFields/TextField'

import ButtonStyleComponent from '../../Utils/ButtonStyle'
import SpaceStyleComponent from '../../Utils/SpaceStyle'
import {
    Slider, CheckBox,
    LineContainer, InputLabel, InputContainer
} from '../../Utils/Helpers'

import VideoConfData from '../../../DataObject/Addons/VideoConf';


const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`
const Container = styled.div`
    width: 100%;
`

const VideoConfDataOptions = Object.keys(VideoConfData.options).map(key => ({
    label: VideoConfData.options[key].optionLabel,
    key
}))
export default ({ videoConfData, onChange }: { videoConfData: VideoConfData, onChange: (data: VideoConfData) => any }) => {

    const videoConfDataOptionSelected = VideoConfData.options[videoConfData.key];

    return (
        <Container>

            <ButtonSelector
                options={VideoConfDataOptions}
                selected={videoConfData.key}
                onChange={key => {
                    onChange(videoConfData.clone(s => {
                        const videoConfDataOptionSelected = VideoConfData.options[key]
                        s.key = key
                        s.buttonText = videoConfDataOptionSelected.defaultButtonText;
                    }))
                }}
                btnStyle={{minWidth: '130px'}}
            />

            <LineContainer>
                <InputLabel>Texte du bouton</InputLabel>
                <InputContainer>
                    <TextField
                        value={videoConfData.buttonText || videoConfDataOptionSelected.defaultButtonText}
                        onChange={e => {
                            const text = e.target.value;
                            onChange(videoConfData.clone(s => s.buttonText = text))
                        }}
                        style={{marginBottom: '10px', width: '100%'}}
                    />
                </InputContainer>
            </LineContainer>

            <LineContainer>
                <InputLabel>Url du bouton</InputLabel>
                <InputContainer>
                    <TextField
                        value={videoConfData.url}
                        onChange={e => {
                            const text = e.target.value;
                            onChange(videoConfData.clone(s => s.url = text))
                        }}
                        style={{marginBottom: '10px', width: '100%'}}
                    />
                </InputContainer>
            </LineContainer>


            <Divider />

            <CheckBox
                label={"Couleurs auto"}
                value={videoConfData.colorsAuto}
                onChange={checked => {
                    if (checked) {
                        onChange(videoConfData.clone(d => {
                            d.colorsAuto = true;
                            d.buttonStyle.color = VideoConfData.options[d.key].color;
                            d.buttonStyle.backgroundColor = VideoConfData.options[d.key].backgroundColor;
                        }))
                    } else {
                        onChange(videoConfData.clone(d => {
                            d.colorsAuto = false;
                        }))
                    }

                }}
            />
            {videoConfData.width && <Slider
                label={"Taille"}
                min={200}
                max={800}
                step={20}
                value={videoConfData.width || 200}
                onChange={value => onChange(videoConfData.clone(d => d.width = value))}
            />}

            <SpaceStyleComponent style={videoConfData.spacingStyle} onChange={spacingStyle => {
                onChange(videoConfData.clone(s => {
                    s.spacingStyle = spacingStyle
                }))
            }}/>

            <ButtonStyleComponent
                buttonStyle={videoConfData.buttonStyle} onChange={buttonStyle => {
                    onChange(videoConfData.clone(s => {
                        s.buttonStyle = buttonStyle
                    }))
                }}
                disableComponents={{
                    color: videoConfData.colorsAuto,
                    backgroundColor: videoConfData.colorsAuto,
                }}
            />
        </Container>
    )
}