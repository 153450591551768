import AbstractObject from './AbstractObject';


const IconList = [
    {"icon":"facebook","src":"036-facebook.png"},
    {"icon":"instagram","src":"029-instagram.png"},
    {"icon":"twitter","src":"008-twitter.png"},
    {"icon":"linkedin","src":"027-linkedin.png"},
    {"icon":"youtube","src":"001-youtube.png"},
    {"icon":"snapchat","src":"014-snapchat.png"},
    {"icon":"google","src":"033-google-plus.png"},
    {"icon":"skype","src":"015-skype.png"},

    {"icon":"yelp","src":"002-yelp.png"},
    {"icon":"wordpress","src":"003-wordpress.png"},{"icon":"wikipedia","src":"004-wikipedia.png"},
    {"icon":"whatsapp","src":"005-whatsapp.png"},{"icon":"vine","src":"006-vine.png"},
    {"icon":"vimeo","src":"007-vimeo.png"},
    {"icon":"tumblr","src":"009-tumblr.png"},{"icon":"trello","src":"010-trello.png"},
    {"icon":"stumbleupon","src":"011-stumbleupon.png"},{"icon":"spotify","src":"012-spotify.png"},
    {"icon":"soundcloud","src":"013-soundcloud.png"},
    {"icon":"shutterstock","src":"016-shutterstock.png"},
    {"icon":"scribd","src":"017-scribd.png"},{"icon":"reddit","src":"018-reddit.png"},
    {"icon":"quora","src":"019-quora.png"},{"icon":"plaxo","src":"020-plaxo.png"},{"icon":"pinterest","src":"021-pinterest.png"},
    {"icon":"periscope","src":"022-periscope.png"},{"icon":"paypal","src":"023-paypal.png"},
    {"icon":"myspace","src":"025-myspace.png"},
    {"icon":"medium","src":"026-medium.png"},
    {"icon":"kickstarter","src":"028-kickstarter.png"},
    {"icon":"html","src":"030-html-5.png"},{"icon":"howcast","src":"031-howcast.png"},{"icon":"hi5","src":"032-hi5.png"},
    {"icon":"foursquare","src":"034-foursquare.png"},
    {"icon":"flickr","src":"035-flickr.png"},{"icon":"etsy","src":"037-etsy.png"},
    {"icon":"envato","src":"038-envato.png"},{"icon":"dropbox","src":"039-dropbox.png"},{"icon":"dribbble","src":"040-dribbble.png"},
    {"icon":"deviantart","src":"041-deviantart.png"},{"icon":"delicious","src":"042-delicious.png"},
    {"icon":"creative","src":"043-creative-market.png"},{"icon":"buffer","src":"044-buffer.png"},
    {"icon":"box","src":"045-box.png"},{"icon":"bing","src":"046-bing.png"},{"icon":"behance","src":"047-behance.png"},
    {"icon":"android","src":"048-android.png"},{"icon":"amazon","src":"049-amazon.png"}

].map(( {icon: iconName, src}, i) => ({
    key: iconName,
    icon: iconName.charAt(0).toUpperCase() + iconName.slice(1),
    src: `/static/img/signally/social/${src}`
}))


export const SocialIconSchemaId = "/SocialIcon";
export const SocialIconSchema = {
    id: SocialIconSchemaId,
    type: "object",
    properties : {
        icon: { type: 'text' },
        src: { type: 'text' },
        url: { type: 'text' },
        editable: { type: 'boolean' }
    }
}
export class SocialIcon extends AbstractObject {

    icon: string
    src: string
    url: string

    editable: boolean = true

    static getList(): Array<SocialIcon> {
        return IconList.map(iconData => {
            const icon = new SocialIcon();
            icon.setData(iconData);
            return icon;
        })
    }

    static getIconByName(iconName: string): SocialIcon {
        const iconData = IconList.find(s => s.key === iconName);
        if (!iconData) {
            return null;
        }

        const socialIcon = new SocialIcon();
        socialIcon.setData(iconData);
        return socialIcon;
    }

    getName() {
        return 'SocialIcon';
    }
}
SocialIcon.register({ name: 'SocialIcon', schema: SocialIconSchema, __class: SocialIcon });



///////////////////////////////////// SOCIAL
export const schemaId = "/Social";
export const SocialSchema = {
    id: schemaId,
    type: "object",
    properties : {
        caption: { type: "string" },

        width: { type: "integer" },
        radius: { type: "integer" },

        socialIcons: {
            type: "array",
            items : { "$ref": SocialIconSchemaId }
        }
    }
}
const NAME = "Social";
export default class Social extends AbstractObject {

    static maxWidth = 50;

    caption: string
    socialIcons: Array<SocialIcon> = []

    width: number = 25
    radius: number = 3

    addIcon(iconName: string, url: string, editable: boolean = true) {
        const icon = SocialIcon.getIconByName(iconName);
        icon.url = url;
        icon.editable = editable;

        this.socialIcons.push(icon);
    }

    setIcon(iconName: string, url: string, editable: boolean = true) {
        const icon = SocialIcon.getIconByName(iconName);
        icon.url = url;
        icon.editable = editable;

        const indexOf = this.socialIcons.findIndex(social => social.icon.toLowerCase() === iconName.toLowerCase());
        if (indexOf !== -1) {
            this.socialIcons[indexOf].url = url;
            this.socialIcons[indexOf].editable = editable;
        } else {
            this.addIcon(iconName, url, editable);
        }
    }

    getIcon(iconName: string) {
        const indexOf = this.socialIcons.findIndex(social => social.icon.toLowerCase() === iconName.toLowerCase());
        if (indexOf !== -1) {
            return this.socialIcons[indexOf];
        }

        return null;
    }

    getContainerStyle() {
        return ({
            display: 'block',
            width: (this.width + 5) + "px",
            height: this.width + "px",
            paddingRight: "5px"
        });
    }
    getStyle() {
        return ({
            width: this.width + "px",
            borderRadius: this.radius + "px",
        });
    }

    getName() {
        return NAME;
    }
}
Social.register({ name: NAME, schema: SocialSchema, __class: Social });
