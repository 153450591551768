// @flow
import React, { useState } from 'react';
import styled from 'styled-components'

import {
    Divider as MuiDivider,
    FormControlLabel,
    Radio
} from '@material-ui/core';

import TextField from 'Apps/Signally/Components/InputFields/TextField'

import FontStyleComponent from '../../Utils/FontStyle'
import SignOffData from '../../../DataObject/Addons/SignOff';


const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`


const Container = styled.div`
    width: 100%;
`

const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`

export default ({ signOffData, onChange }: { signOffData: SignOffData, onChange: (data: SignOffData) => any }) => {

    const [ selectedOption, setSelectedOption ] = useState(signOffData.key);
    const [ customText, setCustomText ] = useState(signOffData.key === 'custom' ? signOffData.text : "");
    const Options = [
        { label: 'Cordialement,', key: 'cdt'},
        { label: 'Merci,', key: 'thx'},
        { label: 'Bien à vous,', key: 'bav'},
        { label: 'Personnalisé', key: 'custom'},
    ];

    return (
        <Container>

            <RadioContainer>
            {Options.map(option => (
                <FormControlLabel
                    key={option.key}
                    control={
                        <Radio
                            checked={selectedOption === option.key}
                            value={option.key}
                            onChange={({ target: { value }}) => {
                                setSelectedOption(value)
                                onChange(signOffData.clone(s => {
                                    s.text = option.key === 'custom' ? customText : option.label
                                    s.key = option.key;
                                }))
                            }}
                        />
                    }
                    label={option.label}
                />
            ))}
            </RadioContainer>

            <TextField
                disabled={selectedOption !== 'custom'}
                value={customText}
                onChange={e => {
                    const text = e.target.value;
                    setCustomText(text)
                    onChange(signOffData.clone(s => s.text = text))
                }}
                style={{marginBottom: '10px'}}
            />


            <Divider />

            <FontStyleComponent fontStyle={signOffData.fontStyle} onChange={fontStyle => {

                onChange(signOffData.clone(s => {
                    s.fontStyle = fontStyle
                }))
            }}/>
        </Container>
    )
}