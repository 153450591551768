export default {

    title: "NOPANOGA ADMIN",
    defaultTitle: "Nopanoga - ADMIN",

    auth: {
        provider: 'firebase'
    },

    instagram: {
        APP_ID: "974598602969747",
        REDIRECT_URI: window.location.hostname === "localhost" ?
            "https://localhost:3000/instagram-callback" : "https://coach.nopanoga.app/instagram-callback"
    },

    firebaseConfig: {
        apiKey: "AIzaSyACCQqe5i7z9Fr3tDC_YnhqDeMpDO9mWsc",
        authDomain: "vigilant-willow-281113.firebaseapp.com",
        databaseURL: "https://vigilant-willow-281113.firebaseio.com",
        projectId: "vigilant-willow-281113",
        storageBucket: "vigilant-willow-281113.appspot.com",
        messagingSenderId: "254617148666",
        appId: "1:254617148666:web:0192df984fe77a00fb3d43",
        measurementId: "G-31DM3RRVYH"
    },

    amplitudeConfig: {
        apiKey: "2fef992c056f581502877e456f47527b"
    },

    reduxPersistConfig: {
        whitelist: [
            "Auth"
        ]
    }
}