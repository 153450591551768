import React from 'react';



export default ({ ...props }) => (
	<ol>
    <li>Generate an email signature using <b>MySignature</b> editor.</li>
    <li>Click on <b>Copy to clipboard</b> button.</li>
    <li>Go to your <b>Outlook</b> and compose a <b>New Email</b>.</li>
    <li>Go to <b>Message Tab</b> and click on <b>Signature</b> &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; <b>Signatures</b>.</li>
    <li>Click <b>New</b> and give name to your signature.</li>
    <li>Click the &nbsp;<span title="plus icon" className="icon icon-site-plus"></span>&nbsp; icon to add a new signature.</li>
    <li>
      <p>
        <i><b>Mac: </b></i> Paste your signature into the <b>Signature box (Ctrl+V or Command+V)</b>, click on the clipboard that popped-up and select <b>Keep Source Formatting</b> pasting option and press <b>OK</b>.
      </p>
      <p>
        <i><b>Windows: </b></i> Right click the <b>Edit Signature</b> box and choose <b>Keep Source Formatting</b> pasting option and press <b>OK</b>.
      </p>
    </li>
  </ol>
)