import React from 'react';
import { Text } from './Text'

export const TableReset = ({ children, style, ...props }) => (
	<table cellSpacing="0" cellPadding="0" border="0" style={{ borderSpacing: 0, margin:0, padding:0, borderCollapse: 'collapse', ...style}}>{children}</table>
)
export const TableLine = ({ children, ...props }) => (
	<tr {...props}>{children}</tr>
)
export const TableCell = ({ children, ...props }) => (
	<td {...props}>{children}</td>
)

export const Container = ({ inTable, children }) => (
	inTable ? <tr><td>{children}</td></tr> : children
)

export const getFontStyleFromTemplate = ({fontSize, fontColor: color, fontFamily }) => ({
	...(fontSize ? { fontSize } : {}),
	...(color ? { color } : {}),
	...(fontFamily ? { fontFamily } : {}),
})

export * from './Picture'
export * from './Banner'
export * from './UserName'
export * from './Disclaimer'
export * from './GreenMessage'
export * from './DownloadApps'
export * from './Social'
export * from './Text'
export * from './Link'


export const HorizontalLine = props => (
	<tr>
		<td style={{padding: '10px 0 10px 0'}}>
			<table width="100%" cellPadding="0" cellSpacing="0" border="0">
				<tbody>
					<tr>
						<td height="1" style={{
							borderTop: "1px solid #000000",
							fontSize:"1px",
							height:"1px",
							lineHeight:"1px",
							width:"100%"
						}}></td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
)


export const TextArray = ({ textArray = [], ...props }) => {

	textArray = textArray.filter(a => !!a)

	if (textArray.length === 0) {
		return null;
	}

	return (
		<Text {...props}>
			{textArray.join(" | ")}
		</Text>
	)
}



export const CompanyAddress = ({ company = {}, ...props }) => {

	if (!company.address) {
		return null;
	}

	return (
		<Text {...props}>
			{company.address.split('\n').map(t => <span key={t}>{t}<br/></span>)}
		</Text>
	)
}


export const Phone = ({ profile = {}, template = {}, ...props }) => {

	if (!profile.phone_number) {
		return null;
	}

	return (
		<Text template={template} {...props}>
			<a style={{...getFontStyleFromTemplate(template), textDecoration: "none"}} href={"tel:"+profile.phone_number}>{profile.phone_number}</a>
		</Text>
	)
}


export const Email = ({ profile = {}, template = {}, ...props }) => {

	if (!profile.email) {
		return null;
	}

	return (
		<Text template={template} {...props}>
			<a style={{...getFontStyleFromTemplate(template), textDecoration: "none"}} href={"mailto:"+profile.email}>{profile.email}</a>
		</Text>
	)
}