// @flow

import React from 'react';
import SignatureData from '../../DataObject';

import {
    Disclaimer,
    GreenMessage,
    Picture
} from './helpers';

import { VideoConf } from './helpers/VideoConf';


import Layout1, { key as layout1key } from '../Layouts/Layout1';
import Layout2, { key as layout2key } from '../Layouts/Layout2';
import Layout3, { key as layout3key } from '../Layouts/Layout3';
import LayoutArtfizz, { key as layout4key } from '../Layouts/LayoutArtfizz';

export const Layouts = [
    { key: layout1key, Component: Layout1 },
    { key: layout2key, Component: Layout2 },
    { key: layout3key, Component: Layout3 },
    { key: layout4key, Component: LayoutArtfizz }
]

const styles = {
	render: {
        paddingTop: '2em',
        color: '#000'
    }
}

const AddonComponent = ({ type, data }) => {

    switch (type) {
        case 'disclaimer':
            return (
                <Disclaimer data={data} />
            )
        case 'videoConf':
            return (
                <VideoConf data={data} />
            )

        case 'greenMessage':
            return (
                <GreenMessage data={data} />
            )

        default:
            return null;
    }

}

export const Layout = ({ data, preview = false }: { data: SignatureData, preview?: boolean }) => {
    const { Component } = (Layouts.find(l => l.key === (data.layoutKey || 'layout1')) || Layouts[0])

    return (
        <Component preview={preview} data={data} />
    )
}

export default ({ data }: { data: SignatureData }) => {

    return (
        <div id="render" style={{ ...styles.render, fontFamily: data.fontStyle.fontFamily }}>

            <Layout data={data} />
            <br/>
            {data.hasBanner() &&
                <Picture image={data.banner} />
            }

            {data.getOrderedEnabledAddons().map((addon, i) => {
                return addon.data.isEnabled() ? (
                    <div key={addon.key}>
                        <AddonComponent data={data} type={addon.key} />
                    </div>
                ): null
            })}

        </div>
    )
}