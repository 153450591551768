import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import MuiCardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Layout } from '../Signature/V2/Preview/Layouts'
import useLoadSignatureData from 'Apps/Signally/react-hooks/useLoadSignatureData';
import styled from 'styled-components';
import SignatureData from '../Signature/V2/DataObject';

const useStyles = makeStyles({
    root: {
        width: 400
    },
    media: {
        height: 140,
    },
});

const LayoutContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
`
const LayoutContentContainer = styled.div`
    text-align: left;
    width: 100%;
    overflow: hidden;
`
const PreventClick = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    left:0px;
`

const CardContent = styled(MuiCardContent)`
    padding-top: 0px;
    padding-bottom: 0px;
`

export default ({ signature, actions = [], onClick }) => {
    const classes = useStyles();

    const signatureData = new SignatureData();
    signatureData.setData(JSON.parse(signature.signatureData));
    const currentSignatureData: SignatureData = useLoadSignatureData({ signatureData });

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => onClick(signature.id)}>

                <LayoutContainer>
                    <PreventClick />
                    <LayoutContentContainer>
                        <Layout preview data={currentSignatureData} />
                    </LayoutContentContainer>
                </LayoutContainer>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {signature.name}
                     </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {actions.map(({ title, onClick }, i) => (
                    <Button key={i} size="small" color="primary" onClick={() => onClick(signature.id, signature)}>
                        {title}
                    </Button>
                ))}
            </CardActions>
        </Card>
    )
}