import React, { useRef, useState } from 'react';
import classnames from 'classnames'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Icon from 'Apps/Signally/Components/Icons';

import css from './index.module.css';
import styles from './mui-styles';
import { withStyles } from '@material-ui/core';

export default withStyles(styles)(
	({
		forceOpen = false,
		title,
		placeholder, helperText, label, noneLabel,

		data = [], value,

		onChange = () => {},
		onBlur = () => {},

		disableNone = false,

		disabled = false,
		error = false,
		className = null,
		style = {},

		classes = {}
	}) => {


		const inputEl = useRef(null);
		const [ open, setOpen ] = useState(false);

		const handleClose = event => {
			if (inputEl.current.contains(event.target)) {
				return;
			}

			setOpen(false);
		}

		const onClickOption = value => event => {
			inputEl.current.focus();
			onChange(
				{target: { value }}
			)
			setOpen(false);
		}

		let valueLabel = placeholder;

		if (!disableNone && !placeholder) {
			valueLabel = "None";
		}
		if (!disableNone && noneLabel) {
			valueLabel = noneLabel
		}
		data.forEach(({ value: item_value, label }) => {
			if (item_value === value) {
				valueLabel = label;
				return false;
			}
		})

		const menuItemClasses = {
			root: classes.menuItemRoot,
			selected: classes.menuItemSelected,
		}

		const menuItemClassesSelected = {
			root: classes.menuItemSelected
		}

		return (
			<div className={classnames(css.container, className)} style={style}>
				{(helperText || label) &&
				<div
					className={css.label}
					onClick={() => {
						!disabled && setOpen(!open);
					}}
				>
					{helperText || label}
				</div>
				}
				<div
					{...(disabled ? {}: {
						tabIndex: 0
					})}
					className={classnames(css.input, {[css.inputDisabled]: disabled})}
					ref={inputEl}
					onClick={() => {
						!disabled && setOpen(!open);
					}}
					onBlur={() => {
						setOpen(false);
					}}
				>{valueLabel}<Icon name="expand_more" className={css.inputIcon}/></div>

				<Popper
					placement="bottom-end"
					open={open || forceOpen}
					anchorEl={inputEl.current} disablePortal transition style={{minWidth: '100%', zIndex: 1000000}}
					modifiers={{
						flip: { enabled: false },
						preventOverflow: { enabled: true }
					}}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							id="menu-list-grow"
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper className={classes.paper}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										classes={{
											root: classes.MenuList
										}}
										// PaperProps={{
										// 	style: {
										// 		maxHeight: '300px'
										// 	}
										// }}
									>
										{!disableNone && <MenuItem classes={menuItemClasses} key={0} value={null} onClick={onClickOption(null)}>
											{noneLabel || "none"}
										</MenuItem>}

										{data.map(({value: _value, label}) => (
											<MenuItem
												classes={value === _value ? menuItemClassesSelected : menuItemClasses}
												key={_value}
												onClick={onClickOption(_value)}
											>{label}</MenuItem>
										))}

									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>

			</div>
		)

	}
);







