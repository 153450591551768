import React from "react";
import { useNavigate } from 'react-router-dom'

import { Edit } from '@material-ui/icons'

import { User } from '@izzistock/models'
import UsersTable from 'Apps/Stockizy/Components/Users/List'
import PageList from 'AppCore/Components/CommonPages/PageList'
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";


export default () => {

    const navigate = useNavigate();
    const currentCentre = useCurrentCentre();

    return (
        <PageList
            addButton={{
                to: '/user/create',
                title: 'Créer un utilisateur'
            }}
            getData={() => {
                if (currentCentre) {
                    return User.getAllFromCentre(currentCentre.id)
                }
                return User.getAll();
            }}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <UsersTable
                    data={data}
                    actions={[
                        user => {
                            return ({ key: 'edit', icon: <Edit />, onClick: id => navigate('/user/'+id) })
                        }
                    ]}
                />
            )}
        />
    )
}