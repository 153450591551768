import React, { useState } from "react";

import TextField from 'Apps/Signally/Components/InputFields/TextField';
import Modal from 'AppCore/Components/Modal';
import Button from 'Apps/Signally/Components/Button';

import { deployUserSignature } from 'Apps/Signally/Actions'
import { useDispatch } from "react-redux";

export default ({ user, deployTeamTemplate, onDeployed = () => {} }) => {

    const [ deployLoading, setDeployLoading ] = useState(false);
    const [ modalOpen, setOpenModal ] = useState(false);
    const [ deployMessage, setDeployMessage ] = useState("");

    const dispatch = useDispatch();

    const deploy = async () => {

        setDeployLoading(true);
        await dispatch(deployUserSignature({ user_id: user.id, deploy_message: deployMessage }));
        await onDeployed();
        setDeployLoading(false);
        closeModal();
    }

    const closeModal = () => {
        setDeployMessage("")
        setOpenModal(false)
    }

    const openModal = e => {

        if (!user.signature) {
            window.alert("No signature template choosen");
            return null;
        }

        setOpenModal(true)
    }

    return (
        <div>
            {modalOpen &&
                <Modal
                    open
                    onClose={closeModal}
                    title={"Deploy '" + user.signature.name + "' on " + user.email + " ?"}
                    actions={[
                        <Button key="cancel" disabled={deployLoading} onClick={closeModal} color="none">Annuler</Button>,
                        <Button key="deploy" loading={deployLoading} onClick={deploy}>Deploy</Button>
                    ]}
                >
                    <TextField
                        multiline
                        rows={3}
                        placeholder={"Enter the message you want to send"}
                        onChange={({ target : { value }}) => setDeployMessage(value)}
                        value={deployMessage}
                        style={{width: '100%'}}
                        disabled={deployLoading}
                    />
                </Modal>
            }

            {!user.is_deploy_sent && <Button loading={deployLoading} disabled={user.is_deployed} color="blue" onClick={openModal}>
                Deploy
            </Button>}

            {user.is_deploy_sent && !user.is_deployed &&
                "Email envoyé"
            }

            {user.is_deployed && "Installé"}
        </div>
    )
}