import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'AppCore/Components/Icons';
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  }
})

const filterCells = (cells, hideCells = []) => {
	return cells.filter(cell => hideCells.indexOf(cell.key));
}

class SimpleTable extends React.PureComponent {

	state = {
		pageIndex: 0,
		rowsPerPage: 50
	}


	renderData = data => {

		const { getSeparator = (el1, el2) => null } = this.props;

		const rows = [];

		for (let i = 0; i < data.length; i++) {
			const el = data[i];
			const previous_el = i === 0 ? null : data[i-1];

			const separator = getSeparator(previous_el, el);

			if (separator !== null) {
				rows.push(
					<TableRow key={el.id + "_separator"}>
						<TableCell align="left" colSpan={this.getNbCells()}>
							{separator}
						</TableCell>
					</TableRow>
				);
			}

			rows.push(this.renderRow(el))
		}

		return rows;
	}

	getNbCells = () => {
		const { config = {} } = this.props;
		const { cells = [], hideCells = [], actions = [] } = config;

		return filterCells(cells, hideCells).length + (actions.length > 0 ? 1 : 0);
	}

	renderRow = el => {
		const { getCell = (el, key) => key, config = {}, getKey } = this.props;
		const { cells = [], hideCells = [], actions = [] } = config;

		let key = el.id;
		if (getKey) {
			key = getKey(el);
		}

		return (
			<TableRow key={key}>

				{filterCells(cells, hideCells).map((cell, i) =>
					<TableCell
						key={cell.key + i}
						style={{
							...(cell.noPadding ? {padding:0} : {}),
							...(cell.width ? {width: cell.width + "px"} : {})
						}}
						align={config.align || "left"}
					>{getCell(el, cell.key)}</TableCell>
				)}

				{actions.length > 0 &&

					<TableCell align="right">

						{actions.map(_action => {

							if (typeof _action === 'function') {
								_action = _action(el);
							}

							if (React.isValidElement(_action)) {
								return _action;
							}

							if (!_action) {
								return null;
							}

							let { Component = null, onClick = () => {}, ...action } = _action

							const actionContent = (!action.icon_name && !action.icon) ? (
								<Button onClick={e => {
									onClick(el.id, el);
								}}>{action.title}</Button>
							) : (
								<IconButton aria-label={action.title || ''} onClick={e => {
									onClick(el.id, el);
								}}>
									{action.icon_name && <Icon name={action.icon_name} />}
									{action.icon}
								</IconButton>
							)

							if (Component === null) {
								Component = React.Fragment;

								return <Component key={action.key || (action.title + action.icon_name)}>{actionContent}</Component>
							}

							return <Component object={el} key={action.key || (action.title + action.icon_name)}>{actionContent}</Component>
						})}

					</TableCell>
				}
			</TableRow>
		)
	}

	renderPagination = () => {

		const { data = [] } = this.props;

		return (
			<TablePagination
				colSpan={this.getNbCells()}
				count={data.length}
				rowsPerPageOptions={[5, 20, 50, 100]}
				rowsPerPage={this.state.rowsPerPage}
				page={this.state.pageIndex}
				onChangePage={(el, pageIndex) => this.setState({ pageIndex })}
				onChangeRowsPerPage={ ({ target: { value: rowsPerPage } }) => this.setState({ rowsPerPage })}
			/>
		)
	}

	getDataFromPaginationOptions = data => {
		const start = this.state.rowsPerPage * this.state.pageIndex;
		const end = start + this.state.rowsPerPage;

		return data.slice(start, end);
	}

	render = () => {

		const { config = {}, data = [], classes } = this.props;
		const { cells = [], hideCells = [], actionsLabel = "Actions", actions = [] } = config;

		const paginatedData = this.getDataFromPaginationOptions(data);
		return (
			<Paper elevation={0} className={classes.root}>

				<Table className={classes.table}>
					<TableHead>

						{/* <TableRow>
							{this.renderPagination()}
						</TableRow> */}

						<TableRow>
							{filterCells(cells, hideCells).map((cell, i) => <TableCell key={cell.key + i}>{cell.title || ''}</TableCell>)}
							{actions.length > 0 && <TableCell align="right">{actionsLabel}</TableCell>}
						</TableRow>

					</TableHead>
					<TableBody>
						{this.renderData(paginatedData)}
					</TableBody>

					{data.length > 50 && <TableFooter>

						<TableRow>
							{this.renderPagination()}
						</TableRow>

					</TableFooter>}
				</Table>

			</Paper>
		)
	}
}


export default withStyles(styles)(SimpleTable);