// @flow
import React from 'react';
import styled from 'styled-components';
import {
    Paper
} from '@material-ui/core'

import SignatureData from '../DataObject';

import Header from './Header';
import Layout from './Layouts'

const Container = styled(Paper)`
    margin-top: 47px;
`

const Body = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    min-height: 300px;
    overflow: auto;
`

type Props = {
    data: SignatureData,
    style: any
}
export default ({ data, style }: Props) => (
    <Container style={style}>
        <Header />
        <Body>
            <Layout data={data} />
        </Body>
    </Container>
)