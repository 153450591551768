import React from 'react';
import styled from 'styled-components';

import {
    Avatar,
    Button,
    Grid,
} from "@material-ui/core";

import {
    SidebarFooter,
    SidebarFooterText
} from 'Apps/Shared/Layouts/Components/Sidebar/StyledComponents'
import { useDispatch } from 'react-redux';
import { logout } from 'AppCore/Actions';

const GridUser = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default ({ profilPictureUrl, currentUser }) => {

    const dispatch = useDispatch();

    return (
        <SidebarFooter>
            <Grid container spacing={2}>
                <Grid item>
                    <Avatar alt="profile picture" src={profilPictureUrl} />
                </Grid>
                <GridUser item>
                    <SidebarFooterText variant="body2">
                        {currentUser.displayName}
                    </SidebarFooterText>
                    <SidebarFooterText variant="body2">
                        <Button color="primary" variant="text" onClick={() => dispatch(logout())}>déconnexion</Button>
                    </SidebarFooterText>
                </GridUser>
            </Grid>
        </SidebarFooter>
    )
}