import React, { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import _get from 'lodash.get';

import TeamSelector from 'Apps/Signally/Components/Views/TeamSelector';
import DataTable from 'AppCore/Components/DataTable';
import Button, { NeedPaymentButton } from 'Apps/Signally/Components/Button';

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import EditCreateUserModal from 'Apps/Signally/Pages/Modals/User/editCreate';
import UserImportModal from 'Apps/Signally/Pages/Modals/UserImport';
import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";
import Api from "Apps/Signally/Api";
import useCurrentUser from "Apps/Signally/react-hooks/useCurrentUser";
import User from "Apps/Signally/Models/User";


export default () => {

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [ users, setUsers ] = useState([]);
	const [ modalEdit, setModalEdit ] = useState(null);
	const [ modalCreate, setModalCreate ] = useState(null);
	const [ modalImport, setModalImport ] = useState(null);
	const company = useCurrentCompany();
	const current_user = useCurrentUser();
	const [ selectedTeam ] = useTeamContext();
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;

	const loadUsers = async () => {
		let result;
		if (selectedTeamId) {
			result = await Api.getTeamMembers({ team_id: selectedTeamId })
		} else {
			result = await Api.getOrganisationUsers({ organisation_id: company.id })
		}
		setUsers(result.data);
	}

	useEffect(() => {
		loadUsers();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedTeamId ])

	const onClickEdit = user_id => {
		setModalEdit({
			user_id,
			open: true
		})
	}

	const onClickCreate = e => {
		e.stopPropagation();
		setModalCreate({
			open: true
		})
	}

	const onClickImport = () => {
		setModalImport({
			open: true
		});
	}

	const onClickDelete = async (user_id, user) => {
		if (!window.confirm(`Are you sure you want to delete ${user.first_name} ${user.last_name} (${user.email}) ?`)) {
			return;
		}

		const key = enqueueSnackbar('Deleting user...', {variant: 'info'});
		await Api.deleteUser({ user_id });
		await loadUsers();
		closeSnackbar(key);
		enqueueSnackbar('deleted', {variant: 'success'});
	}

	const onUserChange = async (user_id, data) => {

		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		const user = { team_id: data.team_id };
		await Api.updateUser({ user_id, user });
		await loadUsers();

		closeSnackbar(key);
		enqueueSnackbar('Saved', {variant: 'success'});
	}

	return (
		<>

			<FlexRowSpaceBetween>
				<Title>
					Employés
				</Title>

				<div>
					<Button
						color="grey"
						onClick={onClickImport}
					>{"Importer une liste"}</Button>
					<NeedPaymentButton onClick={onClickCreate}>
						Créer un nouvel employé
					</NeedPaymentButton>
				</div>
			</FlexRowSpaceBetween>

			<Divider />

			<DataTable
				data={users}
				config={{
					cells: [
						{ title: 'Email', key: 'email' },
						{ title: 'Nom', key: 'display_name' },
						{ title: 'Signature', key: 'signature' },
						...(selectedTeamId ? [] : [
							{ title: 'Team', key: 'team.name' }
						])
					],
					actions: [
						{ icon_name: 'edit', title: "Edit", onClick: onClickEdit },
						user => user.id === current_user.id ? false: { icon_name: 'delete', title: "Delete", onClick: onClickDelete }
					]
				}}
				getCell={(user, attr_name) => {

					const userObj = new User()
					userObj.setData(user);

					if (attr_name === "display_name") {
						return userObj.getDisplayName();
					}

					if (attr_name === 'signature') {
						return user.signature ? user.signature.name : "";
					}

					if (attr_name === 'team_id') {
						return (
							<TeamSelector selected_id={(user.team_id) || null} onChange={(e) => {
								onUserChange(user.id, { team_id: e.target.value })
							}}/>
						)
					}

					return _get(user, attr_name) || '';
				}}
			/>

			{modalImport && (
				<UserImportModal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open
					team_id={selectedTeamId}
					onClose={() => setModalImport(null)}
				/>
			)}

			{(modalEdit || modalCreate) &&
				<EditCreateUserModal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open
					team_id={selectedTeamId}
					user_id={modalEdit ? modalEdit.user_id : null}
					onClose={() => {setModalCreate(null); setModalEdit(null);}}
					onSaved={() => {setModalCreate(null); setModalEdit(null); loadUsers();}}
				/>
			}

		</>
	);
}