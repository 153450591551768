import React from "react";
import { Outlet } from "react-router-dom";

import SidebarHeaderComponent from '../Layouts/Components/Sidebar/Header';
import LayoutDashboard from '../../Shared/Layouts/Dashboard'
import LayoutAuth from '../../Shared/Layouts/Auth'

//// DASHBOARD
import ExperimentsRedux from '../../Shared/Pages/Experiments/Redux'

//// AUTH
import AuthSignin from '../../Shared/Pages/Auth/Signin'

import CoachListPage from './Pages/CoachList'

import {
  Home,
  Database,
} from "react-feather";
import useCurrentUser from "../react-hooks/useCurrentUser";
import { useCurrentUserData } from "../Store/hooks";

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}
const Unauthorized = () => {
    return (<div>Accès interdit</div>)
}

const HomePage = () => (
    <div>HOME</div>
)

const CheckAuthorized = () => {

    const currentUser = useCurrentUser();
    const currentUserData = useCurrentUserData()

    if (!currentUserData) {
        return "Loading...";
    }

    if (!currentUser.isAdmin()) {
        return <Unauthorized />
    }

    return (
        <Outlet />
    )
}

export default {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/home',
        redirectForbiddenPath: '/unauthorized'
    },

    sidebarConfig: {
        SidebarHeaderComponent
    },

    sidebar: [
        { title: "Home", path: "/home", icon: <Home /> },
        { title: "Liste des coachs", path: "/coachs", icon: <Home /> },

        {
            title: "Base de données",
            icon: <Database />,
            children: [
                { title: "Tags", path: "/database/tags" },
                { title: "Equippements", path: "/database/equipments" }
            ]
        },
    ],
    routes: [

        {
            path: "/auth", element: LayoutAuth,
            routes : [
                { path: "", redirectSignin: true },
                { path: "sign-in", needLogout: true, element: AuthSignin },
            ]
        },

        {
            path: "/unauthorized",
            element: Unauthorized
        },

        {
            path: "/",
            element: CheckAuthorized,
            routes : [

                {
                    path: "/", element: LayoutDashboard,
                    needUser: true,
                    routes : [
                        { path: "", redirectHome: true },

                        {
                            path: "home",
                            element: HomePage
                        },

                        {
                            path: "coachs",
                            element: CoachListPage
                        },

                        {
                            path: 'database',
                            routes: [
                                { path: 'tags', element: ExperimentsRedux },
                                { path: 'equipments', element: ExperimentsRedux }
                            ]
                        }
                    ]
                },
            ]
        },

        {
            path: "*", element: NotFound
        }
    ]
}