import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.campaigns, action) => {
	switch (action.type) {
	case C.SET_CAMPAIGNS:
		return {
			...state,
			...action.campaigns,
			loaded: true
		};
	case C.CLEAR_CAMPAIGNS:
		return initialState.campaigns;
	default:
		return state;
	}
}