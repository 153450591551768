import React from 'react';



export default ({ ...props }) => (
	<ol>
		<li>Generate an email signature using <b>MySignature</b> editor.</li>
		<li>Click on <b>Copy to clipboard</b> button.</li>
		<li>Open <a href="https://mail.google.com/" target="_blank" rel="nofollow noopener noreferrer">Gmail</a>.</li>
		<li>
			In the top right, click Settings &nbsp;<span title="Settings" className="icon icon-site-gear"></span><span title="and then" className="icon icon-site-right-b"></span>&nbsp; <b>Settings.</b>
		</li>
		<li>Paste your signature <b>(Ctrl+V or Command+V)</b> into the <b>"Signature"</b> section.</li>
		<li>At the bottom of the page, click <b>Save Changes.</b></li>
	</ol>
)