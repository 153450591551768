export default {

    title: "Matterport embed generator",
    defaultTitle: "Matterport embed generator",

    auth: {
        provider: 'firebase'
    },

    firebaseConfig: {
        apiKey: "AIzaSyBXrJ2pK3MjiCOPclO28pQ4Cxx1bdQ2bm8",
        authDomain: "matterport-embed.firebaseapp.com",
        databaseURL: "https://matterport-embed.firebaseio.com",
        projectId: "matterport-embed",
        storageBucket: "matterport-embed.appspot.com",
        messagingSenderId: "919638128165",
        appId: "1:919638128165:web:0b36cf0cff27d32ac331cc",
        measurementId: "G-75LS8VZEXQ"
    },

    reduxPersistConfig: {
        whitelist: [
            "Auth"
        ]
    }
}