import React from 'react';
import classnames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export type Option = {label: string, value: string | number};
type Props = {
    style?: any,
    className?: string,

    label?: string,
    placeholder?: string,
    disabled?: boolean,

    onChange?: (value: Array<Option>) => any,
    options: Array<Option>,
    value?: Array<Option>,

    TagComponent: any
}
export default ({
    style = {}, className,

    label, placeholder,

    options,
    value = [],
    onChange = value => {},

    disabled = false,

    TagComponent: _TagComponent = null
}: Props) => {
    const classes = useStyles();

    const TagComponent = _TagComponent || Chip

    return (
        <div className={classnames(classes.root, className)} style={style}>
            <Autocomplete
                disabled={disabled}
                multiple
                disableCloseOnSelect
                limitTags={10}
                onChange={(e, value) => {
                    onChange(value)
                }}
                id="multiple-limit-tags"
                options={options}
                getOptionLabel={(option) => option.label}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return (
                            <TagComponent
                                option={option}
                                variant="outlined"
                                label={option.label}
                                size="small"
                                {...getTagProps({ index })}
                            />
                        )
                    })
                }}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </React.Fragment>
                )}
                style={{ width: '100%' }}
                getOptionSelected={(option, value) => {
                    return JSON.stringify(option) === JSON.stringify(value);
                }}
                value={(value || [])}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
                )}
            />
        </div>
    );
}