import React, { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import _get from 'lodash.get';

import {
	FileCopy,
	ViewList as ViewListIcon,
	ViewModule as ViewModuleIcon
} from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DataTable from 'AppCore/Components/DataTable';
import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import Api from 'Apps/Signally/Api'
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";
import Button from 'Apps/Signally/Components/Button';

import CopySignatureModal from '../Modals/CopySignature';
import useSignatureList from "Apps/Signally/react-hooks/useSignatureList";

import SignatureListCard from 'Apps/Signally/Components/SignatureListCards';

export default () => {

	const [ state, setState ] = useState({
		modalOpen: false, modalOptions: {}
	});
	const [ selectedTeam ] = useTeamContext();
	const [ view, setView ] = useState('cards');
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [ signatures, reloadSignatures, loading ] = useSignatureList();

	const onClickDelete = async (signature_id, template) => {
		if (!window.confirm(`Are you sure you want to delete ${template.name} ?`)) {
			return;
		}

		const key = enqueueSnackbar('Deleting template...', {variant: 'info'});
		await Api.deleteTemplate({ template_id: signature_id });
		await reloadSignatures();
		closeSnackbar(key);
		enqueueSnackbar('deleted', {variant: 'success'});
	}
	const onClickCopy = async (template_id, template) => {
		setState({
			modalOpen: true,
			modalOptions: {
				template_id, template
			}
		})
	}

	const handleClose = () => {
		setState({ modalOpen: false });
	};

	const onClickEdit = signature_id => {
		navigate('/signature/' + signature_id);
	};
	const actions = [
		{ icon_name: 'edit', title: "Edit", onClick: onClickEdit},
		{ icon_name: 'delete', title: "Delete", onClick: onClickDelete },
		{ icon: <FileCopy />, title: "Duplicate", onClick: onClickCopy }
	]

	return (

		<>

			<FlexRowSpaceBetween>
				<Title>
					Signatures
				</Title>

				<div>
					<Button
						loading={false}
						component={NavLink} to={'/signatures/new'}
					>Créer une nouvelle signature</Button>
				</div>
			</FlexRowSpaceBetween>

			<FlexRowSpaceBetween>
				<div></div>
				<ToggleButtonGroup size='small' orientation="horizontal" value={view} exclusive onChange={(e, view) => {
					if (view) setView(view);
				}}>
					<ToggleButton value="list" aria-label="list">
						<ViewListIcon />
					</ToggleButton>
					<ToggleButton value="cards" aria-label="module">
						<ViewModuleIcon />
					</ToggleButton>
				</ToggleButtonGroup>
			</FlexRowSpaceBetween>

			<Divider />


			{state.modalOpen &&
				<CopySignatureModal
					open
					onClose={handleClose}
					onSaved={reloadSignatures}
					{...state.modalOptions}
				/>
			}

			{!loading && view === "cards" &&
				<SignatureListCard
					signatures={signatures}
					actions={actions}
					onClick={onClickEdit}
				/>
			}

			{!loading && view === "list" && <DataTable
				data={signatures}
				config={{
					cells: [
						{ title: 'Name', key: 'name' },
						...(selectedTeamId ? [] : [
							{ title: "Créé par", key: 'team.name' }
						])
					],
					actions
				}}
				getCell={(template, attr_name) => {
					return _get(template, attr_name) || '';
				}}
			/>}

		</>
	);
}