import React from "react";
import { useNavigate } from 'react-router-dom'

import { Edit } from '@material-ui/icons'

import { User } from '@nopanoga/models'
import CoachsList from 'Apps/Nopanoga/Components/Coachs/List'
import PageList from 'AppCore/Components/CommonPages/PageList'


export default () => {

    const navigate = useNavigate();

    return (
        <PageList
            addButton={{
                to: '/coach/invite',
                title: 'Envoyer une invitation'
            }}
            getData={User.getAllCoachs}
            DataTableComponent={({ data, searchQuery, reload }) => {

                console.log("datadatadata", data);

                return (
                    <CoachsList
                        data={data}
                        actions={[
                            user => {
                                return ({ key: 'edit', icon: <Edit />, onClick: id => navigate('/coach/'+id) })
                            }
                        ]}
                    />
                )
            }}
        />
    )
}