import React, { useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

export default ({ tabs, classes = {} }) => {
	const [tabIndex, setTab] = useState(0);

	const tabClasses = {
		root: classes.tab
	};

	return (
		<Paper className={classes.root}>
			<Tabs
				value={tabIndex}
				onChange={(e, tabIndex) => setTab(tabIndex)}
				indicatorColor="primary"
			>
				{tabs.map(({ tabLabel }, i) => <Tab key={tabLabel+'_'+i} classes={tabClasses} label={tabLabel}  />)}
			</Tabs>

			{tabs[tabIndex] && tabs[tabIndex].renderTabContent()}

		</Paper>
	)
}