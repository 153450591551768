import React from 'react';
import styled from 'styled-components';
import Tabs from './Tabs'

import General from './General';
import Social from './Social';
import Addons from './Addons';
import Images from './Images';
import Design from './Design';

const TabsPanels = styled.div`
    min-height: 100%;
    flex: 1;
`

export default props => (
    <Tabs
        defaultSelected={0}
        tabs={[
            {
                label: "General",
                panel: (
                    <TabsPanels>
                        <General {...props} />
                    </TabsPanels>
                )
            },
            {
                label: "Images",
                panel: (
                    <TabsPanels>
                        <Images {...props} />
                    </TabsPanels>
                )
            },
            {
                label: "Social",
                panel: (
                    <TabsPanels>
                        <Social {...props} />
                    </TabsPanels>
                )
            },
            {
                label: "Addon",
                panel: (
                    <TabsPanels>
                        <Addons {...props} />
                    </TabsPanels>
                )
            },
            {
                label: "Design",
                panel: (
                    <TabsPanels>
                        <Design {...props} />
                    </TabsPanels>
                )
            }
        ]}
    />
)
