// @flow
import React from 'react';

const labels = [
    "Débutant", "Intermédiaire", "Expert"
]

type Props = {
    level: 1 | 2 | 3,
    label?: boolean
}
export default ({ level = 1, label = false }: Props): any => (
    <span>
        {[...Array(level)].map((e, i) => (
            <span key={"level_"+i} role="img" aria-label="level">🔥</span>
        ))}
        {label &&
            <span>{" " + labels[level-1]}</span>
        }
    </span>
)