import React from "react";
import { ActivityIndicator as NativActivityIndicator } from "react-native";

import classNames from 'classnames';
import css from './index.module.css';

export const Content = ({ className, ...props }) => (
	<div className={classNames(className, css.contentContainer)} {...props} />
)

export const PageContainer = ({ className, ...props }) => <div className={classNames(className, css.PageContainer)} {...props} />

export const ActivityIndicator = () => <NativActivityIndicator />