import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { triggerLogin } from 'Apps/Signally/Actions';
import Button from "Apps/Signally/Components/Button";

import LoginModal from '../Modals/Login';

import css from './CreateSignature.module.css';


class NeedLoginStep extends Component {

	static defaultProps = {
		onEnd: () => {}
	}

	state = {
		displayLogin: false
	}

	onPressPrimary = async () => {
		const { userLogged } = this.props;

		if (!userLogged) {
			this.setState({
				displayLogin: true
			});
		}
	}

	render() {
		const { userLogged, onSkip = null, title = "", skipBtnLabel = "", primaryBtnLabel = "" } = this.props;

		if (userLogged) {
			return this.props.children;
		}
		return (
			<div className={css.NeedLoginContainer}>
				<h1 style={{fontWeight: 300, marginBottom: '1.5em'}}>
                    {title}
                </h1>

                <div className={css.ButtonContainer}>
                	<Button
                        onPress={this.onPressPrimary}
                        style={{width: '200px'}}
                    >
                        {primaryBtnLabel}
                    </Button>
                    {onSkip && <Button
                        onPress={onSkip}
                        style={{width: '200px'}}
                    >
                    	{skipBtnLabel}
                    </Button>}
                </div>

                {this.state.displayLogin &&
					<LoginModal
						onLogged={() => this.setState({displayLogin: false})}
						onClose={() => this.setState({displayLogin: false})}
					/>
				}
			</div>
		)
	}

}



export default connect(
	state => ({
		userLogged: state.Auth.userLogged
	}),
	{ triggerLogin }
)(withSnackbar(NeedLoginStep));