/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui'
require('firebaseui/dist/firebaseui.css');

export default ({ uiConfig = {}, onSignedIn = () => {} }) => {

    const [ isSignedIn, setIsSignedIn ] = useState(!!firebase.auth().currentUser);
    const [ isLoaded, setIsLoaded ] = useState(false);

    useEffect(() => {
        if (isSignedIn) {
            return;
        }
        console.log("Subscribe to onAuthStateChanged")
        firebase.auth().onAuthStateChanged((user) => {
            setIsSignedIn(!!user);
            setIsLoaded(true);

            if (user) {
                onSignedIn(user);
            }
        })
    }, [])

    if (!isLoaded) {
        return null;
    }

    if (isSignedIn) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <FirebaseUi uiConfig={uiConfig} />
    )
}

export const FirebaseUi = ({ uiConfig = {} }) => {

    const defaultUiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                forceSameDevice: false
            },
        ],
        credentialHelper: 'none',
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                console.log("signInSuccessWithAuthResult", authResult, redirectUrl);
                return false;
            },
        }
    }

    useEffect(() => {

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('#FIREBASEUI_CONTAINER', {
            ...defaultUiConfig,
            ...uiConfig
        });

        return () => {
            ui.reset();
            ui.delete();
        }
    }, [])

    return (
        <div id="FIREBASEUI_CONTAINER"></div>
    )
}