import React from "react";

import Selector from 'Apps/Signally/Components/Selector';
import useSignatureList from "Apps/Signally/react-hooks/useSignatureList";

export default ({
	label = "Template Selector",
	helperText = "Select a signature template",
	noneLabel,
	...props }) => {

	const [ signatures ] = useSignatureList();

	return (
		<Selector disableNone={!noneLabel} noneLabel={noneLabel} label={label} helperText={helperText} {...props} data={signatures} />
	);
}