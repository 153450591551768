import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = {}, action) => {
	switch (action.type) {
        case C.CONTEXT_SET_TEAM:
            return {
                ...state,
                selected_team_id: action.selected_team_id
            };
        case C.CONTEXT_CLEAR_TEAM:
            return {
                ...state,
                selected_team_id: null
            };
        default:
            return state;
	}
}