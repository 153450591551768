import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";

import Helmet from 'react-helmet';

import {
  Divider as MuiDivider,
  Typography as MuiTypography
} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';

import { spacing } from "@material-ui/system";

import SearchField from 'AppCore/Components/SearchField'
import { TrainingProgram } from "@nopanoga/models"

import Table from './dataTable'

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

function AddLink() {
    return (

        <Link
            to={"/training-programs/new"}
        >
            <Fab size="small" color="primary" aria-label="add" >
                <Add/>
            </Fab>
        </Link>
    );
}

export default () => {
    return (
        <React.Fragment>
            <Helmet title="Training Programs" />

            <Typography variant="h1" gutterBottom display="inline">
                Training Programs
            </Typography>

            <Divider my={6} />

            <TableHeader>
                <SearchField />
                <AddLink />
            </TableHeader>
            <Table data={TrainingProgram.getAll()}/>

        </React.Fragment>
    );
}