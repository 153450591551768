import React from "react";
import Helmet from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@material-ui/core";


// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  * {
      font-family: 'Open Sans';
  }
`;

export default () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans" rel="stylesheet" />
      </Helmet>
      <CssBaseline />
      <GlobalStyle />
      <Outlet/>
    </>
  );
}
