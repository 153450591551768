import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import _ from 'Apps/Signally/Trads';
import { triggerLogin } from 'Apps/Signally/Actions';
import Button from "Apps/Signally/Components/Button";

import TeamMembersScreen from '../TeamMembers';

import NeedLoginStep from './NeedLoginStep';
import AskForStep from './AskForStep'

import css from './CreateSignature.module.css';


class CreateMemberStep extends Component {

	static defaultProps = {
		onEnd: () => {}
	}

	constructor(props) {
		super(props);
		this.state = {
			afterLogin: !this.props.userLogged
		}
	}


	render() {

		return (
			<NeedLoginStep
				title={_('createSignatureCompany.login_to_add_member')}
				primaryBtnLabel={_('login_or_signup')}
			>
				<div className={css.stepContainer}>

					<AskForStep btnActionTitle={_("teammember.create")} onSkip={this.props.onEnd}>
						<TeamMembersScreen className={css.pageOverride} />
						<div className={css.stepContainerButtons}>
							<Button style={{width: '200px'}} onClick={() => this.props.onEnd()}>Save & Deploy</Button>
						</div>
					</AskForStep>

				</div>
			</NeedLoginStep>
		)
	}

}



export default connect(
	state => ({
		userLogged: state.Auth.userLogged
	}),
	{ triggerLogin }
)(withSnackbar(CreateMemberStep));