// @flow
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { TrainingSession } from "@nopanoga/models"
import LaunchTrainingSession from 'Apps/Nopanoga/Components/TrainingSession/LaunchTrainingSession'
import TrainingSessionPreview from 'Apps/Nopanoga/Components/TrainingSession/TrainingSessionPreview'
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #222;
`
const ContentContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 500px;
`

const LoadingContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const defaultTitle = "Nouvel entrainement";
export default () => {

    const { id } = useParams();
    const [ trainingSession: TrainingSession, setTrainingSession ] = useState(null);
    const [ trainingSessionLaunched, launchTrainingSession ] = useState(false);

    useEffect(() => {
        (async () => {
            setTrainingSession(await TrainingSession.get({ id }))
        })()
    }, [ id ])

    return (
        <React.Fragment>

            <Helmet title={trainingSession ? trainingSession.title : defaultTitle} />

            <Container>
                <ContentContainer>
                    {!trainingSession &&
                        <LoadingContainer>
                            <CircularProgress size="40px" color="primary" />
                        </LoadingContainer>
                    }

                    {trainingSession && trainingSessionLaunched && <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                        <LaunchTrainingSession
                            fullScreen
                            trainingSession={trainingSession}
                            onPressClose={() => launchTrainingSession(false)}
                        />
                    </div>}

                    {trainingSession && !trainingSessionLaunched && <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                        <TrainingSessionPreview
                            trainingSession={trainingSession}
                            onPressLaunch={() => launchTrainingSession(true)}
                        />
                    </div>}
                </ContentContainer>
            </Container>


        </React.Fragment>

    )
}
