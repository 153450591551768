// @flow
import React from "react";
import { Image as ImageIcon } from '@material-ui/icons'
import { Product } from '@izzistock/models'

import Image from 'AppCore/Components/FirebaseImage'
import DataTable from 'AppCore/Components/DataTable';
import { useProductCategories, useSuppliers } from 'Apps/Stockizy/Store/hooks'
import useDataListLoader from "AppCore/react-hooks/useDataListLoader";
import { ImageCellContainer } from "Apps/Shared/Layouts/Datatable";
import { BarCode } from "AppCore/Components/QRCode";
import { FlexColumnCentered } from "Apps/Shared/Layouts/FlexBox";

type Props = {
    searchQuery?: String,
    onClickEdit?: any => any,
    sort?: any,
    actions?: any,

    layout?: string
}

const ProductList = ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null,

    layout = 'full'
}: {data: Array<any>} & Props) => {

    const sortedData = data
    const suppliers = useSuppliers();
    const productCategories = useProductCategories();

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: ({
                full: [
                    { title: 'Photo', key: 'photo' },
                    { title: "Catégorie", key: 'categoryId' },
                    { title: 'Nom', key: 'name' },
                    { title: 'Code', key: 'code' },
                    { title: 'Fournisseur', key: 'supplierId' }
                ],
                minimal: [
                    { title: 'Photo', key: 'photo' },
                    { title: 'Nom', key: 'name' },
                    { title: 'Fournisseur', key: 'supplierId' }
                ]
            })[layout],
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'code') {
                return (
                    <FlexColumnCentered>
                        <BarCode value={item.code} />
                        <div>{item.code}</div>
                    </FlexColumnCentered>
                )
            }

            if (attr_name === 'photo') {
                return (
                    <ImageCellContainer>
                        {!item.photo &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.photo &&
                            <Image storageRef={item.photo.ref} style={{width: '100%'}} />
                        }
                    </ImageCellContainer>
                )
            }

            if (attr_name === 'supplierId') {
                const supplier = (suppliers.data || []).find(_supplier => _supplier.id === item.supplierId);
                return (supplier && supplier.name) || "";
            }

            if (attr_name === 'categoryId') {
                const productCategory = (productCategories.data || []).find(_productCategory => _productCategory.id === item.categoryId);
                return (productCategory && productCategory.name) || "";
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}

export const LoadedProductList = (props: Props) => {
    const dataList = useDataListLoader({ getData: Product.getAll, initialDataList: { data: [] } })
    return <ProductList {...props} data={dataList.data} />
}

export default ProductList