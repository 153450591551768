// @flow
import React from 'react';
import TextField from 'Apps/Signally/Components/InputFields/TextField'
import styled from 'styled-components';
import { SocialIcon } from '../../DataObject/Social';

import {
    DragHandle,
    Close
} from '@material-ui/icons'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`
const DragHandleStyled = styled(DragHandle)`
    cursor: move;
    margin-right: 10px;
`
const CloseStyled = styled(Close)`
    cursor: pointer;
    margin-left: 10px;
`
const Image = styled.img`
    margin-right: 10px;
`

type Props = {
    socialIcon: SocialIcon,
    order: number,
    onChange: (socialIcon: SocialIcon) => any,
    onClickDelete: any => any
}
export default ({ socialIcon, onChange, order, onClickDelete }: Props) => (
    <Container data-order={order}>
        <DragHandleStyled className="drag-bloc-indicator" />
        <Image src={socialIcon.src} alt={socialIcon.icon} width='35' />
        <TextField
            disabled={!socialIcon.editable}
            value={socialIcon.url}
            onChange={e => {
                onChange(socialIcon.clone(c => {
                    c.url = e.target.value
                }))
            }}
        />
        {socialIcon.editable && <CloseStyled onClick={onClickDelete} />}
    </Container>
)