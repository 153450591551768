import React from 'react';
import {
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
    SubTitle
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

export const Typography = styled(MuiTypography)(spacing);


export const Title = styled((props) => (
    <Typography variant="h1" gutterBottom display="inline" {...props} />
))`
    font-family: DIN Next LT Pro;
    font-weight: normal;
`


export const PreviewContainer = ({ title, height, children }) => (
    <div style={{ position: 'relative', width: '30%', maxWidth: '400px', minWidth: '350px', display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <SubTitle>{title}</SubTitle>
        <div style={{width: '90%', marginTop: "20px", height: height+"px", borderRadius: '8px', boxShadow: "2px 2px 19px 6px rgba(153,153,153,1)"}}>
            {children}
        </div>
    </div>
)