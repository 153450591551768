import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';

export default ({ value }) => {

    const canvas = useRef();

    useEffect(() => {
        QRCode.toCanvas(canvas.current, value, function (error) {
            if (error) {
                console.error(error)
                return;
            }
            console.log('success!');
        })
    }, [ value ])

    return (
        <canvas ref={canvas} />
    )
}


export const BarCode = ({ value, height = 40 }) => {

    const canvas = useRef();

    useEffect(() => {
        JsBarcode(canvas.current, value, {

            height,
            displayValue: false
        })
    }, [ value, height ])

    return (
        <canvas ref={canvas} />
    )

}