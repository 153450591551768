// @flow
import React from 'react';
import styled from 'styled-components'
import { PopoverButton } from '@knxlab/components'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const PopOverInfos = ({ children, style = {}, color }: { color?: string, style: any, children: any }) => (
    <PopoverButton style={style} content={(
        <div>
            {children}
        </div>
    )}>
        <HelpOutlineIcon fontSize="small" style={color ? { color } : {}}/>
    </PopoverButton>
)

const LabelContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
`

export default ({ title, popover, popoverColor }: { title: string, popover?: any, popoverColor?: string}) => (
    <LabelContainer>
        {title}
        {popover && <PopOverInfos color={popoverColor} style={{marginLeft: '10px'}}>
            {popover}
        </PopOverInfos>}
    </LabelContainer>
)