import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 18 20" {...props}>
      <path
        d="M10.5 1l6.88 4.6a1.09 1.09 0 01.3 1.5 1.22 1.22 0 01-.3.3L10.5 12a2.73 2.73 0 01-3 0L.62 7.35a1.09 1.09 0 01-.3-1.5 1.22 1.22 0 01.3-.3L7.5 1a2.72 2.72 0 013 0zm7.17 9.5a2.14 2.14 0 01-.62.65l-6.54 4.42a2.68 2.68 0 01-3 0L1 11.1a2.17 2.17 0 01-.84-2.48l7.39 4.93a2.7 2.7 0 002.83.11l.17-.11 7.39-4.93a2.13 2.13 0 01-.27 1.83v.05zm0 3.52a2.1 2.1 0 01-.62.64L10.51 19a2.72 2.72 0 01-3 0L1 14.61a2.16 2.16 0 01-.84-2.47l7.39 4.93a2.7 2.7 0 002.83.11l.17-.11 7.39-4.94a2.15 2.15 0 01-.27 1.87v.02z"
      />
    </svg>
  )
}

export default SvgComponent
