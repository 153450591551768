import React from "react";

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import TabsManager from 'Apps/Signally/Components/TabsManager';
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";


import TeamScreen from './Team';
import TeamsScreen from './Teams';
import UsersScreen from './Users';

export default () => {

	const [ selectedTeam ] = useTeamContext();
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;

    const tabs = [];
    if (selectedTeamId) {
        tabs.push({
            tabLabel: "Equipe \"" + selectedTeam.name + "\"",
            renderTabContent: () => (
                <TeamScreen />
            )
        })
    } else {
        tabs.push({
            tabLabel: "Equipes",
            renderTabContent: () => (
                <TeamsScreen />
            )
        })
    }

    tabs.push({
        tabLabel: "Employés",
        renderTabContent: () => (
            <UsersScreen />
        )
    })

	return (
		<>

			<FlexRowSpaceBetween>
				<Title>
					Déploiement
				</Title>
			</FlexRowSpaceBetween>

			<Divider />

            <TabsManager
                tabs={tabs}
            />
		</>
	);
}