// @flow
import React, { useState, useEffect } from 'react';
import { constructFirebaseStorageUrl } from '@knxlab/utils'
import ActivityIndicator from '../ActivityIndicator'

import { getConfig } from '../../config'

import { Avatar as MuiAvatar, AvatarTypeMap } from '@material-ui/core'

type Props = {
    avatar?: boolean,
    src?: string,
    storageRef?: string
}

export const Avatar = (props: AvatarTypeMap & Props)  => (
    <Image {...props} avatar />
)

const Image = ({ avatar = false, src: srcProp, storageRef, ...props }: Props) => {

    const [ src, setSrc ] = useState(srcProp ? srcProp : null)
    const [ error, setError ] = useState(false);

    useEffect(() => {
        !srcProp && (async () => {
            try {
                const { firebaseConfig: { storageBucket } } = getConfig();
                setSrc(constructFirebaseStorageUrl(storageBucket, storageRef));
            } catch (e) {
                if (e.code === 'storage/object-not-found') {
                    setError(true);
                }
            }
        })()
    }, [ storageRef, srcProp ])

    if (error) {
        return null;
    }

    if (avatar) {
        return (
            <MuiAvatar {...props} key={'avatar'} {...(src ? { src } : {})}>
                {!src && <ActivityIndicator />}
            </MuiAvatar>
        )
    }

    if (!src) {
        return <ActivityIndicator />;
    }
    return (
        <img {...props} src={src} alt={src || ""} />
    )
}

export default Image;