// @flow
import React from 'react';
import styled from 'styled-components';

import {
    Grid, Paper,
} from '@material-ui/core'


///// CONTAINER
export const Container = styled(Paper)`
    padding: 0px;
`


export const Padding = styled.div`
    padding: 20px;
    padding-bottom: 0px;
`
export const GridContainer: any = React.forwardRef((props, ref) => (
    <Padding><Grid ref={ref} container spacing={0} {...props} /></Padding>
));

///// GRID ITEM
const _GridItem = (props) => (
    <Grid item xs={12} sm={12} lg={6} {...props} />
);
export const BlocGridItem = styled(_GridItem)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`

