import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash.get';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator'
import { getTeams, updateTeam } from 'Apps/Signally/Actions';


import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import Button from 'Apps/Signally/Components/Button'

import DataTable from 'AppCore/Components/DataTable';
import EditGroupModal from '../Modals/Group/editCreate';

import NewGroupModal from '../Modals/NewGroup';

import withNavigate from "AppCore/HOC/withNavigate";

const styles = theme => ({

	groupList: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
	},

	groupItem: {
		marginBottom: '20px',
		marginLeft: '10px',
		marginRight: '10px'
	}
});


class TeamsScreen extends PureComponent {

	state = { modalOpen: false, modalEdit: null }

	componentDidMount = async () => {
		this.props.getTeams();

		this.setState({
			modalOpen: this.props.createTeam || false
		})
	}

	onClickEdit = team_id => {
		this.props.navigate('/team/'+ team_id + "")
	}

	handleOpen = () => {
		this.setState({ modalOpen: true });
	};

	handleClose = () => {
		this.setState({ modalOpen: false });
	};

	renderLoading() {
		return <ActivityIndicator />
	}


	render() {

		if (!this.props.teams.loaded) {
			return this.renderLoading();
		}

		const { classes, teams: { data: teams } } = this.props;

		return (
			<>

				<FlexRowSpaceBetween>
					<Title>
						Equipes
					</Title>

					<div>
						<Button
							onClick={this.handleOpen}
						>{"Créer une équipe"}</Button>
					</div>
				</FlexRowSpaceBetween>

				<Divider />

				{this.state.modalOpen &&
					<NewGroupModal
						open
						onClose={this.handleClose}
					/>
				}
				<div className={classes.groupList}>
					<DataTable
						data={teams}
						config={{
							cells: [
								{ title: 'Name', key: 'name' },
								{ title: 'Signature par défaut', key: 'signature_template.name' }
							],
							actions: [
								{ icon_name: 'edit', title: "Edit", onClick: this.onClickEdit },
								team => (team.is_global ? null : { icon_name: 'delete', title: "Delete", onClick: this.onClickDelete })
							]
						}}
						getCell={(team, attr_name) => {

							if (attr_name === 'name') {
								return team.name + (team.is_global ? (" (Default Team)") : "");
							}

							return _get(team, attr_name) || '';
						}}
					/>
				</div>

				{this.state.modalEdit &&
					<EditGroupModal
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open
						team_id={this.state.modalEdit ? this.state.modalEdit.team_id : null}
						onClose={() => this.setState({modalEdit: null})}
						onSaved={() => this.setState({modalEdit: null})}
					/>
				}

			</>
		);
	}

}



export default connect(
	state => ({
		teams: state.teams
	}),
	{ getTeams, updateTeam }
)(withStyles(styles)(withSnackbar(withNavigate(TeamsScreen))));