import React from 'react';
import TextField from 'Apps/Signally/Components/InputFields/TextField'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Label = styled.span`
    color: #486589;
`

export default ({ label, style, ...props }) => (
    <Container style={style}>
        <Label>{label}</Label>
        <TextField
            {...props}
        />
    </Container>
)