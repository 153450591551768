import initialState from "../initialState";

export default (state = initialState.currentUserData, action) => {
	switch (action.type) {
	case 'SET_CURRENT_USER_DATA':
		return {
			...state,
			...action.currentUserData
		};
	default:
		return state;
	}
}