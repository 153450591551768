import React, { useEffect, useState } from 'react';
import { useCurrentUser } from '@knxlab/react-hooks'

import { getAccessToken, loadAccessToken } from 'AppCore/Libs/Instagram'

import InstagramLoginButton from 'AppCore/Components/InstagramLoginButton'
import MediaBrowser from './MediaBrowser'

export default ({
    onLogged,
    onVideoSelected = () => {},
    selectedVideo = null
}) => {

    const currentUser = useCurrentUser();
    const [ igLogged, setIgLogged ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        (async () => {
            await loadAccessToken({ uid: currentUser.uid });
            setLoaded(true);
            setIgLogged(getAccessToken() !== null)
        })()
    }, [ igLogged, currentUser.uid ])

    if (!loaded) {
        return null;
    }

    return (
        <React.Fragment>
            {!igLogged &&
                <InstagramLoginButton
                    onLogged={() => {
                        setIgLogged(true);
                    }}
                />
            }

            {igLogged &&
                <MediaBrowser onVideoSelected={onVideoSelected} selectedVideo={selectedVideo} />
            }
        </React.Fragment>
    )
}