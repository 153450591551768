export const setCurrentCentre = currentCentre => (dispatch, getState) => {
    dispatch({
        type: 'SET_CURRENT_CENTRE',
        currentCentre
    })
}

export const clearCurrentCentre = () => (dispatch, getState) => {
    dispatch({
        type: 'CLEAR_CURRENT_CENTRE'
    })
}