import * as React from "react"

function SvgComponent(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 18 19" {...props}>
      <g>
        <path d="M16.56 8.347H1.44a.93.93 0 00-.94.91v7.27a1.86 1.86 0 001.89 1.82h13.22a1.86 1.86 0 001.89-1.82v-7.27a.93.93 0 00-.94-.91zM15.61 2.847h-1.89v-1a.95.95 0 10-1.89 0v1H6.17v-1a.95.95 0 10-1.89 0v1H2.39a2 2 0 00-1.89 2v1a1 1 0 00.94 1h15.12a1 1 0 00.94-1v-1a2 2 0 00-1.89-2z" />
      </g>
    </svg>
  )
}

export default SvgComponent
