import AbstractObject from './AbstractObject';

export const schemaId = "/SpacingStyle";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        marginTop: { type: 'integer'},
        marginBottom: { type: 'integer'}
    }
}

const NAME = "SpacingStyle";
export default class SpacingStyle extends AbstractObject {
    marginTop: number = 0
    marginBottom: number = 0

    getSpacingStyle() {
        return ({
            marginTop: this.marginTop + "px",
            marginBottom: this.marginBottom + "px",
        });
    }

    getName() {
        return NAME;
    }
}
SpacingStyle.register({ name: NAME, schema, __class: SpacingStyle });
