import React from 'react';

import { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";
import { CircularProgressExperiment } from '@nopanoga/components'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;



export default () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <GlobalStyle />

            <CircularProgressExperiment />
        </React.Fragment>
    )
}