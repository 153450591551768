import React, { useState } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

import { updateOrganisation } from 'Apps/Signally/Actions';

import Form from 'AppCore/Components/Form/formFields';
import MediaUploader from 'Apps/Signally/Components/MediaUploader'
import Button from 'Apps/Signally/Components/Button'
import Paper from 'Apps/Signally/Components/Paper'

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import css from './index.module.css';
import useTeamContext from 'Apps/Signally/react-hooks/useTeamContext';
import { Navigate } from 'react-router-dom';
import TemplateSelector from 'Apps/Signally/Components/Views/TemplateSelector';


const styles = theme => ({
	textField: {
		width: '300px',
		marginBottom: '10px'
	}
});

const Company = ({ enqueueSnackbar, closeSnackbar, updateOrganisation, classes, organisation: organisationProps }) => {

	const [ selectedTeam ] = useTeamContext();
	const [ { organisation, loading, saving, hasError }, _setState ] = useState({
		organisation: organisationProps || {},
		saving: false,
		loading: false,
		hasError: false
	})
	const [ logo, setLogo ] = useState((organisationProps && organisationProps.logo) ?
		{
			src: organisationProps.logo.path,
			type: organisationProps.logo.type
		} :
		null
	)

	const setState = state => _setState({
		organisation,
		loading,
		saving,
		hasError,
		...state
	})

	const onClickSave = async () => {

		setState({ saving: true });
		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await updateOrganisation(organisation);

			closeSnackbar(key);
			enqueueSnackbar('Saved', {variant: 'success'});

			setState({ saving: false });

		} catch (e) {
			closeSnackbar(key);
			enqueueSnackbar('Error', {variant: 'error'});
			setState({ saving: false });
		}
	}

	if (selectedTeam) {
		return <Navigate to={"/team/"+selectedTeam.id} />
	}

	return (

		<>

			<FlexRowSpaceBetween>
                <Title>
                    Company infos
                </Title>

                <div>
                    <Button
                        loading={false} disabled={loading || saving || hasError}
                        onClick={onClickSave}
                    >{"Enregistrer"}</Button>
                </div>
            </FlexRowSpaceBetween>

			<Divider />


			<Paper type="form">

				<MediaUploader
					onChange={media => {
						const logo = media ? media.payload.mediaObject : null
						setState({
							organisation: {
								...organisation,
								logo_id: media ? media.payload.mediaObject.id : null,
								logo: logo
							}
						})
						setLogo(logo ? {
							src: logo.path,
							type: logo.type
						}: null)
					}}
					initialMedia={logo}
					title="Uploadez votre logo"
					infos={"Uploadez votre logo"}
					btnLabel={"Selectionnez une image"}
					style={{ width: "250px", height: "300px" }}
					fileType={"image/*"}
					canCrop={true}
				/>

				<TemplateSelector
					label="Signature par défaut pour tous les employés"
					selected_id={(organisation.signature_template_id) || null}
					onChange={({target: { value: signature_template_id }}) => {
						setState({ organisation: {
							...organisation,
							signature_template_id
						} })
					}}
				/>

				<Form
					config={{
						attributes: {
							domain: { type: 'text', disabled: true },

							name: { type: 'text', disabled: loading || saving },
							address: { type: 'text', disabled: loading || saving },
							website_url: { type: 'text', validators: ['url'], disabled: loading || saving },

							facebook: { type: 'text', validators: ['url'], disabled: loading || saving },
							instagram: { type: 'text', validators: ['url'], disabled: loading || saving },
							linkedin: { type: 'text', validators: ['url'], disabled: loading || saving },
							twitter: { type: 'text', validators: ['url'], disabled: loading || saving },
							// google_play_url: { type: 'text', validators: ['url'], disabled: loading || saving },
							// appstore_url: { type: 'text', validators: ['url'], disabled: loading || saving },
							// disclaimer: { type: 'text', multiline: 10, style: {width: '100%'}, disabled: loading || saving },
						}
					}}
					values={organisation}
					onChange={({values: organisation, hasError}) => setState({ organisation, hasError })}

					className={css.form}
					textFieldClassName={classes.textField}
				/>
			</Paper>

		</>
	);
}


export default connect(
	state => ({
		organisation: state.organisation
	}),
	{ updateOrganisation }
)(withSnackbar(withStyles(styles)(Company)));




