// @flow
import { useState, useEffect } from 'react';

type DataList = {data: Array<any>}
type Props = {
    getData: any => Promise<DataList>,
    initialDataList: DataList
}
export default ({ getData, initialDataList }: Props) => {
    const [dataList: DataList, setDataList] = useState(initialDataList);

    const loadData = async () => {
        setDataList(await getData());
    }
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return dataList;
}