// @flow
import { v4 as generateRandomID } from 'uuid';
import { constructFirebaseStorageUrl } from '@knxlab/utils';

export function extractExtension(filename: string): string {
    let ext = /(?:\.([^.]+))?$/.exec(filename);
    if (ext != null && ext[0] != null) {
        return ext[0]
    } else {
        return ''
    }
}

export default class FirebaseUpload {

    ref: any
    filename: string
    metadata: Object
    task: Object


    async upload({
        file,
        filetype,
        storageRef,
        onUploadProgress = () => {},
        onUploadSuccess = () => {},
        onUploadError = () => {}
    }: {
        file: File | Blob,
        filetype: string,
        storageRef: Object,
        onUploadProgress: (progress: number, task: Object) => void,
        onUploadSuccess?: (filename: string, downloadURL: string, task: Object) => void,
        onUploadError?: (error: Object, task: Object) => void
    }) : Promise<{ downloadURL: string, ref: string }> {


        this.filename = generateRandomID();
        this.metadata = {
            contentType: filetype
        };
        this.task = storageRef.child(this.filename).put(file, this.metadata);

        return await new Promise((resolve) => {
            this.task.on('state_changed', snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onUploadProgress(progress, this.task)
            }, error => {
                onUploadError(error, this.task)
            }, async () => {
                // SUCCESS
                const ref = this.task.snapshot.ref.location.path;
                const downloadURL = constructFirebaseStorageUrl(this.task.snapshot.ref.location.bucket, this.task.snapshot.ref.location.path)
                onUploadSuccess(this.filename, downloadURL, this.task);

                resolve({ downloadURL, ref });
            })
        })


    }
}