// @flow
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from 'notistack';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import Form from 'AppCore/Components/Form/formFields';
import Button from 'AppCore/Components/Button';

import type { Attributes } from './formFields'

import css from './styles.css';

type Props = {
	getData?: () => Promise<Object>,
	updateData?: (data: Object) => Promise<boolean>,

	onSaved?: () => any,
	disabled?: boolean,

	attributes?: Attributes,
	className?: any,
	style?: any
}

export default ({

	getData = async () => ({}),
	updateData = async (data) => true,

	onSaved = () => {},
	disabled = false,

	attributes: _attributes = {},
	className = "",
	style = {}

}: Props) => {

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const form = useRef(null);
	const [ data, setData ] = useState({});

	const [ hasError, setHasError ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const [ initialLoading, setInitialLoading ] = useState(true);
	const [ saving, setSaving ] = useState(false);
	const [ changed, setChanged ] = useState(false);

	useEffect(() => {
		(async () => {
			const _data = (await getData()) || {};

			const data = {};
			Object.keys(_attributes).forEach(key => {
				data[key] = _data[key];
			})

			setData(data);
			setLoading(false);
			setInitialLoading(false);
		})()
	}, [])

	const onClickSave = async () => {
		if (form.current && form.current.checkAllErrors()) {
			return false;
		}

		setSaving(true);
		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		try {

			await updateData(data);

			closeSnackbar(key);
			enqueueSnackbar('Saved', {variant: 'success'});

			setSaving(false);
			setChanged(false);
			await onSaved();
		} catch (e) {
			console.log(e);
			closeSnackbar(key);
			enqueueSnackbar('Error: ' + e.message, {variant: 'error'});
			setSaving(false);
		}
	}

	const attributes = {};
	Object.keys(_attributes).forEach(key => {
		attributes[key] = {..._attributes[key]};
		if (Object.prototype.hasOwnProperty.call(attributes[key], 'disabled')) {
			return;
		}
		attributes[key].disabled = disabled || loading || saving;
	})

	if (initialLoading) {
		return <div className={className}><ActivityIndicator /></div>;
	}

	return (
		<div className={className} style={style}>
			<Form
				ref={form}
				config={{
					attributes
				}}
				values={data}
				onChange={({ values: data, hasError }) => {
					setHasError(hasError);
					setData(data);
					setChanged(true);
				}}
				className={css.form}
			/>

			<Button
				key="save"
				onClick={onClickSave}
				loading={saving}
				variant="contained"
				color="primary"
				disabled={disabled || !changed || loading || saving || hasError}
			>
				save
			</Button>
		</div>
	)
}