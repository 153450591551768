import React from "react";
import PageFormLoader from 'AppCore/Components/CommonPages/PageFormWithLoader/loader';

import { ProductCategory } from '@izzistock/models';

export default () => {

    const getData = async ({ id }) => {
        return await ProductCategory.get({ id })
    }

    return (
        <PageFormLoader
            defaultTitle="Modifier une catégorie"
            getData={getData}
            formAttributes={{
                name: { type: 'text', label: "Nom de la catégorie", validators: ['not_empty'] }
            }}
        />
    )
};