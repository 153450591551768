// flow

export class ErrorType extends Error {
    type: string
}
export class ErrorUnknown extends ErrorType {
    constructor(message: string) {
        super(message);
        this.type = "unknown"
    }
}
export class ErrorAspectRatio extends ErrorType {
    constructor(message: string) {
        super(message);
        this.type = "aspect_ratio"
    }
}

export class ErrorFileType extends ErrorType {
    constructor(message: string) {
        super(message);
        this.type = "file_type"
    }
}