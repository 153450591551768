import React, { useEffect, useState } from "react";
import moment from 'moment'

import { useCurrentCentre, useProducts, useSuppliers } from 'Apps/Stockizy/Store/hooks'
import { Command, SupplierCentreData } from '@izzistock/models';
import { ProductList } from '@izzistock/components';

import { useNavigate, useParams } from "react-router-dom";
import { Divider, Paper, Typography } from "@material-ui/core";
import { FlexRowEnd, FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import Button from "AppCore/Components/Button";
import styled from "styled-components";

import ReceivDialog from './ReceivDialog'
import { useSnackbar } from "notistack";
import MultipleFileUploader from "Apps/Stockizy/Components/MultipleFileUploader";
import SendDialog from "./SendDialog";

const ActionButton = styled(Button)`
    margin-right: 10px;
`


export default () => {

    const { id } = useParams();
    const [ command, _setCommand ] = useState(null);
    const [ hasChanged, setHasChanged ] = useState(null);
    const [ displayReceivScreen, setDisplayReceivScreen ] = useState(false);
    const [ displaySendDialog, setDisplaySendDialog ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ supplierCentreData, setSupplierCentreData ] = useState(null);
    const suppliers = useSuppliers();
    const allProducts = useProducts();
    const currentCentre = useCurrentCentre();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const setCommand = (command, hasChanged = true) => {
        setHasChanged(hasChanged);
        _setCommand(command);
    }

    const getData = async ({ id }) => {
        return await Command.get({ id })
    }

    useEffect(() => {
        getData({ id }).then(command => {
            setCommand(command, false);
        });
    }, [ id ])


    const reloadData = () => getData({ id }).then(command => {
        setCommand(command, false);
    });

    const supplier = command ? (suppliers.data || []).find(_supplier => _supplier.id === command.supplierId) : null;
    const createdAt = command ? moment(command.metadata.createdAt*1000) : null;
    const products = command ? command.getProductsExpanded(allProducts.data || []) : [];
    const needOrdonnance = command ? command.needOrdonnance(allProducts.data || []) : false;

    const supplierId = supplier?.id;
    const currentCentreId = currentCentre?.id;

    useEffect(() => {
        if (!command || !supplierId || !currentCentreId) {
            return;
        }
        (async () => {
            const supplierCentreData = await SupplierCentreData.get({ supplierId, centreId: currentCentreId })
            console.log("supplierCentreData", supplierCentreData);
            setSupplierCentreData(supplierCentreData);
        })()
    }, [supplierId, currentCentreId, command])

    if (!command || !supplierCentreData) {
        return null;
    }

    const onClickSend = async () => {
        setDisplaySendDialog(true)
    }

    const onClickDelete = async () => {
        if (!window.confirm("Etes vous sur de vouloir supprimer la commande ?")) {
            return false;
        }
        await Command.delete({ id })
        navigate("/commands");
    }

    const onClickReceiv = async () => {
        setDisplayReceivScreen(true)
    }
    const onClickSave = async () => {
        setLoading(true);
        await command.save();
        enqueueSnackbar("Sauvegardé", {
            variant: 'success'
        })
        setHasChanged(false);
        setLoading(false);
    }

    const onClickCancel = async () => {
        await reloadData()
        setHasChanged(false);
    }

    console.log("command", command);

    return (
        <>

            {displayReceivScreen &&
                <ReceivDialog
                    command={command}
                    handleClose={() => setDisplayReceivScreen(false)}
                    reloadData={reloadData}
                />
            }
            {displaySendDialog &&
                <SendDialog
                    command={command}
                    supplier={supplier}
                    supplierCentreData={supplierCentreData}
                    handleClose={() => setDisplaySendDialog(false)}
                    reloadData={reloadData}
                />
            }

            <FlexRowSpaceBetween>
                <Typography>Commande pour {supplier && supplier.name}</Typography>
                {!!createdAt && `Date de création : ${createdAt.calendar()}` }
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FlexRowSpaceBetween>
                <div>Status : {command.getDisplayStatus()}</div>
                <FlexRowEnd>
                    {command.status === Command.STATUS_SENT && <ActionButton color="primary" variant="contained" onClick={onClickReceiv}>Receptionner</ActionButton>}
                    {command.status === Command.STATUS_CREATED && <ActionButton disabled={hasChanged} color="primary" variant="contained" onClick={onClickSend}>Envoyer</ActionButton>}
                    {command.status === Command.STATUS_CREATED && <ActionButton disabled={hasChanged} color="primary" variant="contained" onClick={onClickDelete}>Supprimer</ActionButton>}
                    {hasChanged && command.status === Command.STATUS_CREATED && <ActionButton disabled={loading} color="primary" variant="contained" onClick={onClickCancel}>Annuler</ActionButton>}
                    {hasChanged && command.status === Command.STATUS_CREATED && <ActionButton disabled={loading} color="primary" variant="contained" onClick={onClickSave}>Enregistrer</ActionButton>}
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            {needOrdonnance && <>
                <FlexRowSpaceBetween>
                    <div>Ordonnances</div>
                </FlexRowSpaceBetween>

                <Paper style={{ marginTop: "20px", padding: '20px' }}>
                    <MultipleFileUploader
                        files={command.ordonnances}
                        onFilesChange={files => {
                            setCommand(command.clone(c => {
                                c.setData({
                                    ordonnances: files
                                })
                            }))
                        }}
                        editable={command.status === Command.STATUS_CREATED}
                    />
                </Paper>

                <br/>
                <Divider />
                <br/>
            </>
            }

            <FlexRowSpaceBetween>
                <div>Liste des produits</div>
            </FlexRowSpaceBetween>

            <Paper style={{ marginTop: "20px", paddingTop: '20px' }}>
                <ProductList
                    products={products}
                    renderAction={product => {

                        if (command.status === Command.STATUS_RECEIVED) {
                            return (
                                <div>
                                    {product.payload.qtyReceived} / {product.payload.qty} réceptionnés
                                </div>
                            )
                        }


                        if (command.status !== Command.STATUS_CREATED) {
                            return null;
                        }
                        return (
                            <div>
                                <button onClick={e => {
                                    setCommand(command.clone(c => {
                                        c.products = c.products.map(_product => {
                                            return {
                                                ..._product,
                                                qty: _product.id === product.id ? Math.max(0, _product.qty-1) : _product.qty
                                            }
                                        })
                                    }))
                                }}>-</button>
                                {product.payload.qty}
                                <button onClick={e => {
                                    setCommand(command.clone(c => {
                                        c.products = c.products.map(_product => {
                                            return {
                                                ..._product,
                                                qty: _product.id === product.id ? (_product.qty+1) : _product.qty
                                            }
                                        })
                                    }))
                                }}>+</button>
                            </div>
                        )
                    }}
                />
            </Paper>

        </>
    )
};