

export const getFormAttributes = ({ centreId, type, currentUser, centres }) => ({
    email: {
        type: 'text',
        disabled: type === 'edit',
        validators: ['email']
    },
    displayName: {
        type: 'text',
        validators: ['not_empty']
    },
    centreId: {
        type: 'select',
        disabled: !!centreId || !currentUser.isSuperAdmin,
        values: centres.data.map(centre => ({ label: centre.name, value: centre.id }))
    },
    isActive: {
        type: 'switch',
        disabled: !currentUser.hasPermission('user_edit')
    },
    isAdmin: {
        type: 'switch',
        disabled: !currentUser.isSuperAdmin
    }
})