// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import dragula from "react-dragula";


import "react-dragula/dist/dragula.css";

const selfOrParentHasClass = (el, className) => {
    if (el.classList && el.classList.contains && el.classList.contains(className)) {
        return true;
    }
    if (!el.parentNode) {
        return false;
    }
    return selfOrParentHasClass(el.parentNode, className)
}

const Container = styled.div``
const ReorderContainer = styled.div``

type Props = {
    onChange: (order: Array<any>) => any,
    children?: any
}
export default ({ children, onChange }: Props) => {

    const reorderContainers = [];
    const handleReorderContainerLoaded = container => {
        if (container) {
            reorderContainers.push(container);
        }
    };

    useEffect(() => {

        const drake = dragula({
            containers: reorderContainers,
            moves: (el, source, handle, sibling) => {
                if (selfOrParentHasClass(handle, "drag-bloc-indicator")) {
                    return true;
                }
                return false;
            }
        }).on('drop', () => {
            const newOrder = [];
            [ ...reorderContainers[0].childNodes ].map(node => {
                newOrder.push(parseInt(node.dataset.order, 10))
                return true;
            })

            drake.cancel(true);
            onChange(newOrder)

        })

        return () => {
            drake.destroy();
        }
    }, [reorderContainers, onChange])

    return (
        <Container>
            <ReorderContainer ref={handleReorderContainerLoaded}>
                {children}
            </ReorderContainer>
        </Container>
    )
}