// @flow
import AbstractObject from '../AbstractObject';
import ButtonStyle, { schemaId as ButtonStyleSchemaId }  from '../ButtonStyle';
import SpacingStyle, { schemaId as SpacingStyleSchemaId } from '../SpacingStyle';

export const schemaId = "/VideoConf";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {

        key: { type: "text" },
        buttonText: { type: "text" },
        url: { type: "text" },

        colorsAuto: { type: 'boolean' },
        buttonStyle: { '$ref': ButtonStyleSchemaId },
        spacingStyle: { '$ref': SpacingStyleSchemaId }
    }
}

const NAME = "VideoConf";
export default class VideoConf extends AbstractObject {

    static options = {
        'zoom': {
            optionLabel: "Zoom",
            iconName: "zoom",
            iconSrc: "/static/img/signally/icons/zoom.png",
            defaultButtonText: "Meet me on Zoom",

            color: '#fff',
            backgroundColor: '#249dff'
        },
        'skype': {
            optionLabel: "Skype",
            iconName: "skype",
            iconSrc: "/static/img/signally/icons/skype.png",
            defaultButtonText: "Meet me on Skype",

            color: '#fff',
            backgroundColor: '#00bef8'
        },
        'hangouts': {
            optionLabel: "Google Hangouts",
            iconName: "google-hangouts",
            iconSrc: "/static/img/signally/icons/google-hangouts.png",
            defaultButtonText: "Meet me on Google Hangouts",

            color: '#fff',
            backgroundColor: '#00b066'
        },
        'meet': {
            optionLabel: "Google Meet",
            iconName: "google-meet",
            iconSrc: "/static/img/signally/icons/google-meet.png",
            defaultButtonText: "Meet me on Google Meet",

            color: '#fff',
            backgroundColor: '#00a090'
        }
    }

    key: string = 'zoom'
    buttonText: string = VideoConf.options.hangouts.defaultButtonText
    url: string
    colorsAuto: boolean = true

    buttonStyle: ButtonStyle = new ButtonStyle()
    spacingStyle: SpacingStyle = new SpacingStyle()

    getButtonStyle() {
        return {
            ...this.buttonStyle.getButtonStyle(),
            ...(this.colorsAuto ? {
                color: VideoConf.options[this.key].color,
                backgroundColor: VideoConf.options[this.key].backgroundColor
            } : {})
        }
    }

    isEnabled() {
        return true;
    }

    getName() {
        return NAME;
    }
}
VideoConf.register({ name: NAME, schema, __class: VideoConf });