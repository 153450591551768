// @flow

import { AbstractObject } from '@knxlab/models';

import Team, { schemaId as TeamSchemaId } from './Team'

const schema = {
    type: "object",
    properties : {
        id: { type: "string" },
        email: { type: "string" },
        first_name: { type: "string" },
        last_name: { type: "string" },
        position: { type: "string" },
        phone_number: { type: "string" },
        team: { "$ref": TeamSchemaId },

        signature: {
            type: "object",
            properties: {
                id: { type: 'string' },
                name: { type: 'string' },
                version: { type: 'string' }
            }
        }
    }
}
const NAME = "UserSignally";

export default class User extends AbstractObject {

    id: string
    email: string
    first_name: string
    last_name: string
    team: Team
    position: string
    phone_number: string

    signature: Object

    getDisplayName() {
        if (!this.first_name && !this.last_name) {
            return "";
        }
        return `${this.first_name || ""} ${this.last_name || ""}`
    }

    getName() {
        return NAME;
    }
}
User.register({ name: NAME, schema, __class: User });