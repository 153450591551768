import React from "react";
import { useNavigate } from 'react-router-dom'
import css from './HeaderUnlogged.module.css';
import Button from "Apps/Signally/Components/Button";

import Logo from 'Apps/Signally/Images/logo.png';

export default ({ fullScreen = false } = {}) => {

	const navigate = useNavigate();

	return (
		<div className={css.container} style={fullScreen ? {maxWidth: '100%'} : {}}>
			<div className={css.logoContainer} onClick={() => navigate('/')}>
				<img id='logoImg' className={css.logo} alt="" style={{height: 45}} src={Logo} />
				<span className={css.title}>Signally</span>
			</div>

			<div className={css.actionContainer}>
				<Button
					onClick={console.log}
					variant="text"
				>
					Tarifs
				</Button>
				<Button onClick={() => navigate('/auth/sign-in')} style={{marginLeft: '10px'}}>
					Connexion / Inscription
				</Button>
			</div>
		</div>
	)
}