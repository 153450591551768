// @flow
import React from "react";
import styled from 'styled-components'
import {
    Typography
} from "@material-ui/core";
import { Image as ImageIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import Image from 'AppCore/Components/FirebaseImage'

import { renderTime } from 'AppCore/Utils'

import { Exercice, TrainingSessionBlocExercice } from "@nopanoga/models";

import {
    Card, ImageContainer
} from './styledComponents'
import type { Props } from './index'



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        paddingLeft: '24px'
    },
    cover: {
        width: 151,
    },
}));



const InfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;

    > .actions {
        opacity: 0;
    }

    &:hover .actions {
        opacity: 1;
    }
`;
const TitleRepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const CardActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
`

export const Draggable: any = React.memo(({
    exercice = Exercice.getRandomData(),
    blocExercice,
    actions,
    style = {},

}: Props & { blocExercice?: TrainingSessionBlocExercice } ) => {

    const classes = useStyles();

    return (
        <Card className={classes.root} variant='outlined' style={style}>
            <ImageContainer>
                {!exercice.image &&
                    <ImageIcon fontSize='large'/>
                }
                {exercice.image &&
                    <Image storageRef={exercice.image.ref} />
                }
            </ImageContainer>
            <InfoContainer>

                    <TitleRepsContainer>
                        <Typography component="h5" variant="h5">{exercice.title}</Typography>

                        {blocExercice &&
                        <Typography variant="body1" color="textSecondary">
                            {blocExercice.type === "timer" && blocExercice.timer + " secondes"}
                            {blocExercice.type === "reps" && "x" + blocExercice.reps}
                        </Typography>}
                    </TitleRepsContainer>

                    {actions &&
                        <CardActions className="actions">
                            {actions}
                        </CardActions>
                    }

            </InfoContainer>
        </Card>
    );
})



const CardStripes = styled(Card)`
    border-color: #E1E1E1;
    background: repeating-linear-gradient(
        -45deg,
        rgba(117, 117, 117, 0.1),
        rgba(117, 117, 117, 0.1) 40px,
        #fff 40px,
        #fff 50px
    );

    &:hover {
        background: repeating-linear-gradient(
            -45deg,
            rgba(117, 117, 117, 0.2),
            rgba(117, 117, 117, 0.2) 40px,
            #fff 40px,
            #fff 50px
        );
    }
`

type RestProps = {
    timer: number,
    actions?: Array<any>,
    style?: any,
    className?: any
}
export const RestDraggable: any = React.memo(({
    timer,
    actions,
    style = {},
    className,
}: RestProps) => {

    const classes = useStyles();

    return (
        <CardStripes className={classes.root} variant='outlined' style={style}>
            <ImageContainer></ImageContainer>
            <InfoContainer>

                <TitleRepsContainer>
                    <Typography component="h5" variant="h5">repos</Typography>
                    <Typography variant="body1" color="textSecondary">
                        {renderTime(timer)}
                    </Typography>
                </TitleRepsContainer>

                {actions &&
                    <CardActions className="actions">
                        {actions}
                    </CardActions>
                }
            </InfoContainer>
        </CardStripes>
    );
})