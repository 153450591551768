import React from 'react';



export default ({ ...props }) => (
	<article>
          <ol>
            <li>Generate an email signature using <b>MySignature</b> editor.</li>
            <li>Copy HTML code from <b>"Source code"</b> section.</li>
            <li>Within Thunderbird click <b>Tools</b>&nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp;<b>Account Settings</b>, and then, in the left panel, select the account for which you want to create a signature.</li>
            <li>Check the "Use HTML" option and <b>Paste</b> your signature (Ctrl+V or Command+V).</li>
          </ol>
         </article>
)