// @flow
import React from "react";
import styled from 'styled-components'

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ColorPicker from 'AppCore/Components/ColorPicker';

import FontStyle from '../../DataObject/FontStyle';

import {
    LineContainer, InputLabel, InputContainer,
    Slider
} from './Helpers'

const Container = styled.div`
    width: 100%;
`


export default ({ fontStyle, twoColors = false, onChange }: { fontStyle: FontStyle, twoColors: boolean, onChange: (fontStyle: FontStyle) => any}) => {


    return (
        <Container>
            <Slider
                label={"Taille de la police"}
                value={fontStyle.fontSize || 8}
                min={8}
                max={30}
                step={1}
                onChange={value => onChange(fontStyle.clone(s => s.fontSize = value))}
            />

            <LineContainer>
                <InputLabel>Couleur</InputLabel>
                <InputContainer>
                    <ColorPicker
                        style={{width: '30px', height: '30px'}}
                        color={fontStyle.fontColor}
                        onChange={value => onChange(fontStyle.clone(s => s.fontColor = value))}
                    />
                    {twoColors &&
                        <ColorPicker
                            style={{marginLeft: '10px', width: '30px', height: '30px'}}
                            color={fontStyle.fontColor2}
                            onChange={value => onChange(fontStyle.clone(s => s.fontColor2 = value))}
                        />
                    }
                </InputContainer>
            </LineContainer>

            <LineContainer>
                <InputLabel>Police</InputLabel>
                <InputContainer>
                    <Select
                        style={{width: '100%'}}
                        value={fontStyle.fontFamily}
                        onChange={({ target: { value }}) => onChange(fontStyle.clone(s => s.fontFamily = value))}
                    >
                        {[
                            'Arial Black',
                            'Comic Sans MS',
                            'Impact',
                            'Lucida Sans Unicode',
                            'Tahoma',
                            'Trebuchet MS',
                            'Verdana',
                            'Courier New',
                            'Lucida Console',
                            'Georgia',
                            'Palatino',
                            'Times New Roman'
                        ].map(fontFamily => (
                            <MenuItem key={fontFamily} style={{fontFamily}} value={fontFamily}>{fontFamily}</MenuItem>
                        ))}
                    </Select>
                </InputContainer>
            </LineContainer>
        </Container>
    )
}