import Constants from "../Constants";

const { REDUCER: C } = Constants;


export const setOrganisationOnboarding = () => async (dispatch, getState, { api }) => {
	const { organisation } = getState();
	const { id: organisation_id } = organisation;

	await api.setOrganisationOnboarding({ organisation_id });
	await dispatch(reloadOrganisation());

}

export const updateOrganisation = newDataOrganisation => async (dispatch, getState, { api }) => {

	const { organisation } = getState();
	const { id: organisation_id } = organisation;

	if (newDataOrganisation.logo && newDataOrganisation.logo.id) {
		newDataOrganisation.logo_id = newDataOrganisation.logo.id;
	}

	try {
		if (!await api.updateOrganisation({ organisation_id, organisation: newDataOrganisation })) {
			throw new Error("failed to save organisation");
		}

		dispatch({
			type: C.SET_ORGANISATION,
			organisation: {
				...organisation,
				...newDataOrganisation
			}
		})

	} catch (e) {
		console.error(e);
	}
}

export const resetOrganisationPayment = () => async (dispatch, getState, { api, ws }) => {

	const { id: organisation_id } = getState().organisation || {};

	await api.resetOrganisationPayment({ organisation_id });
	await dispatch(reloadOrganisation());
}


export const reloadOrganisation = () => async (dispatch, getState, { api, ws }) => {

	const { id: organisation_id } = getState().organisation || {};

	const organisation = await api.getOrganisation({ organisation_id });

	dispatch({
		type: C.SET_ORGANISATION,
		organisation
	});

	return organisation;
}