import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Modal, { ModalContent } from 'AppCore/Components/Modal';
import Button from 'Apps/Signally/Components/Button';
import TextField from 'Apps/Signally/Components/InputFields/TextField';
import { createTeam } from 'Apps/Signally/Actions';


const styles = theme => ({
	paper: {

		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		alignItems: 'center'
	},

	groupList: {

		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',

	},

	groupItem: {
		marginBottom: '20px',
		marginLeft: '10px',
		marginRight: '10px'
	}
});

class NewGroupModal extends PureComponent {

	static defaultProps = {
		onClose: () => {}
	}

	state = { name: "" }

	createTeam = async name => {

		const { createTeam, onClose } = this.props;

		this.setState({ loading: true })
		await createTeam({team: { name }})
		this.setState({ loading: false })
		onClose();
	}

	render() {

		const { classes, onClose = () => {} } = this.props;
		const { loading = false, name } = this.state;

		return (

			<Modal
				open
				onClose={onClose}
				title="Create a Team"
				actions={[
					<Button key="cancel" color="none" onClick={onClose}>
						Cancel
					</Button>,
					<Button loading={loading} key="loading" color="blue" onClick={e => this.createTeam(this.state.name)}>
						Save
					</Button>
				]}
			>
				<ModalContent>
					<TextField
						id="outlined-with-placeholder"
						label="Name of the team"
						placeholder="Name of the team"
						className={classes.textField}
						margin="normal"
						variant="outlined"
						value={name}
						style={{width: '440px'}}
						onChange={ ({target: {value: name}}) => this.setState({ name })}
					/>
				</ModalContent>
			</Modal>

		)
	}
}


export default connect(
	state => ({ }),
	{ createTeam }
)(withStyles(styles)(NewGroupModal));