// @flow
import React from 'react';
import TimerSelector from 'AppCore/Components/TimerSelector'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Button } from '@nopanoga/components'

import { TrainingSessionBlocExercice as BlocExercice } from "@nopanoga/models";
import styled from 'styled-components';
import NumberSelector from 'AppCore/Components/NumberSelector';

const SelectorContainer = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`

type Props = {
    blocExercice: BlocExercice,
    onBlocExerciceChange: (blocExercice: BlocExercice) => any,
    onClose: any => any
}
export default ({
    blocExercice,
    onClose,
    onBlocExerciceChange = (blocExercice: BlocExercice) => {}
}: Props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ blocExerciceCopy, setBlocExerciceCopy ] = useState(blocExercice.clone());

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >

            <DialogTitle disableTypography>
                <Typography variant="h2">{blocExerciceCopy.isRest() ? "Repos": blocExerciceCopy.exercice.title}</Typography>
            </DialogTitle>

            <DialogContent>

                {blocExerciceCopy.isRest() && <DialogContentText>
                    Réglez le temps de repos
                </DialogContentText>}

                {!blocExerciceCopy.isRest() &&
                    <ToggleButtonGroup size='small' orientation="horizontal" value={blocExerciceCopy.type} exclusive onChange={(e, value) => {
                        if (!value) {
                            return;
                        }
                        setBlocExerciceCopy(blocExerciceCopy.clone(clone => {
                            clone.setData({
                                type: value
                            })
                        }))
                    }}>
                        <ToggleButton value="reps" aria-label="list">
                            Répétitions
                        </ToggleButton>
                        <ToggleButton value="timer" aria-label="module">
                            Timer
                        </ToggleButton>
                    </ToggleButtonGroup>
                }

                <SelectorContainer>
                    {(blocExerciceCopy.isRest() || blocExerciceCopy.type === "timer") &&
                        <TimerSelector
                            timer={blocExerciceCopy.timer}
                            onChange={timer => {
                                setBlocExerciceCopy(blocExerciceCopy.clone(clone => {
                                    clone.setData({
                                        timer
                                    })
                                }))
                            }}
                        />
                    }

                    {blocExerciceCopy.type === 'reps' &&
                        <NumberSelector min={1} value={blocExerciceCopy.reps} onChange={reps => {
                            setBlocExerciceCopy(blocExerciceCopy.clone(clone => {
                                clone.setData({
                                    reps
                                })
                            }))
                        }} />
                    }
                </SelectorContainer>
            </DialogContent>
            <DialogActions>
                <Button color="tertiary" autoFocus onClick={onClose}>
                    Annuler
                </Button>
                <Button onClick={() => {
                    onBlocExerciceChange(blocExerciceCopy.clone());
                    onClose();
                }} color="primary" autoFocus>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
}