import React, { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import _get from 'lodash.get';

import DataTable from 'AppCore/Components/DataTable';

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import Button from 'Apps/Signally/Components/Button'
import Api from 'Apps/Signally/Api';

import EditCreateCampaignModal from 'Apps/Signally/Pages/Modals/Campaign/editCreate';
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";
import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";


export default () => {

	const [ campaigns, setCampaigns ] = useState([]);
	const [ modalEdit, setModalEdit ] = useState(null);
	const [ modalCreate, setModalCreate ] = useState(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [ selectedTeam ] = useTeamContext();
	const selectedTeamId = selectedTeam ? selectedTeam.id : null;
	const company = useCurrentCompany();

	const loadBanners = async () => {
		let result;
		if (selectedTeamId) {
			result = await Api.getTeamCampaigns({ team_id: selectedTeamId })
		} else {
			result = await Api.getCampaigns({ organisation_id: company.id });
		}
		setCampaigns(result.data);
	}

	useEffect(() => {
		loadBanners();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedTeamId ])

	const onClickEdit = campaign_id => {
		setModalEdit({
			campaign_id,
			open: true
		})
	}

	const onClickCreate = () => {
		setModalCreate({
			open: true
		})
	}

	const onClickDelete = async (campaign_id, campaign) => {
		if (!window.confirm(`Are you sure you want to delete ${campaign.name} ?`)) {
			return;
		}

		const key = enqueueSnackbar('Deleting campaign...', {variant: 'info'});
		await Api.deleteCampaign({ campaign_id });
		await loadBanners();
		closeSnackbar(key);
		enqueueSnackbar('deleted', {variant: 'success'});
	}

	return (
		<>

			<FlexRowSpaceBetween>
				<Title>
					Bannières
				</Title>

				<div>
					<Button
						loading={false}
						onClick={onClickCreate}
					>{"Créer une bannière"}</Button>
				</div>
			</FlexRowSpaceBetween>

			<Divider />

			<>

				<DataTable
					data={campaigns}
					config={{
						cells: [
							{ title: 'Name', key: 'name' },
							...(selectedTeamId ? [] : [
								{ title: "Equipe", key: 'team.name' }
							]),
							{ title: 'Date de début', key: 'start_date' },
							{ title: 'Date de fin', key: 'end_date' },
							{ title: 'Banner', key: 'banner', noPadding: true },
						],
						actions: [
							{ icon_name: 'edit', title: "Edit", onClick: onClickEdit },
							{ icon_name: 'delete', title: "Delete", onClick: onClickDelete }
						]
					}}
					getCell={(campaign, attr_name) => {

						if (attr_name === 'start_date' || attr_name === 'end_date') {
							if (!campaign[attr_name]) {
								return "";
							}
							return moment(campaign[attr_name]).format('LLLL');
						}

						if (attr_name === 'banner') {
							if (!campaign.banner || !campaign.banner.path) {
								return null
							}
							return (
								<img src={campaign.banner.path} alt="" style={{border: "0.9px dotted black",maxHeight: '50px', maxWidth: '200px'}} />
							)
						}

						if (attr_name === 'active') {
							return campaign[attr_name] ? "YES": "NO";
						}

						return _get(campaign, attr_name) || '';
					}}
				/>

				{(modalEdit || modalCreate) &&
					<EditCreateCampaignModal
						open
						campaign_id={modalEdit ? modalEdit.campaign_id : null}
						team_id={selectedTeamId}
						onClose={() => { setModalCreate(null); setModalEdit(null); }}
						onSaved={() => { setModalCreate(null); setModalEdit(null); loadBanners(); }}
					/>
				}

			</>

		</>
	);
}