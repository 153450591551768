
let CONFIG = {};
export const setConfig = _config => {
    CONFIG = _config;
}

export const getConfig = () => {
    return {
        ...CONFIG
    };
}