import { blue, green, grey } from "@material-ui/core/colors";
import { colors } from '@nopanoga/components'

import overrides from './Overrides'

export default {
    name: "Dark",

    overrides,

    palette: {
        primary: {
            main: colors.primary,
            contrastText: colors.primaryContrastText
        },
        secondary: {
            main: colors.secondary,
            contrastText: colors.secondaryContrastText
        }
    },

    header: {
        color: grey[500],
        background: "#FFFFFF",
        search: {
            color: grey[800]
        },
        indicator: {
            background: blue[600]
        }
    },

    sidebar: {
        color: 'white',
        background: colors.secondary,
        backgroundActiv: 'rgba(0, 0, 0, 0.3)',
        backgroundHover: 'rgba(0, 0, 0, 0.15)',

        item: {
            colorActiv: colors.primary
        },

        header: {
            color: 'white',
            background: colors.secondary
        },
        footer: {
            color: 'white',
            background: colors.secondary,
            online: {
                background: green[500]
            }
        },
        category: {
            fontWeight: 400
        },
        badge: {
            color: "#FFF",
            background: blue[500]
        }
    },
    body: {
        background: "#F3F5F3"
    }
}