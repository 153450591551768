import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { ModalContent } from 'AppCore/Components/Modal';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import TemplateSelector from 'Apps/Signally/Components/Views/TemplateSelector';
import CampaignSelector from 'Apps/Signally/Components/Views/CampaignSelector';

import { updateTeam, createTeam } from 'Apps/Signally/Actions';
import Api from 'Apps/Signally/Api';

import css from './editCreate.module.css';

import ModalForm from '../ModalForm'

class EditGroupModal extends ModalForm {

	getFormAttributeConfig = () => {

		const { loading = false, saving = false } = this.state;

		return {
			name: { type: 'text', validators: ['not_empty'], disabled: loading || saving},
		};
	}

	loadData = async () => {
		this.setState({
			data: await this.getData(),
			loading: false
		})
	}

	getData = async () => {
		const { team_id } = this.props;
		if (team_id) {
			const team = await Api.getTeam({ team_id });
			return team;
		}

		return {};
	}

	save = async () => {
		const { team_id, organisation_id } = this.props;
		const { data: team } = this.state;

		if (team_id) {
			await this.props.updateTeam({ team_id, team });
		} else {
			await this.props.createTeam({ organisation_id, team });
		}
	}

	onChangeValue = attr_name => ({ target: { value } }) => {
		this.setState({
			data: {
				...this.state.data,
				[attr_name]: value
			}
		})
	}

	renderModalContent() {

		const { loading = false, data = {} } = this.state;

		return (loading ? <ActivityIndicator /> :

			<ModalContent>

				<div className={css.formContainer}>

					{this.renderForm({ formClassName: css.form })}

					<TemplateSelector selected_id={(data.signature_template_id) || null} onChange={this.onChangeValue('signature_template_id')}/>
					<CampaignSelector selected_id={(data.campaign_id) || null} onChange={this.onChangeValue('campaign_id')}/>
				</div>
			</ModalContent>);
	}

	getTitle() {
		const { team_id } = this.props;
		return team_id ? "Edit Team" : "New Team"
	}


}


export default connect(
	state => ({
		organisation_id: state.Auth.organisation_id
	}),
	{ updateTeam, createTeam }
)(withSnackbar(EditGroupModal));