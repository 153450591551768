// @flow
import React, { PureComponent } from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import _get from 'lodash.get';
import Icon from 'AppCore/Components/Icons';

import * as validatorsFct from './validators';
import type { ValidatorOptions } from './validators'
import type { TransformersOptions } from './transformers'

import InputField from './inputField';
import AutocompleteTags from "./inputFields/AutocompleteTags";
import Slider from "./inputFields/Slider";
import type { Option as OptionType } from './inputFields/AutocompleteTags'

const styles = theme => ({

	container: {
		position: 'relative',
		marginBottom: '10px'
	},

	errorContainer: {
		position: 'absolute',
		right: 10,
		top: 0,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
    }
});

const objectToArrayMap = obj => Object.keys(obj).map(attr_name => ({ attr_name, value: obj[attr_name] }))

// const { type, multiline = false, disabled = false, label = null, placeholder = null, values = [], options = [] } = attributes[attr_name];

export type Attributes = {
	[key: string]: {
		type: string,
		multiline?: boolean | number,
		disabled?: boolean,

		label: ?string,
		placeholder?: ?string,
		values?: Array<any>,
		options?: Array<OptionType> | Array<any>,

		validators?: ValidatorOptions,
		transformers?: TransformersOptions,
		onsave_transformers?: any,
		style?: any,

		TagComponent?: any,

		endAdornment?: any,
		startAdornment?: any
	}
}

type Props = {
	config: {
		attributes: Attributes
	},
	onChange: ({ values: any, errors: any, hasError: boolean }) => any,
	values: any,

	textFieldClassName: any,
	classes: any
}
type State = {
	errors: any
}
class Form extends PureComponent<Props, State> {

	state = { errors : {} }

	hasError = attr_name => {
		if (attr_name !== undefined) {
			return this.hasErrorAttr(attr_name);
		}

		let hasError = false;
		Object.keys(this.state.errors).forEach(attr_name => {
			if (this.state.errors[attr_name].hasError) {
				hasError = true;
				return false;
			}
		})

		return hasError;
	}

	hasErrorAttr = attr_name => (this.state.errors[attr_name] && this.state.errors[attr_name].hasError) || false
	getErrorMessage = attr_name => {
		if (!this.hasError(attr_name)) {
			return '';
		}

		const { errors } = this.state;

		return errors[attr_name]
				.errors.map(error => (validatorsFct[error] && validatorsFct[error].error_msg) || '')
				.join('; ');
	}
	getError = (attr_name, value) => {

		const { values, config: { attributes } } = this.props;

		const { validators = [] } = attributes[attr_name];
		if (value === undefined) {
			value = values[attr_name] || '';
		}

		const errors = [];
		let hasError = false;

		if (validators.indexOf('not_empty') !== -1 || value !== "") {
			validators.forEach(validator => {
				if (!validatorsFct[validator] || validatorsFct[validator](value)) return;
				hasError = true;
				errors.push(validator);
			});
		}

		return {
			hasError,
			errors
		}
	}

	checkAllErrors = () => {
		const { config: { attributes } } = this.props;

		const errors = {};
		let hasError = false;
		Object.keys(attributes).forEach(attr_name => {
			errors[attr_name] = this.getError(attr_name);
			hasError = hasError || errors[attr_name].hasError;
		});

		this.setState({
			errors: {
				...this.state.errors,
				...errors
			}
		}, this.callOnChange)

		return hasError;
	}

	checkErrors = (attr_name, value) => {
		this.setState({
			errors: {
				...this.state.errors,
				[attr_name]: this.getError(attr_name, value)
			}
		}, this.callOnChange)
	}

	callOnChange = _newValues => {
		const { values, onChange = config => {} } = this.props;
		const newValues = {
			...values,
			..._newValues
		};

		onChange({
			values: JSON.parse(JSON.stringify(newValues)),
			errors: this.state.errors,
			hasError: this.hasError()
		});
	}

	newOnChangeField = (attr_name, event_attr = 'value') => value => {
		this.callOnChange({
			[attr_name]: value
		});
		if (this.hasError(attr_name)) {
			this.checkErrors(attr_name, value);
		}
	}

	getFieldValue = (attr_name, _default = "") => {
        const { values } = this.props;

        return _get(values, attr_name, _default);
	}

	renderFieldAttribute = attr_name => {

		const { config: { attributes } } = this.props;
		const {
			type, multiline = false, disabled = false, label = null, placeholder = null, values = [], options = [], TagComponent = null,
			endAdornment, startAdornment
		} = attributes[attr_name];


		if (type === 'autocomplete') {
			return (
				<AutocompleteTags
					onChange={this.newOnChangeField(attr_name)}
					value={this.getFieldValue(attr_name)}
					options={options}
					disabled={disabled}
					label={label || attr_name}
					placeholder={placeholder || attr_name}
					style={{width: '100%'}}
					TagComponent={TagComponent}
				/>
			)
		}

		if (type === 'slider') {
			return (
				<Slider
					onChange={this.newOnChangeField(attr_name)}
					value={this.getFieldValue(attr_name)}
					options={options}
					label={label || attr_name}
					style={{width: '100%'}}
				/>
			)
		}


		return (
			<InputField
				transformers={attributes[attr_name].transformers || []}
				onsave_transformers={attributes[attr_name].onsave_transformers || []}
				label={label || attr_name}
				type={type}
				onChange={this.newOnChangeField(attr_name)}
				onBlur={() => this.checkErrors(attr_name)}
				disabled={disabled}

				value={this.getFieldValue(attr_name)}
				values={values}

				error={this.hasError(attr_name)}
				placeholder={placeholder || attr_name}
				multiline={multiline}

				style={{width: '100%'}}
				endAdornment={endAdornment}
				startAdornment={startAdornment}
			/>
		)
	}

	render() {
		const { classes, textFieldClassName, config: { attributes }, values, onChange = () => {}, ...props } = this.props; // eslint-disable-line

		return (
			<div {...props}>
				{objectToArrayMap(attributes).map(
					({ attr_name, value: { style = {} } = {} }) => (

							<div key={attr_name} className={classNames(textFieldClassName, classes.container)} style={{
								...style,
								position: 'relative'
							}}>

								<Tooltip
									title={this.getErrorMessage(attr_name)}
									open={this.hasError(attr_name)}
									placement="right"
								>
									<div style={{display: 'inline-block', width: '100%'}}>
										{this.renderFieldAttribute(attr_name)}
									</div>
								</Tooltip>

								{this.hasError(attr_name) &&
									<div className={classes.errorContainer}>
										<Icon name="error" color="error" />
									</div>
								}
							</div>
					)
				)}

			</div>
		)
	}
}

export default withStyles(styles)(Form);