import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { setSignatureData, setOnboardingSignatureName } from 'Apps/Signally/Actions';
import SignatureData from "Apps/Signally/Components/Signature/V2/DataObject";

import Modal, { ModalContent } from 'AppCore/Components/Modal';
import Button from "Apps/Signally/Components/Button";
import SignatureLayout from 'Apps/Signally/Components/Signature/v2'

import css from './CreateSignature.module.css';

export default ({
    onEnd = () => {}
}) => {

    const dispatch = useDispatch();
    const [ openNameSignatureModal, setOpenNameSignatureModal ] = useState(false)
    const onboardingData = useSelector(state => state.onboarding)

    const {
        signatureDataV2: signatureDataRaw,
        first_edit: signatureFirstEdit,
        signatureName
    } = onboardingData || {};

    const getSignatureLayoutData = (mode = "preview") => {

		const signatureData = new SignatureData();
		signatureData.setData(signatureDataRaw);

        if ((signatureData.isInfosEmpty() || !signatureFirstEdit) && mode === "preview") {
            signatureData.setData({
                firstName: "John",
                lastName: "Smith",
                companyName: "MonEntreprise",
                positionInCompany: "CEO",
                teamName: "CODIR",
                phone: "+33612345678",
                mobile: "+33612345678",
                email: "john.smith@mon-entreprise.com",
                address: "2 rue des franc, 75001 Paris",
                companyWebsite: "https://mon-entreprise.com"
            })
        }

		return signatureData;
	}

    return (
        <div className={css.stepContainer}>
            <SignatureLayout
                style={{width: '100%'}}
                data={getSignatureLayoutData("edit")}
                dataPreview={getSignatureLayoutData("preview")}
                onChange={signatureData => {
                    dispatch(setSignatureData({
                        signatureDataV2: signatureData.export(),
                        first_edit: true
                    }))
                }}
                hasCopyButton
            />

            {openNameSignatureModal && <Modal
                open
                title="Donnez un nom à votre signature"
                onClose={() => {
                    setOpenNameSignatureModal(false);
                }}
                actions={[
                    <Button
                        onPress={() => {
                            onEnd()
                            this.setState({activeStep: 1})
                        }}
                        style={{width: '200px'}}>Save</Button>
                ]}
            >
                <ModalContent>
                    <TextField
                        variant="outlined"
                        label="Nom de la signature"
                        value={signatureName}
                        onChange={e => {
                            dispatch(setOnboardingSignatureName(e.target.value))
                        }}
                        margin="normal"
                    />
                </ModalContent>
            </Modal>}
        </div>
    )
}