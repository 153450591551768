// @flow
import * as React from 'react';
import {
    Info
} from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert';

export default ({ children }) => (
    <Alert icon={<Info />} severity="success">
        {children}
    </Alert>
)