// @flow

const TYPE_IMAGE = "image";
const TYPE_VIDEO = "video";

export const TYPES = {
    "image/jpeg" : TYPE_IMAGE,
    "image/jpg" : TYPE_IMAGE,
    "image/png" : TYPE_IMAGE,
    "image/gif" : TYPE_IMAGE,

    "video/mp4": TYPE_VIDEO
}

export const isImage = (type: string): boolean => TYPES[type] === TYPE_IMAGE
export const isVideo = (type: string): boolean => TYPES[type] === TYPE_VIDEO
export const isGif = (type: string): boolean => type === 'image/gif'

type Video = {
    src: string,
    width: number,
    height: number
}

export const loadMedia = async (fileSrc: string, fileType: string): Promise<Image | Video> => {
    return isImage(fileType) ? await loadImage(fileSrc) : await loadVideo(fileSrc)
}

export const loadImage = async (url: string) : Promise<Image> => {
    return await new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            resolve(image);
        }
        const onerror = () => {
            console.log("ERROR LOADING IMAGE");
            reject();
        }
        image.onerror = onerror
        image.src = url;
    })
}


export const loadVideo = async (url: string) : Promise<Video> => {
    return await new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.addEventListener('loadedmetadata', (event: Event) => {
            resolve({
                src: url,
                width: video.videoWidth,
                height: video.videoHeight
            })
        })
        const onerror = () => {
            console.log("ERROR LOADING VIDEO");
            reject();
        }
        video.onerror = onerror
        video.src = url;
    })
}

export const loadMediaFromFile = async (file: any) : Promise<{ src: string, width: number, height: number, type: string }> => {
    let mediaSrc = URL.createObjectURL(file);

    // OLD VERSION, keep it just in case
    // if (isVideo(file.type)) {
    //     mediaSrc = URL.createObjectURL(file);
    // } else {
    //     mediaSrc = await new Promise(resolve => {
    //         var reader = new FileReader();
    //         reader.onload = async ({ target }) => {
    //             //$FlowFixMe
    //             const { result: mediaSrc } = target;
    //             resolve(mediaSrc)
    //         }
    //         reader.readAsDataURL(file);
    //     })
    // }

    const media = await loadMedia(mediaSrc, file.type);

    return {
        src: mediaSrc,
        width: media.width,
        height: media.height,
        type: file.type
    }
}