

const CACHE_KEY = 'localstorage-cache'

const getCache = () => {
    const cacheRaw = window.localStorage.getItem(CACHE_KEY)
    try {
        return JSON.parse(cacheRaw) || {};
    } catch (e) {
        return {};
    }
}
const setCache = cacheObj => {
    const cacheRaw = JSON.stringify(cacheObj);
    window.localStorage.setItem(CACHE_KEY, cacheRaw)
}

export const set = (key, val) => {
    const cache = getCache();
    cache[key] = val;

    setCache(cache);
}

export const get = key => {
    const cache = getCache();
    return cache[key];
}