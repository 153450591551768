// @flow
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import matcher from 'matcher';
import {
	LinearProgress, CircularProgress,
    Fab,
	Icon
} from '@material-ui/core';

import { uploadFirebaseStorage, uploadMeth } from './upload'
import type { StorageConfType } from './upload'

import { useOpenModal as useOpenCropMediaModal } from './Media/CropMediaModal'

import { ErrorAspectRatio, ErrorFileType } from './errors';
import UploadedMedia from './Media';
import InstagramImport from './InstagramImport'
import DropZone from './DropZone'
import Infos from './DropZoneInfos'
import Style from './style.module.css';

import type { MediaType } from './Media'
import { loadMediaFromFile, loadMedia, isGif } from './Media/helpers'
import { isAspectRatioValid, loadFirebaseMedia } from './helpers'

const Container = styled.div`
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
`

type Props = {
    needAspectRatio?: number,
    cropAspectRatio?: number,
    canCrop?: boolean,
    onlyCropValues?: boolean,
    displayVisibleArea?: boolean,
    initialCropConf?: Object,
    onCropValuesChanged? : any => any,

    btnLabel: string,
    title: string,
    infos: string,
    style: any,

    enableInstagramImport?: boolean,

    media?: MediaType,
    onChangeMedia?: (media?: MediaType | null, cropConf?: Object) => any,
    fileType: string,

    onError?: (e: ErrorAspectRatio | ErrorFileType) => any,
    onClickDelete: any => any,

    firebaseUploadConf?: StorageConfType,
    uploadMethod?: ({
        file: File | Blob,
        filetype: string,
        onProgress: (progress: number) => any
    }) => Promise<{ downloadURL: string } & any>
}

const MediaUploader = ({
    needAspectRatio,
    cropAspectRatio,
    canCrop = true,
    onlyCropValues = false,
    displayVisibleArea = false,
    initialCropConf = {},
    onCropValuesChanged = () => {},

    btnLabel, title, infos, style = {},

    enableInstagramImport = false,
    media,
    onChangeMedia = () => {},
    fileType,

    onError,
    onClickDelete = () => {},

    firebaseUploadConf,
    uploadMethod,
}: Props) => {

    const openCropMediaModal = useOpenCropMediaModal();
    const dropZone = useRef(null)
    const [ uploading, setUploading ] = useState(false);
    const [ progress, setProgress ] = useState(0);

    const isCroppable = (_media = media) => {
        return !isGif(_media ? _media.type : "");
    }

    const uploadFile = async ({ file, media, cropConf }: { file: File | Blob, media: MediaType, cropConf?: Object }) => {
        setUploading(true);
        setProgress(0);

        let mediaUploaded = null;
        if (firebaseUploadConf) {
            mediaUploaded = await uploadFirebaseStorage({
                onProgress: setProgress,
                file,
                filetype: media.type,
                storageConf: firebaseUploadConf
            })
        } else if (uploadMethod) {
            mediaUploaded = await uploadMeth({
                onProgress: setProgress,
                file,
                filetype: media.type,
                uploadMethod
            })
        }

        console.log("cropConf", cropConf);
        onChangeMedia(mediaUploaded, cropConf)

        setProgress(100);
        setUploading(false);
    }

    const openCropMedia = (media: MediaType) => new Promise((resolve, reject) => {
        openCropMediaModal({
            media,
            aspect: needAspectRatio || cropAspectRatio,
            initialCropConf,
            onSaveFile: async ({ file, cropConf, media }) => {
                onChangeMedia(media, cropConf);
                resolve({ file, media, cropConf });
            },
            onCancel: () => {
                if (media.local) {
                    onChangeMedia(null);
                    resolve(null)
                }
            }
        })
    })

    const openCropMediaAndUpload = async (media: MediaType) => {
        const cropResult = await openCropMedia(media);
        if (cropResult !== null) {
            const { file, media, cropConf } = cropResult
            if (file && !onlyCropValues) {
                uploadFile({ file, media });
            }
            if (onlyCropValues) {
                onCropValuesChanged(cropConf);
            }
            return { file, media, cropConf };
        }
    }

    const onDrop =  async (acceptedFiles: Array<any>): Promise<void> => {

		if (acceptedFiles.length === 0) {
			return;
		}

		const file = acceptedFiles[0];

		if (!matcher.isMatch(file.type, fileType)) {
			onError && onError(new ErrorFileType(file.type))
			return;
		}

        const loadedMedia = await loadMediaFromFile(file);
		let media = {
            ...loadedMedia,
            src: URL.createObjectURL(file),
            local: true
        }

		const isValidAspectRatio = isAspectRatioValid({ height: media.height, width: media.width }, needAspectRatio)

		if (!isValidAspectRatio && !(canCrop && isCroppable(media))) {
			onError && onError(new ErrorAspectRatio("Bad aspect Ratio"))
			return;
		}

        onChangeMedia(media);
        if (canCrop && isCroppable(media)) {
            const result = await openCropMedia(media);
            if (result) {
                const { file: croppedFile, cropConf } = result;
                const fileToUpload = !onlyCropValues ? (croppedFile || file) : file;

                await uploadFile({ file: fileToUpload, media, cropConf });
            }
        } else {
            uploadFile({ file, media });
        }
    }

    const uploadMediaFromUrl = async (url: string, fileType: string) => {

		const blob = await fetch(url).then(r => r.blob())
		const blobUrl = URL.createObjectURL(blob);

		try {
			const newMedia = await loadMedia(blobUrl, fileType);
			const media = {
                src: newMedia.src,
                width: newMedia.width,
                height: newMedia.height,
                local: true,
                type: fileType
            };
            onChangeMedia(media);
			await uploadFile({ file: blob, media });
		} catch (e) {
			console.error(e);
		}
	}

    const triggerFileSelection = (e: SyntheticEvent<EventTarget, Event>) => {
		if (dropZone.current) {
            dropZone.current.triggerFileSelection();
        }
		e.stopPropagation();
	}



    let content = null;
    if (!media) {
        content = (
            <DropZone
                className={Style.container}
                ref={dropZone}
                onDrop={onDrop}
                disabled={!!media || uploading}
                accept={fileType}
            >
                <Infos
                    onClick={triggerFileSelection}
                    btnLabel={btnLabel}
                    title={title}
                    infos={infos}
                    actions={enableInstagramImport ? [
                        <InstagramImport
                            key={"insta-import"}
                            onImportMedia={async media => {
                                const mediaInfos = await loadMedia(media.media_url, 'video/mp4')
                                if (!isAspectRatioValid(mediaInfos, needAspectRatio) && !(canCrop && isCroppable())) {
                                    onError && onError(new ErrorAspectRatio("Bad aspect Ratio"))
                                    return false;
                                }
                                console.log("mediaInfos", mediaInfos);
                                uploadMediaFromUrl(media.media_url, 'video/mp4')
                                return true;
                            }}
                        />
                    ] : []}
                />
            </DropZone>
        )
    } else {
        content = (
            <UploadedMedia media={media} loading={uploading} visibleArea={displayVisibleArea ? initialCropConf : null} />
        )
    }

    const controls: Array<{onClick: any => any, iconName: string, active: boolean}> = [
       {
            onClick: () => media && openCropMediaAndUpload(media),
            iconName: 'crop',
            active: !!media && canCrop && isCroppable()
        },
        {
            iconName: 'delete',
            onClick: onClickDelete,
            active: !!media
        }
    ];
    return (
        <Container
            style={style}
        >
            {uploading &&
                <LinearProgress
					className={classNames(Style.progressBar)}
					variant="determinate"
					value={progress}
				/>}
            {media && !uploading &&
                <div className={Style.controlsContainer}>
                    {controls.map(control => control.active && (
                        <Fab key={control.iconName} className={Style.icon} size="small" color="secondary" aria-label={control.iconName} onClick={control.onClick}>
                            <Icon>{control.iconName}</Icon>
                        </Fab>
                    ))}
                </div>
            }
            {content}
        </Container>
    )
}



export const FirebaseMediaUploader = ({ media, style, fileType, firebaseUploadConf, onChangeMedia, ...props }: any) => {

    useEffect(() => {
        (async () => {
            try {
                const loadedMedia = await loadFirebaseMedia({ fileType, media, storageConf: firebaseUploadConf })
                onChangeMedia(loadedMedia);
            } catch (e) {
                onChangeMedia(null);
                console.error(e);
                console.log("ERROR loading media");
                return null;
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container
            style={style}
        >
            <CircularProgress style={{ marginLeft: "50%", left: "-20px", position: "absolute" }}/>
        </Container>
    )
}


export default ({ media, ...props }: any) => {

    if (media && media.ref && !media.src) {
        return (
            <FirebaseMediaUploader media={media} {...props} />
        )
    }

    return (
        <MediaUploader media={media} {...props} />
    )
}