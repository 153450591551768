import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;

export default (state = initialState.authData, action) => {
	switch (action.type) {
	case C.SET_AUTH_DATA:
		return {
			...action.authData,
			user: action.authData.user_id ? {
				id: action.authData.user_id
			}: null,
			userLogged: !!action.authData.access_token
		};
	default:
		return state;
	}
}