import styled from "styled-components";

import {
    Paper
} from "@material-ui/core";
import { Avatar as FirebaseAvatar } from 'AppCore/Components/FirebaseImage'

export const Container = styled(Paper)`
    padding: ${props => props.theme.spacing(6)}px;
    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(10)}px;
    }
`;

export const Avatar = styled(FirebaseAvatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${props => props.theme.spacing(5)}px;
`;