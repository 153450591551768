

export default {
    paper: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 4px 0 rgba(10,29,53,0.18), 0 20px 30px 0 rgba(10,29,53,0.19)',
        borderRadius: '8px'
    },

    MenuList: {
        maxHeight: '300px',
        overflow: 'auto'
    },

    menuItemRoot: {
        fontSize: '14px',
        color: '#121C2C',
        letterSpacing: 0,

        "&:hover": {
            background: 'rgba(84,101,251,0.05)',
            boxShadow: '0 0 1px 0 #5465FB'
        }
    },

    menuItemSelected: {
        color: '#5465FB',
        "&:hover": {
            background: 'rgba(84,101,251,0.05)',
            boxShadow: '0 0 1px 0 #5465FB'
        }
    }
}