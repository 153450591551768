// @flow
import React from "react";
import moment from 'moment'

import DataTable from 'AppCore/Components/DataTable';
import { useCentres, useSuppliers } from 'Apps/Stockizy/Store/hooks'

type Props = {
    searchQuery?: String,
    onClickEdit?: any => any,
    sort?: any,
    actions?: any
}

const CommandList = ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}: {data: Array<any>} & Props) => {

    const sortedData = data
    const suppliers = useSuppliers();
    const centres = useCentres();

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: "Date de création", key: 'date' },
                { title: 'Centre', key: 'centreId' },
                { title: 'Fournisseur', key: 'supplierId' },
                { title: 'Status', key: 'status' }
            ],
            actions: actions || [
                { icon_name: 'view', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'date') {
                if (!item.metadata || !item.metadata.createdAt) {
                    return "";
                }
                return moment(item.metadata.createdAt*1000).format("LLL");
            }

            if (attr_name === 'supplierId') {
                const supplier = (suppliers.data || []).find(_supplier => _supplier.id === item.supplierId);
                return (supplier && supplier.name) || "";
            }

            if (attr_name === 'centreId') {
                const centre = (centres.data || []).find(_centre => _centre.id === item.centreId);
                return (centre && centre.name) || "";
            }

            if (attr_name === 'status') {
                switch (item.status) {
                    case 'created':
                        return "Créé, pas encore retourné";
                    case 'returned':
                        return "Retournée";
                    default:
                        return "Inconnu";
                }
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}

export default CommandList