import React from "react";
import { Navigate } from "react-router-dom";

import LayoutDashboard from '../Shared/Layouts/Dashboard'

import Common from './Layouts/Common'
import Onboarding from './Layouts/Onboarding'
import SidebarFooter from './Layouts/Components/SidebarFooter'
import SidebarContent from './Layouts/Components/SidebarContent'

import Login from './Pages/Auth'

import CreateSignatureCompanyScreen from './Pages/CreateSignature/company'
import SignaturesScreen from './Pages/Signatures'

import HomeUnlogged from './Pages/HomeUnlogged';
import TeamEdit from './Pages/Team/Edit';
import TeamsList from './Pages/Teams';
import TeamMembersScreen from './Pages/TeamMembers'
import SignatureEditCreateScreen from './Pages/SignatureEditCreate/v2'
import BannersScreen from './Pages/Banners'
import CompanyScreen from './Pages/Company'
import DeployScreen from './Pages/Deploy'
import InstallEmailPage from './Pages/InstallEmailPage';

import BillingScreen from './Pages/Billing'

// EXPERIMENTS
import SignatureEditorExperiment from './Pages/Experiments/SignatureEditor'
import ExperimentsUpload from './Pages/Experiments/UploadMedia'

import {
  Mail,
  Users, User,
  Image,
  DollarSign,
  Code
} from "react-feather";
import useCurrentUser from "./react-hooks/useCurrentUser";

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}


const HomeSwitch = () => {

    const current_user = useCurrentUser();

    if (current_user && current_user.id) {
        return (
            <Navigate to={"/home"} />
        )
    }

    return (
        <HomeUnlogged />
    );
}


const getPath = (path, teamIdRoutes) => {
    if (teamIdRoutes) {
        return "/team/:team_id" + path;
    }
    return path;
}
const getRoutes = (teamIdRoutes = false) => ([
    {
        path: getPath('/home', teamIdRoutes),
        element: teamIdRoutes ? TeamEdit : CompanyScreen,
        needUser: true
    },

    { path: getPath('/deploy', teamIdRoutes), element: DeployScreen, needUser: true },
    { path: getPath('/signatures', teamIdRoutes), element: SignaturesScreen, needUser: true },
    { path: getPath('/signatures/new', teamIdRoutes), element: SignatureEditCreateScreen, needUser: true },
    { path: getPath('/signature/:signature_id', teamIdRoutes), element: SignatureEditCreateScreen, needUser: true },

    { path: getPath('/team/:team_id', teamIdRoutes), element: TeamEdit, needUser: true },

    { path: getPath('/users', teamIdRoutes), element: TeamMembersScreen, needUser: true },
    { path: getPath('/banners', teamIdRoutes), element: BannersScreen, needUser: true },
])

export default {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/home'
    },
    sidebar: [
        { title: 'Signatures', path: '/signatures', icon: <Mail /> },
        { title: 'Teams', path: '/teams', icon: <Users /> },
        { title: 'Team members', path: '/users', icon: <User /> },
        { title: 'Campaigns', path: '/campaigns', icon: <Image /> },

        { title: 'Billing', path: '/billing', icon: <DollarSign />},
        { title: 'Company', path: '/company', icon: <DollarSign />},


        {
            title: "Experiments",
            icon: <Code />,
            children: [
                { title: "Signature editor", path: "/experiments/signature-editor" },
                { title: "Upload media", path: "/experiments/upload" }
            ]
        }
    ],

    sidebarConfig: {
        SidebarFooterComponent: SidebarFooter,
        SidebarContent: SidebarContent
    },

    routes: [

        {
            path: "/",
            element: Common,
            routes: [

                {
                    path: "/", element: HomeSwitch
                },

                {
                    path: '/auth/sign-in',
                    element: Login
                },

                {
                    path: '/user/:user_id/install-email',
                    element: InstallEmailPage
                },

                {
                    path: "/", element: LayoutDashboard,
                    needUser: true,
                    routes : [
                        { path: '/teams', element: TeamsList, needUser: true },

                        ...getRoutes(),
                        ...getRoutes(true),


                        { path: '/billing', element: BillingScreen, needUser: true },
                        { path: '/experiments/signature-editor', element: SignatureEditorExperiment },
                        { path: '/experiments/upload', element: ExperimentsUpload },

                    ]
                },

                {
                    path: "/",
                    element: Onboarding,
                    routes: [
                        { path: '/create-signature-company', element: CreateSignatureCompanyScreen },
                    ]
                },
            ]
        },






        {
            path: "*", element: NotFound
        }
    ]
}