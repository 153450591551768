// @flow

import React from 'react';
import SignatureData from '../../DataObject';

import {
	Picture,
	Text,
	SocialIcons,

	TableReset,
	TableLine, TableCell,
} from './helpers';

export const key = 'layout-art-f';
const stars = <div>**********************************************************************</div>;
export default ({ data, preview = false }: { data: SignatureData, preview?: boolean }) => (
	<>
		{data.signoff && data.signoff.text &&
			<>
				<Text fontStyle={data.signoff.fontStyle}>{data.signoff.text}</Text>
				<br />
				<br />
			</>
		}
        {stars}
        <br/>
		<TableReset>
			<tbody>

				<TableLine>

					<td style={{border:0}}>
						<table>
							<tbody>

                                {data.hasLogo() &&
                                    <TableLine>
                                        <TableCell style={{ verticalAlign:'top'}}>
                                            <Picture preview={preview} image={data.logo} />
                                        </TableCell>
                                    </TableLine>
                                }

								{!!data.getDisplayName() && (
									<TableLine>
										<TableCell><Text color2 fontStyle={data.fontStyle}>{data.getDisplayName()}</Text></TableCell>
									</TableLine>
								)}

								{['companyName', 'positionInCompany'].map(key => !!data[key]).filter(a => a).length > 0 &&
									<TableLine>
										<TableCell>
											{data.positionInCompany &&
												<Text fontStyle={data.fontStyle}><b>{data.positionInCompany}</b></Text>
											}
											{data.companyName && data.positionInCompany ?
												<Text fontStyle={data.fontStyle}><b>{" | "}</b></Text> : null
											}
											{data.companyName &&
												<Text fontStyle={data.fontStyle}><b>{data.companyName}</b></Text>
											}
										</TableCell>
									</TableLine>
								}
                                <TableLine>
									<TableCell></TableCell>
                                </TableLine>

								{data.address &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>a: </Text>
											<Text fontStyle={data.fontStyle}>{data.address}</Text>
										</TableCell>
									</TableLine>
								}
								{data.phone &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>T: </Text>
											<Text link={'tel:'+data.phone} fontStyle={data.fontStyle}>{data.phone}</Text>
										</TableCell>
									</TableLine>
								}
								{data.skype &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>s: </Text>
											<Text link={'skype:'+data.skype+"?chat"} fontStyle={data.fontStyle}>{data.skype}</Text>
										</TableCell>
									</TableLine>
								}

								{(data.customFields || []).map((customField, index) => (
									<TableLine key={customField.key + "_" + index}>
										<TableCell>
											<Text fontStyle={data.fontStyle} color2>{customField.key}:</Text> <Text link={customField.url} fontStyle={data.fontStyle}>{customField.value}</Text>
										</TableCell>
									</TableLine>
								))}

								{data.email &&
									<TableLine>
										<TableCell>
											<Text link={'mailto:'+data.email} fontStyle={data.fontStyle} style={{color: 'blue', textDecoration:'underlined'}}>{data.email}</Text>
										</TableCell>
									</TableLine>
								}
								{data.companyWebsite &&
									<TableLine>
										<TableCell><Text color2 link={data.companyWebsite} fontStyle={data.fontStyle} style={{color: 'blue', textDecoration:'underlined'}}>{data.companyWebsite}</Text></TableCell>
									</TableLine>
								}

								{data.hasSocialIcons() &&
									<>
										<TableLine>
											<TableCell>
                                                <TableReset>
                                                    <tbody>
                                                        <TableLine>
                                                            <TableCell style={{paddingRight: !!data.social.caption ? '10px' : "0px"}}>
                                                                {!!data.social.caption &&
                                                                    <Text color2 fontStyle={data.fontStyle}>{data.social.caption}</Text>
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <SocialIcons data={data} />
                                                            </TableCell>
                                                        </TableLine>
                                                    </tbody>
                                                </TableReset>
											</TableCell>
										</TableLine>
									</>
								}
							</tbody>
						</table>

					</td>
				</TableLine>
			</tbody>
		</TableReset>
        <br/>
        {stars}
	</>
)