// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { setSignatureData } from 'Apps/Signally/Actions'

// import useCurrentUser from 'Apps/Signally/react-hooks/useCurrentUser';
// import useCurrentCompany from 'Apps/Signally/react-hooks/useCurrentCompany';
import SignatureLayout from 'Apps/Signally/Components/Signature/v2'
import SignatureData from 'Apps/Signally/Components/Signature/V2/DataObject';

const useReduxSignatureDataOnboarding = () => {
    const signatureDataRedux = useSelector(
        state => {
            const signatureData = new SignatureData();
            signatureData.setData(state.onboarding.signatureDataV2);
            return signatureData;
        }
    )

    const dispatch = useDispatch();

    return [
        signatureDataRedux,
        signatureDataV2 => dispatch(setSignatureData({ signatureDataV2 }))
    ]

}

export default () => {

    const [ signatureData, setSignatureDataRedux ] = useReduxSignatureDataOnboarding();

    const { enqueueSnackbar } = useSnackbar();

    const setSignatureData = (signatureData: SignatureData) => {
        setSignatureDataRedux(signatureData.export());
    }

    return (
        <div>
            <SignatureLayout
                data={signatureData}
                //disableFields={Object.fromEntries(Object.keys(fixedData).map(key => ([key, true])))}
                onChange={setSignatureData}
                onSignatureCopied={() => {
                    enqueueSnackbar('Copied', {variant: 'success'});
                }}
                hasCopyButton
            />
        </div>
    )
}