import React, { useEffect } from 'react';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator'
import { TrainingSession } from "@nopanoga/models"

import LaunchTrainingSession from 'Apps/Nopanoga/Components/TrainingSession/LaunchTrainingSession'

export default ({ style = {width:'600px'}, className }) => {


    const [ trainingSession: TrainingSession, setTrainingSession ] = React.useState()
    const id = "iYEgLw0f8nANvbhfwNMV";

    useEffect(() => {
        (async () => {
            setTrainingSession(await TrainingSession.get({ id }))
        })()
    }, [])

    if (!trainingSession) {
        return <ActivityIndicator />
    }

    return (
        <div style={{width: '400px', height: '700px'}}>
            <LaunchTrainingSession
                fullScreen
                trainingSession={trainingSession}
            />
        </div>
    )
}