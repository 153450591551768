import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Edit, Delete } from '@material-ui/icons'

import Helmet from 'react-helmet';

import { Button } from '@nopanoga/components'
import { TrainingSession } from "@nopanoga/models"
import { useCurrentUser } from '@knxlab/react-hooks';

import { useOpenDialog } from 'AppCore/Components/Dialog'

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'
import {
    Divider, Title
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import Table from './dataTable'

function AddLink() {

    return (
        <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/training-sessions/new"
        >
            Créer une séance
        </Button>
    )
}

export default () => {

    const [ trainingSessions, setTrainingSessions ] = useState({ data: [] });
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    const openDialog = useOpenDialog();

    const onClickEdit = (index, trainingSession: TrainingSession) => {
        navigate(`/training-sessions/${trainingSession.id}`)
    }

    const loadTrainingSessions = async () => {
        setTrainingSessions(await TrainingSession.getAll({ owner: currentUser.uid }));
    }

    useEffect(() => {
        loadTrainingSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const count = trainingSessions.data.length;

    return (
        <React.Fragment>
            <Helmet title="Séances d'entrainement" />

            <FlexRowSpaceBetween>
                <Title>
                    Mes séances d'entrainement ({count})
                </Title>
                <AddLink />
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <Table
                data={[...trainingSessions.data].sort((a, b) => (a.title > b.title) ? 1 : -1)}
                onClickEdit={onClickEdit}
                actions={[
                    { key: 'edit', icon: <Edit />, onClick: onClickEdit },
                    {
                        key: 'delete',
                        icon: <Delete />,
                        onClick: async (id, trainingSession) => {
                            const { confirm, close, setLoading } = await openDialog({
                                title: "Voulez-vous vraiment supprimer cette séance ?",
                                content: (
                                    <div>
                                        <div>En supprimant cette séance, elle ne sera plus utilisable, mais elle ne sera pas supprimé de la marketplace et des programmes dans lesquels il est déjà présent.</div>
                                        <div><i><b>Cette action est irreversible</b></i></div>
                                    </div>
                                )
                            })
                            setLoading(true);
                            if (confirm) {
                                await trainingSession.delete();
                                await loadTrainingSessions();
                                close();
                            }
                            setLoading(false);
                            close();
                        }
                    }
                ]}
            />
            <Divider my={6} />
        </React.Fragment>
    );
}