// @flow
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';


const Viewport = styled.div`
    width: 100%;
`

type RefObject = {|
  current: any,
|};
export default ({ children, aspectRatio = 4/5, ...props }: { children: any, aspectRatio?: number }) => {
    const viewport: RefObject = useRef(null);
    const [ height, setHeight ] = useState(0);

    const onWindowResize = () => {
        if (!viewport.current) {
            return;
        }
        setHeight(viewport.current.offsetWidth / aspectRatio);
    }

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aspectRatio ])

    return (
        <Viewport ref={viewport} style={{ ...(height === 0 ? {} : { height: height+ 'px' })}} >
            {children}
        </Viewport>
    )
}