import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Helmet from 'react-helmet';

import {
  Grid
} from "@material-ui/core";

import { Edit, Delete } from '@material-ui/icons'
import { Button } from '@nopanoga/components'
import { Exercice } from "@nopanoga/models";
import { useCurrentUser } from '@knxlab/react-hooks';

import { useOpenDialog } from 'AppCore/Components/Dialog'
import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import ExerciceTable from 'Apps/Nopanoga/Components/ExerciceList/list/table'
import SearchField from 'AppCore/Components/SearchField'
import {
    Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import {
    Title
} from 'Apps/Nopanoga/Layouts/Components/Page'
import {
    TableHeader
} from 'Apps/Shared/Layouts/Dashboard/Components/Table'


function AddLink() {
    return (
        <Button
            color="primary"
            component={Link}
            to="/exercices/new"
        >
            Créer un exercice
        </Button>
    )
}


export default () => {

    const [ exercices, setExercices ] = useState({ data: [] });
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    const loadExercices = async () => {
        setExercices(await Exercice.getAll({ owner: currentUser.uid }));
    }

    useEffect(() => {
        loadExercices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openDialog = useOpenDialog();

    const count = exercices.data.length;

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <Helmet title="Exercices" />

            <FlexRowSpaceBetween>
                <Title>
                    Mes exercices ({count})
                </Title>
                <AddLink key="addLink" />
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <TableHeader>
                <SearchField />
            </TableHeader>
            <Grid container>
                <Grid item xs={12}>
                    <ExerciceTable
                        data={[...exercices.data].sort((a, b) => (a.title > b.title) ? 1 : -1)}
                        actions={[
                            { key: 'edit', icon: <Edit />, onClick: id => navigate('/exercices/'+id) },
                            { key: 'delete', icon: <Delete />, onClick: async (id, exercice) => {
                                const { confirm, close, setLoading } = await openDialog({
                                    title: "Voulez-vous vraiment supprimer cet exo ?",
                                    content: (
                                        <div>
                                            <div>En supprimant cet exercice, il ne sera plus utilisable, mais il ne sera pas supprimé des séances et programmes dans lesquels il est déjà présent.</div>
                                            <div><i><b>Cette action est irreversible</b></i></div>
                                        </div>
                                    )
                                })
                                setLoading(true);
                                if (confirm) {
                                    await exercice.delete();
                                    await loadExercices();
                                    close();
                                }
                                setLoading(false);
                                close();
                            }}
                        ]}
                    />
                </Grid>
            </Grid>

        </div>
    );
}