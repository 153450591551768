import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import { Collapse } from '@material-ui/core';

import {
    Link, LinkText,
    Category, CategoryText, CategoryIconMore, CategoryIconLess,
    CategoryBadge
} from './StyledComponents'

const NavLink = RouterNavLink

const SidebarCategory = ({ title, icon, children }) => {

    const [ open, setOpen ] = useState(false);

    return (
        <React.Fragment>
            <SidebarCategoryItem name={title} icon={icon} isOpen={open} onClick={() => setOpen(!open)} />
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
            >
                {children.map((sidebar, index) => (
                    <SidebarItem
                        hasParent
                        key={index}
                        {...sidebar}
                    />
                ))}
            </Collapse>
        </React.Fragment>
    )
}

function SidebarCategoryItem({
    name,
    icon,
    classes,
    isOpen,
    isCollapsable = true,
    badge,
    ...rest
}) {
    return (
        <Category {...rest} button={isCollapsable}>
            {icon}
            <CategoryText>{name}</CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore />
                ) : (
                        <CategoryIconLess />
                    )
            ) : null}
            {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
    );
}

function SidebarLink({ name, to, badge, ...rest }) {
    return (
        <Link
            button
            dense
            component={NavLink}
            to={to}
            activeClassName="active"
        >
            <LinkText>{name}</LinkText>
        </Link>
    );
}

const SidebarItem = ({ path, children, title, icon, hasParent = false, disabled = false }) => {

    if (disabled) {
        return null;
    }

    if (!path) {
        return (
            <SidebarCategory
                title={title}
                children={children}
                icon={icon}
            />
        )
    }

    if (hasParent) {
        return (
            <SidebarLink
                name={title}
                to={path}
            />
        );
    }

    return (
        <SidebarCategoryItem
            component={NavLink}
            activeClassName="active"
            name={title}
            to={path}
            icon={icon}
            isCollapsable={false}
        />
    )
}

export default SidebarItem;