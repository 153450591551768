import React, { useState } from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import {
    AddCircleOutline,
    RemoveCircleOutline
} from '@material-ui/icons'

const Input = styled.input`
    padding: 20px;
    text-align: center;
    font-size: 40px;
    width: 150px;
`
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`


export default ({ value, min, onChange, ...props }) => {

    const intValue = parseInt(value, 10) || 0;
    const [ tmpValue, setTmpValue ] = useState(intValue);


    const setValue = value => {
        setTmpValue(value);
        let newValue = value;
        if (min) {
            newValue = Math.max(min, newValue);
        }
        onChange(newValue)
    }

    return (
        <Container {...props} >
            <IconButton onClick={e => {
                setValue(intValue - 1);
            }}>
                <RemoveCircleOutline />
            </IconButton>

            <Input type="text" value={tmpValue} onChange={e => setValue(parseInt(e.target.value, 10) || 0)} />

            <IconButton onClick={e => setValue(intValue + 1)}>
                <AddCircleOutline />
            </IconButton>
        </Container>
    )
}