import React from "react";

import DataTable from 'AppCore/Components/DataTable';
import { User } from '@izzistock/models';
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";
import { useCentres, useProducts } from "Apps/Stockizy/Store/hooks";

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}) => {

    const sortedData = data
    const currentUser: User = useCurrentUser()
    const centres = useCentres();
    const products = useProducts();

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData.filter(item => {
            const product = (products.data || []).find(_product => _product.id === item.productId);
            return !!product && (product.name || "").toLocaleLowerCase().substr(0, searchQuery.length) === searchQuery.toLocaleLowerCase();
        })}

        config={{
            cells: [
                { title: 'Produit', key: 'productId' },
                ...(currentUser.isSuperAdmin ? [
                    { title: 'Centre', key: 'centreId' }
                ] : []),
                { title: 'Stock', key: 'qty' }
            ],
            actions: []
        }}
        getKey={item => `${item.productId}-${item.centreId}`}
        getCell={(item, attr_name) => {

            if (attr_name === 'centreId') {
                const centre = (centres.data || []).find(_centre => _centre.id === item.centreId);
                return (centre && centre.name) || "";
            }
            if (attr_name === 'productId') {
                const product = (products.data || []).find(_product => _product.id === item.productId);
                return (product && product.name) || "";
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}