// @flow
import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
    Modal,
} from '@material-ui/core'
import { Tabs } from '@knxlab/components'

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import { TrainingSession } from "@nopanoga/models"
import LaunchTrainingSession from 'Apps/Nopanoga/Components/TrainingSession/LaunchTrainingSession'
import TrainingSessionPreview from 'Apps/Nopanoga/Components/TrainingSession/TrainingSessionPreview'
import { Button } from '@nopanoga/components'

import {
    Title, Divider,
    Container
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import {
    PreviewContainer
} from 'Apps/Nopanoga/Layouts/Components/Page'

import {
    TabsContainer, TabsPanels
} from './styledComponents'

import General from './general'
import ExerciceList from './ExerciceList'
import { useNavigate } from 'react-router-dom';
import UrlDialog from './UrlDialog'

type Props = {
    initialTrainingSession: any,
    saveData: any
}

const getSizePreviewFromHeight = heightWanted => {
    const previewHeight = window.innerHeight - 50
    const heightPercent = heightWanted / previewHeight;
    const previewWidth = 375 / heightPercent;
    return {
        height: previewHeight,
        width: previewWidth
    }
}

const defaultTitle = "Créer une séance";
export default ({ initialTrainingSession, saveData }: Props) => {

    const navigate = useNavigate();
    const [ displayPreview, setDisplayPreview ] = useState(false);
    const [ displayShare, setDisplayShare ] = useState(false);
    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ trainingSession: TrainingSession, setTrainingSession ] = React.useState(initialTrainingSession)

    const displayPreviewSize = getSizePreviewFromHeight(812);
    const leftPreviewHeight = window.innerHeight - 200;

    const theme = useTheme();
    const onMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const { enqueueSnackbar } = useSnackbar();

    const onChangeTrainingSession = trainingSession => {
        setChanged(true);
        setTrainingSession(trainingSession)
    }

    const onClickCancel = () => {
        setTrainingSession(initialTrainingSession);
    }
    const onClickSave = async () => {
        if (!saveData) {
            return;
        }

        let success = false;
        setLoading(true);
        try {
            await saveData(trainingSession)
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setChanged(false);
            success = true;
        } catch (e) {
            alert("une erreur est survenue")
        }
        setLoading(false);

        // If we have just created an exercice
        if (success && !initialTrainingSession.id) {
            navigate('/training-sessions/' + trainingSession.id , { replace: true });
        }
    }

    const canSave = () => {
        if (!changed || loading) {
            return false;
        }

        if (!trainingSession.video) {
            return false
        }

        return true;
    }

    const openPreview = () => {
        if (trainingSession.isEmpty()) {
            alert("Vous devez d'abord ajouter des exercices");
            return;
        }
        setDisplayPreview(true)
    }

    const openShareUrl = () => {

        if (trainingSession.isEmpty()) {
            alert("Vous devez d'abord ajouter des exercices");
            return;
        }
        setDisplayShare(true)
    }

    const getShareUrl = () => {
        const { host, protocol } = window.location;
        return `${protocol}//${host}/app/training-sessions/${trainingSession.id}`;
    }


    return (
        <React.Fragment>
            <Helmet title={trainingSession.title || defaultTitle} />
            <Prompt when={changed} message="Vous n'avez pas enregistré vos modifications. Etes-vous sûr de vouloir quitter cette page ?"/>


            <FlexRowSpaceBetween>

                <Title>
                    {trainingSession.title || defaultTitle}
                </Title>

                <div>
                    {saveData && <>
                        <Button
                            loading={false} disabled={!canSave()} color="tertiary"
                            onClick={onClickCancel} style={{marginRight: '10px'}}
                        >{"annuler les modifications".toUpperCase()}</Button>
                        <Button
                            style={{marginRight: '10px'}}
                            loading={false} disabled={!canSave()} color="primary"
                            onClick={onClickSave}
                        >{"Enregistrer".toUpperCase()}</Button>
                    </>}
                    <Button color="primary" onClick={() => { openShareUrl() }}>Partager la séance</Button>

                </div>
            </FlexRowSpaceBetween>

            <Divider my={6} />

            {!(displayPreview && onMobile) &&

                <FlexRowSpaceBetween style={{alignItems: 'flex-start'}}>
                    <Container style={{flex:1}}>

                        <TabsContainer orientation={"horizontal"}>

                            <Tabs
                                defaultSelected={0}
                                orientation={"horizontal"}
                                variant="scrollable"
                                tabs={[
                                    {
                                        label: "Détails",
                                        panel: (
                                            <TabsPanels>
                                                <General
                                                    trainingSession={trainingSession}
                                                    onChangeTrainingSession={onChangeTrainingSession}
                                                />
                                            </TabsPanels>
                                        )
                                    },

                                    ...(trainingSession.type === "exercices" ? [{
                                        label: "Exercices",
                                        panel: (
                                            <TabsPanels>
                                                <ExerciceList trainingSession={trainingSession} setTrainingSession={onChangeTrainingSession} />
                                            </TabsPanels>
                                        )
                                    }]: [])
                                ]}
                            />

                        </TabsContainer>

                    </Container>

                    <PreviewContainer
                        title={"Prévisualiser sur l'application"}
                        height={leftPreviewHeight}
                    >
                        <TrainingSessionPreview
                            trainingSession={trainingSession}
                            onPressLaunch={openPreview}
                        />
                    </PreviewContainer>

                </FlexRowSpaceBetween>
            }

            {displayShare &&
                <UrlDialog onClose={() => setDisplayShare(false)} url={getShareUrl()} />
            }

            {displayPreview &&
                <Modal
                    open
                    onClose={() => setDisplayPreview(false)}
                    style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    <div
                        style={{
                            position: 'relative', display: 'flex',
                            width: onMobile ? '100%' : (displayPreviewSize.width + "px"),
                            height: onMobile ? '100%' : (displayPreviewSize.height + "px")
                        }}
                    >
                        <LaunchTrainingSession
                            onPressClose={() => setDisplayPreview(false)}
                            fullScreen
                            trainingSession={trainingSession}
                        />
                    </div>
                </Modal>
            }

        </React.Fragment>

    )
}
