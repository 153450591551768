import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

import { updateTeam } from 'Apps/Signally/Actions';

import TemplateSelector from 'Apps/Signally/Components/Views/TemplateSelector';
import Form from 'AppCore/Components/Form/formFields';
import Button from 'Apps/Signally/Components/Button'
import Paper from 'Apps/Signally/Components/Paper'
import { PageContainer, Content } from '../Layout';
import Header from '../Layout/Header';


import css from './index.module.css';
import useTeamList from 'Apps/Signally/react-hooks/useTeamList';
import useTeamContext from 'Apps/Signally/react-hooks/useTeamContext';

const styles = theme => ({
	textField: {
		width: '300px',
		marginBottom: '10px'
	}
});

const getTeamById = (id, teams) => {
    const index = teams.data.findIndex(team => team.id === id);
    if (index === -1) {
        return null;
    }

    return teams.data[index];
}

const TeamEdit = ({ enqueueSnackbar, closeSnackbar, classes }) => {

	const navigate = useNavigate();
    const dispatch = useDispatch();
	const [ selectedTeam ] = useTeamContext();
    const teams = useTeamList();
    const { team_id } = useParams();
    const initialTeam = getTeamById(team_id, teams);

	const [ { team, loading, saving, hasError }, _setState ] = useState({
		team: initialTeam,
		saving: false,
		loading: false,
		hasError: false
	})

	const setState = state => _setState({
		team, loading, saving,
		...state
	})

	const afterSave = () => {
		if (!selectedTeam) {
			navigate("/teams");
		}
	}

	const onClickSave = async () => {

		setState({ saving: true });
		const key = enqueueSnackbar('Saving...', {variant: 'info'});

		try {
			await dispatch(updateTeam({ team_id: team.id, team }));

			closeSnackbar(key);
			enqueueSnackbar('Saved', {variant: 'success'});

			setState({ saving: false });

			afterSave();

		} catch (e) {
			closeSnackbar(key);
			enqueueSnackbar('Error', {variant: 'error'});
			setState({ saving: false });
		}
	}

    const onChangeValue = attr_name => ({ target: { value } }) => {
		setState({
			team: {
				...team,
				[attr_name]: value
			}
		})
	}

	return (

		<PageContainer>

			<Header title={"Modifier la team : " + team.name} />

			<Content>

				<Paper type="form">

					<Form
						config={{
							attributes: {
								name: { type: 'text', disabled: loading || saving },
							}
						}}
						values={team}
						onChange={({values: team, hasError}) => setState({ team, hasError })}

						className={css.form}
						textFieldClassName={classes.textField}
					/>

                    <TemplateSelector
						noneLabel="Signature attribué à la société"
						label="Signature (prioritaire sur la signature de la société)"
						selected_id={(team.signature_template_id) || null}
						onChange={onChangeValue('signature_template_id')}
					/>

					<div className={css.actionContainer}>
						<Button onClick={onClickSave} disabled={loading || saving || hasError}>Save</Button>
					</div>
				</Paper>

			</Content>
		</PageContainer>
	);
}


export default (withSnackbar(withStyles(styles)(TeamEdit)))




