import uniqid from 'Meth/utils/uniqid';
import Constants from "../Constants";
import { displayLoading, displayError, displayLogin } from './display'

import { getTeams, createTeam } from './Teams';
import { getTemplates } from './SignatureTemplates';
import { getCampaigns } from './Campaigns';
import { updateOrganisation, reloadOrganisation } from './Organisation'

import Api from 'Apps/Signally/Api';
import SignatureData from "Apps/Signally/Components/Signature/V2/DataObject";

export * from './Organisation';
export * from './SignatureTemplates';
export * from './Campaigns';
export * from './Teams';
export * from './Generated';
export * from './display';
export * from './Onboarding';

const { REDUCER: C } = Constants;


export const triggerLogin = () => async (dispatch, getState, { api }) => {
	dispatch(displayLogin());
	return false;
}

export const deployUserSignature = ({ user_id, deploy_message }) => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.deployUserTemplate({ user_id, deploy_message });
		dispatch(getTeams())
		dispatch(reloadCurrentUser());

		return result;

	} catch (e) {

		console.error(e);
	}

}


export const saveOnboardingUserData = () => async (dispatch, getState, { api }) => {
	const {
		current_user,
		onboarding
	} = getState();

	const { signatureDataV2: signatureDataRaw } = onboarding || {};
	const signatureData = new SignatureData();
	signatureData.setData(signatureDataRaw);

	const user = {
		first_name: signatureData.firstName,
		last_name: signatureData.lastName,
		position: signatureData.positionInCompany,
		phone_number: signatureData.phone
	}

	await dispatch(updateUser({ user_id: current_user.id, user }))
	await dispatch(updateOrganisation(signatureData.getCompany()))

	return true;
}

export const saveOnboardingData = () => async (dispatch, getState, { api }) => {

	const {
		current_user,
		onboarding,
		organisation
	} = getState();

	const { signatureDataV2: signatureDataRaw, signatureName } = onboarding || {};
	const signatureData = new SignatureData();
	signatureData.setData(signatureDataRaw);

	let team = null;
	const user = {
		first_name: signatureData.firstName,
		last_name: signatureData.lastName,
		position: signatureData.positionInCompany,
		phone_number: signatureData.phone
	}

	const template = await Api.createTemplate({
		organisation_id: organisation.id,
		template: {
			name: signatureName,
			signatureData: JSON.stringify(signatureData.getLayoutData())
		}
	});

	if (signatureData.hasBanner() && signatureData.banner.mediaId) {
		await Api.createCampaign({
			organisation_id: organisation.id,
			campaign: {
				name: "Bannière par défaut",
				banner_id: signatureData.banner.mediaId
			}
		});
	}

	if (signatureData.teamName) {
		team = await dispatch(createTeam({
			team: {
				name: signatureData.teamName,
				signature_template_id: template.id
			}
		}));
		user.team_id = team.id;
	}

	await dispatch(updateUser({ user_id: current_user.id, user }))
	await dispatch(updateOrganisation({
		...signatureData.getCompany(),
		signature_template_id: template.id
	}))

	return true;
};

const processAuthData = async ({ authData, user, organisation, error } , dispatch) => {

	if (error) {
		throw new Error(error.message);
	}

	dispatch({
		type: C.setObject,
		storeName: 'user',
		itemType: 'user',
		storeKey: user.id,
		item: user
	});

	dispatch({
		type: C.SET_CURRENT_USER,
		user
	});

	dispatch({
		type: C.SET_ORGANISATION,
		organisation
	})

	const other_accounts = [];
	if (user.master_user) {
		user.master_user.is_master = true;
		other_accounts.push(user.master_user);

		if (user.master_user.sub_accounts && user.master_user.sub_accounts.data) {
			user.master_user.sub_accounts.data.forEach(account =>
				account.id !== user.id && other_accounts.push(account)
			);
		}
	}
	if (user.sub_accounts && user.sub_accounts.data) {
		user.sub_accounts.data.forEach(account => other_accounts.push(account));
	}

	dispatch({
		type: C.SET_OTHER_ACCOUNTS,
		other_accounts
	});

	dispatch({
		type: C.SET_AUTH_DATA,
		authData
	});

	dispatch(loadUserData());

	return user.id;
}

const clearData = () => (dispatch, getState, { api }) => {
	dispatch({
		type: C.CLEAR_TEAMS
	});
	dispatch({
		type: C.CLEAR_USERS
	});
	dispatch({
		type: C.CLEAR_TEMPLATES
	});
	dispatch({
		type: C.CLEAR_CAMPAIGNS
	});
}

export const loadUserData = () => (dispatch, getState, { api }) => {
	dispatch(reloadCurrentUser());
	dispatch(getCampaigns());
	dispatch(getUsers());
	dispatch(getTeams());
	dispatch(getTemplates());
}

export const loginWithOtherAccount = ({ account_id }) => async (dispatch, getState, { api }) => {
	try {

		dispatch(displayLoading());

		const newAccountLoginResult = await api.loginWithOtherAccount({ account_id });

		dispatch(clearData());
		processAuthData(newAccountLoginResult, dispatch);

		dispatch(displayLoading({ display: false }));

	} catch (e) {
		console.log(e.message);
		console.error(e);
	}
}


export const logout = () => async (dispatch, getState, { api }) => {

	dispatch({
		type: C.SET_AUTH_DATA,
		authData: {}
	});
	dispatch({
		type: C.SET_CURRENT_USER,
		user: {}
	});
	dispatch({
		type: C.SET_ORGANISATION,
		organisation: {}
	});
	dispatch({
		type: C.SET_OTHER_ACCOUNTS,
		other_accounts: {}
	});

}

export const magicLinkLogin = ({ email, code, from_email }) => async (dispatch, getState, { api }) => {


	if (from_email) {
		dispatch(displayLoading());
	}

	const authData = await api.magicLinkLogin({
			email,
			code,
			...(from_email ? { from_email } : {})
	});

	if (from_email) {
		dispatch(clearData());
	}

	await processAuthData(authData, dispatch);

	if (from_email) {
		dispatch(displayLoading({ display: false }));
	}

	return authData;

}

/*
	getOrganisationUsers
*/
export const getUsers = () => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.getOrganisationUsers({ organisation_id });

		dispatch({
			type: C.SET_USERS,
			users: result
		});

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalUser = ({ user_id, user }) => async (dispatch, getState, { api, ws }) => {
	const users = JSON.parse(JSON.stringify(getState().users));

	users.data.forEach((_user, i) => {
		if (_user.id === user_id) {
			users.data[i] = {
				...users.data[i],
				...user
			}
		}
	});

	dispatch({
		type: C.SET_USERS,
		users
	});
}

export const reloadCurrentUser = () => async (dispatch, getState, { api, ws }) => {

	const user = await api.getUser();

	dispatch({
		type: C.SET_CURRENT_USER,
		user
	});

	return user;
}

export const updateUser = ({ user_id, user }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_users = {
		...(getState().users)
	}

	dispatch(updateLocalUser({ user_id, user }));

	try {


		const result = await api.updateUser({ user_id, user });
		dispatch(getUsers())

		if (getState().current_user.id === user_id) {
			dispatch(reloadCurrentUser());
		}


		return result;

	} catch (e) {

		dispatch({
			type: C.SET_USERS,
			users: rollback_users
		});

		throw e;
	}

}

export const createUser = ({ organisation_id, user = {} }) => async (dispatch, getState, { api, ws }) => {
	// user

	const { users } = getState();
	const rollback_users = {
		...users
	}

	const tmp_id = uniqid();

	dispatch({
		type: C.SET_USERS,
		users: {
			...users,
			data: [
				...users.data,
				{
					id: tmp_id,
					tmp_id,
					...user
				}
			]
		}
	});

	try {

		const result = await api.createUser({ organisation_id, user });

		await dispatch(getUsers())
		await dispatch(reloadOrganisation())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_USERS,
			users: rollback_users
		});

		// dispatch(displayError({ error: e }));

		throw e;
	}

}

export const deleteUser = ({ user_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteUser({ user_id });

		await dispatch(getUsers())
		await dispatch(reloadOrganisation())

		return result;

	} catch (e) {

		dispatch(displayError({ error: e }));

		throw e;
	}

}






















