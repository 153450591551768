// @flow

import React from 'react';
import SignatureData from '../../../DataObject';
import VideoConfData from '../../../DataObject/Addons/VideoConf'

import {
	Link
} from './index';

export const VideoConf = ({ data }: { data: SignatureData }) => {

    const videoConf = data.addons.videoConf.data
    const buttonStyle = videoConf.getButtonStyle();

    return (data.addons.videoConf && data.addons.videoConf.data) ? (
        <Link
            href={videoConf.url}
            style={{
                ...buttonStyle,
                lineHeight: buttonStyle.height,
                paddingLeft: '20px',
                paddingRight: '20px',
                display: 'inline-block',
                ...videoConf.spacingStyle.getSpacingStyle()
            }}
            color={buttonStyle.color}
        >
            <img src={VideoConfData.options[data.addons.videoConf.data.key].iconSrc} alt={"icon"} style={{width: '30px', verticalAlign: 'middle'}} />
            {videoConf.buttonText}
        </Link>
    ) : null
}