import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Divider as MuiDivider, Grid, TextField } from '@material-ui/core';
import styled from 'styled-components';
import MediaUploader from 'AppCore/Components/MediaUploader/v2';
import EmbededMatterport from '../Components/EmbededMatterport'
import FontStyleEditor from '../Components/Editor/FontStyle'
import {
    Slider
} from '../Components/Editor/Helpers'

const Divider = styled(MuiDivider)`
    margin-top: 20px;
    margin-bottom: 20px;
`

const MediaUploadContainer = styled.div`
    max-width: 500px;
    max-height: 500px;
`

const UrlContainer = styled.div`
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
`

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function HomePage(props) {

    const [ logo, setLogo ] = useState(null)
    const [ logoSize, setLogoSize ] = useState(150)
    const [ bg, setBg ] = useState(null)
    const [ matterportId, setMatterportId ] = useState("");
    const [ clientName, setClientName ] = useState("");
    const [ fontStyle, setFontStyle ] = useState({
        fontColor: "#000000",
        fontFamily: 'Open Sans',
        fontSize: 20
    })

    const getEmbedUrl = () => {
        // `https://my.matterport.com/show/?m=${matterportId}&brand=1&play=1&qs=1&help=0&vrcoll=0&nozoom=1`

        if (!matterportId) {
            return null;
        }

        const { protocol, host } = window.location;

        let url = `${protocol}//${host}/embed?m=${matterportId}&c=${encodeURIComponent(clientName)}`
        if (logo && logo.ref) {
            url += "&logo=" + encodeURIComponent(logo.ref) + "&logoSize=" + logoSize
        }

        if (bg && bg.ref) {
            url += "&bg=" + encodeURIComponent(bg.ref)
        }

        if (fontStyle) {
            url += "&fontStyle=" + encodeURIComponent(JSON.stringify(fontStyle));
        }

        return url;
    }

    const getLogoRef = () => {
        if (logo && logo.ref) {
            return (logo.ref)
        }
        return "";
    }
    const getBgRef = () => {
        if (bg && bg.ref) {
            return (bg.ref)
        }
        return "";
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6">Matterport embed generator</Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
            <Container>
                <Box my={2}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <form>
                                <TextField
                                    label="Identifiant matterport" value={matterportId}
                                    onChange={({ target: { value }}) => setMatterportId(value)}
                                />
                                <br/>
                                <TextField
                                    label="Nom du client" value={clientName}
                                    onChange={({ target: { value }}) => setClientName(value)}
                                />
                                <br/>
                                <FontStyleEditor
                                    fontStyle={fontStyle}
                                    onChange={setFontStyle}
                                />
                                <Divider />
                                <Typography variant="h6">Ajouter un logo</Typography>
                                {logo &&
                                    <Slider
                                        label={"Taille de l'image"}
                                        value={logoSize || 100}
                                        min={50}
                                        max={1000}
                                        step={5}
                                        onChange={setLogoSize}
                                    />
                                }
                                <MediaUploadContainer>
                                    <MediaUploader
                                        title="Ajoutez un logo"
                                        infos={"Uploadez un image"}
                                        btnLabel={"Selectionnez une image"}
                                        firebaseUploadConf={{
                                            storageDir: "medias/images",
                                            storageRef: firebase.storage()
                                        }}
                                        style={{ width: "100%", height: "100%" }}
                                        fileType={"image/*"}
                                        canCrop={false}
                                        media={logo}
                                        onChangeMedia={setLogo}
                                        onClickDelete={() => setLogo(null)}

                                        onError={e => {
                                            switch (e.type) {
                                                case 'file_type':
                                                    alert("Votre video doit etre au format jpg");
                                                    break;
                                                default:
                                                    alert("Une erreur est survenue");
                                                    break;
                                            }
                                        }}
                                    />
                                </MediaUploadContainer>
                                <br/>
                                <Divider />
                                <Typography variant="h6">Ajouter une image arrière plan</Typography>

                                <MediaUploadContainer>
                                    <MediaUploader
                                        title="Ajoutez un image"
                                        infos={"Uploadez un image"}
                                        btnLabel={"Selectionnez une image"}
                                        firebaseUploadConf={{
                                            storageDir: "medias/images",
                                            storageRef: firebase.storage()
                                        }}
                                        style={{ width: "100%", height: "100%" }}
                                        fileType={"image/*"}
                                        canCrop={false}
                                        media={bg}
                                        onChangeMedia={setBg}
                                        onClickDelete={() => setBg(null)}

                                        onError={e => {
                                            switch (e.type) {
                                                case 'file_type':
                                                    alert("Votre video doit etre au format jpg");
                                                    break;
                                                default:
                                                    alert("Une erreur est survenue");
                                                    break;
                                            }
                                        }}
                                    />
                                </MediaUploadContainer>

                            </form>
                        </Grid>

                        <Grid item xs={6}>
                            <br/>
                            {getEmbedUrl() &&
                                <>
                                    <UrlContainer>
                                        <a href={getEmbedUrl()} target="_blank" rel="noopener noreferrer">{getEmbedUrl()}</a>
                                    </UrlContainer>
                                </>
                            }
                            <br/>
                            {matterportId && <EmbededMatterport
                                client={clientName}
                                matterportId={matterportId}
                                logo={getLogoRef()}
                                bg={getBgRef()}
                                fontStyle={fontStyle}
                                logoSize={logoSize}
                                style={{height: '500px'}}
                            />}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>
    );
}