// @flow
import React from 'react';

import {
    Grid
} from '@material-ui/core'

import TextField from '../Utils/TextField'
import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';
import SignatureData from '../../DataObject';
import SocialIcons, { SocialIcon } from '../../DataObject/Social'

import IconStyle from '../Utils/IconStyle';

import SocialIconsListEditor from './SocialIcons'

const Container = styled.div`
    padding: 10px;
`
const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`
const IconsContainer = styled(Grid)`
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`

const SocialIconImg = styled.img`
    width: 35px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
`

type Props = {
    data: SignatureData,
    onChange: (data: SignatureData) => any
}
export default ({ data, onChange }: Props) => {

    const allIcons = SocialIcon.getList().filter(icon => {
        if (data.social.socialIcons.find(i => i.icon === icon.icon)) {
            return false;
        }
        return true;
    });

    const onClickIcon = (socialIcon: SocialIcon) => () => {
        onChange(data.clone(d => {
            d.social.socialIcons.push(socialIcon);
        }))
    }

    return (
        <Container>

            <TextField
                label={"Caption"}
                value={data.social.caption || ""}
                onChange={e => {
                    onChange(data.clone(d => d.social.caption = e.target.value))
                }}
                style={{marginBottom: '10px'}}
            />

            <IconStyle
                maxWidth={SocialIcons.maxWidth}
                socialIcons={data.social}
                onChange={social => onChange(data.clone(d => d.social = social))}
            />

            <Divider />

            {data.social.socialIcons.length > 0 &&
                <>
                    <SocialIconsListEditor
                        data={data}
                        onChange={onChange}
                    />
                    <Divider />
                </>
            }

            <IconsContainer>
                {allIcons.map((socialIcon: SocialIcon) => {
                    return (
                        <SocialIconImg key={socialIcon.icon} src={socialIcon.src} alt={socialIcon.icon} onClick={onClickIcon(socialIcon)} />
                    )
                })}
            </IconsContainer>

        </Container>
    )
}