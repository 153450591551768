// @flow
import React, { useState } from 'react';
import * as firebase from 'firebase/app';

import { constructFirebaseStorageUrl } from 'AppCore/Utils'
import Form from 'AppCore/Components/Form/formFields'
import Label from 'AppCore/Components/Form/Label'
import Level from 'Apps/Nopanoga/Components/Level'
import MediaUploader from 'AppCore/Components/MediaUploader/v2';


import { TrainingSession } from "@nopanoga/models"

import Sports from 'Apps/Nopanoga/Components/Selectors/Sports';

import {
    Divider,
    SubTitle,
    BlocMediaUpload
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import {
    GridContainer,
    FormContainer,
    GridItem
} from './styledComponents'


type Props = {
    trainingSession: TrainingSession,
    onChangeTrainingSession: (trainingSession: TrainingSession) => any
}

export default ({ trainingSession, onChangeTrainingSession }: Props) => {

    const onChangeForm = ({ values, hasError}) => {
        onChangeTrainingSession(trainingSession.clone(t => {
            t.setData(values);
        }))
    }

    const [ videoMedia, setVideoMedia ] = useState(trainingSession.video ? {
        ...trainingSession.video,
        src: (trainingSession.video && trainingSession.video.ref) ? constructFirebaseStorageUrl(trainingSession.video.ref) : null
    } : null)
    const [ imageMedia, setImageMedia ] = useState(trainingSession.image ? {
        ...trainingSession.image,
        src: (trainingSession.image && trainingSession.image.ref) ? constructFirebaseStorageUrl(trainingSession.image.ref) : null
    } : null)

    const onChangeVideoMedia = file => {
        onChangeTrainingSession(trainingSession.clone(t => {
            t.setData({
                video: file
            });
        }))
        setVideoMedia(file);
    }
    const onChangeImageMedia = file => {
        onChangeTrainingSession(trainingSession.clone(t => {
            t.setData({
                image: file
            });
        }))
        setImageMedia(file);
    }

    return (

        <GridContainer>
            <FormContainer>
                <SubTitle style={{alignSelf: 'flex-start'}}>Informations générales</SubTitle>
                <Divider my={6} style={{width: '100%'}}/>
                <Form
                    style={{width: '100%'}}
                    config={{ attributes: {
                        title: {
                            label: (
                                <Label
                                    title="Nom de la séance"
                                    popoverColor={'blue'}
                                    popover="Un titre accrocheur peut vous permettre de capter l'attention des clients."
                                />
                            ),
                            placeholder: "Nom de la séance",
                            type: 'text'
                        },

                        type: {
                            label: (
                                <Label
                                    title="Type de séance"
                                    popoverColor={'blue'}
                                    popover={(
                                        <React.Fragment>
                                            <p>En choisissant "enchainement d'exercice", vous pourrez ajouter et reordonner une suite d'exercices, intercallés de temps de repos.</p>
                                            <p>En choisissant "vidéo", votre séance d'entrainement ne sera composé que d'une seule vidéo.</p>
                                        </React.Fragment>
                                    )}
                                />
                            ),
                            type: 'select',
                            values : [
                                { value: "exercices", label: "Enchainement d'exercices" },
                                { value: "video", label: "Vidéo seule" }
                            ]
                        },
                        description: {
                            label: (
                                <Label
                                    title="Description de la séance"
                                    popoverColor={'blue'}
                                    popover={(
                                        <React.Fragment>
                                            <p>En choisissant "enchainement d'exercice", vous pourrez ajouter et reordonner une suite d'exercices, intercallés de temps de repos.</p>
                                            <p>En choisissant "vidéo", votre séance d'entrainement ne sera composé que d'une seule vidéo.</p>
                                        </React.Fragment>
                                    )}
                                />
                            ),
                            type: 'text',
                            multiline: 10,
                            disabled: false
                        },
                        level: {
                            label: "Niveau", type: 'select',
                            values : [
                                { value: 1, label: <Level level={1} label /> },
                                { value: 2, label: <Level level={2} label /> },
                                { value: 3, label: <Level level={3} label /> },
                            ]
                        }
                    }}}
                    values={{
                        ...trainingSession.export()
                    }}
                    onChange={onChangeForm}
                />

                <Sports
                    style={{ width: '100%' }}
                    selectedIds={trainingSession.sports}
                    onChange={selectedIds => {
                        onChangeForm({
                            values: {
                                sports: selectedIds
                            },
                            hasError: false
                        })
                    }}
                />
            </FormContainer>
            <GridItem lg={4} >
                <BlocMediaUpload title="Video de présentation de la séance">

                    <MediaUploader
                        enableInstagramImport
                        title="Ajoutez une video pour l'execice"
                        infos={"Uploadez une video"}
                        btnLabel={"Selectionnez une video"}

                        firebaseUploadConf={{
                            storageDir: "medias/videos",
                            storageRef: firebase.storage()
                        }}
                        style={{ width: "100%", height: "100%" }}
                        fileType={"video/mp4"}
                        canCrop={false}

                        media={videoMedia}
                        onChangeMedia={onChangeVideoMedia}
                        onClickDelete={() => onChangeVideoMedia(undefined)}
                        onError={e => {
                            switch (e.type) {
                                case 'file_type':
                                    alert("Votre video doit etre au format mp4");
                                    break;
                                default:
                                    alert("Une erreur est survenue");
                                    break;
                            }
                        }}
                    />
                </BlocMediaUpload>

                <BlocMediaUpload title="Illustration de la séance (image)" style={{marginTop: '20px'}}>
                    <MediaUploader
                        title="Ajoutez une photo pour la séance"
                        infos={"Uploadez une photo"}
                        btnLabel={"Selectionnez une photo"}
                        firebaseUploadConf={{
                            storageDir: "medias/images",
                            storageRef: firebase.storage()
                        }}
                        style={{ width: "100%", height: "100%" }}
                        fileType={"image/*"}
                        canCrop

                        media={imageMedia}
                        onChangeMedia={onChangeImageMedia}
                        onClickDelete={() => onChangeImageMedia(undefined)}

                        onError={e => {
                            switch (e.type) {
                                case 'file_type':
                                    alert("Votre photo doit etre au format jpg");
                                    break;
                                default:
                                    alert("Une erreur est survenue");
                                    break;
                            }
                        }}
                    />
                </BlocMediaUpload>
            </GridItem>
        </GridContainer>

    )
}
