export class ApiError extends Error {

	constructor(error) {

		super("unknown");

		if (error === undefined) {
			this.type = 'UNKNOWN';
			this.code = -1;
			this.message = 'Unknown error';
			return;
		}

		this.error_raw = error;
		this.type = error.type;
		this.message = error.message;
		this.code = error.code;
		this.payload = null;
		if (error.payload) {
			this.payload = error.payload;
		}
	}
}