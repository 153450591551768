// @flow
import React from 'react';

import SignatureData from '../../../DataObject';
import { SocialIcon } from '../../../DataObject/Social';

import { TableReset, TableLine, TableCell } from './index';


export const SocialIcons = ({ data }: { data :SignatureData }) => {

	if (!data.hasSocialIcons()) {
		return null;
	}

	return (
		<TableReset style={{ height: data.social.width+"px" }}>
			<tbody>
				<TableLine style={{ height: data.social.width+"px" }}>
					{data.social.socialIcons.map((socialIcon: SocialIcon) => (
						<TableCell key={socialIcon.icon} style={{ height: (data.social.width+10)+"px", width: data.social.width+"px"}}>
							<a
								href={socialIcon.url} target="_blank" rel="noopener noreferrer"
								style={data.social.getContainerStyle()}
							>
								<img
									style={{...data.social.getStyle(), marginRight: '5px'}}
									width={data.social.width}
									alt={socialIcon.icon}
									src={socialIcon.src}
								/>
							</a>
						</TableCell>
					))}
				</TableLine>

			</tbody>
		</TableReset>
	)
};