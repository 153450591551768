// @flow
import React, { useRef, useEffect, useState } from 'react';
import Editor from './V2/Editor';

import SignatureData from './V2/DataObject';
import Preview from './V2/Preview'
import LayoutSelector from './V2/Preview/Selector'
import styled from 'styled-components';

const Container = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`
const EditorContainer = styled.div`
    max-width: 500px;
    margin-right: 20px;
    @media (max-width: 1220px) {
        max-width: none;
        width: 100%;
        margin-right: 0px;
    }
`
const PreviewContainer = styled.div`
    flex:1;
    min-width: 400px;
    @media (max-width: 1220px) {
        width: 100%;
    }
`

const LayoutChooserContainer = styled.div`
    max-width: 100%;
    margin-top: 10px;
    overflow: auto;
`

type Props = {
    disableFields?: Object,
    onChange: (data: SignatureData) => any,
    style?: any,
    onlyStyle?: boolean,
    data: SignatureData,
    dataPreview?: SignatureData
}
export default ({ data, dataPreview, onChange, disableFields, onlyStyle = false, style }: Props) => {

    const ref = useRef(null);
    const [ width, setWidth ] = useState(0)

    const onResize = () => {
        if (!ref.current) {
            return;
        }
        setWidth(ref.current.offsetWidth - 1);
    }
    useEffect(() => {
        onResize()
    }, [ ref ]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    return (
        <Container style={style}>
            <EditorContainer>
                <Editor onlyStyle={onlyStyle} disableFields={disableFields} data={data} onChange={onChange}/>
            </EditorContainer>
            <PreviewContainer ref={ref}>
                <Preview data={dataPreview || data} />

                <LayoutChooserContainer style={{width: width+'px'}}>
                    <LayoutSelector
                        data={data}
                        dataPreview={dataPreview}
                        onChange={layoutKey => {
                            onChange(data.clone(d => d.layoutKey = layoutKey));
                        }}
                    />
                </LayoutChooserContainer>
            </PreviewContainer>
        </Container>
    )
}