import React from "react";
import styled from "styled-components";
import DataTable from 'AppCore/Components/DataTable';
import TagComponent from "Apps/Nopanoga/Components/Tag/Sport"
import Level from 'Apps/Nopanoga/Components/Level'

const TagContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}) => {

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        key="training_session_table"
        data={sortedData}
        config={{
            cells: [
                { title: 'Nom', key: 'title' },
                { title: 'Activités', key: 'sports' },
                { title: 'Niveau', key: 'level' },
            ],
            actionsLabel: "Modifier/Supprimer",
            actions: actions || [
                { key: 'edit', icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === "level") {
                return <Level level={item.level} />
            }

            if (attr_name === 'sports') {
                return (
                    <TagContainer>
                        {item.sports && item.sports.map((tag_key, i) =>
                            <TagComponent key={tag_key+i} tag_key={tag_key} style={{marginRight: '10px'}} />
                        )}
                    </TagContainer>
                )
            }
            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}