// @flow

import React from 'react';
import SignatureData from '../../DataObject';

import {
	Picture,
	Text,
	SocialIcons,

	TableReset,
	TableLine, TableCell,
} from './helpers';

export const key = 'layout3';
export default ({ data, preview = false }: { data: SignatureData, preview?: boolean }) => (
	<>
		{data.signoff && data.signoff.text &&
			<>
				<Text fontStyle={data.signoff.fontStyle}>{data.signoff.text}</Text>
				<br />
				<br />
			</>
		}
		<TableReset>
			<tbody>

				<TableLine>
					{data.hasLogo() &&
						<TableCell style={{ verticalAlign:'top', paddingRight: '12px', borderRight: '1px solid #223B04'}}>
							<Picture preview={preview} image={data.logo} />
						</TableCell>
					}
					<td style={{border:0, paddingLeft: '12px'}}>
						<table>
							<tbody>

								{!!data.getDisplayName() && (
									<TableLine>
										<TableCell><Text color2 fontStyle={data.fontStyle}>{data.getDisplayName()}</Text></TableCell>
									</TableLine>
								)}

								{['companyName', 'positionInCompany'].map(key => !!data[key]).filter(a => a).length > 0 &&
									<TableLine>
										<TableCell>
											{data.positionInCompany &&
												<Text fontStyle={data.fontStyle}>{data.positionInCompany}</Text>
											}
											{data.companyName && data.positionInCompany ?
												<Text fontStyle={data.fontStyle}>{" | "}</Text> : null
											}
											{data.companyName &&
												<Text fontStyle={data.fontStyle}>{data.companyName}</Text>
											}
										</TableCell>
									</TableLine>
								}
								{data.phone &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>t: </Text>
											<Text link={'tel:'+data.phone} fontStyle={data.fontStyle}>{data.phone}</Text>
										</TableCell>
									</TableLine>
								}
								{data.skype &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>s: </Text>
											<Text link={'skype:'+data.skype+"?chat"} fontStyle={data.fontStyle}>{data.skype}</Text>
										</TableCell>
									</TableLine>
								}
								{data.email &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>e: </Text>
											<Text link={'mailto:'+data.email} fontStyle={data.fontStyle}>{data.email}</Text>
										</TableCell>
									</TableLine>
								}
								{data.address &&
									<TableLine>
										<TableCell>
											<Text color2 fontStyle={data.fontStyle}>a: </Text>
											<Text fontStyle={data.fontStyle}>{data.address}</Text>
										</TableCell>
									</TableLine>
								}

								{(data.customFields || []).map((customField, index) => (
									<TableLine key={customField.key + "_" + index}>
										<TableCell>
											<Text fontStyle={data.fontStyle} color2>{customField.key}:</Text> <Text link={customField.url} fontStyle={data.fontStyle}>{customField.value}</Text>
										</TableCell>
									</TableLine>
								))}

								{data.companyWebsite &&
									<TableLine>
										<TableCell><Text color2 link={data.companyWebsite} fontStyle={data.fontStyle}>{data.companyWebsite}</Text></TableCell>
									</TableLine>
								}

								{data.hasSocialIcons() &&
									<>
										{data.social.caption &&
											<TableLine>
												<TableCell>
													<Text color2 fontStyle={data.fontStyle}>{data.social.caption}</Text>
												</TableCell>
											</TableLine>
										}
										<TableLine>
											<TableCell>
												<SocialIcons data={data} />
											</TableCell>
										</TableLine>
									</>
								}
							</tbody>
						</table>

					</td>
				</TableLine>
			</tbody>
		</TableReset>
	</>
)