// @flow
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
    Paper, Grid
} from '@material-ui/core'

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import {
    Title, SubTitle as SubTitleBase, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { Button } from '@nopanoga/components'

const Container = styled(Paper)`
    padding: 20px;
`

const ComponentDivider = styled(Divider)`
    margin-top: 20px;
    margin-bottom: 20px;
`
const SubTitle = styled(SubTitleBase)`
    margin-bottom: 20px;
    display: block;
`

const title = "All Components";
export default () => (
    <React.Fragment>
        <Helmet title={title} />

        <FlexRowSpaceBetween>
            <Title>
                {title}
            </Title>
        </FlexRowSpaceBetween>

        <Divider my={6} />

        <Container>
            <SubTitle>Paper</SubTitle>
            <Paper style={{padding: '20px'}}>Paper</Paper>
            <ComponentDivider/>


            <SubTitle>Buttons</SubTitle>

            <Grid container spacing={6} style={{alignItems: 'center'}}>
                <Grid item>
                    <Button>primary</Button>
                </Grid>
                <Grid item>
                    <Button color="secondary">secondary</Button>
                </Grid>
                <Grid item>
                    <Button color="tertiary">tertiary</Button>
                </Grid>
            </Grid>


        </Container>
    </React.Fragment>
)