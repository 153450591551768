import React, { useState } from 'react';
import {
	Instagram
} from '@material-ui/icons'

import InstagramMediaBrowserModal from 'AppCore/Components/InstagramMediaBrowser/modal'
import Button from 'AppCore/Components/Button';


export default ({ onImportMedia = () => {}}) => {

    const [ instagramBrowserOpened, setInstagramBrowserOpened ] = useState(false)

    return (
        <div onClick={e => {
            e.stopPropagation();
        }}>
            {instagramBrowserOpened &&
                <InstagramMediaBrowserModal
                    onClose={() => {
                        setInstagramBrowserOpened(false);
                    }}
                    onClickImport={async video => {
                        const result = await onImportMedia(video);
                        if (result) {
                            setInstagramBrowserOpened(false);
                        }
                    }}
                />
            }
            <Button
                color="secondary" variant="outlined" onClick={e => {
                    e.stopPropagation();
                    setInstagramBrowserOpened(true);
                }}
                startIcon={<Instagram />}
            >{"Import depuis instagram"}</Button>
        </div>
    )
}