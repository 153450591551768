import Api from 'Meth/api/request';

export const checkParam = (param, type, param_name, required = false) => {

	if ((param === undefined || param === null) && !required) {
		return true;
	}

	let error = false;
	switch (true) {
		case type === 'string' && typeof param !== 'string' :
		case type === 'array' && !Array.isArray(param) :
			error = true;
			break;
		default:
			break;
	}

	if (error) {
		throw new Error(`${param_name} must be a ${type} !`);
	}

	return true;
}

export default {
	
	/*
		
	*/
	getConf: ({ filter = null } = {}) => {
		return Api.call({
			uri : `conf`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		List all api end points availalble
	*/
	getSchema: ({ filter = null } = {}) => {
		return Api.call({
			uri : `schema`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	userLogout: ({ revoke_token, filter = null } = {}) => {
		checkParam(revoke_token, 'string', 'revoke_token', true)
		return Api.call({
			uri : `auth/logout`,
			request_method: Api.request_method.GET,
			params: {
				revoke_token,
			}
		})
	},

	
	/*
		
	*/
	loginWithOtherAccount: ({ account_id, filter = null } = {}) => {
		checkParam(account_id, 'string', 'account_id', true)
		return Api.call({
			uri : `auth/login/other-account`,
			request_method: Api.request_method.GET,
			params: {
				account_id,
			}
		})
	},

	
	/*
		
	*/
	magicLinkSend: ({ email, from_email, filter = null } = {}) => {
		checkParam(email, 'string', 'email', true)
		checkParam(from_email, 'string', 'from_email', false)
		return Api.call({
			uri : `auth/magiclink/send`,
			request_method: Api.request_method.GET,
			params: {
				email,
				from_email,
			}
		})
	},

	
	/*
		
	*/
	magicLinkLogin: ({ email, code, from_email, filter = null } = {}) => {
		checkParam(email, 'string', 'email', true)
		checkParam(code, 'string', 'code', true)
		checkParam(from_email, 'string', 'from_email', false)
		return Api.call({
			uri : `auth/magiclink/login`,
			request_method: Api.request_method.GET,
			params: {
				email,
				code,
				from_email,
			}
		})
	},

	
	/*
		get a user with its id
	*/
	getInstallSignatureData: ({ user_id = "me", signatureversion, filter = null } = {}) => {
		checkParam(signatureversion, 'undefined', 'signatureversion', true)
		return Api.call({
			uri : `user/install-signature/${user_id}`,
			request_method: Api.request_method.GET,
			params: {
				signatureversion,
			}
		})
	},

	
	/*
		set missing data from Signature
	*/
	setInstallSignatureData: ({ user_id = "me", signatureversion, user: { phone_number, first_name, last_name, position } = {} } = {}) => {
		checkParam(signatureversion, 'undefined', 'signatureversion', true)
		checkParam(phone_number, 'string', 'phone_number', false)
		checkParam(first_name, 'string', 'first_name', false)
		checkParam(last_name, 'string', 'last_name', false)
		checkParam(position, 'string', 'position', false)
		return Api.call({
			uri : `user/install-signature/${user_id}`,
			request_method: Api.request_method.POST,
			data: {
				phone_number,
				first_name,
				last_name,
				position,
			},
			params: {
				signatureversion,
			}
		})
	},

	
	/*
		
	*/
	setUserSignatureDeployed: ({ user_id = "me", signatureversion } = {}) => {
		checkParam(signatureversion, 'undefined', 'signatureversion', true)
		return Api.call({
			uri : `user/install-signature/${user_id}/installed`,
			request_method: Api.request_method.POST,
			params: {
				signatureversion,
			}
		})
	},

	
	/*
		Redirect to the actual banner image
	*/
	redirectBannerImage: ({ user_id = "me", filter = null } = {}) => {
		return Api.call({
			uri : `user/${user_id}/banner`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		get a user with its id
	*/
	getUser: ({ user_id = "me", filter = null } = {}) => {
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updateUser: ({ user_id = "me", user: { team_id, signature_template_id, picture_id, phone_number, first_name, last_name, position, linkedIn } = {} } = {}) => {
		checkParam(team_id, 'string', 'team_id', false)
		checkParam(signature_template_id, 'string', 'signature_template_id', false)
		checkParam(picture_id, 'string', 'picture_id', false)
		checkParam(phone_number, 'string', 'phone_number', false)
		checkParam(first_name, 'string', 'first_name', false)
		checkParam(last_name, 'string', 'last_name', false)
		checkParam(position, 'string', 'position', false)
		checkParam(linkedIn, 'string', 'linkedIn', false)
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				team_id,
				signature_template_id,
				picture_id,
				phone_number,
				first_name,
				last_name,
				position,
				linkedIn,
			},
		})
	},

	
	/*
		
	*/
	deleteUser: ({ user_id = "me" } = {}) => {
		return Api.call({
			uri : `user/${user_id}`,
			request_method: Api.request_method.DELETE,
		})
	},

	
	/*
		deploy a template for a user
	*/
	deployUserTemplate: ({ user_id = "me", deploy_message } = {}) => {
		checkParam(deploy_message, 'string', 'deploy_message', false)
		return Api.call({
			uri : `user/${user_id}/deploy-signature`,
			request_method: Api.request_method.POST,
			data: {
				deploy_message,
			},
		})
	},

	
	/*
		
	*/
	debug_payment: ({ user_id = "me", filter = null } = {}) => {
		return Api.call({
			uri : `user/${user_id}/debug_payment`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		get an organisation with its id
	*/
	getOrganisation: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updateOrganisation: ({ organisation_id, organisation: { signature_template_id, logo_id, name, address, website_url, facebook, instagram, twitter, linkedin, disclaimer, google_play_url, appstore_url } = {} } = {}) => {
		checkParam(signature_template_id, 'string', 'signature_template_id', false)
		checkParam(logo_id, 'string', 'logo_id', false)
		checkParam(name, 'string', 'name', false)
		checkParam(address, 'string', 'address', false)
		checkParam(website_url, 'string', 'website_url', false)
		checkParam(facebook, 'string', 'facebook', false)
		checkParam(instagram, 'string', 'instagram', false)
		checkParam(twitter, 'string', 'twitter', false)
		checkParam(linkedin, 'string', 'linkedin', false)
		checkParam(disclaimer, 'string', 'disclaimer', false)
		checkParam(google_play_url, 'string', 'google_play_url', false)
		checkParam(appstore_url, 'string', 'appstore_url', false)
		return Api.call({
			uri : `organisation/${organisation_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				signature_template_id,
				logo_id,
				name,
				address,
				website_url,
				facebook,
				instagram,
				twitter,
				linkedin,
				disclaimer,
				google_play_url,
				appstore_url,
			},
		})
	},

	
	/*
		
	*/
	setOrganisationOnboarding: ({ organisation_id } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/set-onboarding`,
			request_method: Api.request_method.POST,
		})
	},

	
	/*
		Get an Organisation Teams
	*/
	getTeams: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/teams`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createTeam: ({ organisation_id, team: { name, is_global, signature_template_id, campaign_id } = {} } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(is_global, 'boolean', 'is_global', false)
		checkParam(signature_template_id, 'string', 'signature_template_id', false)
		checkParam(campaign_id, 'string', 'campaign_id', false)
		return Api.call({
			uri : `organisation/${organisation_id}/teams`,
			request_method: Api.request_method.POST,
			data: {
				name,
				is_global,
				signature_template_id,
				campaign_id,
			},
		})
	},

	
	/*
		Get an Organisation's Users
	*/
	getOrganisationUsers: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/users`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createUser: ({ organisation_id, user: { email, team_id, signature_template_id, picture_id, phone_number, first_name, last_name, position, linkedIn } = {} } = {}) => {
		checkParam(email, 'string', 'email', true)
		checkParam(team_id, 'string', 'team_id', false)
		checkParam(signature_template_id, 'string', 'signature_template_id', false)
		checkParam(picture_id, 'string', 'picture_id', false)
		checkParam(phone_number, 'string', 'phone_number', false)
		checkParam(first_name, 'string', 'first_name', false)
		checkParam(last_name, 'string', 'last_name', false)
		checkParam(position, 'string', 'position', false)
		checkParam(linkedIn, 'string', 'linkedIn', false)
		return Api.call({
			uri : `organisation/${organisation_id}/users`,
			request_method: Api.request_method.POST,
			data: {
				email,
				team_id,
				signature_template_id,
				picture_id,
				phone_number,
				first_name,
				last_name,
				position,
				linkedIn,
			},
		})
	},

	
	/*
		List all folders and files
	*/
	importUsers: ({ organisation_id, file } = {}) => {
		checkParam(file, 'file', 'file', true)
		return Api.call({
			uri : `organisation/${organisation_id}/users/import`,
			request_method: Api.request_method.POST,
			data: {
				file,
			},
		})
	},

	
	/*
		
	*/
	importUsersOld: ({ organisation_id, file } = {}) => {
		checkParam(file, 'file', 'file', true)
		return Api.call({
			uri : `organisation/${organisation_id}/users/import-old`,
			request_method: Api.request_method.POST,
			data: {
				file,
			},
		})
	},

	
	/*
		Get an Organisation's Templates
	*/
	getTemplates: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/signature-templates`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createTemplate: ({ organisation_id, template: { name, signatureData } = {} } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(signatureData, 'string', 'signatureData', false)
		return Api.call({
			uri : `organisation/${organisation_id}/signature-templates`,
			request_method: Api.request_method.POST,
			data: {
				name,
				signatureData,
			},
		})
	},

	
	/*
		Get an Organisation's Campaigns
	*/
	getCampaigns: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/campaigns`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createCampaign: ({ organisation_id, campaign: { banner_id, type, name, start_date, end_date, expire } = {} } = {}) => {
		checkParam(banner_id, 'string', 'banner_id', false)
		checkParam(type, 'string', 'type', false)
		checkParam(name, 'string', 'name', true)
		checkParam(start_date, 'integer', 'start_date', false)
		checkParam(end_date, 'integer', 'end_date', false)
		checkParam(expire, 'integer', 'expire', false)
		return Api.call({
			uri : `organisation/${organisation_id}/campaigns`,
			request_method: Api.request_method.POST,
			data: {
				banner_id,
				type,
				name,
				start_date,
				end_date,
				expire,
			},
		})
	},

	
	/*
		
	*/
	resetOrganisationPayment: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/payment-method/reset`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	getSetupIntentSecretKey: ({ organisation_id, filter = null } = {}) => {
		return Api.call({
			uri : `organisation/${organisation_id}/payment-method/get-setup-intent-key`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updatePaymentMethod: ({ organisation_id, payment: { payment_method } = {} } = {}) => {
		checkParam(payment_method, 'string', 'payment_method', false)
		return Api.call({
			uri : `organisation/${organisation_id}/payment-method/update`,
			request_method: Api.request_method.POST,
			data: {
				payment_method,
			},
		})
	},

	
	/*
		get a team with its id
	*/
	getTeam: ({ team_id, filter = null } = {}) => {
		return Api.call({
			uri : `team/${team_id}`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updateTeam: ({ team_id, team: { name, signature_template_id, campaign_id } = {} } = {}) => {
		checkParam(name, 'string', 'name', false)
		checkParam(signature_template_id, 'string', 'signature_template_id', false)
		checkParam(campaign_id, 'string', 'campaign_id', false)
		return Api.call({
			uri : `team/${team_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				name,
				signature_template_id,
				campaign_id,
			},
		})
	},

	
	/*
		
	*/
	deleteTeam: ({ team_id } = {}) => {
		return Api.call({
			uri : `team/${team_id}`,
			request_method: Api.request_method.DELETE,
		})
	},

	
	/*
		deploy a team template
	*/
	deployTeamTemplate: ({ team_id, deploy_message } = {}) => {
		checkParam(deploy_message, 'string', 'deploy_message', false)
		return Api.call({
			uri : `team/${team_id}/deploy-signature`,
			request_method: Api.request_method.POST,
			data: {
				deploy_message,
			},
		})
	},

	
	/*
		Get a Team's signatures
	*/
	getTeamSignatures: ({ team_id, filter = null } = {}) => {
		return Api.call({
			uri : `team/${team_id}/signatures`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createTeamSignature: ({ team_id, template: { name, signatureData } = {} } = {}) => {
		checkParam(name, 'string', 'name', true)
		checkParam(signatureData, 'string', 'signatureData', false)
		return Api.call({
			uri : `team/${team_id}/signatures`,
			request_method: Api.request_method.POST,
			data: {
				name,
				signatureData,
			},
		})
	},

	
	/*
		Get a Team's Campaigns
	*/
	getTeamCampaigns: ({ team_id, filter = null } = {}) => {
		return Api.call({
			uri : `team/${team_id}/campaigns`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	createTeamCampaign: ({ team_id, campaign: { banner_id, type, name, start_date, end_date, expire } = {} } = {}) => {
		checkParam(banner_id, 'string', 'banner_id', false)
		checkParam(type, 'string', 'type', false)
		checkParam(name, 'string', 'name', true)
		checkParam(start_date, 'integer', 'start_date', false)
		checkParam(end_date, 'integer', 'end_date', false)
		checkParam(expire, 'integer', 'expire', false)
		return Api.call({
			uri : `team/${team_id}/campaigns`,
			request_method: Api.request_method.POST,
			data: {
				banner_id,
				type,
				name,
				start_date,
				end_date,
				expire,
			},
		})
	},

	
	/*
		Get a Team members
	*/
	getTeamMembers: ({ team_id, filter = null } = {}) => {
		return Api.call({
			uri : `team/${team_id}/members`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		get a template with its id
	*/
	getTemplate: ({ template_id, filter = null } = {}) => {
		return Api.call({
			uri : `template/${template_id}`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updateTemplate: ({ template_id, template: { name, signatureData } = {} } = {}) => {
		checkParam(name, 'string', 'name', false)
		checkParam(signatureData, 'string', 'signatureData', false)
		return Api.call({
			uri : `template/${template_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				name,
				signatureData,
			},
		})
	},

	
	/*
		
	*/
	deleteTemplate: ({ template_id } = {}) => {
		return Api.call({
			uri : `template/${template_id}`,
			request_method: Api.request_method.DELETE,
		})
	},

	
	/*
		get a campaign with its id
	*/
	getCampaign: ({ campaign_id, filter = null } = {}) => {
		return Api.call({
			uri : `campaign/${campaign_id}`,
			request_method: Api.request_method.GET,
		})
	},

	
	/*
		
	*/
	updateCampaign: ({ campaign_id, campaign: { banner_id, type, name, start_date, end_date, expire } = {} } = {}) => {
		checkParam(banner_id, 'string', 'banner_id', false)
		checkParam(type, 'string', 'type', false)
		checkParam(name, 'string', 'name', false)
		checkParam(start_date, 'integer', 'start_date', false)
		checkParam(end_date, 'integer', 'end_date', false)
		checkParam(expire, 'integer', 'expire', false)
		return Api.call({
			uri : `campaign/${campaign_id}`,
			request_method: Api.request_method.PATCH,
			data: {
				banner_id,
				type,
				name,
				start_date,
				end_date,
				expire,
			},
		})
	},

	
	/*
		
	*/
	deleteCampaign: ({ campaign_id } = {}) => {
		return Api.call({
			uri : `campaign/${campaign_id}`,
			request_method: Api.request_method.DELETE,
		})
	},

	
	/*
		List all folders and files
	*/
	upload: ({ file } = {}) => {
		checkParam(file, 'file', 'file', true)
		return Api.call({
			uri : `media`,
			request_method: Api.request_method.POST,
			data: {
				file,
			},
		})
	},

	
	/*
		get a Media with its id
	*/
	getMedia: ({ media_id, filter = null } = {}) => {
		return Api.call({
			uri : `media/${media_id}`,
			request_method: Api.request_method.GET,
		})
	}
}