import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { ModalContent } from 'AppCore/Components/Modal';

import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import TemplateSelector from 'Apps/Signally/Components/Views/TemplateSelector';
import TeamSelector from 'Apps/Signally/Components/Views/TeamSelector';
import { updateUser, createUser, displayError } from 'Apps/Signally/Actions';
import Api from 'Apps/Signally/Api';

import css from './editCreate.module.css';

import ModalForm from '../ModalForm'


class EditUserModal extends ModalForm {

	getFormAttributeConfig = () => {

		const { user_id } = this.props;
		const { loading = false, saving = false } = this.state;
		const isEdit = user_id !== null;

		return {
			email: { type: 'text', validators: ['not_empty', 'email'], disabled: loading || saving || isEdit},
			first_name: { type: 'text', disabled: loading || saving },
			last_name: { type: 'text', disabled: loading || saving },
			position: { type: 'text', disabled: loading || saving },
			phone_number: { type: 'text', disabled: loading || saving }
		};
	}

	loadData = async () => {
		this.setState({
			data: await this.getUserData(),
			loading: false
		})
	}

	getUserData = async () => {
		const { user_id } = this.props;
		if (user_id) {
			const user = await Api.getUser({ user_id });
			return user;
		}

		return { email: "dd@sdfkf.com" };
	}

	onChangeUserValue = attr_name => ({target: { value }}) => {
		this.setState({
			data: {
				...this.state.data,
				[attr_name]: value
			}
		})
	};

	save = async () => {
		const { user_id, organisation_id } = this.props;
		const { data: user } = this.state;

		if (user_id) {
			await this.props.updateUser({ user_id, user });
		} else {
			await Api.createUser({ organisation_id, user });
		}

	}

	renderModalContent() {

		const { loading = false, data: user } = this.state;

		return (loading ? <ActivityIndicator /> :

			<ModalContent>

				<div className={css.formContainer}>

					{this.renderForm({ formClassName: css.form })}

					<TemplateSelector
						noneLabel="Signature attribué à l'équipe"
						label="Signature (prioritaire sur la signature de l'équipe)"
						selected_id={(user.signature_template_id) || null}
						onChange={this.onChangeUserValue('signature_template_id')}
					/>

					<TeamSelector label="Equipe" selected_id={(user.team_id) || null} onChange={this.onChangeUserValue('team_id')}/>
				</div>
			</ModalContent>);
	}

	getTitle() {
		const { user_id } = this.props;
		return user_id ? "Edition" : "Nouvel employé"
	}


}


export default connect(
	state => ({
		organisation_id: state.Auth.organisation_id,
		current_user: state.current_user
	}),
	{ updateUser, createUser, displayError }
)(withSnackbar(EditUserModal));