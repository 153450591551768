// @flow
import * as React from 'react';
import { useEffect } from 'react';
import dragula from "react-dragula";
import autoScroll from 'dom-autoscroller';

import { TrainingSessionBloc as BlocModel } from "@nopanoga/models"

import Bloc from './bloc'
import {
    Padding
} from './styledComponents'

import "react-dragula/dist/dragula.css";
import styled from 'styled-components';


const BlocContainer = styled(Padding)`

`


const selfOrParentHasClass = (el, className) => {
    if (el.classList && el.classList.contains && el.classList.contains(className)) {
        return true;
    }
    if (!el.parentNode) {
        return false;
    }
    return selfOrParentHasClass(el.parentNode, className)
}

type Props = {
    blocsExercicesChecksum?: string,
    blocs: Array<BlocModel>,
    onChange: (Array<any>) => any,

    renderExerciceComponent?: any => any,
    renderBlocComponent?: any => any
}
export default ({
    blocsExercicesChecksum,
    blocs, onChange = () => {},
    renderExerciceComponent,
    renderBlocComponent,
} : Props) => {

    const exercicesContainers = [];
    const blocContainers = [];

    const onContainerReady = container => {
        exercicesContainers.push(container);
    };

    const handleBlocContainerLoaded = container => {
        if (container) {
            blocContainers.push(container);
        }
    };

    useEffect(() => {

        const onOrderChanged = drakes => {

            const newBlocs: Array<BlocModel> = blocs.map((bloc: BlocModel) => {
                const newBloc: BlocModel = bloc.clone();
                newBloc.setData({ exercices: [] })
                return newBloc;
            });

            exercicesContainers.forEach(container => {

                const newExerciceList = [];

                [ ...container.childNodes ].map(exerciceNode => {
                    if (exerciceNode.dataset.order === undefined) {
                        return true;
                    }
                    const blocIndex = exerciceNode.dataset.blocindex;
                    const exerciceIndex = exerciceNode.dataset.order;

                    newExerciceList.push(blocs[blocIndex].exercices[exerciceIndex].clone());

                    return true;
                })

                newBlocs[container.dataset.blocindex].setData({
                    exercices: newExerciceList
                })
            });

            const newNewBlocs = [];
            [ ...blocContainers[0].childNodes ].map(node => {

                if (node.dataset.type !== "bloc" ) {
                    return true;
                }

                newNewBlocs.push(newBlocs[node.dataset.order].clone());

                return true;
            })

            /// https://github.com/bevacqua/react-dragula/issues/23#issuecomment-298149311
            drakes.map(drake => {
                drake.cancel(true);
                return true;
            })

            onChange(newNewBlocs);
        }

        ////// TOUCHMOVE LISTENER
        let scrollable = true;
        const touchMoveListener = (e: Event) => {
            if (!scrollable)    {
                e.preventDefault();
            }
        }

        document.addEventListener('touchmove', touchMoveListener, { passive:false });
        const destroyTouchMoveListener = () => {
            document.removeEventListener('touchmove', touchMoveListener);
        }
        ////// TOUCHMOVE LISTENER

        // Exercices
        const drakeExercices = dragula({
            containers: exercicesContainers
        });

        // Blocs
        const drakeBlocs = dragula({
            containers: blocContainers,
            moves: (el, source, handle, sibling) => {
                if (selfOrParentHasClass(handle, "drag-bloc-indicator")) {
                    return true;
                }
                return false;
            }
        });

        [drakeBlocs, drakeExercices].forEach(drake => drake.on('drag', () => {
            scrollable = false;
        }).on('dragend', () => {
            scrollable = true;
        }).on('drop', () => {
            scrollable = true;
            onOrderChanged([drakeExercices, drakeBlocs])
        }));

        var scroll = autoScroll([
            document.querySelector("#root")
        ], {
            margin: 20,
            maxSpeed: 5,
            scrollWhenOutside: true,
            autoScroll: function () {
                //Only scroll when the pointer is down, and there is a child being dragged.
                return this.down && (drakeBlocs.dragging || drakeExercices.dragging)
            }
        });

        return () => {
            destroyTouchMoveListener();
            drakeExercices.destroy();
            drakeBlocs.destroy();
            scroll.destroy();
        }

    }, [ exercicesContainers, blocContainers, blocs, onChange ]);

    return (
        <BlocContainer ref={handleBlocContainerLoaded} style={{'WebkitOverflowScrolling': 'unset', marginBottom:'0px'}}>
            {blocs.map((bloc, i) => {
                return (
                    <Bloc
                        key={bloc.key}
                        blocsExercicesChecksum={blocsExercicesChecksum}
                        bloc={bloc}
                        order={i}
                        onContainerLoaded={onContainerReady}
                        title={"Bloc "+(i+1)+" - " + bloc.title}
                        renderExerciceComponent={renderExerciceComponent}
                        renderBlocComponent={renderBlocComponent}
                    />
                )
            })}
        </BlocContainer>
    )
}