import React from 'react';
import { useCurrentUser } from '@knxlab/react-hooks';
import { TrainingSession } from '@nopanoga/models'

import TrainingSessionEdit from './index'

export default () => {

    const userAuth = useCurrentUser();

    return (
        <TrainingSessionEdit
            initialTrainingSession={(new TrainingSession()).clone(trainingSession => {
                trainingSession.owner = userAuth.uid;
                trainingSession.type = 'exercices';
            })}
            saveData={async trainingSession => {
                trainingSession.owner = userAuth.uid;
                await trainingSession.save();
            }}
        />
    )
}