import React from "react";
import { useNavigate } from 'react-router-dom'

import { Edit } from '@material-ui/icons'

import { Product } from '@izzistock/models'
import ProductsTable from 'Apps/Stockizy/Components/Products/List'
import PageList from 'AppCore/Components/CommonPages/PageList'


export default () => {

    const navigate = useNavigate();

    return (
        <PageList
            title="Liste des produits"
            addButton={{
                to: '/product/create',
                title: 'Créer un produit'
            }}
            getData={Product.getAll}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <ProductsTable
                    data={data.filter(item => {
                        return (item.name || "").toLocaleLowerCase().substr(0, searchQuery.length) === searchQuery.toLocaleLowerCase();
                    })}
                    actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/product/'+id) }
                    ]}
                />
            )}
        />
    )
}