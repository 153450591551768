import React from 'react';

export default ({ ...props }) => (
	<article>
  <ol>
    <li>Generate an email signature using <b>MySignature</b> editor.</li>
    <li>Click on <b>Copy to clipboard</b> button.</li>
    <li>
      In Apple Mail Click on <b>Preferences</b> mac-command (or <b>Choose Mail</b> &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; <b>Preferences</b> &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; <b>Signatures</b>).
      <br/>
      Choose <b>Google</b> to add the signature to all accounts OR choose a specific account.
      <br/>
      Click the &nbsp;<span title="plus icon" className="icon icon-site-plus"></span>&nbsp; icon to add a new signature and give it a name.
    </li>
    <li>
      Uncheck the box <b>“Always match my default message font”</b>.
      <br/>
      Paste your signature into the <b>Edit Signature box (Ctrl+V or Command+V)</b>.<br/><br/>
    </li>
    <li>
      If you are updating an existing signature, please use <b>cmd+a before pasting the new signature</b>.
    </li>
  </ol>
  <b>Important:</b>
  <br/> When you Paste your signature, it may look like a blank image with wrong text. It's OK. Compose a new email to see your new signature.<br/>
  <p>Use Chrome or Mozilla browser when you click <b>"copy to clipboard"</b> button to keep right formatting.</p>
  </article>
)