import React, { useState } from "react";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


import { CommandReceivScreen, validateCommand } from '@izzistock/components';
import { useProducts } from 'Apps/Stockizy/Store/hooks'

import Button from "AppCore/Components/Button";

export default ({ handleClose: _handleClose = () => {}, reloadData = () => {}, command: initialCommand }) => {

    const [ loading, setLoading ] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const products = useProducts();
    const [ command, setCommand ] = useState(initialCommand);

    const onClickValidate = async () => {
        setLoading(true);
        await validateCommand({ command });
        await reloadData();
        setLoading(false);
        handleClose();
    }

    const handleClose = () => {
        if (loading) {
            return false;
        }

        _handleClose();
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={'sm'}
            fullWidth
            open
            onClose={handleClose}
        >
            <DialogTitle>{"Reception de commande"}</DialogTitle>
            <DialogContent>
                <CommandReceivScreen
                    command={command}
                    onCommandChange={setCommand}
                    products={products}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} autoFocus onClick={handleClose} color="secondary">
                    Annuler
                </Button>
                <Button disabled={loading} onClick={onClickValidate} color="primary" autoFocus>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}