import React from 'react';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import { PageLoader } from '@knxlab/components';
import { Exercice } from "@nopanoga/models";

import ExerciceEdit from './index'

export default () => {

    return (
        <PageLoader
            loadData={async ({ id }) => {

                return await Exercice.get({ id })

            }}
            saveData={async (exercice: Exercice) => {
                await exercice.save();
                return exercice;
            }}

            LoadingComponent={() => (
                <ActivityIndicator material />
            )}
            Component={({ data, saveData }) => (
                <ExerciceEdit
                    initialExercice={data}
                    saveData={saveData}
                />
            )}
        />
    )
}