import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.organisation, action) => {
	switch (action.type) {
	case C.SET_ORGANISATION:
		return action.organisation || state;
	default:
		return state;
	}
}