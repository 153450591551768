// @flow
import React from 'react';
import styled from 'styled-components'
import type { Node as ReactNode } from 'react';

import {
    Tabs, Tab as MuiTab
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';


const AntTabs = withStyles({
  root: {
      overflow:'visible'
  },
  scroller: {
      overflow: 'visible !important'
  },
  indicator: {
    backgroundColor: '#fff',
    transition: 'none',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderTop: '0px',
    borderBottom: '0px',
    position: 'absolute',
    bottom: "-1px",
    height: "10px"
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    border: '1px solid transparent',
    borderBottom: '0px solid transparent',
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottom: '0px solid black'
  },
}))((props) => <MuiTab disableRipple {...props} />);


const TabsPanels = styled.div`
    flex: 1;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-top-left-radius: 0px;
    min-width: 450px;
`


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    //backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

type Props = {
    tabs: Array<{
        label: any,
        panel: any,
        tab?: ReactNode
    }>,
    defaultSelected?: number
}
export default ({ tabs, defaultSelected = 0, ...props }: Props) => {

    const classes = useStyles();
    const [index, setIndex] = React.useState(defaultSelected);

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    }

    return (

        <div className={classes.demo1}>
            <AntTabs
                {...props}
                value={index}
                onChange={handleChange}
                scrollButtons="off"
                variant="scrollable"
            >
                {tabs.map((tab, index) => (
                    React.isValidElement(tab.tab) ? tab.tab : <AntTab key={index+"_"} label={tab.label} />
                ))}
            </AntTabs>

            {tabs[index] &&
                <TabsPanels>{tabs[index].panel}</TabsPanels>
            }

        </div>
    )
}