import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Grid } from '@material-ui/core';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import { retrieveUserVideos } from 'AppCore/Libs/Instagram'

import Video from './video'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
`
const ContainerCentered = styled(Container)`
    justify-content: center;
    align-items: center;
`

export default ({
    selectedVideo = null,
    onVideoSelected = () => {}
}) => {

    const [ loading, setLoading ] = useState(true);
    const [ videos, setVideos ] = useState([])

    const loadUserMedias = async () => {
        setLoading(true);
        const data = await retrieveUserVideos();
        setVideos(data);
        setLoading(false);
    }

    useEffect(() => {
        loadUserMedias();
    }, [])

    if (loading) {
        return (
            <ContainerCentered>
                <ActivityIndicator size={30}/>
            </ContainerCentered>
        )
    }



    return (
        <Container>
            <Grid container spacing={6} style={{userSelect: 'none'}}>
            {videos.map(video => (

                <Video
                    key={video.id} video={video}
                    isSelected={selectedVideo && video.id === selectedVideo.id}
                    onClick={() => {
                        onVideoSelected(video)
                    }}
                />

            ))}
            </Grid>
        </Container>
    )
}