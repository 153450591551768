import centres from './Centres'
import suppliers from './Suppliers'
import productCategories from './ProductCategories'
import products from './Products'

import currentCentre from './CurrentCentre'

export default {
    centres,
    products,
    suppliers,
    productCategories,

    currentCentre
};