import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


import { useCentres, useProducts } from 'Apps/Stockizy/Store/hooks'

import Button from "AppCore/Components/Button";

export default ({ handleClose: _handleClose = () => {}, reloadData = () => {}, command, supplier, supplierCentreData }) => {

    const products = useProducts();
    const centres = useCentres();

    const centre = centres.data.find(ce => command.centreId === ce.id);

    const [ loading, setLoading ] = useState(false);
    const [ emailAddress, setEmailAddress ] = useState(supplier.email);
    const [ emailSubject, setEmailSubject ] = useState("Nouvelle commande pour " + (centre.name|| "") + " : " + (supplierCentreData.clientNumber|| ""));
    const [ emailText, setEmailText ] = useState(getDefaultEmailMessage({ command, products, supplierCentreData }));

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onClickValidate = async () => {
        setLoading(true);
        await command.sendToSupplier({
            emailAddress, emailSubject, emailText
        });
        await reloadData();
        setLoading(false);
        handleClose();
    }

    const handleClose = () => {
        if (loading) {
            return false;
        }

        _handleClose();
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={'sm'}
            fullWidth
            open
            onClose={handleClose}
        >
            <DialogTitle>{"Envoyer la commande au fournisseur"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Si besoin, modifiez l'email avant envois. Les pièces jointes sont automatiquement ajoutées au moment de l'envoi</DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    label="Adresse email"
                    type="email"
                    fullWidth
                    value={emailAddress}
                    onChange={({ target: { value } }) => setEmailAddress(value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Sujet"
                    type="text"
                    fullWidth
                    value={emailSubject}
                    onChange={({ target: { value } }) => setEmailSubject(value)}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Corps de l'email"
                    type="text"
                    fullWidth
                    multiline
                    rows={10}
                    value={emailText}
                    onChange={({ target: { value } }) => setEmailText(value)}
                />

            </DialogContent>
            <DialogActions>
                <Button disabled={loading} autoFocus onClick={handleClose} color="secondary">
                    Annuler
                </Button>
                <Button disabled={loading} onClick={onClickValidate} color="primary" autoFocus>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const getDefaultEmailMessage = ({ command, supplierCentreData, products }) => {
    return `
Bonjour,

Merci de bien vouloir prendre en compte la commande suivante pour ${supplierCentreData.clientNumber} :

${command.getProductsExpanded(products.data || []).map(product =>
    product.conditionnement ?
    `${product.payload.qty / product.conditionnement}x ${product.name} (boite de ${product.conditionnement}) REF : ${product.supplierRef}
` :
    `${product.payload.qty}x ${product.name} REF : ${product.supplierRef}
`).join('')}

Cordialement
`
}