import keyMirror from 'keymirror';
import Generated from './Generated';

export default {
	REDUCER : {
		...keyMirror({
			SET_AUTH_DATA: true,
			SET_USER: true,
			SET_USER_GROUPS: true,

			SET_CURRENT_USER: true,
			SET_ORGANISATION: true,
			SET_OTHER_ACCOUNTS: true,

			SET_TEAMS: true,
			SET_USERS: true,
			SET_TEMPLATES: true,
			SET_CAMPAIGNS: true,

			CLEAR_TEAMS: true,
			CLEAR_USERS: true,
			CLEAR_TEMPLATES: true,
			CLEAR_CAMPAIGNS: true,

			// DISPLAY
			DISPLAY_LOGIN: true,
			DISPLAY_LOADING: true,
			DISPLAY_ERROR: true,
			DISPLAY_MOBILE_MENU: true,


			SET_ONBOARDING_SIGNATURE_DATA: true,
			CLEAR_ONBOARDING_SIGNATURE_DATA: true,


			CONTEXT_SET_TEAM: true,
			CONTEXT_CLEAR_TEAM: true

		}),

		...Generated
	}
}