import React from 'react';
import styled from 'styled-components'

const Container = styled.div``

const Line = styled.div`
    height: 40px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ccc;
    color: #ccc;
`
const Title = styled(Line)`
    background-color: #b7b3b7;
    color: white;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    border-bottom: 0px;
`


export default () => (
    <Container>
        <Title>Nouvel email</Title>
        <Line>To: </Line>
        <Line>Sujet: </Line>
    </Container>
)