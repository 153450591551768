// @flow
import React from "react";
import type { ComponentType } from 'react';
import { Link } from 'react-router-dom'

import Helmet from 'react-helmet';

import {
  Grid
} from "@material-ui/core";

import Button from "@knxlab/components/lib/Button";

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import SearchField from 'AppCore/Components/SearchField'
import {
    Divider, Title
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import {
    TableHeader
} from 'Apps/Shared/Layouts/Dashboard/Components/Table'

function AddLink({ to, title }) {

    return (
        <Button
            variant="contained"
            color="primary"
            component={Link}
            to={to}
        >
            {title}
        </Button>
    )
}

type Props = {

    dataList: {
        data: Array<any>
    },
    hasSearch: boolean,
    reload?: any => any,
    searchQuery?: string,
    setSearchQuery?: (query: string) => any,

    title?: string,
    headerActions?: any,
    actions?: any,
    header?: any,
    addButton?: { title: string, onClick?: any => any, to?: string },
    DataTableComponent: ComponentType<{
                            data: Array<any>,
                            searchQuery?: string,
                            reload: any => Promise<any>
                        }>
}
export default ({

    title,
    headerActions,
    addButton,
    actions,
    header,

    dataList, reload,
    hasSearch = true,
    searchQuery, setSearchQuery,
    DataTableComponent
}: Props) => {

    const { to: addBtnTo, onClick: addBtnOnClick, title: addBtnTitle} = addButton || {};

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <Helmet title={title} />

            <FlexRowSpaceBetween>
                <Title>
                    {title}
                </Title>
                {headerActions &&
                    <div>
                        {headerActions}
                    </div>
                }
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <TableHeader>
                {hasSearch && <SearchField value={searchQuery} onChange={setSearchQuery} />}
                {addButton && addBtnTo &&
                    <AddLink key="addLink" to={addBtnTo} title={addBtnTitle} />
                }

                {addButton && addBtnOnClick &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addBtnOnClick}
                    >
                        {addBtnTitle}
                    </Button>
                }

                {actions &&
                    <div>
                        {actions}
                    </div>
                }
                {header}
            </TableHeader>
            <Grid container>
                <Grid item xs={12}>
                    <DataTableComponent
                        data={dataList.data}
                        searchQuery={searchQuery}
                        reload={reload || (async () => {})}
                    />
                </Grid>
            </Grid>

        </div>
    );
}