// @flow
import React from "react";
import styled from 'styled-components'
import MuiSlider from '@material-ui/core/Slider';

import {
    FormControlLabel,
    // Radio,
    Checkbox as MuiCheckBox,
    Typography
} from '@material-ui/core';

export const SectionTitle = ({ children, style, ...props }: { style?: Object, children: any }) => (
    <Typography {...props} style={{...style, marginBottom: '20px', marginTop: '20px' }} variant="h6">
        {children}
    </Typography>
)


export const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`
export const InputLabel = styled.div`
    color: #333;
`
export const InputContainer = styled.div`
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

type CheckBoxProps = {
    label: string,
    value: boolean,
    onChange: (value: boolean) => any,
}
export const CheckBox = ({ label, value, onChange }: CheckBoxProps) => (
    <FormControlLabel
        control={
            <MuiCheckBox
                checked={value}
                onChange={({ target: { checked }}) => {
                    onChange(checked)
                }}
            />
        }
        label={label}
    />
)

type SliderProps = {
    label: string,
    value: number,
    onChange: (value: number) => any,

    min: number,
    max: number,
    step: number
}
export const Slider = ({ label, value, onChange, min, max, step }: SliderProps) => (
    <LineContainer>
        <InputLabel>{label}</InputLabel>
        <InputContainer>
            <MuiSlider
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={(e, value) => onChange(parseInt(value, 10) || min)}
            />
        </InputContainer>
    </LineContainer>
)