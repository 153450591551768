// @flow
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactCrop from 'react-image-crop';

import { isVideo, isGif } from './helpers'
import { getInitialCropConf } from './cropHelpers'
import Style from './style.module.css';

import 'react-image-crop/dist/ReactCrop.css';

export type CropConf = {
    aspect?: number,
    unit?: string,
    height?: number,
    width?: number,
    x?: number,
    y?: number
}


export type MediaToCropType = {
    src: string,
    width: number,
    height: number,
    type: string
}
export const getCroppedImg = async (image: MediaToCropType, cropConf: CropConf): Promise<{blob: Blob, media: MediaToCropType}> => {

    if (isGif(image.type)) {
        throw new Error("Cannot crop a gif file")
    }

    const canvas = document.createElement('canvas');

    let canvasWidth = 0;
    let canvasHeight = 0;
    if (cropConf.width) {
        canvasWidth = cropConf.width * (image.width || 0) / 100;
    }
    if (cropConf.height) {
        canvasHeight = cropConf.height * (image.height || 0) / 100;
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    if (!image || !image.src) {
        throw new Error("missing image param");
    }

    const htmlImage = new Image();
    htmlImage.src = image.src;
    htmlImage.crossOrigin = "anonymous";

    return await new Promise(resolve => {
        htmlImage.onload = () => {
            const x = image.width * (cropConf.x || 0) / 100;
            const y = image.height * (cropConf.y || 0) / 100;
            ctx.drawImage(htmlImage, -x, -y, htmlImage.naturalWidth, htmlImage.naturalHeight);
            canvas.toBlob(
                blob => {
                    const src = URL.createObjectURL(blob);
                    const media: MediaToCropType = {
                        src,
                        height: canvasHeight,
                        width: canvasWidth,
                        type: image.type,
                        local: true
                    }
                    resolve({
                        blob, media
                    });
                },
                'image/jpeg',
                1,
            );
        }
    });
}




type Props = {
	media: MediaToCropType,
    aspect?: number,
    onCropChange?: (crop: CropConf) => void,
    initialCropConf?: Object
}
export default ({ media, onCropChange = crop => {}, aspect, initialCropConf = {} }: Props) => {

	const cropConf: CropConf = {aspect, unit: '%' };

	const [crop, setCrop] = useState({
        ...cropConf,
        ...getInitialCropConf({ aspect, media })
    });

    const _onCropChange = newCrop => {
        const crop = {
            ...newCrop, ...cropConf
        }
        setCrop(crop)
        onCropChange(crop);
    }

    useEffect(() => {
        setCrop({
            ...crop,
            ...initialCropConf
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return (
        <div
            className={classNames(Style.imgContainer)}
            style={{width: '100%', height: '100%'}}
        >
            {isVideo(media.type) ?
                <ReactCrop
                    className={classNames(Style.ReactCrop)}
                    ruleOfThirds
                    crop={crop}
                    onChange={(crop, newCrop) => { _onCropChange(newCrop) }}
                    keepSelection
                    locked={false}
                    renderComponent={
                        <video autoPlay loop
                            style={{ width: '100%' }}
                            src={media.src}
                            onLoadStart={e => {
                                // You must inform ReactCrop when your media has loaded.
                                e.target.dispatchEvent(new Event('medialoaded', { bubbles: true }));
                            }}
                        />
                    }
                /> :
                <ReactCrop
                    className={classNames(Style.ReactCrop)}
                    ruleOfThirds
                    src={media.src}
                    crop={crop}
                    onChange={(crop, newCrop) => { _onCropChange(newCrop) }}
                    keepSelection
                    locked={false}
                />
            }
        </div>
	)
}