// @flow
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import * as firebase from 'firebase/app';
import { User } from "@nopanoga/models"

import {
    Typography
} from "@material-ui/core";

import {
    Container, Avatar
} from './styledComponents'

import Form from 'AppCore/Components/Form'


export default () => {

    const userAuth = useSelector(state => state.Auth.user);
    const [ photoURL ] = useState(null);
    const navigate = useNavigate();

    const profilPictureUrl = userAuth.photoURL || photoURL;

    const getData = async () => {

        const userData = await firebase.firestore().collection('users').doc(userAuth.uid).get();
        if (userData.exists) {
            return userData.data();
        }

        let first_name = "";
        let last_name = "";

        if (userAuth.displayName) {
            let displayNameSplitted = userAuth.displayName.split(" ").map(txt => txt.trim());
            first_name = displayNameSplitted[0];
            last_name = displayNameSplitted[1] || "";
        }

        return {
            first_name, last_name
        }
    }

    return (

        <Container>
            <Helmet title="Onboarding" />
            {profilPictureUrl &&
                <Avatar alt="profil_pic" src={profilPictureUrl} />
            }

            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Welcome {userAuth.displayName}, let us get to know you
            </Typography>

            <Form
                style={{width: '100%'}}
				attributes={{
                    last_name: { label: "Nom", type: 'text', disabled: false },
                    first_name: { label: "Prénom", type: 'text', disabled: false },
                    gender: {
                        label: "Gender", type: 'select',
                        values : [
                            { value: "male", label: "Homme" },
                            { value: "female", label: "Femme" }
                        ]
                    }
				}}
				getData={getData}
                updateData={async (data) => {

                    const user = new User();
                    user.setData(data);
                    user.id = userAuth.uid;

                    try {
                        await firebase.firestore().collection('users').doc(user.id).set(user.export());
                    } catch (error) {
                        console.error(error);
                    }

                    navigate("/");
                    return true;
                }}
				onChange={({values: data, hasError}) => console.log({ data, hasError })}
			/>

        </Container>
    )
}