
// Initial State Object Definition.
export default {
	userLogged: false,
	authData: {},

	Auth: null,

	subaccounts: {},

	teams: {
		data: [],
		total: 0,
		loaded: false
	},
	users: {
		data: [],
		total: 0,
		loaded: false
	},
	templates: {
		data: [],
		total: 0,
		loaded: false
	},
	campaigns: {
		data: [],
		total: 0,
		loaded: false
	},

	current_user: {},
	organisation: {},
	other_accounts: [],

	display: {
		login: null,
		loading: false,
		error: null,
		mobileMenu: false
	},


	onboarding: {
		hasData: false,
		first_edit: false,
		signatureName: "Signature de la société",
		signatureDataV2: {},
		signatureData: {}
	}

};
