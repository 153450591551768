import React from 'react';
import TimerSelector from 'AppCore/Components/TimerSelector'

export default ({ style = {width:'600px'}, className }) => {

    const [selectedTimer, setSelectedTimer] = React.useState(80);

    return (
        <TimerSelector
            style={style}
            timer={selectedTimer}
            onChange={setSelectedTimer}
        />
    )
}