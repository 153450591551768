import React from "react";
import { useNavigate } from 'react-router-dom'

import { Edit } from '@material-ui/icons'

import { Praticien } from '@izzistock/models'
import PraticiensTable from 'Apps/Stockizy/Components/Praticiens/List'
import PageList from 'AppCore/Components/CommonPages/PageList'
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";


export default () => {

    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const currentCentre = useCurrentCentre();

    const getData = () => {
        return currentCentre ? Praticien.getAll({ currentUser, centreId: currentCentre.id }) : Praticien.getAll({ currentUser })
    }

    return (
        <PageList
            addButton={{
                to: '/praticien/create',
                title: 'Créer un praticien'
            }}
            getData={getData}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <PraticiensTable
                    data={data}
                    actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/praticien/'+id) }
                    ]}
                />
            )}
        />
    )
}