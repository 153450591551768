import React from 'react';



export default ({ ...props }) => (
	<article>
    <ol>
        <li>Generate an email signature using <b>MySignature</b> editor.</li>
        <li>Open signature preview link on your iPhone/iPad</li>
        <li>Tap on <b>Copy to clipboard</b> button.</li>
        <li>Go to your <b>Settings</b> on your iPhone/iPad &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; Mail &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; Signature</li>
        <li><b>Paste</b> your rich HTML signature. When you paste in the copied signature iOS will automatically strip it of formatting.</li>
        <li>Physically <b>shake your iPhone/iPad</b> to drop default styling of the iPhone/iPad.</li>
        <li>Tap <b>Undo</b>.</li>
        <li>You should see your new signature when you compose a new message on your iPhone/iPad now. Go back to the Mail app and try sending an email from the account that you installed the signature on.</li>
    </ol>
</article>
)