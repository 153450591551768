// @flow
import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core'
import {
    Add, DragIndicator,
    Delete,
    FileCopy,
    Edit,
} from '@material-ui/icons'

import Alert from 'Apps/Nopanoga/Components/Alert';
import {
    FlexRowSpaceBetween, FlexRowCentered
} from 'Apps/Shared/Layouts/FlexBox'
import { Button } from '@nopanoga/components'
import { renderTime } from 'AppCore/Utils'

import {
    TrainingSessionBloc as BlocModel,
    TrainingSession,
    TrainingSessionBlocExercice as BlocExercice,
    Exercice
} from "@nopanoga/models"

import { useOpenBlocDialog } from 'Apps/Nopanoga/Components/TrainingSession/BlocModal'
import ModalBlocExercice from 'Apps/Nopanoga/Components/TrainingSession/BlocExerciceModal'

import ModalAddExercice from 'Apps/Nopanoga/Components/ExerciceList/modal';
import { Draggable as ExerciceCard, RestDraggable } from 'Apps/Nopanoga/Components/Exercice/draggable'

import BlocExerciceReorder from './BlocExerciceReorder'
import {
    Padding
} from './styledComponents'
import styled from 'styled-components';
import { Divider, Spacer } from 'Apps/Shared/Layouts/Dashboard/Components/Page';

import emptyPlaceholder from 'Apps/Nopanoga/Assets/placeholders/exercices-empty.svg'

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
`
const Action = styled.div`
    margin-left: 10px;
    margin-bottom: 10px;
`

const HeaderTitleContainer = styled.div`
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
`

const Bloc = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .bloc-right {
        visibility: hidden;
    }

    &:hover {
        .bloc-right {
            visibility: visible;
        }
    }
`;
const BlocItemLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const BlocItemRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;


export default ({
    trainingSession, setTrainingSession
}: {
    trainingSession: TrainingSession, setTrainingSession: (trainingSession: TrainingSession) => any
}) => {

    const openBlocDialog = useOpenBlocDialog();

    const [ modalBlocExercice, setModalBlocExercice ] = React.useState(null);
    const [ modalAddExercice, setModalAddExercice ] = React.useState(false);

    const setBlocs = blocs => {
        const cloneTrainingSession: TrainingSession = trainingSession.clone();
        cloneTrainingSession.setData({
            blocs
        })
        setTrainingSession(cloneTrainingSession);
    }

    const addBloc = (bloc: BlocModel) => {
        const cloneTrainingSession: TrainingSession = (trainingSession.clone(): TrainingSession);
        cloneTrainingSession.addBloc(bloc, true)
        setTrainingSession(cloneTrainingSession);
    }
    const addBlocDialog = (bloc: BlocModel) => {
        openBlocDialog({
            bloc,
            onBlocChange: bloc => {
                addBloc(bloc);
            }
        })
    }
    const deleteBloc = (blocOrder: number) => {
        setTrainingSession(trainingSession.clone(clone => {
            clone.removeBloc(blocOrder)
        }))
    }

    const deleteBlocExercice = (blocOrder, exerciceOrder) => {
        const cloneTrainingSession: TrainingSession = trainingSession.clone();
        cloneTrainingSession.removeBlocExercice(blocOrder, exerciceOrder);
        setTrainingSession(cloneTrainingSession);
    }
    const cloneBlocExercice = (blocOrder, exerciceOrder) => {
        setTrainingSession(trainingSession.clone(cloneTrainingSession => {
            const cloneBlocExercice: BlocExercice = cloneTrainingSession.blocs[blocOrder].exercices[exerciceOrder].clone();
            cloneBlocExercice.generateId();
            cloneTrainingSession.addExercice(
                cloneBlocExercice,
                blocOrder,
                exerciceOrder
            )
        }));
    }
    const updateExerciceInBlocExercice = (blocOrder, exerciceOrder, newExercice) => {
        setTrainingSession(trainingSession.clone(clone => {
            clone.blocs[blocOrder].exercices[exerciceOrder].exercice = newExercice;
        }));
    }
    const addBlocExercice = (blocExercice: BlocExercice) => {
        const cloneTrainingSession: TrainingSession = trainingSession.clone();
        cloneTrainingSession.addExercice(blocExercice, 0, 0)
        setTrainingSession(cloneTrainingSession);
    }

    const defaultActions = [
        { label: "exercice", icon: <Add />, onClick: () => {
            setModalAddExercice(true);
        }},
        { label: "temps de repos", icon: <Add />, onClick: () => {
            const rest = new BlocExercice({ rest: true });
            rest.generateId();
            rest.timer = 10;

            if (!trainingSession.hasBloc()) {
                const bloc = new BlocModel();
                bloc.exercices = [ rest ];
                addBloc(bloc)
            } else {
                addBlocExercice(rest);
            }
        }},
        { label: "bloc", icon: <Add />, onClick: () => {
            const bloc = new BlocModel();
            addBlocDialog(bloc)
        }},
    ]

    const DuplicateButton = ({ blocOrder, exerciceOrder }) => (
        <IconButton
            variant="contained" color="primary"
            onClick={() => cloneBlocExercice(blocOrder, exerciceOrder)}
        ><FileCopy /></IconButton>
    )
    const DeleteButton = ({ blocOrder, exerciceOrder }) => (
        <IconButton
            variant="contained" color="primary"
            onClick={() => deleteBlocExercice(blocOrder, exerciceOrder)}
        >
            <Delete />
        </IconButton>
    )

    return (
        <React.Fragment>

            {modalAddExercice &&
                <ModalAddExercice
                    onClose={() => setModalAddExercice(false)}
                    actions={[
                        { icon_name: 'add', title: "Add", onClick: (id: string, exercice: Exercice) => {

                            const blocExercice = new BlocExercice();
                            blocExercice.generateId();
                            blocExercice.type = 'reps';
                            blocExercice.exercice = exercice.clone();

                            if (!trainingSession.hasBloc()) {
                                const bloc = new BlocModel();
                                bloc.exercices = [ blocExercice ];
                                addBloc(bloc)
                            } else {
                                addBlocExercice(blocExercice);
                            }

                            setModalAddExercice(false);
                            setModalBlocExercice({
                                blocOrder: 0, exerciceOrder: 0,
                                blocExercice: blocExercice.clone()
                            })
                        }}
                    ]}
                />
            }

            {modalBlocExercice &&
                <ModalBlocExercice
                    {...modalBlocExercice}
                    onBlocExerciceChange={(blocExercice: BlocExercice) => {
                        setTrainingSession(trainingSession.clone(t => {
                            if (!t.blocs[modalBlocExercice.blocOrder].exercices) {
                                t.blocs[modalBlocExercice.blocOrder].exercices = [];
                            }
                            t.blocs[modalBlocExercice.blocOrder].exercices[modalBlocExercice.exerciceOrder] = blocExercice;
                        }))
                    }}
                    onClose={() => setModalBlocExercice(null)}
                />
            }

            <Padding className="not-draggable">
                <FlexRowSpaceBetween style={{width: '100%', flexWrap: 'wrap'}}>
                    <HeaderTitleContainer>
                        <Typography variant="h3">Organisez votre séance</Typography>
                        <Typography variant="body1" color="textSecondary">Temps total des exercices: {renderTime(trainingSession.getExecutionTime(), 'long')}</Typography>
                    </HeaderTitleContainer>
                    <ActionContainer>
                        {defaultActions.map((action, index) => (
                            <Action key={index}>
                                <Button
                                    loading={false} variant="contained" color="secondary" startIcon={action.icon}
                                    onClick={action.onClick}
                                >{action.label}</Button>
                            </Action>
                        ))}
                    </ActionContainer>
                </FlexRowSpaceBetween>

                <Divider />

                <Alert>
                    N’hésitez pas à changer l’ordre des exercices, repos et blocs avec un glisser déposer
                </Alert>

                {!trainingSession.hasBloc() &&
                    <>
                        <Spacer />
                        <FlexRowCentered>
                            <img src={emptyPlaceholder} alt="empty" />
                        </FlexRowCentered>
                        <Spacer />
                        <FlexRowCentered>
                            <Typography variant="body1" color="textSecondary">
                                Utilisez les trois boutons d’ajout pour organiser votre séance d’entrainement
                            </Typography>
                        </FlexRowCentered>
                    </>
                }

            </Padding>

            <BlocExerciceReorder
                blocsExercicesChecksum={trainingSession.getBlocsExercicesChecksum()}
                blocs={trainingSession.blocs || []}
                onChange={blocs => setBlocs(blocs)}

                renderExerciceComponent={({ blocExercice, blocOrder, exerciceOrder }) => {
                    const actions = (
                        <React.Fragment>
                            <IconButton
                                variant="contained" color="primary"
                                onClick={() => {
                                    setModalBlocExercice({
                                        blocOrder, exerciceOrder,
                                        blocExercice: blocExercice.clone()
                                    })
                                }}
                            >
                                <Edit />
                            </IconButton>
                            <DuplicateButton blocOrder={blocOrder} exerciceOrder={exerciceOrder} />
                            <DeleteButton blocOrder={blocOrder} exerciceOrder={exerciceOrder} />
                        </React.Fragment>
                    );
                    return (
                        blocExercice.isRest() ?
                        (
                            <RestDraggable
                                style={{cursor: "pointer"}}
                                timer={blocExercice.timer}
                                actions={actions}
                            />
                        ) :
                        (
                            <ExerciceCard
                                exercice={blocExercice.exercice}
                                blocExercice={blocExercice}
                                style={{cursor: "pointer"}}
                                onChange={exercice => updateExerciceInBlocExercice(blocOrder, exerciceOrder, exercice)}
                                actions={actions}
                            >{actions}</ExerciceCard>
                        )
                    )

                }}
                renderBlocComponent={({
                    bloc, order, dragHandlerClass
                }) => (
                    <Bloc>
                        <BlocItemLeft>
                            <DragIndicator style={{cursor: 'move'}} className={dragHandlerClass} />
                            <Typography variant="h6">
                                {"Bloc " + (order+1) + " -- " + bloc.title}
                                {` X ${bloc.volume || 1}`}
                            </Typography>
                        </BlocItemLeft>

                        <BlocItemRight className="bloc-right">
                            <IconButton
                                variant="contained" color="primary"
                                onClick={() => {
                                    openBlocDialog({
                                        bloc: bloc.clone(),
                                        onBlocChange: bloc => {
                                            setTrainingSession(trainingSession.clone(t => {
                                                t.blocs[order] = bloc;
                                            }))
                                        }
                                    })
                                }}
                            ><Edit /></IconButton>
                            <IconButton
                                variant="contained" color="primary"
                                onClick={() => window.confirm(`Supprimer le bloc ${order+1} - ${bloc.title} ?`) && deleteBloc(order)}
                            ><Delete /></IconButton>
                        </BlocItemRight>
                    </Bloc>
                )}

            />
        </React.Fragment>

    )
}