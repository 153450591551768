import React from 'react'
import classnames from 'classnames';
import MaterialIcon from '@material-ui/core/Icon';


export const fontello_icon_names = [

];


const Icon = ({
	name,
	size,
	color,
	...props
}) => {
	if (fontello_icon_names.indexOf(name) !== -1) {
		const style = {
			fontSize: size+"px"
		}

		if (color) {
			style.color = ({
				primary: '#5465FB',
				secondary: '#E3E4EA'
			})[color] || color;
		}

		return (
			<i className={classnames("icon-"+ name, props.className)} style={{
				...style,
				...(props.style || {}),
			}} />
		)
	}

	return <MaterialIcon style={props.style} color={color || 'primary'} size='large' {...props}>{name}</MaterialIcon>;

}


export default Icon;