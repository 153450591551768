import React, { Component } from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import delay from 'delay'

import {
	LinearProgress
} from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import withNavigate from 'AppCore/HOC/withNavigate';
import { setSignatureData, setOrganisationOnboarding, saveOnboardingData } from 'Apps/Signally/Actions';

import { PageContainer as LayoutPageContainer } from '../Layout';
import HeaderUnlogged from '../Layout/HeaderUnlogged'
import Header from '../Layout/HeaderPageStandalone'
import LoginModal from '../Modals/Login';

import css from './CreateSignature.module.css';

import CreateSignatureStep from './CreateSignatureStep';
// import CreateTeamStep from './CreateTeamStep';
import CreateMemberStep from './CreateMemberStep';
import styled from "styled-components";
import Button from "Apps/Signally/Components/Button";
import { FlexColumnCentered } from "Apps/Shared/Layouts/FlexBox";

const PageFixedFooter = styled.div`
	width: 100%;
	position: fixed;
	background: white;
	bottom: 0; left:0;
	height: 70px;
	padding-left: 10px;
	padding-right: 10px;
	box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.35);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`
const PageContainer = styled(LayoutPageContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding:0;
	padding-left: 10px;
	padding-right: 10px;
`
const Content = styled.div`
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 80px;
`

class CreateSignatureScreen extends Component {

	state = {
		activeStep: 0,
		prepare_timer: 3
	}

	next = () => {
		this.setState({
			activeStep: this.state.activeStep + 1
		})
	}

	getSteps = () => ([

		{
			label: 'Créez une signature',
			render: () => (<CreateSignatureStep onEnd={() => this.setState({activeStep: 1})} />),
			nextLabel: 'Suivant'
		},

		...(this.props.userLogged ? [] : [
			{
				label: 'Connexion / Inscription',
				render: () => (
					<LoginModal
						onClose={() => this.setState({ activeStep: this.state.activeStep-1 })}
					/>
				),
				nextLabel: 'Suivant'
			}
		]),

		// {
		// 	label: 'Créez des équipes',
		// 	render: () => (<CreateTeamStep onEnd={() => this.setState({activeStep: 2})}/>),
		// 	nextLabel: 'Suivant'
		// },

		{
			label: 'Ajouter des employés',
			render: () => (<CreateMemberStep key="memberSTEP" onEnd={this.onClickSaveAndDeploy} />),
			nextLabel: 'Déployez la signature',
			onClickNext: this.onClickSaveAndDeploy
		},
		{
			label: 'Déployez votre première signature',
			render: () => (
				<FlexColumnCentered>
					<div>Preparing your Dashboard...</div>
					<LinearProgress style={{width: '60%'}} variant="determinate" value={(3 - this.state.prepare_timer)*100/3} />
				</FlexColumnCentered>
			)
		},



	])

	onClickSaveAndDeploy = async () => {

		this.setState({activeStep: this.getSteps().length - 1})

		window.setTimeout(() => {
			this.setState({prepare_timer: 2});
		}, 200)

		const { setOrganisationOnboarding, saveOnboardingData } = this.props;
		await setOrganisationOnboarding();
		await saveOnboardingData();

		this.setState({prepare_timer: 1});
		await delay(1000);
		this.setState({prepare_timer: 0});

		this.props.navigate('/home');
	}

	onClickStepFct = step => e => {
		this.setState({ activeStep: step })
	}

	render() {

		const { userLogged } = this.props;

		return (
			<PageContainer>

				{userLogged ? <Header /> : <HeaderUnlogged fullScreen />}

				<Content>
					<Stepper activeStep={this.state.activeStep} alternativeLabel className={css.Stepper}>
						{this.getSteps().map(({ label }, step) => (
							<Step key={"step_"+step} completed={this.state.activeStep > step}>
								<StepButton onClick={this.onClickStepFct(step)}>
									{label}
								</StepButton>
							</Step>
						))}
					</Stepper>

					{this.getSteps()[this.state.activeStep].render()}
				</Content>


				<PageFixedFooter>
					<div />
					<Button style={{width: '200px'}} onClick={this.getSteps()[this.state.activeStep].onClickNext || this.next}>Suivant</Button>
				</PageFixedFooter>
			</PageContainer>
		);
	}
}

export default connect(
	state => ({
		userLogged: state.Auth.userLogged,
		onboarding: state.onboarding,
	}),
	{ setSignatureData, setOrganisationOnboarding, saveOnboardingData }
)(withSnackbar(withNavigate(CreateSignatureScreen)));




