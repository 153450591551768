export default (state = {}, action) => {
    switch (action.type) {
        case 'auth.setuser':
            return {
                ...state,
                user: action.user
            };
        default:
            return state;
    }
}