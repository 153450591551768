import React from "react";

import DataTable from 'AppCore/Components/DataTable';
import { useCentres } from "Apps/Stockizy/Store/hooks";

const CentreList = ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}) => {

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'Nom', key: 'name' },
                { title: 'Adresse', key: 'address' }
            ],
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit },
                { icon_name: 'view', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {
            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}

export const LoadedCentreList = (props: Props) => {
    const dataList = useCentres();
    return <CentreList {...props} data={dataList.data} />
}

export default CentreList