// @flow
import React from 'react';
import styled from 'styled-components';

import { Divider as MuiDivider, Paper } from '@material-ui/core';
import {
    DragHandle,
    Close
} from '@material-ui/icons'

import {
    Video,
    PenTool,
    FileText
} from 'react-feather'

import SortableList from 'AppCore/Components/SortableList';

import SignatureData from '../../DataObject';
import SignOff from '../../DataObject/Addons/SignOff'
import GreenMessage from '../../DataObject/Addons/GreenMessage'

import Accordion from '../Utils/Accordion'
import SignOffComponent from './SignOff';
import DisclaimerComponent from './Disclaimer';
import GreenMessageComponent from './GreenMessage';
import VideoConfComponent from './VideoConf';
import { SectionTitle } from '../Utils/Helpers'


const Container = styled.div`
    padding: 10px;
`
const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`

const AddonAvailable = styled(Paper)`
    cursor:pointer;
    padding:20px;
    margin-bottom: 20px;
`

const SortableAccordionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
`
const DragHandleStyled = styled(DragHandle)`
    cursor: move;
    margin-right: 10px;
    margin-top: 13px;
`
const SortableAccordion = styled(Accordion)`
    flex:1;
    &.Mui-expanded {
        margin-top:0px;
    }
`
const CloseStyled = styled(Close)`
    cursor: pointer;
    margin-left: 10px;
    margin-top: 13px;
`

const AddOnIcons = {
    signOff: <PenTool style={{marginRight: '10px'}}/>,
    disclaimer: <FileText style={{marginRight: '10px'}} size={20} />,
    greenMessage: <img src={GreenMessage.iconPath} alt="signoff icon" style={{marginRight: '10px'}} />,
    videoConf: <Video style={{marginRight: '10px'}} />,
}


const AddonEditor = ({ type, data, onChange, ...props }) => {

    switch (type) {
        case 'disclaimer':
            return (
                <DisclaimerComponent disclaimerData={data} onChange={onChange} {...props}/>
            )
        case 'videoConf':
            return (
                <VideoConfComponent videoConfData={data} onChange={onChange} {...props} />
            )

        case 'greenMessage':
            return (
                <GreenMessageComponent greenMessageData={data} onChange={onChange} {...props} />
            )

        default:
            return null;
    }

}

export default ({ data, onChange }: { data: SignatureData, onChange: (data: SignatureData) => any }) => {

    const availableAddons = data.getAvailableAddons();
    const enabledAddons = data.getOrderedEnabledAddons();

    const onChangeOrder = newOrder => {
        onChange(data.clone(d => {
            d.setNewAddonOrders(newOrder);
        }))
    }

    const removeAddon = key => e => {
        onChange(data.clone(d => {
            d.addons[key].enabled = false;
            d.cleanAddonsOrder();
        }))
    }

    const addAddon = key => e => {
        onChange(data.clone(d => {
            d.addons[key].enabled = true;
            d.addons[key].order = enabledAddons.length;
        }))
    }


    return (
        <Container>

            <Accordion title={"Sign Off"} icon={AddOnIcons.signOff}>
                <SignOffComponent
                    signOffData={data.signoff}
                    onChange={(signOffData: SignOff) => {
                        onChange(data.clone(d => {
                            d.signoff = signOffData.clone()
                        }))
                    }}
                />
            </Accordion>

            {enabledAddons.length > 0 &&
                <>
                    <Divider />

                    <SortableList onChange={onChangeOrder}>
                        {enabledAddons.map((addon, i) => (
                            <SortableAccordionContainer key={addon.key} data-order={i}>
                                <DragHandleStyled className="drag-bloc-indicator" />
                                <SortableAccordion title={SignatureData.addonsOptions[addon.key].label} icon={AddOnIcons[addon.key]}>
                                    <AddonEditor
                                        type={addon.key}
                                        data={addon.data}
                                        onChange={addonData => {
                                            onChange(data.clone(d => {
                                                d.addons[addon.key].data = addonData.clone()
                                            }))
                                        }}
                                    />
                                    <div></div>
                                </SortableAccordion>
                                <CloseStyled onClick={removeAddon(addon.key)} />
                            </SortableAccordionContainer>

                        ))}
                    </SortableList>
                </>
            }


            {availableAddons.length > 0 &&
                <>
                    <Divider />

                    <SectionTitle>Addons Disponibles</SectionTitle>

                    {availableAddons.map(addon => (
                        <AddonAvailable key={addon.key} onClick={addAddon(addon.key)}>{SignatureData.addonsOptions[addon.key].label}</AddonAvailable>
                    ))}
                </>
            }


        </Container>
    )
}