import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import './signature.css';
import Clipboard from 'clipboard';
import delay from 'delay';




export default ({ children, target = "#render", style, onSignatureCopied = () => {}, ...props }) => {

	useEffect(() => {
		const clipboard = new Clipboard('.copyBtn');
        clipboard.on('success', e => {

            onSignatureCopied();

            delay(1000).then(() => {
            	e.clearSelection();
            })

        });

        return () => {
        	clipboard.destroy();
        }
	}, [ target, onSignatureCopied ])


	return (
		<Button className="copyBtn" data-clipboard-action="copy" data-clipboard-target={target} style={style} color="primary" variant="contained" >
			{children}
		</Button>
	)

}