import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import merge from 'lodash.mergewith';
import isArray from 'lodash.isarray';
import arrayUniq from 'lodash.uniq';

import AppsReducers from './Reducers'

const commonPersistConfig = {
    key: 'root',
    storage,
    whitelist: [
        "Auth",
        "counter"
    ],
    stateReconciler: autoMergeLevel2
}

let store;
let persistor;

export default ({ APP_NAME, reduxPersistConfig, extraArgument }) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const persistConfig = merge(
        commonPersistConfig,
        ((reduxPersistConfig && reduxPersistConfig) || {}),
        (a, b) => isArray(a) ? arrayUniq(a.concat(b)) : undefined
    )

    if (!AppsReducers[APP_NAME]) {
        throw new Error(`Missing ${APP_NAME} in Reducers ! AppCore/Store/Reducers/index.js `)
    }

    const persistedReducer = persistReducer(
        persistConfig,
        AppsReducers[APP_NAME]
    )

    store = createStore(
        persistedReducer,
        composeEnhancers(
            applyMiddleware(thunkMiddleware.withExtraArgument(extraArgument))
        )
    )
    persistor = persistStore(store)
    return { store, persistor };
}

export const getStore = () => ({ store, persistor });