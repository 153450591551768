// @flow
import React from "react";
import styled from 'styled-components'

import {
    Slider
} from './Helpers'

const Container = styled.div`
    width: 100%;
`

export default ({ style, disableComponents = {} , onChange }: { style: Object, onChange: (style: Object) => any}) => {


    return (
        <Container>
            <Slider
                label={"Espace au dessus"}
                value={style.marginTop}
                min={0}
                max={60}
                step={1}
                onChange={value => onChange(style.clone(s => s.marginTop = value))}
            />

            <Slider
                label={"Espace en dessous"}
                value={style.marginBottom}
                min={0}
                max={60}
                step={1}
                onChange={value => onChange(style.clone(s => s.marginBottom = value))}
            />

        </Container>
    )
}