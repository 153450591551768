import React from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { magicLinkLogin } from "Apps/Signally/Actions";

import { PageContainer, Content } from '../Layout';
import HeaderUnlogged from '../Layout/HeaderUnlogged'
import LoginFlow from './loginFlow';

const LoginScreen = ({ organisation, magicLinkLogin }) => {

	const navigate = useNavigate();

	return (
		<PageContainer>

			<HeaderUnlogged />

			<Content>
				<LoginFlow signinMethod={magicLinkLogin} onLogged={() => {
					if (organisation && !organisation.has_onboarding) {
						navigate('/create-signature-company');
					} else {
						navigate('/home');
					}
				}}/>
			</Content>

		</PageContainer>
	);
}

export default connect(
	state => ({
		organisation: state.organisation
	}),
	{ magicLinkLogin }
)(LoginScreen);