import _get from 'lodash.get'
import { useEffect, useState } from 'react'

import * as validatorsFct from './validators';
import type { ValidatorOptions } from './validators'
import type { TransformersOptions } from './transformers'


export type Attributes = {
	[key: string]: {
		validators?: ValidatorOptions,
		transformers?: TransformersOptions,
	}
}


const getErrorMessages = (errors) =>
    errors
        .map(error => (validatorsFct[error] && validatorsFct[error].error_msg) || '')
        .join('; ');



const checkErrors = ({ config, value, attr_name }) => {

    const { validators = [] } = config[attr_name];

    const errors = [];
    let hasError = false;

    validators.forEach(validator => {
        if (!validatorsFct[validator] || validatorsFct[validator](value)) return;
        hasError = true;
        errors.push(validator);
    });

    return {
        hasError,
        errors
    }
}


const getDefaultForType = ({ config, attr_name }) => {

    console.log("config[attr_name]", config[attr_name], attr_name)
    const { type, defaultValue } = config[attr_name];

    if (defaultValue !== undefined) {
        return defaultValue;
    }

    switch (type) {
        case 'text':
            return "";
        default:
            return "";
    }
}

export default ({
    config, initialData: _initialData , onChange = () => {}
} = {}) => {

    const [ initialData, setInitialData ] = useState(_initialData)
    const [ data, _setData ] = useState(_initialData || {})
    const [ errors, _setErrors ] = useState({})

    const checkAttrs = attr_names => {
        const new_errors = {};
        let has_error = false;
        attr_names.forEach(attr_name => {
            const error = checkErrors({
                config, attr_name, value: getDataAttr(attr_name)
            })

            new_errors[attr_name] = error;
            has_error = has_error || new_errors[attr_name].hasError
        })
        _setErrors({
            ...errors,
            ...new_errors
        })

        return has_error;
    }

    const setData = data => {
        _setData(data || {});
        if (!initialData) {
            setInitialData(data || {});
        } else {
            onChange();
        }
    }
    const getData = () => ({...data});

    const getDataAttr = (attr_name, _default) => {
        return _get(data, attr_name, _default || getDefaultForType({ attr_name, config }));
    }
    const setDataAttr = (attr_name, value) => {
        setData({
            ...data,
            [attr_name]: value
        })
    }

    const getErrorAttr = attr_name => {
        return _get(errors, attr_name, { hasError: false, errors: [] });
    }
    const hasErrorAttr = attr_name => {
        const errors = getErrorAttr(attr_name)
        return errors.hasError;
    }

    const hasError = () => Object.keys(errors).some(error_key => {
        return errors[error_key].hasError;
    })

    const checkAllErrors = () => {
        return checkAttrs(Object.keys(config));
    }

    useEffect(() => {
        if (hasError()) {
            checkAttrs(Object.keys(errors));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data ]);


    return {
        setData,
        getData,
        getDataAttr, setDataAttr,
        getErrorAttr,
        hasErrorAttr,
        checkAttr: attr_name => checkAttrs([ attr_name ]),

        onChangeText: attr_name => ({ target: { value }}) => {
            setDataAttr(attr_name, value)
        },

        hasError, checkAllErrors,

        getFormFieldProps: attr_name => ({
            value: getDataAttr(attr_name),
            onChange: value => {
                setDataAttr(attr_name, value)
            },
            onBlur: () => checkAttrs([ attr_name ]),
            hasError: hasErrorAttr(attr_name),
            errorMsg: getErrorMessages(getErrorAttr(attr_name).errors),
            name: attr_name,
            config: _get(config, attr_name)
        })
    }
}