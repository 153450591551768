// @flow
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames'

import {
    Button as MuiButton
} from '@material-ui/core'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
`
const ButtonContainer = styled.div`
    margin-bottom:12px;
    width: 49%;
    position: relative;
`
const ButtonSelect = styled(MuiButton)`
    width:100%;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    text-align: left;
    font-weight: normal;
    border: 1px solid #eee;

    &.activ {
        border: 1px solid #333;
        &:hover {
            background-color: white;
        }
    }
`

type Key = string;
export default ({ options, selected, onChange = () => {}, btnStyle = {}, style = {} }: {
    options: Array<{label: string, key: Key}>,
    selected?: Key,
    onChange?: (key: Key) => any,
    btnStyle?: any,
    style?: any
}) => {

    console.log("options", options);
    return (
        <Container style={style} spacing={3}>
            {options.map(option => (
                <ButtonContainer key={option.key}>
                    <ButtonSelect
                        startIcon={option.icon}
                        disableRipple
                        disableElevation
                        disableFocusRipple
                        style={btnStyle}
                        onClick={() => onChange(option.key)}
                        className={classnames({ activ: selected === option.key })}
                    >{option.label}</ButtonSelect>
                </ButtonContainer>
            ))}
        </Container>
    )
}