import initialState from "../initialState";

export default (state = initialState.currentCentre, action) => {
	switch (action.type) {
	case 'SET_CURRENT_CENTRE':
		return action.currentCentre;
    case 'CLEAR_CURRENT_CENTRE':
		return null;
	default:
		return state;
	}
}