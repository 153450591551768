// @flow
import * as React from 'react';
import {
    View, StyleSheet
} from 'react-native';
import {
    LaunchTrainingSession
} from '@nopanoga/components'

import { TrainingSession, Exercice } from '@nopanoga/models'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%',
        borderRadius: 8,
        borderWidth: 0,
        overflow: 'hidden'
    },
    listContainer: {
        flex: 1
    }
});

type Props = {
    fullScreen?: boolean,
    onPressClose?: any => any,
    exercice: Exercice
}
export default ({
    fullScreen = false,
    onPressClose = () => {},
    exercice
}: Props) => {

    const trainingSession = new TrainingSession();
    trainingSession.setData({
        blocs: [
            {
                title: "Exemple",
                key: Math.random(),
                id: Math.random(),
                volume: 1,
                exercices: [
                    {
                        type: 'reps',
                        reps: 10,
                        id: Math.random(),
                        exercice: exercice.export()
                    }
                ]
            }
        ]
    })

    return (
        <View style={styles.container}>
            <LaunchTrainingSession
                key={Math.random()}
                trainingSession={trainingSession}
                onClickClose={() => {
                    onPressClose();
                }}
            />
        </View>
    );
}
