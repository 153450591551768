import Constants from "../Constants";

const { REDUCER: C } = Constants;


export const displayLogin = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_LOGIN,
		display
	});
}

export const displayError = ({ display = true, error_msg = null, error = null }) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_ERROR,
		error,
		error_msg,
		display
	});
}

export const displayLoading = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_LOADING,
		display
	});
}

export const displayMobileMenu = ({ display = true } = {}) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.DISPLAY_MOBILE_MENU,
		display
	});
}