import InitStore from './Store';
import InitFirebaseAuth from './Auth';
import InitFirebase from './config/firebase';
import InitAmplitude from './config/amplitude'
import * as firebase from 'firebase/app';
import { setConfig } from './config'
import { APP_CONFIGS } from './configs'
import getInitFunctions from './initApp'
import moment from 'moment';
import 'moment/locale/fr';

import {
    onAuthStateChanged
} from './Actions/Auth'

moment.locale('fr')

export default ({ APP_NAME }) => {


    if (!APP_CONFIGS[APP_NAME]) {
        throw new Error(APP_NAME + ": This app does not exists");
    }

    const APP_CONFIG = APP_CONFIGS[APP_NAME];
    const { firebaseConfig, amplitudeConfig, auth, reduxPersistConfig, ...AppConfig } = APP_CONFIG;

    if (firebaseConfig) {
        InitFirebase(firebaseConfig);
    }

    if (amplitudeConfig) {
        InitAmplitude(amplitudeConfig)
    }

    const {
        init: initApp, onStoreReady
    } = getInitFunctions(APP_NAME);

    const { storeExtraArguments } = initApp();

    const extraArgument = {
        ...storeExtraArguments,
        ...(firebaseConfig ? { firebase } : {})
    };

    const { store, persistor } = InitStore({
        extraArgument,
        APP_NAME,
        APP_CONFIG,
        reduxPersistConfig
    });

    onStoreReady({ store });

    if (auth.provider === 'firebase') {
        InitFirebaseAuth(user => {
            store.dispatch(onAuthStateChanged(user));
        });
    }

    setConfig({
        APP_NAME,
        firebaseConfig,
        amplitudeConfig,
        ...AppConfig,
        auth
    });

    return {
        AppConfig,
        store: {
            store, persistor
        }
    }
}