import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import {
    CounterIncrement, CounterIncrementDelayed, CounterDecrement
} from 'AppCore/Actions';

const CounterSelector = createSelector(
    state => state.counter,
    counter => counter
);

const HookExperiment = () => {

    const counter = useSelector(CounterSelector)
    const dispatch = useDispatch();

    return (
        <div style={{borderTop: '2px solid black'}}>
            <div>{counter}</div>
            <div>
                <button onClick={() => dispatch(CounterDecrement())}>-</button>
                <button onClick={() => dispatch(CounterIncrement())}>+</button>
                <button onClick={async () => {
                    await dispatch(CounterIncrementDelayed())
                    console.log("bluu")
                }}>+ (delayed)</button>
            </div>
        </div>
    )
}

const ConnectedComponent = connect(
    state => ({
        counter: state.counter
    }),
    {
        CounterIncrement,
        CounterDecrement,
        CounterIncrementDelayed
    }
)(
    ({
        counter,
        CounterIncrement, CounterDecrement, CounterIncrementDelayed
    }) => {

        return (
            <React.Fragment>
                <div>
                    <div>{counter}</div>
                    <div>
                        <button onClick={() => CounterDecrement()}>-</button>
                        <button onClick={() => CounterIncrement()}>+</button>
                        <button onClick={() => CounterIncrementDelayed()}>+</button>
                    </div>
                </div>
                <HookExperiment />
            </React.Fragment>
        )
    }
)

export default ConnectedComponent