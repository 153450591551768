import React from 'react';



export default ({ ...props }) => (
	<article>
        <ol>
          <li>Generate an email signature using <b>MySignature</b> editor.</li>
          <li>Click on <b>Copy to clipboard</b> button.</li>
          <li>Go to Office365 or Outlook.com.</li>
          <li>In the top right, click &nbsp;<b>Settings</b>&nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp;<b>View full settings.</b></li>
          <li>Go to <b>Mail</b> &nbsp;<span title="and then" className="icon icon-site-right-b"></span>&nbsp; <b>Compose and reply</b>.</li>
          <li>Paste your signature <b>(Ctrl+V or Command+V)</b> into the <b>"Email signature"</b> section.</li>
          <li>Choose "Compose messages in <b>HTML</b> format" in the <b>"Message format"</b> section.</li>
          <li>Click "<b>Save</b>" button.</li>
        </ol>
      </article>
)