// @flow
import React from 'react';
import Image from '../../../DataObject/Image'

const _style = {
	width: '200px'
}

export const Picture = ({ image, preview = false }: { image: Image, preview: boolean}) => {

	const IMG = <img
		alt={""}
		style={{..._style, ...image.getStyle(), ...(preview ? {width: '50px'} : {})}}
		width={image.width}
		src={image.media.src}
	/>;

	return image.url ?
		<a target="_blank" rel="noopener noreferrer" href={image.url}>{IMG}</a> :
		IMG
};