import React from 'react';
import classnames from 'classnames'

import Tooltip from '@material-ui/core/Tooltip';

import css from './index.module.css';


export default ({
    value = '',
    onChange = () => {},
    onBlur = () => {},
    onClick = () => {},

    error = false,
    errorMessage,
    multiline = false,
    rows = 5,

    disabled = false,
    className,
    style,
    helperText, label, placeholder,
    inputRef
}) => {

    const inputProps = {
        ref: inputRef,
        placeholder,
        className: classnames(css.input, {
            [css.inputDisabled]: disabled,
            [css.error]: error
        }),
        disabled,
        onBlur,
        onChange,
        onClick
    }

    const Input = multiline ? <textarea {...inputProps} rows={rows} value={value} /> : <input {...inputProps} value={value}  />;

    return (
        <div className={classnames(css.container, className)} style={style}>
            {(helperText || label) &&
                <div
                    className={css.label}
                >
                    {helperText || label}
                </div>
            }
            <Tooltip
                title={errorMessage || ""}
                placement="right"
                open={error}
                PopperProps={{
                    modifiers: {
                        flip: { enabled: false }
                    }
                }}
            >
                {Input}
            </Tooltip>


        </div>
    )
}