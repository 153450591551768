import React, { useState } from "react";
import { connect } from "react-redux";

import TextField from 'Apps/Signally/Components/InputFields/TextField';
import Modal from 'AppCore/Components/Modal';
import Button from 'Apps/Signally/Components/Button';
import {deployTeamTemplate } from 'Apps/Signally/Actions';

export default connect(
	state => ({}),
	{ deployTeamTemplate }
)(
	({ team, deployTeamTemplate, onDeployed = () => {} }) => {

		const [ deployLoading, setDeployLoading ] = useState(false);
		const [ modalOpen, setOpenModal ] = useState(false);
		const [ deployMessage, setDeployMessage ] = useState("");

		const deploy = async () => {

			setDeployLoading(true);
			await deployTeamTemplate({ team_id: team.id, deploy_message: deployMessage });
			await onDeployed();
			setDeployLoading(false);
			closeModal();
		}

		const closeModal = () => {
			setDeployMessage("")
			setOpenModal(false)
		}

		const openModal = e => {

			if (!team.signature_template) {
				window.alert("No signature template choosen");
				return null;
			}

			if (!team.members_count) {
				window.alert("No one is in the team");
				return null;
			}

			setOpenModal(true)
		}

		return (
			<div>

				{modalOpen &&
					<Modal
						open
						onClose={closeModal}
						title={"Deploy '" + team.signature_template.name + "' on team " + team.name + " ?"}
						actions={[
							<Button key="cancel" disabled={deployLoading} onClick={closeModal} color="none">Annuler</Button>,
							<Button key="deploy" loading={deployLoading} onClick={deploy}>Deploy</Button>
						]}
					>
						<TextField
							multiline
							rows={3}
							placeholder={"Entrez un message à envoyer aux utilisateurs"}
							onChange={({ target : { value }}) => setDeployMessage(value)}
							value={deployMessage}
							style={{width: '100%'}}
							disabled={deployLoading}
						/>
					</Modal>
				}

				<Button disabled={
					(team.signature_template_deploy_install_email_sent_count === team.members_count)
				} loading={deployLoading} color="blue" onClick={openModal}>
					Deploy
				</Button>

				<div>{"Deployed : " + (team.signature_template_deploy_install_email_sent_count || 0) + " / " + team.members_count}</div>
				<div>{"Installed : " + (team.signature_template_deploy_count  || 0) + " / " + (team.signature_template_deploy_install_email_sent_count || 0)}</div>

			</div>
		)
	}
)