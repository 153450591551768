import React, { useState, useEffect } from "react";
import _get from 'lodash.get';


import DataTable from 'AppCore/Components/DataTable';

import DeployBtn from '../Team/DeployBtn';
import useCurrentCompany from "Apps/Signally/react-hooks/useCurrentCompany";
import Api from "Apps/Signally/Api";


export default () => {

    const [ teams, setTeams ] = useState([]);
    const company = useCurrentCompany();

    const loadTeams = async () => {
		let result = await Api.getTeams({ organisation_id: company.id })
		setTeams(result.data);
	}

	useEffect(() => {
		loadTeams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    return (
        <DataTable
            data={teams}
            config={{
                cells: [
                    { title: 'Name', key: 'name' },
                    { title: 'Signature par défaut', key: 'signature_template.name' },
                    { title: 'Deploy', key:'deploy_action' }
                ],
                actions: []
            }}
            getCell={(team, attr_name) => {

                if (attr_name === 'name') {
                    return team.name + (team.is_global ? (" (Default Team)") : "");
                }

                if (attr_name === 'deploy_action') {
                    return <DeployBtn team={team} onDeployed={loadTeams} />
                }

                return _get(team, attr_name) || '';
            }}
        />
    );

}