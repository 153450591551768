// @flow
import React from 'react';
import BaseTextField from 'Apps/Signally/Components/InputFields/TextField'
import styled from 'styled-components';
import CustomField from '../../DataObject/CustomField';

import {
    DragHandle,
    Close
} from '@material-ui/icons'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
`
const DragHandleStyled = styled(DragHandle)`
    cursor: move;
    margin-right: 10px;
    margin-top: 10px;
`
const CloseStyled = styled(Close)`
    cursor: pointer;
    margin-left: 10px;
    margin-top: 13px;
`

const TextFieldContainer = styled.div`
    flex: 1;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

const TextField = styled(BaseTextField)`
    margin-bottom: 10px;
    width: 49%;
`
const UrlTextField = styled(TextField)`
    width: 100%;
`

type Props = {
    customField: CustomField,
    order: number,
    onChange: (customField: CustomField) => any,
    onDelete: (order: number) => any
}
export default ({ customField, onChange, onDelete, order }: Props) => (
    <Container data-order={order}>
        <DragHandleStyled className="drag-bloc-indicator" />

        <TextFieldContainer>
            <TextField
                value={customField.key}
                placeholder="Titre"
                onChange={e => {
                    onChange(customField.clone(c => {
                        c.key = e.target.value
                    }))
                }}
            />

            <TextField
                value={customField.value}
                placeholder="Valeur"
                onChange={e => {
                    onChange(customField.clone(c => {
                        c.value = e.target.value
                    }))
                }}
            />

            <UrlTextField
                value={customField.url}
                placeholder="Lien"
                onChange={e => {
                    onChange(customField.clone(c => {
                        c.url = e.target.value
                    }))
                }}
            />
        </TextFieldContainer>

        <CloseStyled onClick={e => onDelete(order)} />
    </Container>
)