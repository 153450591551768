import keyMirror from 'keymirror';

export default keyMirror({
	setObject: true,
	deleteObject: true,
	mergeObject: true,

	setList: true,
	appendToList: true,
	prependToList: true,
	removeFromList: true,

	
})