import React from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import css from './HeaderUnlogged.module.css';
import Button from "Apps/Signally/Components/Button";
import { logout } from "Apps/Signally/Actions";


import Logo from 'Apps/Signally/Images/logo.png'


export default connect(
	state => ({
		userLogged: state.Auth.userLogged,
		current_user: state.current_user,
	}),
	{ logout }
)(
	({ userLogged, current_user, logout, ...props }) => {

		const navigate = useNavigate();

		const [ menuAnchor, setMenuAnchor ] = React.useState(null);


		const handleClose = () => {
			setMenuAnchor(null);
		};
		const onClickLogout = () => {
			handleClose();
			logout();
		}
		const openMenu = event => setMenuAnchor(event.currentTarget)

		return (
			<div className={css.container}>
				<div className={css.logoContainer} onClick={() => navigate('/')}>
					<img id='logoImg' className={css.logo} alt="" style={{height: 45}} src={Logo} />
					<span className={css.title}>Signally</span>
				</div>

				<div className={css.actionContainer}>
					<Button
						onPress={console.log}
						variant="text"
					>
						Pricing
					</Button>
					{!userLogged && <Button onPress={() => navigate('/login')}>
						Sign in
					</Button>}

					{userLogged && <div className={css.loggedUserInfo} onClick={openMenu}>{current_user.email || ""}</div>}

					{userLogged &&
						<Menu
							anchorEl={menuAnchor}
							keepMounted
							open={Boolean(menuAnchor)}
							onClose={handleClose}
						>
							<MenuItem onClick={onClickLogout}>Logout</MenuItem>
						</Menu>
					}
				</div>
			</div>
		);

	}
)