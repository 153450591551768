// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import dragula from "react-dragula";

import SignatureData from '../../DataObject';
import SocialFieldEditor from './SocialFieldEditor'

import "react-dragula/dist/dragula.css";
import { SocialIcon } from '../../DataObject/Social';

const selfOrParentHasClass = (el, className) => {
    if (el.classList && el.classList.contains && el.classList.contains(className)) {
        return true;
    }
    if (!el.parentNode) {
        return false;
    }
    return selfOrParentHasClass(el.parentNode, className)
}

const Container = styled.div``
const ReorderContainer = styled.div``

type Props = {
    data: SignatureData,
    onChange: (data: SignatureData) => any
}
export default ({ data, onChange }: Props) => {

    const reorderContainers = [];
    const handleReorderContainerLoaded = container => {
        if (container) {
            reorderContainers.push(container);
        }
    };

    const onChangeSocialIcon = index => (socialIcon: SocialIcon) => {
        onChange(data.clone(d => {
            d.social.socialIcons[index] = socialIcon;
        }))
    }
    const onClickDelete = index => () => {
        onChange(data.clone(d => {
            d.social.socialIcons.splice(index, 1)
        }))
    }

    useEffect(() => {

        const drake = dragula({
            containers: reorderContainers,
            moves: (el, source, handle, sibling) => {
                if (selfOrParentHasClass(handle, "drag-bloc-indicator")) {
                    return true;
                }
                return false;
            }
        }).on('drop', () => {
            const newIcons = [];
            [ ...reorderContainers[0].childNodes ].map(node => {

                newIcons.push(data.social.socialIcons[node.dataset.order].clone())

                return true;
            })

            drake.cancel(true);
            onChange(data.clone(d => {
                d.social.socialIcons = newIcons;
            }))

        })

        return () => {
            drake.destroy();
        }
    }, [reorderContainers, data, onChange])

    return (
        <Container>
            <ReorderContainer ref={handleReorderContainerLoaded}>
                {(data.social.socialIcons || []).map((socialIcon, index) => (
                    <SocialFieldEditor
                        order={index}
                        key={index}
                        socialIcon={socialIcon}
                        onChange={onChangeSocialIcon(index)}
                        onClickDelete={onClickDelete(index)}
                    />
                ))}
            </ReorderContainer>
        </Container>
    )
}