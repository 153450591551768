

import NOPANOGA from '../Apps/Nopanoga/config'
import NOPANOGA_ADMIN from '../Apps/Nopanoga/Admin/config'
import SIGNALLY from '../Apps/Signally/config'
import STOCKIZY from '../Apps/Stockizy/config'
import MATTERPORT from '../Apps/Matterport/config'

const HOSTNAMES_TO_CONFIG_NAME = {

    // LOCAL
    'localhost': 'STOCKIZY',

    // PROD
    'coach.nopanoga.app': 'NOPANOGA',

    'staging.signallydev.com': 'SIGNALLY',
    'www.signally.io': 'SIGNALLY',
    'signally.io': 'SIGNALLY',

    'www.izzistock.com': 'STOCKIZY',
    'izzistock.com': 'STOCKIZY',
    'www.stockizy.com': 'STOCKIZY',
    'izzistock-knx.web.app': 'STOCKIZY',

    'matterport-embed.com': 'MATTERPORT',
    'www.matterport-embed.com': 'MATTERPORT'
}


export const APP_CONFIGS = {
    NOPANOGA,
    NOPANOGA_ADMIN,

    SIGNALLY,
    STOCKIZY,
    MATTERPORT
}


export const getAppNameFromHost = () => {
    const { hostname } = window.location;

    return HOSTNAMES_TO_CONFIG_NAME[hostname] || HOSTNAMES_TO_CONFIG_NAME['localhost'];
}