import React from "react";
import { useNavigate } from 'react-router-dom'

import { useCurrentCentre } from 'Apps/Stockizy/Store/hooks'
import { Praticien } from '@izzistock/models';
import PageForm from 'AppCore/Components/CommonPages/PageFormWithLoader/index';
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";

export default () => {

    const navigate = useNavigate();
    const currentCentre = useCurrentCentre();
    const currentUser = useCurrentUser();


    return (
        <PageForm
            formTitle={"Nouveau praticien"}
            formAttributes={{
                name: {
                    type: 'text',
                    validators: ['not_empty']
                }
            }}
            initialData={(new Praticien()).clone(p => {
                p.centreId = currentUser.centreId;
            })}
            saveData={async praticien => {
                praticien.centreId = currentCentre.id;
                await praticien.save();
            }}
            onCreated={praticien => {
                navigate('/praticien/' + praticien.id)
            }}
        />
    )
};