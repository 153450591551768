import React from 'react';

export const Link = ({ style, color, children, ...props }: { style?: Object, color?: string }) => {

    if (!children) {
        return null;
    }

    return (
        <a style={{...(style || {}), textDecoration: 'none', color: color || 'inherit'}} target="_blank" rel="noreferrer" {...props}>
            {children}
        </a>
    )
}