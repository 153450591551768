import React, { useState } from 'react';
import styled from 'styled-components';
import { Play } from 'react-feather';

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const Overlay = styled.div`
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`

const ImageBackground = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
`

const InfosContainer = styled.div`
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const ButtonIconPlay = styled.div`
    cursor: pointer;
    padding: 10px;
`
const ClientName = styled.span`
    color: white;
    font-size: 30px;
`
const LogoContainer = styled.div`

`

const LogoContainerTopRight = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index:1000;
`


const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: black;
`


const generateImageUrl = ref => (
    `https://firebasestorage.googleapis.com/v0/b/matterport-embed.appspot.com/o/${encodeURIComponent(ref)}?alt=media`
)


export default ({
    client, matterportId, logo, bg,
    fontStyle = {
        fontColor: "#000000",
        fontFamily: 'Open Sans',
        fontSize: 20
    },
    logoSize = 150,
    style = {}
}) => {

    const [ play, setPlay ] = useState(false);

    return (
        <>
            <Container style={style}>

                {!play &&
                    <>
                        <Overlay />
                        <ImageBackground style={bg ? {backgroundImage: `url(${generateImageUrl(bg)})`} : {background: 'black'}} />

                        <InfosContainer>
                            {logo && <LogoContainer>
                                <img src={generateImageUrl(logo)} alt="logo" width={logoSize} />
                            </LogoContainer>}
                            <ClientName style={fontStyle}>{client}</ClientName>
                            <ButtonIconPlay onClick={() => setPlay(true)}>
                                <Play color="white" size="40"/>
                            </ButtonIconPlay>
                        </InfosContainer>
                    </>
                }

                {play && logo && <LogoContainerTopRight>
                                <img src={generateImageUrl(logo)} alt="logo" width="100" />
                            </LogoContainerTopRight>
                }


                <Iframe
                    width="100%"
                    height="100%"
                    src={`https://my.matterport.com/show/?m=${matterportId}&brand=1&play=1&qs=1&help=0&vrcoll=0&nozoom=1`}
                    frameborder="0" allowfullscreen="" allow="vr"
                ></Iframe>
            </Container>
        </>
    )
}