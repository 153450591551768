import React from 'react';
import { useNavigate } from 'react-router-dom'

import { Centre } from '@izzistock/models'
import PageForm from 'AppCore/Components/CommonPages/PageFormWithLoader/index';

export default () => {

    const navigate = useNavigate();

    return (
        <PageForm
            formAttributes={{
                name: { type: 'text' },
                address: {
                    type: 'text'
                }
            }}
            initialData={new Centre()}
            saveData={async centre => {
                await centre.save();
            }}
            onCreated={centre => {
                navigate('/centre/' + centre.id)
            }}
        />
    )
}