import React from 'react';
import * as firebase from 'firebase/app'
import FirebaseUi from './FirebaseUi'


export default ({ onSignedIn = user => {} }) => {
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                forceSameDevice: false,
            },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            // 'apple.com'
        ],
        credentialHelper: 'none',
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                console.log("signInSuccessWithAuthResult", authResult, redirectUrl)
            }
        }
    };

    return (
        <FirebaseUi
            uiConfig={uiConfig}
            onSignedIn={onSignedIn}
        />
    )
}