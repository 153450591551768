// @flow
import * as React from 'react';
import {
    View, StyleSheet
} from 'react-native';
import {
    TrainingSessionPage,
    BottomButton
} from '@nopanoga/components'

import { TrainingSession } from '@nopanoga/models'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%',
        borderRadius: 8,
        overflow: 'hidden'
    }
});

type Props = {
    onPressLaunch?: any => any,
    trainingSession: TrainingSession
}
export default ({
    trainingSession,
    onPressLaunch = () => {}
}: Props) => {

    return (
        <View style={styles.container}>
            <TrainingSessionPage
                trainingSession={trainingSession}
            />
            <BottomButton title="Lancer la séance" onPress={onPressLaunch} />
        </View>
    );
}
