import React, { Component } from "react";
import { NavLink } from 'react-router-dom'

import {
    Button
} from '@material-ui/core'
import _ from 'Apps/Signally/Trads';

import { PageContainer, Content } from '../Layout';
import HeaderUnlogged from '../Layout/HeaderUnlogged'

import css from './Home.module.css';

export default class HomeScreen extends Component {

	render() {

        return (
            <PageContainer className={css.PageContainer}>

                <HeaderUnlogged />

                <Content className={css.Content}>
                    <h1 style={{fontWeight: 300, marginBottom: '1.5em'}}>
                        {_('home.mainSentence')}
                    </h1>

                    <div className={css.ButtonContainer}>
                        <Button
                            style={{width: '200px'}}
                            component={NavLink} to={'/create-signature-company'}
                            variant="outlined"
                        >
                            {_('home.begin_journey')}
                        </Button>
                    </div>
                </Content>

            </PageContainer>
        );
	}
}