// @flow
import React, { useState, useRef } from 'react';
import type { Node as ReactNode } from 'react';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';

import Form from 'AppCore/Components/Form/formFields'

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import {
    Title, SubTitle, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { FormContainer, GridContainer, Container } from './styledComponents'
import Button from 'AppCore/Components/Button';

import type { Attributes } from 'AppCore/Components/Form/formFields';
type Props = {
    initialData: any,
    saveData?: any => any,
    onCreated?: any => any,
    title: string,
    formTitle: string,
    formAttributes: Attributes,
    children: ReactNode,
    beforeForm?: ReactNode,
    afterForm?: ReactNode
}
export default ({ children, beforeForm, afterForm, formAttributes = {}, title, formTitle, initialData, saveData, onCreated = () => {} }: Props) => {

    const form = useRef(null)

    const [ formHasError, setFormHasError ] = useState(false);
    const [ data, setData ] = useState(initialData)
    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    const onChangeForm = ({ values, hasError  }) => {
        setFormHasError(hasError);
        setChanged(true);
        setData(data.clone(t => {
            t.setData(values);
        }))
    }

    const onClickCancel = () => {
        setData(initialData);
    }
    const onClickSave = async () => {
        if (!saveData || form.current === null) {
            return;
        }
        if (formHasError || form.current.checkAllErrors()) {
            return false;
        }
        setLoading(true);
        let success = false;
        try {
            await saveData(data)
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            success = true;
        } catch (e) {
            alert(e.displayMessage || "une erreur est survenue")
            console.error(e);
        }
        setLoading(false);

        // If we have just created an exercice
        if (success && !initialData.id) {
            onCreated(data);
        }
    }

    const canSave = () => {
        if (!changed || loading || formHasError) {
            return false;
        }

        return true;
    }

    return (
        <React.Fragment>
            <Helmet title={title} />

            <FlexRowSpaceBetween>
                <Title>
                    {title}
                </Title>

                {saveData && <div>
                    <Button
                        loading={false} disabled={!canSave()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={false} disabled={!canSave()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>}
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <Container>
                <GridContainer>
                    <FormContainer>
                        <SubTitle style={{alignSelf: 'flex-start'}}>{formTitle}</SubTitle>
                        <Divider my={6} style={{width: '100%'}}/>

                        {beforeForm}
                        <Form
                            ref={form}
                            style={{width: '100%'}}
                            disabled
                            config={{
                                attributes: formAttributes
                            }}
                            values={data.export()}
                            onChange={onChangeForm}
                        />
                        {afterForm}
                    </FormContainer>

                    {children}

                </GridContainer>
            </Container>
        </React.Fragment>
    )
}
