// @flow
import { useEffect, useState } from 'react';
import Api from 'Apps/Signally/Api';

import useCurrentCompany from './useCurrentCompany'
import SignatureData from '../Components/Signature/V2/DataObject';
import useCurrentUser from './useCurrentUser';
import User from '../Models/User';

export default ({ user_id = 'me', team_id, signatureData: initialSignatureData }: { user_id?: string, team_id: string, signatureData?: SignatureData } = {}) => {

    const [ signatureData, setSignatureData ] = useState(initialSignatureData || new SignatureData());
    const currentCompany = useCurrentCompany();
    const currentUser = useCurrentUser();

    useEffect(() => {

        const getUserData = async () => {
            if (user_id === 'me') {
                return currentUser;
            } else {
                const user = new User();
                user.setData(await Api.getUser({ user_id }))
                return user;
            }
        }

        const getTeamData = async () => {
            if (!team_id) {
                return (currentUser && currentUser.team) ? currentUser.team : {};
            } else {
                return await Api.getTeam({ team_id });
            }
        }

        (async () => {
            const user = await getUserData();
            const team = await getTeamData();

            const newSignatureData = signatureData.clone();
            newSignatureData.setCompany(currentCompany);
            newSignatureData.setTeam(team);
            newSignatureData.setUser(user);

            setSignatureData(newSignatureData);

        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user_id, team_id, currentCompany, currentUser ])


    return signatureData;
}