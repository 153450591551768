// @flow

import React from 'react';
import SignatureData from '../../../DataObject';
import GreenMessageModel from '../../../DataObject/Addons/GreenMessage'

import {
	Text
} from './index';

export const GreenMessage = ({ data }: { data: SignatureData }) =>
(data.addons.greenMessage && data.addons.greenMessage.data && data.addons.greenMessage.data.text) ? (
    <Text
		bloc
		fontStyle={data.addons.greenMessage.data.fontStyle}
        style={data.addons.greenMessage.data.spacingStyle.getSpacingStyle()}
	>
        <img src={GreenMessageModel.iconPath} alt="signoff icon" style={{marginRight: '10px', verticalAlign: 'middle'}} />
        {data.addons.greenMessage.data.text}
    </Text>
) : null