import React from "react";
import { useNavigate } from 'react-router-dom'


import { Edit } from '@material-ui/icons'

import { ProductCategory } from '@izzistock/models'
import ProductCategoryTable from 'Apps/Stockizy/Components/ProductCategories/List'
import PageList from 'AppCore/Components/CommonPages/PageList'


export default () => {

    const getData = async () => {
        return await ProductCategory.getAll();
    }

    const navigate = useNavigate();

    return (
        <PageList
            hasSearch={false}
            addButton={{
                to: '/product-categories/create',
                title: 'Créer une catégorie'
            }}
            getData={getData}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <ProductCategoryTable
                    data={data}
                    actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/product-categories/'+id) }
                    ]}
                />
            )}
        />
    )
}