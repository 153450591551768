import React from "react";
import styled from "styled-components";

import {
  Chip as MuiChip,
} from "@material-ui/core";
import { red, green, blue } from "@material-ui/core/colors";

import { Sport } from "@nopanoga/models"

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${props => props.rgbcolor};
  color: ${props => props.theme.palette.common.white};
`;

const colors = {
    red: red[500],
    green: green[500],
    blue: blue[500]
};

export default ({ tag, tag_key, ...props } : { tag: Sport, tag_key: string }) => {

    if (!tag && tag_key) {
        tag = Sport.getById(tag_key);
    }

    return (
        <Chip label={tag.label} rgbcolor={colors[tag.color]} {...props} variant='default' />
    );
}