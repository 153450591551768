import { combineReducers } from 'redux'

//// COMMON
import Auth from './Auth'
import { reducer as DialogReducer} from 'AppCore/Components/Dialog'


//// SPECIFIC
import ReducerSignally from 'Apps/Signally/Store/reducer'
import ReducerStockizy from 'Apps/Stockizy/Store/reducer'
import ReducerNopanoga from 'Apps/Nopanoga/Store/reducer'

export default {

    SIGNALLY: combineReducers({
        ...ReducerSignally,
        dialogs: DialogReducer
    }),

    NOPANOGA: combineReducers({
        counter: (state = 0, action) => {
            switch (action.type) {
                case 'counter.increment':
                    return state+1;
                case 'counter.decrement':
                    return state-1;
                default:
                    return state;
            }
        },
        dialogs: DialogReducer,

        Auth
    }),

    NOPANOGA_ADMIN: combineReducers({
        counter: (state = 0, action) => {
            switch (action.type) {
                case 'counter.increment':
                    return state+1;
                case 'counter.decrement':
                    return state-1;
                default:
                    return state;
            }
        },
        dialogs: DialogReducer,

        Auth,
        ...ReducerNopanoga
    }),


    STOCKIZY: combineReducers({
        Auth,
        dialogs: DialogReducer,
        ...ReducerStockizy
    }),

    MATTERPORT: combineReducers({
        Auth,
        dialogs: DialogReducer,
    }),
}