import AbstractObject from './AbstractObject';

export const schemaId = "/FontStyle";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        fontSize: { type: 'integer'},
        fontColor: { type: 'string'},
        fontColor2: { type: 'string'},
        fontFamily: { type: 'string'}
    }
}

const NAME = "FontStyle";
export default class FontStyle extends AbstractObject {
    fontSize: number = 14
    fontColor: string = '#333'
    fontColor2: string = '#333'
    fontFamily: string = 'Verdana'

    getFontStyle(which = 1) {
        return ({
            fontSize: this.fontSize,
            color: which === 1 ? this.fontColor : this.fontColor2,
            fontFamily: this.fontFamily,
        });
    }

    getName() {
        return NAME;
    }
}
FontStyle.register({ name: NAME, schema, __class: FontStyle });
