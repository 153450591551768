import * as firebase from "firebase/app";
import amplitude from 'amplitude-js';
import { getConfig } from 'AppCore/config'

export const LogEvent = (eventName, eventParams = {}) => {
    const { firebaseConfig, amplitudeConfig } = getConfig();
    if (firebaseConfig && firebaseConfig.measurementId) {
        firebase.analytics().logEvent(eventName, eventParams);
    }

    if (amplitudeConfig) {
        amplitude.getInstance().logEvent(eventName, eventParams);
    }
}