// @flow
import React, { useState } from 'react';
import Api from 'Apps/Signally/Api';

import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';

import MediaUploader from 'AppCore/Components/MediaUploader/v2'

const Container = styled.div`
    padding: 10px;
`
const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`


export default () => {

    const [ file, setFile ] = useState();

    return (
        <Container>

            <MediaUploader

                onChangeMedia={setFile}
                media={file}

                title="Uploadez votre logo"
                infos={"Uploadez votre logo"}
                btnLabel={"Selectionnez une photo"}
                style={{ width: "250px", height: "300px" }}
                fileType={"image/*"}
                canCrop={true}
                needAspectRatio={4/5}
                onError={e => {
                    switch (e.type) {
                        case 'file_type':
                            alert("Votre video doit etre une image");
                            break;
                        default:
                            alert("Une erreur est survenue :  " + e.type);
                            break;
                    }
                }}

                uploadMethod={async ({ file, filetype, onProgress }) => {
                    onProgress(10);
                    const fileResult = await Api.upload({ file });
                    onProgress(100);

                    return fileResult.path;
                }}
            />

            <Divider />

            {file &&
            <div>
                <div>Type: {file.type}</div>
                <div>Width: {file.width}</div>
                <div>Height: {file.height}</div>
                {!file.local && <div>SRC: {file.src}</div>}
            </div>
            }

        </Container>
    )
}