import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
        width: 200,
    },
});

export default ({ onChange, value, label, options: {min = 1, max = 10, renderValue = value => value} = {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography id="continuous-slider" gutterBottom>
                {label} {renderValue(value)}
            </Typography>
            <Grid container spacing={2}>
                <Grid item>
                    {min}
                </Grid>
                <Grid item xs>
                    <Slider
                        value={value}
                        onChange={(e, value) => onChange(value)}
                        aria-labelledby="continuous-slider"
                        min={min}
                        max={max}
                    />
                </Grid>
                <Grid item>
                    {max}
                </Grid>
            </Grid>
        </div>
    );
}