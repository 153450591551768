// @flow
import React, { useState, useEffect } from "react";
import type { ComponentType } from 'react';
import { useCurrentUser } from '@knxlab/react-hooks';

import PageListView from './view'


type Props = {
    getData: any => Promise<{data: Array<any>}>,
    initialDataList: {data: Array<any>},
    title: string,
    hasSearch?: boolean,
    addButton: { title: string, to: string },
    DataTableComponent: ComponentType<{
                            data: Array<any>,
                            searchQuery?: string,
                            reload: any => Promise<any>
                        }>
}
export default ({
    getData = async () => ({ data: [] }),
    initialDataList = { data: [] },
    hasSearch = true,

    title,
    addButton,

    DataTableComponent
}: Props) => {

    const [ dataList, setDataList ] = useState(initialDataList);
    const [ searchQuery, setSearchQuery ] = useState("");
    const currentUser = useCurrentUser();

    const loadData = async () => {
        setDataList(await getData({ currentUser }));
    }
    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log("dataList", dataList);

    return (
        <PageListView
            title={title}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            addButton={addButton}
            hasSearch={hasSearch}

            dataList={dataList}
            reload={loadData}
            DataTableComponent={DataTableComponent}
        />
    )
}