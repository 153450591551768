import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import Helmet from 'react-helmet';
import * as firebase from 'firebase/app';

import {
    Avatar,
    Paper,
    Typography
} from "@material-ui/core";

import FirebaseSignin from './firebaseSignin'
import { useSelector } from "react-redux";

const Wrapper = styled(Paper)`
    padding: ${props => props.theme.spacing(6)}px;
    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(10)}px;
    }
`;

const BigAvatar = styled(Avatar)`
    width: 92px;
    height: 92px;
    text-align: center;
    margin: 0 auto ${props => props.theme.spacing(5)}px;
`;

const AlreadySignedIn = () => {

    const navigate = useNavigate();
    const user = useSelector(state => state.Auth.user);

    useEffect(() => {
        if (user && user.uid) {
            (async () => {
                const userData = await firebase.firestore().collection('users').doc(user.uid).get();
                if (!userData.exists) {
                    navigate("/auth/onboarding");
                } else {
                    navigate("/");
                }
            })()
        }
    }, [ user, navigate ])

    return (
        <Wrapper>
            <Helmet title="Sign In" />
            {user && user.photoURL &&
                <BigAvatar alt="Lucy" src={user.photoURL} />
            }

            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Welcome back {user && user.displayName}
            </Typography>
        </Wrapper>
    );
}

function SignIn() {
    const [ signedIn, setSignedIn ] = useState(false);
    const userAuth = useSelector(state => state.Auth.user);

    const onSignedIn = async (_user) => {
        const user = userAuth || _user;
        console.log("OnSignedIn", user);
        setSignedIn(true)
    }

    if (signedIn || userAuth) {
        return (
            <AlreadySignedIn />
        )
    }

    return (
        <Wrapper>
            <Helmet title="Sign In" />

            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Welcome
            </Typography>
            {!signedIn && <Typography component="h2" variant="body1" align="center">
                Sign in to your account to continue
            </Typography>}
            {!signedIn && <FirebaseSignin onSignedIn={onSignedIn} />}
        </Wrapper>
    );
}

export default SignIn;
