import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';

import { ModalContent } from 'AppCore/Components/Modal';

import MediaUploader from 'Apps/Signally/Components/MediaUploader'
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import { updateCampaign, createCampaign } from 'Apps/Signally/Actions';
import Api from 'Apps/Signally/Api';

import css from './editCreate.module.css';

import ModalForm from '../ModalForm'


class EditCampaignModal extends ModalForm {

	getFormAttributeConfig = () => {

		const { loading = false, saving = false } = this.state;

		return {
			name: { type: 'text', validators: ['not_empty'], disabled: loading || saving},
			start_date: { label: "Date de début", type: 'timestamp' },
			end_date: { label: "Date de fin", type: 'timestamp' }
		};
	}

	loadData = async () => {
		this.setState({
			data: await this.getData(),
			loading: false
		})
	}

	getData = async () => {
		const { campaign_id } = this.props;
		if (campaign_id) {
			const campaign = await Api.getCampaign({ campaign_id });
			return campaign;
		}

		return {
			type: 'small'
		};
	}

	save = async () => {
		const { campaign_id, organisation_id, team_id } = this.props;
		const { data: campaign } = this.state;

		if (campaign_id) {
			await Api.updateCampaign({ campaign_id, campaign });
		} else {
			if (team_id) {
				await Api.createTeamCampaign({ team_id, campaign });
			} else {
				await Api.createCampaign({ organisation_id, campaign });
			}
		}
	}

	renderModalContent() {

		const { loading = false, data } = this.state;

		return (loading ? <ActivityIndicator /> :

			<ModalContent>

				<div className={css.formContainer}>

					<MediaUploader

						onChange={media => {
							this.setState({
								data: {
									...this.state.data,
									banner_id: media ? media.payload.mediaObject.id : null,
									banner: media ? media.payload.mediaObject : null
								}
							})
						}}

						initialMedia={data.banner && data.banner.path ? {
							src: data.banner.path,
							type: data.banner.type
						} : null}

						title="Uploadez votre logo"
						infos={"Uploadez votre logo"}
						btnLabel={"Selectionnez une image"}
						style={{ width: "250px", height: "300px" }}
						fileType={"image/*"}
						canCrop={true}
					/>

					{this.renderForm({ formClassName: css.form })}

				</div>
			</ModalContent>);
	}

	getTitle() {
		const { campaign_id } = this.props;
		return campaign_id ? "Edit campaign" : "New campaign"
	}


}


export default connect(
	state => ({
		organisation_id: state.Auth.organisation_id
	}),
	{ updateCampaign, createCampaign }
)(withSnackbar(EditCampaignModal));