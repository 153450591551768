import AbstractObject from './AbstractObject';

export const schemaId = "/CustomField";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        key: { type: "string" },
        value: { type: "string" },
        url: { type: "string" }
    }
}

const NAME = "CustomField";
export default class CustomField extends AbstractObject {
    key: string
    value: string
    url: string

    getName() {
        return NAME;
    }
}
CustomField.register({ name: NAME, schema, __class: CustomField });
