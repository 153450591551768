import { colors } from '@izzistock/settings';

export default {
    MuiPaper: {
        rounded: {
            borderRadius: '8px'
        }
    },

    MuiTextField: {
        root: {
            '& label.Mui-focused': {
                color: 'black',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: colors.secondary,
                },
                '&:hover fieldset': {
                    borderColor: colors.primary,
                },
                '&.Mui-focused fieldset': {
                    borderColor: colors.primary,
                },
            },
        },
    },

    MuiAlert: {
        message: {
            display: 'flex',
            alignItems: 'center'
        }
    }
}