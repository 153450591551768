// @flow

import React from 'react';
import SignatureData from '../../../DataObject';

import {
	Text
} from './index';

export const Disclaimer = ({ data }: { data: SignatureData }) =>
data.addons.disclaimer.data.text ? (
    <Text
		bloc
		style={{
			...(data.addons.disclaimer.data.width ? {width: data.addons.disclaimer.data.width + "px"} : {}),
			...data.addons.disclaimer.data.spacingStyle.getSpacingStyle()
		}}
		fontStyle={data.addons.disclaimer.data.fontStyle}
	>{data.addons.disclaimer.data.text}:</Text>
) : null