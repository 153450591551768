import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { Menu as MuiMenu, MenuItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
    Info,
    Users,
    User,
    Mail,
    DollarSign,
    Send
} from "react-feather";


import {
    List,
    Category, CategoryText, CategoryIconMore, CategoryIconLess,
    CategoryBadge
} from './StyledComponents'
import useTeamContext from 'Apps/Signally/react-hooks/useTeamContext';
import useTeamList from 'Apps/Signally/react-hooks/useTeamList';
import useCurrentCompany from 'Apps/Signally/react-hooks/useCurrentCompany';


function SidebarCategoryItem({
    name,
    icon,
    classes,
    isOpen,
    isCollapsable = true,
    badge,
    ...rest
}) {
    return (
        <Category {...rest} button={isCollapsable}>
            {icon}
            <CategoryText>{name}</CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore />
                ) : (
                        <CategoryIconLess />
                    )
            ) : null}
            {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
    );
}



const Menu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '250px',
    marginLeft:'-10px',
    maxHeight: '600px'
  },
})(({ allLabel = "all", anchorEl, setAnchorEl, onClickTeam = () => {}, ...props }) => {

    const teams = useTeamList();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickItem = team_id => () => {
        onClickTeam(team_id);
        handleClose();
    }

    return (
        <MuiMenu
            {...props}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{width: '100%'}}
        >
            <MenuItem onClick={onClickItem(null)}>{allLabel}</MenuItem>
            {teams.data.map(team => (
                <MenuItem key={team.id} onClick={onClickItem(team.id)}>{team.name}</MenuItem>
            ))}

        </MuiMenu>
    )
});


const SidebarItem = ({ sidebarItem }) => (
    <SidebarCategoryItem
        key={sidebarItem.path}
        component={NavLink}
        end
        activeClassName="active"
        name={sidebarItem.title}
        to={sidebarItem.path}
        icon={sidebarItem.icon}
        isCollapsable={false}
    />
)

export default ({ profilPictureUrl }) => {

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ selectedTeam, setSelectedTeamId ] = useTeamContext();
    const company = useCurrentCompany();

    const selected_team_id = selectedTeam ? selectedTeam.id : null;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getPath = path => path;

    const sidebarItems = [
        (selected_team_id
            ? { title: "Général", path: getPath("/team/"+selected_team_id), icon: <Info /> }
            : { title: "Général", path: getPath("/"), icon: <Info /> }
        ),
        { title: "Deploiement", path: getPath("/deploy"), icon: <Send /> },
        { title: "Signatures", path: getPath("/signatures"), icon: <Mail /> },
        ...(!selected_team_id ? [
            { title: "Equipes", path: getPath("/teams"), icon: <Users /> }
        ] : []),
        { title: "Employés", path: getPath("/users"), icon: <User /> },
        { title: "Bannières", path: getPath("/banners"), icon: <Users /> }
    ];

    const adminItems = [
        { title: 'Billing', path: '/billing', icon: <DollarSign />}
    ];

    return (
        <List>
            <SidebarCategoryItem
                component={Button}
                name={selectedTeam ? selectedTeam.name : company.name}
                icon={<CategoryIconMore />}
                isCollapsable={false}
                onClick={handleClick}
            />
            <Menu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                onClickTeam={setSelectedTeamId}
                allLabel={company.name}
            />

            {sidebarItems.map(sidebarItem => (
                <SidebarItem
                    key={sidebarItem.path}
                    sidebarItem={sidebarItem}
                />
            ))}

            {adminItems.map(sidebarItem => (
                <SidebarItem
                    key={sidebarItem.path}
                    sidebarItem={sidebarItem}
                />
            ))}

        </List>
    )
}