import React from 'react';
import { TimePicker } from "@material-ui/pickers";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersBasePicker: {
            pickerView: {
                alignSelf: 'center'
            }
        }
    },
});

export default ({ timer = 65, onChange = () => {}, style = {width:'600px'}, className }) => {

    const getDateFromTimer = timer => {
        const date = new Date();
        const minutes = Math.floor(timer/60);
        const seconds = timer - minutes*60
        date.setMinutes(minutes, seconds)
        return date;
    }
    const getTimerFromDate = date => {
        return date.getMinutes() * 60 + date.getSeconds()
    }


    return (
        <div style={style} className={className}>
            <ThemeProvider theme={materialTheme}>
                <TimePicker
                    autoOk
                    style={{width: '200px'}}
                    variant="static"
                    ampm={false}
                    openTo="minutes"
                    views={["minutes", "seconds"]}
                    label="Minutes and seconds"
                    value={getDateFromTimer(timer)}
                    onChange={date => {
                        onChange(getTimerFromDate(date))
                    }}
                />
            </ThemeProvider>
        </div>
    )
}