// @flow
import React, { useState, useRef } from 'react';
import { useNavigate, Prompt } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';
import * as firebase from 'firebase/app';
import {
    Grid
} from '@material-ui/core'
import { Exercice } from "@nopanoga/models"

import { constructFirebaseStorageUrl } from 'AppCore/Utils'
import Form from 'AppCore/Components/Form/formFields'
import Label from 'AppCore/Components/Form/Label'
import MediaUploader from 'AppCore/Components/MediaUploader/v2';

import Level from 'Apps/Nopanoga/Components/Level'

import MuscleViewer from 'Apps/Nopanoga/Components/MuscleViewer'
import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import {
    Title, SubTitle, Divider, BlocMediaUpload
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import {
    PreviewContainer
} from 'Apps/Nopanoga/Layouts/Components/Page'
import ExercicePreviewOnApp from 'Apps/Nopanoga/Components/Exercice/PreviewOnApp'

import { FormContainer, GridContainer, Container } from './styledComponents'
import { Button } from '@nopanoga/components'
import Sports from 'Apps/Nopanoga/Components/Selectors/Sports';
import Equipements from 'Apps/Nopanoga/Components/Selectors/Equipements';


const defaultTitle = "Créer un exercice";
type Props = {
    initialExercice: Exercice,
    saveData?: any => any
}
export default ({ initialExercice, saveData }: Props) => {

    const navigate = useNavigate();
    const form = useRef(null)
    const [ formHasError, setFormHasError ] = useState(false);
    const [ exercice: Exercice, setExercice ] = useState(initialExercice)
    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const [ videoMedia, setVideoMedia ] = useState(exercice.video ? {
        ...exercice.video,
        src: (exercice.video && exercice.video.ref) ? constructFirebaseStorageUrl(exercice.video.ref) : null
    } : null)
    const [ imageMedia, setImageMedia ] = useState(exercice.image ? {
        ...exercice.image,
        src: (exercice.image && exercice.image.ref) ? constructFirebaseStorageUrl(exercice.image.ref) : null
    } : null)

    const onChangeForm = ({ values, hasError  }) => {
        setFormHasError(hasError);
        setChanged(true);
        setExercice(exercice.clone(t => {
            t.setData(values);
        }))
    }
    const onChangeVideoMedia = (file, cropValues) => {
        setChanged(true);
        setExercice(exercice.clone(ex => {
            ex.setData({
                video: file
            });
            if (cropValues) {
                const { x, y, width, height } = cropValues;
                ex.video.visibleArea = { x, y, width, height }
            }
        }))
        setVideoMedia(file);
    }
    const onChangeVideoCropValues = cropValues => {
        setChanged(true);
        setExercice(exercice.clone(ex => {
            if (ex.video && cropValues) {
                const { x, y, width, height } = cropValues;
                ex.video.visibleArea = { x, y, width, height }
            }
        }))
    }
    const onChangeImageMedia = file => {
        setChanged(true);
        setExercice(exercice.clone(t => {
            t.setData({
                image: file
            });
        }))
        setImageMedia(file);
    }

    const onChangeMainMuscleGroup = mainMuscleGroup => {
        setExercice(exercice.clone(t => {
            t.setData({
                mainMuscleGroup
            });
        }))
        setChanged(true);
    }

    const onClickCancel = () => {
        setExercice(initialExercice);
    }
    const onClickSave = async () => {
        if (!saveData || form.current === null) {
            return;
        }
        if (formHasError || form.current.checkAllErrors()) {
            return false;
        }
        setLoading(true);
        let success = false;
        try {
            await saveData(exercice)
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            success = true;
        } catch (e) {
            alert("une erreur est survenue")
            console.error(e);
        }
        setLoading(false);

        // If we have just created an exercice
        if (success && !initialExercice.id) {
            navigate('/exercices/' + exercice.id , { replace: true });
        }
    }

    const canSave = () => {
        if (!changed || loading || formHasError) {
            return false;
        }

        if (!exercice.video) {
            return false
        }

        return true;
    }

    return (
        <React.Fragment>

            <Prompt when={changed} message="Vous n'avez pas enregistré vos modifications. Etes-vous sûr de vouloir quitter cette page ?"/>
            <Helmet title={exercice.title || defaultTitle} />

            <FlexRowSpaceBetween>
                <Title>
                    {exercice.title || defaultTitle}
                </Title>

                {saveData && <div>
                    <Button
                        loading={false} disabled={!canSave()} color="tertiary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={false} disabled={!canSave()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>}
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <FlexRowSpaceBetween style={{alignItems: 'flex-start'}}>

                <Container>
                    <GridContainer>
                        <FormContainer>
                            <SubTitle style={{alignSelf: 'flex-start'}}>Informations générales</SubTitle>
                            <Divider my={6} style={{width: '100%'}}/>
                            <Form
                                ref={form}
                                style={{width: '100%'}}
                                disabled
                                config={{ attributes: {
                                    title: {
                                        label: <Label
                                            title="Nom de l'exercice"
                                            popoverColor={'blue'}
                                            popover={(
                                                <React.Fragment>
                                                    <p>Le titre est affiché sur l'ecran lors d'une séance d'entrainement en cours</p>
                                                </React.Fragment>
                                            )}
                                        />,
                                        placeholder: "Nom de l'exercice",
                                        type: 'text', required: true,
                                        disabled: loading,
                                        validators: ['not_empty']
                                    },
                                    description: {
                                        label: <Label
                                            title="Description de l'exercice"
                                            popoverColor={'blue'}
                                            popover={(
                                                <React.Fragment>
                                                    <p>La description est affichée sur la page de l'exercice et lorsque le coaché appuis sur le 'i' lors d'une séance d'entrainement</p>
                                                </React.Fragment>
                                            )}
                                        />,
                                        placeholder: "Description de l'exercice",
                                        type: 'text',
                                        multiline: 10,
                                        disabled: loading
                                    },
                                    level: {
                                        label: "Niveau", type: 'select',
                                        values : [
                                            { value: 1, label: <Level level={1} label /> },
                                            { value: 2, label: <Level level={2} label /> },
                                            { value: 3, label: <Level level={3} label /> },
                                        ],
                                        disabled: loading
                                    }

                                }}}
                                values={{
                                    ...exercice.export()
                                }}
                                onChange={onChangeForm}
                            />

                            <Sports
                                style={{ width: '100%' }}
                                selectedIds={exercice.sports}
                                onChange={selectedIds => {
                                    onChangeForm({
                                        values: {
                                            sports: selectedIds
                                        },
                                        hasError: false
                                    })
                                }}
                            />
                            <Equipements
                                style={{ width: '100%', marginTop: '10px' }}
                                selectedIds={exercice.equipments}
                                onChange={selectedIds => {
                                    onChangeForm({
                                        values: {
                                            equipments: selectedIds
                                        },
                                        hasError: false
                                    })
                                }}
                            />


                            <MuscleViewer
                                sex={'male'}
                                selectedGroups={exercice.mainMuscleGroup}
                                onChangeSelectedGroups={groups => onChangeMainMuscleGroup(groups)}
                            />
                        </FormContainer>
                        <Grid item lg={4} >
                            <BlocMediaUpload title="Video de l'exercice">
                                <MediaUploader
                                    enableInstagramImport
                                    title="Video de l'exercice"
                                    infos={"Uploadez une viceo"}
                                    btnLabel={"Selectionnez une video"}

                                    style={{ width: "100%", height: "100%" }}

                                    fileType={"video/mp4"}
                                    canCrop={true}
                                    displayVisibleArea
                                    onlyCropValues
                                    onCropValuesChanged={onChangeVideoCropValues}
                                    initialCropConf={(exercice.video && exercice.video.visibleArea) || { x: 0, y: 0 }}
                                    cropAspectRatio={3/4}
                                    onChangeMedia={onChangeVideoMedia}
                                    media={videoMedia}

                                    onClickDelete={() => onChangeVideoMedia(undefined)}

                                    firebaseUploadConf={{
                                        storageDir: "medias/videos",
                                        storageRef: firebase.storage()
                                    }}
                                    onError={e => {
                                        switch (e.type) {
                                            case 'file_type':
                                                alert("Votre video doit etre au format mp4");
                                                break;
                                            default:
                                                alert("Une erreur est survenue");
                                                break;
                                        }
                                    }}
                                />
                            </BlocMediaUpload>
                            <BlocMediaUpload title="Illustration de l'exercice (image)" style={{marginTop: '20px'}}>
                                <MediaUploader
                                    title="Ajoutez une photo pour l'execice"
                                    infos={"Uploadez une photo"}
                                    btnLabel={"Selectionnez une photo"}
                                    firebaseUploadConf={{
                                        storageDir: "medias/images",
                                        storageRef: firebase.storage()
                                    }}
                                    style={{ width: "100%", height: "100%" }}
                                    fileType={"image/*"}
                                    canCrop={true}
                                    media={imageMedia}
                                    onChangeMedia={onChangeImageMedia}
                                    onClickDelete={() => onChangeImageMedia(undefined)}

                                    onError={e => {
                                        switch (e.type) {
                                            case 'file_type':
                                                alert("Votre video doit etre au format jpg");
                                                break;
                                            default:
                                                alert("Une erreur est survenue");
                                                break;
                                        }
                                    }}
                                />
                            </BlocMediaUpload>
                        </Grid>
                    </GridContainer>
                </Container>

                <PreviewContainer
                    height={Math.min(window.innerHeight - 200, 650)}
                    title="Prévisualiser sur l'application"
                >
                    <ExercicePreviewOnApp exercice={exercice.clone()} />
                </PreviewContainer>

            </FlexRowSpaceBetween>

        </React.Fragment>
    )
}
