import React from "react";
import { useNavigate } from 'react-router-dom'


import { Edit } from '@material-ui/icons'

import { Supplier } from '@izzistock/models'
import SupplierTable from 'Apps/Stockizy/Components/Suppliers/List'
import PageList from 'AppCore/Components/CommonPages/PageList'
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";


export default () => {

    const getData = async () => {
        return await Supplier.getAll();
    }

    const navigate = useNavigate();
    const currentCentre = useCurrentCentre();

    return (
        <PageList
            addButton={!currentCentre ? {
                to: '/supplier/create',
                title: 'Créer un fournisseur'
            }: null}
            getData={getData}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <SupplierTable
                    data={data}
                    actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/supplier/'+id) }
                    ]}
                />
            )}
        />
    )
}