import React, { useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { removeSlashesFromPath } from 'AppCore/Utils';

import SignallyRoutes from '../Signally/routes'
import StockizyRoutes from '../Stockizy/routes'
import MatterportRoutes from '../Matterport/routes'
import NopanogaRoutes from '../Nopanoga/routes'
import NopanogaAdminRoutes from '../Nopanoga/Admin/routes'
import { LogEvent } from 'AppCore/Libs/Log'
import { useCurrentUser } from "@knxlab/react-hooks/lib/useCurrentUser";

let routesConfig = {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/home'
    },
    routes: []
}

const AuthSelector = createSelector(
    state => state.Auth,
    Auth => !!(Auth && Auth.user)
);

export const CheckRouteElement = ({ children, config, route, ...props }) => {

    const userIsLogged = useSelector(AuthSelector)
    const currentUser = useCurrentUser();

    if (route.canAccess && !route.canAccess(currentUser)) {
        return <Navigate replace to={config.redirectForbiddenPath || config.redirectHomePath} />
    }

    if (route.redirectSignin || (route.needUser && !userIsLogged)) {
        return <Navigate replace to={config.redirectSigninPath} />
    }

    if (route.redirectHome || (route.needLogout && userIsLogged)) {
        return <Navigate replace to={config.redirectHomePath} />
    }

    return children || <Outlet />
}

export const RenderRoutes = ({ routes, config, parentPath = '', current_user = null }) => {

    return routes.map(({ element: Element, routes = null, path, ...route }) => {

        const absolutePath = '/' + removeSlashesFromPath(parentPath + "/" + path);

        return (
            <Route key={absolutePath} path={path} element={
                <CheckRouteElement config={config} route={route}>
                    {Element && <Element/>}
                </CheckRouteElement>
            }>
                {routes &&
                    RenderRoutes({ routes, config, parentPath: parentPath + "/" + path, current_user })
                }
            </Route>
        )
    })

};

export const getRoutesConfig = () => (routesConfig);
export const setRoutesConfig = config => {
    routesConfig = config;
}

export const RoutesContext = React.createContext(getRoutesConfig());

export default ({ APP_NAME }) => {

    let location = useLocation();

    let config;
    switch(APP_NAME) {
        case 'NOPANOGA':
            config = NopanogaRoutes
            break;
        case 'NOPANOGA_ADMIN':
            config = NopanogaAdminRoutes
            break;
        case 'SIGNALLY':
            config = SignallyRoutes
            break;
        case 'STOCKIZY':
            config = StockizyRoutes;
            break;
        case 'MATTERPORT':
            config = MatterportRoutes;
            break;
        default:
            throw new Error("No router defined for " + APP_NAME)
    }

    console.log("setRouteConfig", config);
    setRoutesConfig(config);

    useEffect(() => {
        console.log("ROUTE", location.pathname);
        LogEvent('navigate', { path: location.pathname })
    }, [location.pathname])

    return (
        <RoutesContext.Provider value={getRoutesConfig()}>
            <Routes>
                {RenderRoutes(getRoutesConfig())}
            </Routes>
        </RoutesContext.Provider>
    )
}