import * as firebase from 'firebase/app';
import axios from 'axios';
import queryString from 'query-string'
import { getConfig } from 'AppCore/config'

import { firebaseFunctions } from 'AppCore/Libs/Firebase'

import { set as setCache, get as getCache } from './cache'

const INSTAGRAM_OAUTH_BASE_URL = 'https://api.instagram.com/oauth'
const INSTAGRAM_GRAPH_BASE_URL = 'https://graph.instagram.com'

let access_token = null;
export const getAccessToken = () => access_token;
export const setAccessToken = _access_token => {
    access_token = _access_token;
}

export const getOauthUrl = () => {

    const {
        instagram: { APP_ID, REDIRECT_URI }
    } = getConfig();

    return `${INSTAGRAM_OAUTH_BASE_URL}/authorize?${queryString.stringify({
        client_id: APP_ID,
        redirect_uri: REDIRECT_URI,
        scope: 'user_profile,user_media',
        response_type: 'code'
    })}`
}


export const retrieveToken = async (code) => {

    const {
        instagram: { REDIRECT_URI }
    } = getConfig();

    var retrieveAccessTokenFromCode = firebaseFunctions().httpsCallable('instagram-retrieveAccessTokenFromCode');
    const result = await retrieveAccessTokenFromCode({
        code,
        redirect_uri: REDIRECT_URI
    });

    setAccessToken(result.data.access_token)

    return result.data.access_token;
}


export const loadAccessToken = async ({ uid } = {}) => {
    const ref = firebase.firestore().collection("userssecrets").doc(uid)
    const userSecret = await ref.get();
    if (!userSecret.exists) {
        return false;
    }

    const { access_token } = userSecret.data()
    if (!access_token) {
        return false;
    }

    setAccessToken(access_token);

    return true;
}

export const retrieveUserMedia = async (
    id,
    fields = 'caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username'
) => {

    const access_token = getAccessToken();
    if (!access_token) {
        return null;
    }

    const requestData = {
        fields,
        access_token
    }

    const result = await axios.get(`${INSTAGRAM_GRAPH_BASE_URL}/${id}?${queryString.stringify(requestData)}`);

    if (!result.data) {
        return null;
    }

    return result.data;
}

export const retrieveUserMedias = async (
    limit = 20,
    fields = 'caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username,children{media_type,media_url,thumbnail_url}'
) => {

    const access_token = getAccessToken();
    if (!access_token) {
        return null;
    }

    const requestData = {
        fields,
        limit,
        access_token
    }

    const url = `${INSTAGRAM_GRAPH_BASE_URL}/me/media?${queryString.stringify(requestData)}`;

    let result = null;
    const cache = getCache(url);
    if (cache) {
        console.log("-- FROM CACHE --")
        result = cache;
    } else {
        result = await axios.get(url);
        setCache(url, result);
    }

    if (!result.data) {
        return null;
    }

    return result.data;
}

export const retrieveUserVideos = async (
    limit = 10,
) => {
    const userMedias = await retrieveUserMedias();
    if (!userMedias) {
        return [];
    }

    let videos = [];
    for (let i = 0; i < userMedias.data.length; i++) {
        const media = userMedias.data[i];
        if (media.media_type === 'CAROUSEL_ALBUM') {
            if (media.children && media.children.data && media.children.data.length) {
                videos = [
                    ...videos,
                    ...(media.children.data.filter(t => t.media_type === 'VIDEO').map(video => {
                        return {
                            ...video,
                            caption: media.caption
                        }
                    }))
                ]
            }
        } else if (media.media_type === 'VIDEO') {
            videos.push(media);
        }
    }

    return videos;
}