import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export default ({ onClose = () => {}, url = "" }) => (
    <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Copiez ce lien et partagez le avec vos clients."}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {url}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
                Fermer
            </Button>
        </DialogActions>
    </Dialog>
)