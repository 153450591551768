import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.templates, action) => {
	switch (action.type) {
	case C.SET_TEMPLATES:
		return {
			...state,
			...action.templates,
			loaded: true
		};
	case C.CLEAR_TEMPLATES:
		return initialState.templates;
	default:
		return state;
	}
}