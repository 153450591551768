import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useParams } from "react-router-dom";
import { Supplier, SupplierCentreData } from '@izzistock/models';

import useForm from "AppCore/Components/Form/useForm";
import FormField from "AppCore/Components/Form/formField";
import Button from 'AppCore/Components/Button';

import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";

import { useSnackbar } from "notistack";
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";

export default () => {

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const currentCentre = useCurrentCentre();
    const [ loading, setLoading ] = useState(false)
    const [ changed, setChanged ] = useState(false)
    const [ supplier, setSupplier ] = useState(null);
    const [ supplierCentreData, setSupplierCentreData ] = useState(null);

    const formConfig = {
        clientNumber: { type: 'text', label: "Numéro de client pour " + currentCentre.name, validators: ['not_empty'] },
    }

    const {
        getFormFieldProps, hasError, checkAllErrors,
        setData: setFormData,
        getData: getFormData,
    } = useForm({ config: formConfig,
        onChange: () => {
            setChanged(true)
        }
    });

    const loadSupplierCentreData = async () => {
        const supplierCentreData = await SupplierCentreData.get({ supplierId: id, centreId: currentCentre.id })
        setSupplierCentreData(supplierCentreData)
        setSupplier(await Supplier.get({ id }))
        setFormData(supplierCentreData.export());
    }

    useEffect(() => {
        loadSupplierCentreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ])

    const onClickCancel = () => {
        setFormData(supplierCentreData.export());
    };
    const onClickSave = async () => {
        if (hasError() || checkAllErrors()) {
            console.log("HAS ERROR")
            return false;
        }
        setLoading(true);
        try {
            const supplierCentreData = await SupplierCentreData.get({ supplierId: id, centreId: currentCentre.id })
            supplierCentreData.setData(getFormData())
            console.log("supplierCentreData", supplierCentreData);
            await supplierCentreData.save();
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setSupplierCentreData(supplierCentreData)
        } catch (e) {
            alert(e.displayMessage || "une erreur est survenue")
            console.error(e);
        }
        setLoading(false);
    }

    const updatedAt = supplier ? moment(supplier.metadata.updatedAt*1000) : null;

    return (
        <>

            <FlexRowSpaceBetween>
                <Title>
                    {supplier ? supplier.name : ""}
                </Title>

                <div>
                    <Button
                        loading={false} disabled={!changed || loading || hasError()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={loading} disabled={!changed || loading || hasError()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>
            </FlexRowSpaceBetween>

            <FlexRowSpaceBetween>
                <div/>
                {!!updatedAt && `Dernière modification: ${updatedAt.calendar()}` }
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FormField {...getFormFieldProps('clientNumber')} />

        </>
    )
};