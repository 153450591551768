import Constants from "../Constants";

const { REDUCER: C } = Constants;

export const setOnboardingSignatureName = signatureName => async (dispatch) => {
	dispatch({
		type: C.SET_ONBOARDING_SIGNATURE_DATA,
		signatureName
	});
}

export const setSignatureData = ({ signatureData, signatureDataV2, first_edit }) => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.SET_ONBOARDING_SIGNATURE_DATA,
		signatureData,
		signatureDataV2,
		first_edit
	});
}

export const clearSignatureData = () => async (dispatch, getState, { api }) => {
	dispatch({
		type: C.CLEAR_ONBOARDING_SIGNATURE_DATA
	});
}