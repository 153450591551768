import initialState from "../initialState";

export default (state = initialState.centres, action) => {
	switch (action.type) {
	case 'SET_CENTRES':
		return {
			...state,
			...action.centres
		};
	default:
		return state;
	}
}