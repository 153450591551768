// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import dragula from "react-dragula";

import SignatureData from '../../DataObject';
import CustomFieldEditor from './CustomFieldEditor'
import CustomField from '../../DataObject/CustomField';
import Button from 'Apps/Signally/Components/Button';

import { SectionTitle as BaseSectionTitle } from '../Utils/Helpers'

import "react-dragula/dist/dragula.css";

const SectionTitle = styled(BaseSectionTitle)`
    margin-bottom:20px;
`

const selfOrParentHasClass = (el, className) => {
    if (el.classList && el.classList.contains && el.classList.contains(className)) {
        return true;
    }
    if (!el.parentNode) {
        return false;
    }
    return selfOrParentHasClass(el.parentNode, className)
}

const Container = styled.div``
const ReorderContainer = styled.div``

type Props = {
    data: SignatureData,
    onChange: (data: SignatureData) => any,
    disableFields: Object
}
export default ({ data, onChange, disableFields }: Props) => {

    const reorderContainers = [];
    const handleReorderContainerLoaded = container => {
        if (container) {
            reorderContainers.push(container);
        }
    };

    const onChangeCustomField = index => (customField: CustomField) => {
        onChange(data.clone(d => {
            d.customFields[index] = customField;
        }))
    }

    const addNewCustomField = () => {
        onChange(data.clone(d => {
            if (!d.customFields) {
                d.customFields = [];
            }
            d.customFields.push(new CustomField());
        }))
    }

    const deleteCustomField = index => {
        onChange(data.clone(d => {
            if (!d.customFields) {
                d.customFields = [];
            }
            if (d.customFields[index]) {
                d.customFields.splice(index, 1);
            }
        }))
    }

    useEffect(() => {

        const drake = dragula({
            containers: reorderContainers,
            moves: (el, source, handle, sibling) => {
                if (selfOrParentHasClass(handle, "drag-bloc-indicator")) {
                    return true;
                }
                return false;
            }
        }).on('drop', () => {
            const newCustomFields = [];
            [ ...reorderContainers[0].childNodes ].map(node => {

                newCustomFields.push(data.customFields[node.dataset.order].clone())

                return true;
            })

            drake.cancel(true);
            onChange(data.clone(d => {
                d.customFields = newCustomFields;
            }))

        })

        return () => {
            drake.destroy();
        }
    }, [reorderContainers, data, onChange])

    return (
        <Container>

            <SectionTitle>
                Champs personnalisés
            </SectionTitle>

            <ReorderContainer ref={handleReorderContainerLoaded}>
                {(data.customFields || []).map((customField, index) => (
                    <CustomFieldEditor onDelete={order => deleteCustomField(order)} order={index} key={index} customField={customField} onChange={onChangeCustomField(index)} />
                ))}
            </ReorderContainer>

            <Button onClick={addNewCustomField}>Ajouter un champ personnalisé</Button>
        </Container>
    )
}