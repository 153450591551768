import React from 'react';
import { useCurrentUser } from '@knxlab/react-hooks';
import { Exercice } from '@nopanoga/models'

import ExerciceEdit from './index'

export default () => {

    const userAuth = useCurrentUser();

    return (
        <ExerciceEdit
            initialExercice={(new Exercice()).clone(t => {
                t.owner = userAuth.uid;
            })}
            saveData={async exercice => {
                exercice.owner = userAuth.uid;
                await exercice.save();
            }}
        />
    )
}