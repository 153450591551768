import React, { useState } from "react";

import _ from 'Apps/Signally/Trads';
import Button from "Apps/Signally/Components/Button";


import css from './style.module.css';


export default ({ btnActionTitle = "Create Team", onSkip = () => {}, display = false, children } = {}) => {
	const [ displayScreen, setDisplayScreen ] = useState(display);

	if (!displayScreen) {
		return (
			<div className={css.container}>
				<Button key="primary" style={{width: '200px'}} onClick={() => setDisplayScreen(true)}>{btnActionTitle}</Button>
				<Button key="skip" style={{width: '200px'}} onClick={onSkip}>{_('skip')}</Button>
			</div>
		)
	}

	return children;
}