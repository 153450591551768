import React from "react";
import { connect } from "react-redux";

import Selector from 'Apps/Signally/Components/Selector';

export default connect(
	state => ({
		campaigns: state.campaigns
	}),
	{}
)(({ campaigns = {data: []}, label = "Campaign", helperText = "Select a Campaign", ...props }) => {
	return (
		<Selector label={label} helperText={helperText} {...props} data={campaigns.data} />
	)
});