// @flow
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


import MediaBrowser from './index';

type Props = {
    onClose: any => any,
    onClickImport: any => any,
    children: any
}
export default ({
    onClose = () => {},
    onClickImport = () => {},
    children
}: Props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ selectedVideo, setSelectedVideo ] = useState(null)

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >

            <DialogTitle id="responsive-dialog-title">
                Importer une video depuis instagram
            </DialogTitle>

            <DialogContent>
                <MediaBrowser
                    onVideoSelected={video => {
                        setSelectedVideo(selectedVideo && selectedVideo.id === video.id ? null : video);
                    }}
                    selectedVideo={selectedVideo}
                />
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={e => {
                    onClose();
                }} color="primary">
                    Annuler
                </Button>

                <Button disabled={selectedVideo === null} autoFocus onClick={e => {
                    if (!selectedVideo) {
                        return false;
                    }
                    onClickImport(selectedVideo);
                }} color="primary">
                    Importer
                </Button>

            </DialogActions>
        </Dialog>
    );
}