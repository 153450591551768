import AbstractObject from './AbstractObject';

export const schemaId = "/ButtonStyle";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        radius: { type: 'integer'},
        height: { type: 'integer'},
        color: { type: 'string'},
        backgroundColor: { type: 'string'}
    }
}

const NAME = "ButtonStyle";
export default class ButtonStyle extends AbstractObject {

    radius: number = 0
    height: number = 35
    color: string = '#fff'
    backgroundColor: string = "#ccc"

    getButtonStyle() {
        return ({
            borderRadius: this.radius + "px",
            color: this.color,
            backgroundColor: this.backgroundColor,
            height: this.height + "px"
        });
    }

    getName() {
        return NAME;
    }
}
ButtonStyle.register({ name: NAME, schema, __class: ButtonStyle });