import { useDispatch, useSelector } from 'react-redux';
import useTeamList from './useTeamList';
import Constants from "../Constants";

const { REDUCER: C } = Constants;

export default () => {
    const selected_team_id = useSelector(state => state.context.selected_team_id);
    const teams = useTeamList();
    const dispatch = useDispatch();

    const teamSelectedIndex = teams.data.findIndex(team => team.id === selected_team_id)
    const selectedTeam = teamSelectedIndex !== -1 ? teams.data[teamSelectedIndex] : null;

    return [ selectedTeam, selected_team_id => {
        dispatch({
            type: C.CONTEXT_SET_TEAM,
            selected_team_id
        })
    }]
}