// @flow
import React from 'react';

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'

import Alert from 'Apps/Nopanoga/Components/Alert';
import NumberSelector from 'AppCore/Components/NumberSelector';

import { Button } from '@nopanoga/components'

import Form from 'AppCore/Components/Form/formFields'
import { TrainingSessionBloc as Bloc } from "@nopanoga/models";
import { Spacer } from 'Apps/Shared/Layouts/Dashboard/Components/Page';
import { FlexRowCentered } from 'Apps/Shared/Layouts/FlexBox';

type Props = {
    bloc: Bloc,
    onBlocChange: (bloc: Bloc) => any,
    onClose: any => any
}
const BlocDialog = ({
    onClose,
    bloc,
    onBlocChange = (bloc: Bloc) => {}
}: Props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ blocCopy, setBlocCopy ] = useState({
        title: bloc.title,
        volume: bloc.volume,
        description: bloc.description
    });

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">{blocCopy.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Alert>
                    Ajoutez un titre et une description qui s'afficheront dans la description de la séance
                </Alert>

                <Spacer size='small' />

                <Form
                    style={{width: '100%'}}
                    config={{ attributes: {
                        title: { label: "Ajoutez un titre", type: 'text', disabled: false },
                        description: {
                            label: "Ecrivez une description", type: 'text',
                            multiline: 10
                        }
                    }}}
                    values={{
                        title: blocCopy.title,
                        description: blocCopy.description,
                        volume: blocCopy.volume
                    }}
                    onChange={({values, errors, hasError}) => {
                        setBlocCopy({
                            ...blocCopy,
                            ...values
                        })
                    }}
                />

                <FlexRowCentered>
                    <NumberSelector min={1} value={blocCopy.volume} onChange={volume => {
                        setBlocCopy({
                            ...blocCopy,
                            volume
                        })
                    }} />
                </FlexRowCentered>


            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="tertiary">
                    Annuler
                </Button>
                <Button onClick={() => {
                    onBlocChange(bloc.clone(cloneBloc => {
                        cloneBloc.setData(blocCopy)
                    }));
                    onClose();
                }} color="primary" autoFocus>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BlocDialog;

export const useOpenBlocDialog = () => {
    const openDialogComponent = useOpenDialogComponent();
    return props => openDialogComponent(
        ({ close }) => (
            <BlocDialog {...props} onClose={close} />
        )
    )
}