import { User as UserModel } from '@nopanoga/models';

let currentUser = null;
export const onStoreReady = async ({ store }) => {

    currentUser = store.getState().Auth.user;

    console.log("ON STORE READY");

    store.subscribe(async () => {
        const currentState = store.getState();
        if (!currentUser && currentState.Auth.user) {
            // USER HAS LOGGED
            console.log("USER HAS LOGGED");
            currentUser = currentState.Auth.user;
            const user = new UserModel()
            user.id = currentUser.uid;
            user.setData(currentUser || {});
            onLogin({ store, user });
        }

        if (currentUser && (!currentState.Auth || !currentState.Auth.user)) {
            // USER HAS LOGGED OUT
            currentUser = null;
            onLogout({ store });
        }
    })

}


const onLogin = async ({ store, user }: { store: any, user: UserModel }) => {

    try {
        const userData = await UserModel.getUserData({ id: user.id });
        console.log("userData", userData);
        store.dispatch({
            type: 'SET_CURRENT_USER_DATA',
            currentUserData: userData
        })
    } catch (e) {
        return false;
    }

}

const onLogout = async ({ store }) => {
    console.log("LOGGED OUT => CLEAR ALL")
    store.dispatch({
        type: 'SET_CURRENT_USER_DATA',
        currentUserData: null
    })
}