import React, { useContext } from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
    ListItem as MuiListItem,
    Box as MuiBox,
    Button
} from "@material-ui/core";
import { ConfigContext } from 'App';
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";
import { useDispatch } from "react-redux";
import { clearCurrentCentre } from "Apps/Stockizy/Actions/CurrentCentre";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    background: ${props => props.theme.sidebar.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
`

const ListItem = styled(MuiListItem)`
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  min-height: 20px;
  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 30px;
  }
`;
const Box = styled(MuiBox)(spacing);


export default () => {

    const AppConfig = useContext(ConfigContext);
    const currentCentre = useCurrentCentre();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickChooseCentre = () => {
        dispatch(clearCurrentCentre());
        navigate('/centres');
    }

    return (
        <Container>
            <Brand>
                <Box ml={1}><i>{AppConfig.title}</i></Box>
            </Brand>

            {currentCentre && (
            <ListItem>
                <Box ml={1}>{currentCentre.name}</Box>
                <Button key="connect" color="primary" variant="text" onClick={onClickChooseCentre}>Choix du centre</Button>
            </ListItem>

            )}
        </Container>
    )
}