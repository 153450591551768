import styled from "styled-components";


export const ImageCellContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`