// @flow
import FirebaseUpload from 'AppCore/Libs/FirebaseUpload'
import { loadMedia } from './Media/helpers'
import type { MediaType } from './Media'

export type StorageConfType = { storageRef: any, storageDir: string };

type Args = {
    file: File | Blob,
    filetype: string,

    onProgress: (progress: number) => any
}
type UploadMethodCustomType = ({
    file: File | Blob,
    filetype: string,
    onProgress: (progress: number) => any
}) => Promise<{ downloadURL: string } & any>


export const uploadMeth = async ({ onProgress, file, filetype, uploadMethod }: Args & { uploadMethod: UploadMethodCustomType }) : Promise<MediaType & any> => {
    const { downloadURL, ...payload } = await uploadMethod({
        file,
        filetype,
        onProgress
    });

    const media = await loadMedia(downloadURL, filetype);
    return {
        src: media.src,
        width: media.width,
        height: media.height,
        type: file.type,
        payload
    }
}

export const uploadFirebaseStorage = async ({ onProgress, file, filetype, storageConf }: Args & { storageConf: StorageConfType }) : Promise<MediaType> => {
    const { storageRef, storageDir } = storageConf;

    const firebaseUpload = new FirebaseUpload()
    const { downloadURL, ref } = await firebaseUpload.upload({
        file,
        filetype,
        storageRef: storageRef.ref(storageDir),
        onUploadProgress: (progress, task) => {
            onProgress && onProgress(progress);
        }
    })

    const media = await loadMedia(downloadURL, filetype);
    return {
        src: media.src,
        ref,
        width: media.width,
        height: media.height,
        type: file.type
    }
}