// @flow
import React, { useState } from 'react';
import * as firebase from 'firebase/app';
import MediaUploader from 'AppCore/Components/MediaUploader/v2';
// import CropMedia from 'AppCore/Components/MediaUploader/Media/CropMedia'
// import styled from 'styled-components';


// @toast-ui/react-image-editor


// const IMAGE_VERTICAL = {
//     src: "https://firebasestorage.googleapis.com/v0/b/vigilant-willow-281113.appspot.com/o/medias%2Fimages%2F975f47dd-2bcd-4470-8640-61f85c1b06da?alt=media&token=3b79c4cc-29e8-41a9-a04d-8dd8aedc0bf1",
//     height: 1468,
//     ref: "medias/images/975f47dd-2bcd-4470-8640-61f85c1b06da",
//     type: "image/jpeg",
//     width: 360
// }
// const IMAGE_HORIZONTAL = {
//     src: "https://firebasestorage.googleapis.com/v0/b/vigilant-willow-281113.appspot.com/o/medias%2Fimages%2F70409321-d241-406a-b538-c3cbb86edccc?alt=media&token=8b07d7af-64fe-472b-bccc-24d915443ffc",
//     type: "image/jpeg",
//     ref: "medias/images/70409321-d241-406a-b538-c3cbb86edccc",
//     width: 1468,
//     height: 360
// }
// const IMAGE_DEFAULT = {
//     src: "https://firebasestorage.googleapis.com/v0/b/vigilant-willow-281113.appspot.com/o/medias%2Fimages%2F0d9d6952-fe04-4782-903c-beed5502fa24?alt=media&token=18332d2a-dfdc-44e8-8953-06e8e97d86d2",
//     "ref": "medias/images/0d9d6952-fe04-4782-903c-beed5502fa24",
//     "width": 281,
//     "height": 352,
//     "type": "image/jpeg"
// };

// const CropContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     flex-wrap:wrap;
//     > div {
//         margin-right: 20px;
//     }
// `

export default () => {

    const [ image, setImage ] = useState();

    const [ video, setVideo ] = useState();


    return (
        <div>
{/*
            <CropContainer>
                <div style={{height: '600px'}}>
                    <CropMedia
                        media={IMAGE_DEFAULT}
                        aspect={aspect}
                    />
                </div>

                <div style={{height: '600px'}}>
                    <CropMedia
                        media={IMAGE_VERTICAL}
                        aspect={aspect}
                    />
                </div>

                <div style={{height: '600px'}}>
                    <CropMedia
                        media={IMAGE_HORIZONTAL}
                        aspect={aspect}
                    />
                </div>
            </CropContainer> */}


            <div style={{display: 'flex', borderTop: '2px solid black', justifyContent: 'space-around'}}>

                <div>
                    <MediaUploader
                        title="Ajoutez une image pour l'execice"
                        infos={"Uploadez une image"}
                        btnLabel={"Selectionnez une image"}

                        style={{ width: "400px", height: "500px" }}

                        fileType={"image/*"}
                        canCrop={true}
                        onChangeMedia={setImage}
                        media={image}

                        onClickDelete={() => setImage(undefined)}

                        firebaseUploadConf={{
                            storageDir: "medias/images",
                            storageRef: firebase.storage()
                        }}
                    />
                </div>

                <div>
                    <MediaUploader
                        title="Ajoutez une video pour l'execice"
                        infos={"Uploadez une video"}
                        btnLabel={"Selectionnez une video"}

                        style={{ width: "400px", height: "500px" }}

                        fileType={"video/mp4"}
                        canCrop={false}
                        onChangeMedia={media => {
                            setVideo(media);
                        }}
                        onClickDelete={() => setVideo(undefined)}
                        media={video}

                        firebaseUploadConf={{
                            storageDir: "medias/videos",
                            storageRef: firebase.storage()
                        }}

                        onError={e => {
                            switch (e.type) {
                                case 'file_type':
                                    alert("Votre video doit etre au format mp4");
                                    break;
                                default:
                                    alert("Une erreur est survenue");
                                    break;
                            }
                        }}
                    />
                </div>



            </div>
            {[image, video].map((file, i) => (
                file &&
                    <div key={i} style={{marginTop: '20px', width: '100%', overflow: 'auto'}}>
                        <pre style={{width: '100px'}}>{(i===0 ? "image" : "video") + " --- " + JSON.stringify(file, null, 4)}</pre>
                    </div>


            ))}
        </div>
    )
}
