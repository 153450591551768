import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useParams } from "react-router-dom";
import * as firebase from 'firebase/app';
import { Product } from '@izzistock/models';

import { BarCode } from 'AppCore/Components/QRCode'
import MediaUploader from 'AppCore/Components/MediaUploader/v2';
import useForm from "AppCore/Components/Form/useForm";
import FormField from "AppCore/Components/Form/formField";
import { constructFirebaseStorageUrl } from 'AppCore/Utils'
import Button from 'AppCore/Components/Button';
import EditCentreData from './EditCentreData'

import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";

import { useCurrentCentre, useProductCategories, useSuppliers } from 'Apps/Stockizy/Store/hooks'

import { getFormAttributes } from './helpers';
import { useSnackbar } from "notistack";

export default () => {

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false)
    const [ changed, setChanged ] = useState(false)

    const [ product, setProduct ] = useState(null);
    const [ imageMedia, setImageMedia ] = useState(null);
    const suppliers = useSuppliers();
    const productCategories = useProductCategories();

    const currentCentre = useCurrentCentre();

    if (currentCentre) {
        return <EditCentreData />;
    }


    const formConfig = getFormAttributes({ suppliers, productCategories, type: 'edit' });
    const {
        getFormFieldProps,
        hasError,
        checkAllErrors,
        setData: setFormData,
        getData: getFormData,
        getDataAttr
    } = useForm({
        config: formConfig,
        onChange: () => {
            setChanged(true)
        }
    });

    const loadProduct = async () => {
        const product = await Product.get({ id })
        setProduct(product)
        if (product.photo) {
            console.log("product.photo", product.photo);
            setImageMedia({
                ...product.photo,
                src: (product.photo && product.photo.ref) ? constructFirebaseStorageUrl(product.photo.ref) : null
            })
        }
        setFormData(product.export());
    }

    useEffect(() => {
        loadProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ])

    const onClickCancel = () => {
        setFormData(product.export());
    };
    const onClickSave = async () => {
        if (hasError() || checkAllErrors()) {
            console.log("HAS ERROR")
            return false;
        }
        setLoading(true);
        try {
            const product = await Product.get({ id })
            product.setData(getFormData())
            if (imageMedia) {
                product.setData({
                    photo: imageMedia
                });
            }
            await product.save();
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setProduct(product)
        } catch (e) {
            alert(e.displayMessage || "une erreur est survenue")
            console.error(e);
        }
        setLoading(false);
    }

    const updatedAt = product ? moment(product.metadata.updatedAt*1000) : null;

    const onChangeImageMedia = file => {
        setImageMedia(file);
        setChanged(true);
    }

    return (
        <>

            <FlexRowSpaceBetween>
                <Title>
                    {getDataAttr('name')}
                </Title>

                <div>
                    <Button
                        loading={false} disabled={!changed || loading || hasError()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={loading} disabled={!changed || loading || hasError()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>
            </FlexRowSpaceBetween>

            <FlexRowSpaceBetween>
                <div/>
                {!!updatedAt && `Dernière modification: ${updatedAt.calendar()}` }
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FlexRowSpaceBetween>
                <MediaUploader
                    title="Ajoutez une photo pour le produit"
                    infos={"Uploadez une photo"}
                    btnLabel={"Selectionnez une photo"}
                    firebaseUploadConf={{
                        storageDir: "medias/images",
                        storageRef: firebase.storage()
                    }}
                    style={{ width: "300px", height: "300px" }}
                    fileType={"image/jpeg"}
                    canCrop={true}
                    media={imageMedia}
                    onChangeMedia={onChangeImageMedia}
                    onClickDelete={() => onChangeImageMedia(undefined)}

                    onError={e => {
                        switch (e.type) {
                            case 'file_type':
                                alert("Votre photo doit etre au format jpg");
                                break;
                            default:
                                alert("Une erreur est survenue");
                                break;
                        }
                    }}
                />

                {(product && product.code) ? <BarCode value={product.code} /> : <div/>}
            </FlexRowSpaceBetween>

            <FormField {...getFormFieldProps('categoryId')} />
            <FormField {...getFormFieldProps('name')} />
            <FormField {...getFormFieldProps('code')} />
            <FormField {...getFormFieldProps('supplierRef')} />
            <FormField {...getFormFieldProps('supplierId')} />
            <FormField {...getFormFieldProps('conditionnement')} />
            <FormField {...getFormFieldProps('needOrdonnance')} />

        </>
    )
};