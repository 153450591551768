// @flow

import { AbstractObject } from '@knxlab/models';

export const schemaId = "/Team";
const schema = {
    id: schemaId,
    type: "object",
    properties : {
        id: { type: "string" },
        name: { type: "string" },
    }
}
const NAME = "Team";

export default class Team extends AbstractObject {

    id: string
    name: string

    getName() {
        return NAME;
    }
}
Team.register({ name: NAME, schema, __class: Team });
