import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import { Visibility } from '@material-ui/icons'

import { CommandReturn } from '@izzistock/models'
import CommandReturnsTable from 'Apps/Stockizy/Components/CommandReturns/List'
import PageList from 'AppCore/Components/CommonPages/PageList/view'
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";


export default () => {

    const navigate = useNavigate();
    const location = useLocation();
    const currentCentre = useCurrentCentre();
    const [ commands, setCommands ] = useState(null);

    let onlyValidation = location.pathname === '/validate-returns';

    const loadData = async ({ centreId }: { centreId: string }) => {
        if (centreId && onlyValidation) {
            setCommands(await CommandReturn.getAllByCentreIdWaitingForValidation({ centreId }));
        } else if (centreId) {
            setCommands(await CommandReturn.getAllByCentreId({ centreId }));
        }
    }

    useEffect(() => {
        reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentCentre.id ])

    const reload = () => {
        if (currentCentre.id) {
            loadData({ centreId: currentCentre.id });
        }
    }

    return (
        <PageList
            hasSearch={false}
            title="Liste des retours"

            dataList={commands || { data: [] }}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <CommandReturnsTable
                    data={data}
                    actions={[
                        { key: 'edit', icon: <Visibility />, onClick: id => navigate('/commandreturn/'+id) }
                    ]}
                />
            )}
        />
    )
}