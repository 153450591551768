import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import PageFormLoader from 'AppCore/Components/CommonPages/PageFormWithLoader/loader';
import QrCode from 'AppCore/Components/QRCode'

import { useCentres, useCurrentCentre } from 'Apps/Stockizy/Store/hooks'
import { User } from '@izzistock/models';
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";

import { getFormAttributes } from './helpers'

export default () => {

    const centres = useCentres();
    const currentUser = useCurrentUser();
    const currentCentre = useCurrentCentre();
    const { id } = useParams();

    const [userId, setUserId] = useState(null)

    const getData = async ({ id }) => {
        return await User.get({ id })
    }

    useEffect(() => {
        (async () => {
            const user = await getData({ id });
            setUserId(user.id);
        })()
    }, [id])

    return (
        <>

            {userId && <QrCode value={userId} />}

            <PageFormLoader
                formTitle="Modifier un utilisateur"
                getData={getData}
                formAttributes={getFormAttributes({ centreId: currentCentre ? currentCentre.id: null, currentUser, centres, type: 'edit' })}
            />

        </>
    )
};