import { getConfig } from '../config';

import { logout as logoutSignally } from 'Apps/Signally/Actions'

export const onAuthStateChanged = user => (dispatch, getState) => {
    dispatch({
        type: 'auth.setuser',
        user
    })
}

export const logout = () => (dispatch, getState, { firebase }) => {
    const config = getConfig();

    if (config.APP_NAME === 'SIGNALLY') {
        dispatch(logoutSignally())
        return;
    }

    firebase.auth().signOut()
}