// @flow
import React from 'react';
import TextField from '../Utils/TextField'
import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import SignatureData from '../../DataObject';

import CustomFields from './CustomFields'

const Container = styled.div`
    padding: 10px;
`

const TextFieldRoot = ({ label, data, attrName, disableFields, disabled = false, onChange }) => (
    <TextField
        disabled={disabled || (disableFields && disableFields[attrName])}
        label={label}
        value={data[attrName] || ""}
        onChange={e => {
            onChange(data.clone(d => d[attrName] = e.target.value))
        }}
        style={{marginBottom: '10px'}}
    />
)

type Props = {
    data: SignatureData,
    onChange: (data: SignatureData) => any,
    disableFields: Object,
    onlyStyle: boolean
}
export default ({ data, onChange, disableFields, onlyStyle }: Props) => {

    const textFieldProps = { data, onChange, disableFields };

    return (
        <Container>
            <TextFieldRoot disabled={onlyStyle} label={"Prénom"} attrName={"firstName"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Nom"} attrName={"lastName"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Company"} attrName={"companyName"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Position"} attrName={"positionInCompany"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Equipe"} attrName={"teamName"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Téléphone"} attrName={"phone"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Skype"} attrName={"skype"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Email"} attrName={"email"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Adresse"} attrName={"address"} {...textFieldProps} />
            <TextFieldRoot disabled={onlyStyle} label={"Website"} attrName={"companyWebsite"} {...textFieldProps} />

            <Divider style={{margin: '20px'}}/>

            <CustomFields data={data} disableFields={disableFields} onChange={onChange} />

        </Container>
    )
}