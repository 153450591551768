import initialState from "../initialState";

export default (state = initialState.products, action) => {
	switch (action.type) {
	case 'SET_PRODUCTS':
		return {
			...state,
			...action.products
		};
	default:
		return state;
	}
}