import React, { useEffect } from 'react';
import * as firebase from 'firebase/app'
import { useCurrentUser } from '@knxlab/react-hooks'

import {
	Instagram
} from '@material-ui/icons'
import Button from 'AppCore/Components/Button';
import { retrieveToken, getOauthUrl } from 'AppCore/Libs/Instagram'

const openCenteredPopup = url => {
    const w = 500;
    const h = window.screen.height/2 + 200;

    var left = (window.screen.width - w) / 2;
    var top = (window.screen.height - h) / 2;  // for 25% - devide by 4  |  for 33% - devide by 3

    window.open(url, "instagram", 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
}


export default ({
    onLogged = () => {}
}) => {

    const currentUser = useCurrentUser();

    const OauthCodeUse = async code => {
        const ref = firebase.firestore().collection("userssecrets").doc(currentUser.uid)
        const userSecret = await ref.get();
        if (!userSecret.exists) {
            await ref.set({});
        }

        const access_token = await retrieveToken(code)

        if (!access_token) {
            return;
        }

        ref.update({
            access_token
        })
        window.localStorage.setItem('ig-data', "");
        onLogged();
    }

    const onStorageChanged = () => {
        const igData = window.localStorage.getItem('ig-data');
        if (igData) {
            unsubscribeStorage();
            OauthCodeUse(igData)
        }
    }

    let unsubscribeStorage = () => {}
    useEffect(() => {
        onStorageChanged();
        return () => {
            unsubscribeStorage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onClickConnect = e => {
        e.stopPropagation();

        window.localStorage.setItem('ig-data', "");
        window.addEventListener("storage", onStorageChanged, true);

        unsubscribeStorage = () => {
            window.removeEventListener("storage", onStorageChanged)
        }

        const oauthUrl = getOauthUrl();
        openCenteredPopup(oauthUrl);
    }


    return (
        <React.Fragment>
            <Button
                color="primary" variant="contained" onClick={onClickConnect}
                startIcon={<Instagram />}
            >{"Instagram connect"}</Button>
        </React.Fragment>
    )
}