// @flow
import React from 'react';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;


export default () => (
    <>
        <CssBaseline />
        <GlobalStyle />
        <Outlet/>
    </>
)