// @flow
import { init as initSignally, onStoreReady as onStoreReadySignally } from 'Apps/Signally/init'

import { onStoreReady as onStoreReadyStockizy } from 'Apps/Stockizy/init'
import { onStoreReady as onStoreReadyNopanoga } from 'Apps/Nopanoga/init'



const defaultInit = () => ({
    storeExtraArguments: {}
})

type ReturnType = {
    init: any => { storeExtraArguments: any },
    onStoreReady: ({ store: any }) => any
}
export default (APP_NAME: string) : ReturnType => {
    switch(APP_NAME) {
        case 'NOPANOGA':
            return {
                init: defaultInit,
                onStoreReady: onStoreReadyNopanoga
            };
        case 'NOPANOGA_ADMIN':
            return {
                init: defaultInit,
                onStoreReady: onStoreReadyNopanoga
            };
        case 'SIGNALLY':
            return {
                init: initSignally,
                onStoreReady: onStoreReadySignally
            };
        case 'STOCKIZY':
            return {
                init: defaultInit,
                onStoreReady: onStoreReadyStockizy
            };
        default:
            return {
                init: defaultInit,
                onStoreReady: ({ store }) => {}
            };
    }
}