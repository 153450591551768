// @flow
import AbstractObject from '../AbstractObject';
import FontStyle, { schemaId as FontStyleSchemaId }  from '../FontStyle';

export const schemaId = "/SignOff";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        text: { type: "text" },
        key: { type: 'text' },
        fontStyle: { '$ref': FontStyleSchemaId }
    }
}

const NAME = "SignOff";
export default class SignOff extends AbstractObject {

    text: string
    key: 'cdt' | 'thx' | 'bav' | 'custom'
    fontStyle: FontStyle = new FontStyle()

    getName() {
        return NAME;
    }
}
SignOff.register({ name: NAME, schema, __class: SignOff });
