import React from 'react';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import { PageLoader } from '@knxlab/components';

import FormPage from './index'

export default ({ getData, saveData, onSaved = () => {}, ...props }) => {

    return (
        <PageLoader

            loadData={async ({ id }) => {
                return await getData({ id })
            }}

            saveData={async data => {
                if (saveData) {
                    await saveData(data);
                } else {
                    await data.save();
                }
                onSaved(data);
                return data;
            }}

            LoadingComponent={() => (
                <ActivityIndicator material />
            )}

            Component={({ data, saveData }) => (
                <FormPage
                    {...props}
                    initialData={data}
                    saveData={saveData}
                />
            )}
        />
    )
}