// @flow

const get100PercentHeightAndAspect = ({ aspect, media }) => {
    const cropConf = {};
    cropConf.height = 100;

    let absoluteHeight = media.height;
    let absoluteWidth = absoluteHeight * aspect;
    cropConf.width = 100 * absoluteWidth / media.width;
    cropConf.x = 100*((media.width - absoluteWidth) / 2) / media.width;

    return cropConf;
}
const get100PercentWidthAndAspect = ({ aspect, media }) => {
    const cropConf = {};
    cropConf.width = 100;

    let absoluteWidth = media.width;
    let absoluteHeight = absoluteWidth / aspect;

    cropConf.height = 100 * absoluteHeight / media.height;
    cropConf.y = 100*((media.height - absoluteHeight) / 2) / media.height;

    return cropConf;
}


export const calculateMustContain = ({ contentWidth, contentHeight, containerWidth, containerHeight }) => {
    const aspect = contentWidth / contentHeight;
    const containerAspect = containerWidth / containerHeight;

    let cropConf = null;

    // horizontal
    if (containerAspect > 1) {
        if (aspect <= containerAspect) {
            cropConf = get100PercentHeightAndAspect({ aspect, media: { width: containerWidth, height: containerHeight } });
        } else {
            cropConf = get100PercentWidthAndAspect({ aspect, media: { width: containerWidth, height: containerHeight } });
        }
    } else {
        if (aspect >= containerAspect) {
            cropConf = get100PercentWidthAndAspect({ aspect, media: { width: containerWidth, height: containerHeight } });
        } else {
            cropConf = get100PercentHeightAndAspect({ aspect, media: { width: containerWidth, height: containerHeight } });
        }
    }

    return {
        width: containerWidth*cropConf.width/100,
        height: containerHeight*cropConf.height/100
    }
}


export const getInitialCropConf = ({ aspect, media }) => {
    let initialCropConf = {};

    const imageAspect = media.width / media.height;

    // horizontal
    if (imageAspect > 1) {

        // NO NEED ASPECT :
        initialCropConf = get100PercentHeightAndAspect({ aspect: 1, media });

        if (aspect && aspect <= imageAspect) {
            initialCropConf = get100PercentHeightAndAspect({ aspect, media });
        }
        if (aspect && aspect > imageAspect) {
            // ON inverse, ca doit etre 100% width
            initialCropConf = get100PercentWidthAndAspect({ aspect, media });
        }

    // vertical
    } else {

        // NO NEED ASPECT :
        initialCropConf = get100PercentWidthAndAspect({ aspect: 1, media });

        if (aspect && aspect >= imageAspect) {
            initialCropConf = get100PercentWidthAndAspect({ aspect, media });
        }
        if (aspect && aspect < imageAspect) {
            // ON inverse, ca doit etre 100% width
            initialCropConf = get100PercentHeightAndAspect({ aspect, media });
        }
    }

    return initialCropConf;
}