// @flow
import React from "react";
import styled from 'styled-components'

import ImageModel from '../../DataObject/Image';

import SpaceStyleComponent from './SpaceStyle'

import TextField from './TextField'
import {
    LineContainer,
    Slider
} from './Helpers'

const Container = styled.div`
    width: 100%;
`

export default ({ image, enableSpaceStyle = false, maxWidth = 400, onChange }: { image: ImageModel, enableSpaceStyle?: boolean, maxWidth?: number, onChange: (image: any) => any}) => {

    const getImageVisibleHeight = (width) => width; //!image.media ? width : (image.media.height * width / image.media.width)

    return (
        <Container>
            <Slider
                label={"Forme arrondie"}
                value={image.radius}
                min={0}
                max={getImageVisibleHeight(image.width)/2}
                step={1}
                onChange={value => {
                    onChange(image.clone(s => s.radius = value))
                }}
            />

            <Slider
                label={"Largeur"}
                value={image.width}
                min={50}
                max={maxWidth}
                step={1}
                onChange={value => onChange(image.clone(s => {
                    s.radius = (getImageVisibleHeight(value) * s.radius) / getImageVisibleHeight(s.width)
                    s.width = value
                }))}
            />

            {enableSpaceStyle &&
                <SpaceStyleComponent style={image.spacingStyle} onChange={spacingStyle => {
                    onChange(image.clone(s => {
                        s.spacingStyle = spacingStyle
                    }))
                }}/>
            }

            <LineContainer>
                <TextField
                    disabled={!image.urlEditable}
                    label={"Lien de l'image"}
                    value={image.url || ""}
                    onChange={e => {
                        onChange(image.clone(d => d.url = e.target.value))
                    }}
                    style={{marginBottom: '10px', width: '100%'}}
                />
            </LineContainer>

        </Container>
    )
}