import React from "react";

import HomePage from './Homepage'
import EmbedPage from './EmbedPage'

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}

export default {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/'
    },

    routes: [

        {
            path: "/",
            element: HomePage
        },

        {
            path: "/embed",
            element: EmbedPage
        },

        {
            path: "*", element: NotFound
        }
    ]
}