import React, { PureComponent } from "react";
import classnames from 'classnames';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import Icon from 'Apps/Signally/Components/Icons';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	root: {
		fontSize: '14px',
		color: '#FFFFFF',
		transition: 'none !important',
  		animation: 'none !important',
  		borderRadius: '22px',
		fontFamily: 'OpenSans-Regular'
	},
	noColor: {
		color: '#A5A9B1',
		'&:hover': {
			background: 'none'
		},
		'&:active': {
			background: 'none'
		}
	},
	grey: {
		color: '#000000',
		backgroundColor: '#E3E4EA',
		'&:hover': {
			backgroundColor: '#F2F2F8'
		},
		'&:active': {
			backgroundColor: '#D5D6DB'
		}
	},
	blue: {
		backgroundColor: '#5465FB',
		'&:hover': {
			backgroundColor: '#6E7DFF'
		},
		'&:active': {
			backgroundColor: '#3547E6'
		}
	},
	red: {
		backgroundColor: '#FF2D55',
		'&:hover': {
			backgroundColor: '#FF2D55'
		},
		'&:active': {
			backgroundColor: '#CD193B'
		}
	},
	disabled: {
		background: 'grey',
		color: '#666'
	},
});


class BaseButton extends PureComponent {

	render() {
		const {
			children,
			icon,
			color = 'blue', // 'gray' | 'blue' (default) | 'red' | 'none'
			loading = false,
			className = null,
			classes,
			...props
		} = this.props;

		if (props.onPress) {
			props.onClick = props.onPress;
			delete props.onPress;
		}

		return (
			<MuiButton disableRipple variant="text" className={classnames(
				classes.root,
				{
					[classes.noColor]: color === 'none',
					[classes.blue]: color === 'blue',
					[classes.grey]: color === 'grey',
					[classes.red]: color === 'red',
					[classes.disabled]: props.disabled
				},
				className
			)} {...props} >
				{loading &&
					<CircularProgress size={20} color="inherit" style={{marginRight: 10}} />
				}
				{icon && !loading &&
					<Icon style={{marginRight: 4}} color="inherit" name={icon} />
				}
				{children}

			</MuiButton>
		);
	}
}

const Button = withStyles(styles)(BaseButton);


export const NeedPaymentButton = connect(
	state => ({
		organisation: state.organisation
	}),
	{}
)(({ onClick, organisation, history, location, match, staticContext, ...props }) => {

    const navigate = useNavigate();

    return (
        <Button onClick={e => {

            if (!organisation.payment_subscription || !organisation.payment_subscription.item_id) {
                const go = window.confirm("You need to upgrade! Go to billing page ?");
                e.stopPropagation();
                go && navigate("/billing");
                return;
            }

            return onClick(e);

        }} {...props} />
    )

})



export default Button;