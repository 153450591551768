import { User } from '@izzistock/models';

export default {

    title: "STOCKIZZI",
    defaultTitle: "Stockizzi - L'outils magique pour les stocks",

    auth: {
        provider: 'firebase',
        userClass: User
    },

    firebaseConfig: {
        apiKey: "AIzaSyDfRaEG4JGX0E4Q-1PHrr0fmneK4XJmPG4",
        authDomain: "izzistock-knx.firebaseapp.com",
        databaseURL: "https://izzistock-knx.firebaseio.com",
        projectId: "izzistock-knx",
        storageBucket: "izzistock-knx.appspot.com",
        messagingSenderId: "294464023369",
        appId: "1:294464023369:web:245f14771c9afcb64b4898",
        measurementId: "G-J3J73KJLHG"
    },

    reduxPersistConfig: {
        whitelist: [
            "Auth", "currentCentre"
        ]
    }
}