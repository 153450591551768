//import userReducer from "./user";
import authDataReducer from "./auth";
import CurrentUserReducer from './currentUser';
import OrganisationReducer from './organisation';
import OtherAccountsReducer from './otherAccounts';
import TeamsReducer from "./teams";
import UsersReducer from "./users";
import TemplatesReducer from "./templates";
import CampaignsReducer from "./campaigns";
import OnboardingReducer from './onboarding';

import DisplayReducer from './display';

import ContextReducer from './context';

export default {

    Auth: authDataReducer,
    current_user: CurrentUserReducer,
    organisation: OrganisationReducer,
    other_accounts: OtherAccountsReducer,
    teams: TeamsReducer,
    users: UsersReducer,

    templates: TemplatesReducer,
    campaigns: CampaignsReducer,

    display: DisplayReducer,

    onboarding: OnboardingReducer,

    context: ContextReducer
};