// @flow
import React, { useState } from 'react';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Add } from '@material-ui/icons'

import { Stock } from '@izzistock/models';
import { LoadedProductList } from 'Apps/Stockizy/Components/Products/List'
import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import NumberSelector from 'AppCore/Components/NumberSelector';
import { Product } from '@izzistock/models';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

const SelectorContainer = styled.div`
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const AddStock = ({ type, close, centreId, onChange = () => {} }: {
    type: 'add' | 'remove', close: any=> any, centreId: string, onChange?: any => any
}) => {

    const [ loading: boolean, setLoading ] = useState(false);
    const [ selectedProduct: Product, setSelectedProduct ] = useState();
    const [ qty, setQty ] = useState(1);

    const canAdd = Boolean(selectedProduct);

    const onClickAdd = async () => {
        if (!selectedProduct) {
            throw new Error("Cannot add");
        }
        setLoading(true);
        let stock;
        try {
            stock = await Stock.get({ productId: selectedProduct.id, centreId });
        } catch (e) {
            stock = Stock.constructFromData({
                centreId,
                productId: selectedProduct.id,
                qty: 0
            })
        }
        stock.qty = type === 'add' ? (stock.qty + qty) : Math.max(0, stock.qty - qty);
        await stock.save();
        setLoading(false);
        close();
        onChange();
    }

    return (
        <MuiDialog
            fullWidth
            maxWidth='md'
            open
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{type === 'add' ? "Ajouter" : "Retirer"} du stock{selectedProduct ? "": " - selectionnez un produit"}</DialogTitle>
            <DialogContent>
                {!selectedProduct &&
                    <LoadedProductList
                        layout='minimal'
                        actions={[
                            product => {
                                return { icon: <Add/>, onClick: (id, product) => setSelectedProduct(product) }
                            }
                        ]}
                    />
                }
                {selectedProduct &&
                    <div>
                        Produit: {selectedProduct.name}
                    </div>
                }

                {selectedProduct &&
                    <SelectorContainer>
                        <NumberSelector
                            value={qty}
                            onChange={setQty}
                        />
                    </SelectorContainer>
                }
            </DialogContent>
            <DialogActions>
                {loading ?
                    <ActivityIndicator />:
                    <>
                        <Button onClick={close}>Annuler</Button>
                        <Button disabled={!canAdd} onClick={onClickAdd}>Valider</Button>
                    </>
                }

            </DialogActions>
        </MuiDialog>

    )
}

export const useOpenAddModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({ centreId, ...props }: { centreId: string }) => openDialogComponent(
        ({ close }) => (
            <AddStock {...props} type='add' close={close} centreId={centreId} />
        )
    )
}
export const useOpenRemoveModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({ centreId, ...props }: { centreId: string }) => openDialogComponent(
        ({ close }) => (
            <AddStock {...props} type='remove' close={close} centreId={centreId} />
        )
    )
}