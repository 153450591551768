// @flow
import React from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import SignatureData from '../../DataObject';

import FontStyleComponent from '../Utils/FontStyle'

import { SectionTitle } from '../Utils/Helpers'

const Container = styled.div`
    padding: 10px;
`

type Props = {
    data: SignatureData,
    onChange: (data: SignatureData) => any,
    disableFields: Object
}
export default ({ data, onChange, disableFields }: Props) => {

    return (
        <Container>

            <SectionTitle>Textes</SectionTitle>

            <Divider style={{margin: '20px'}}/>

            <FontStyleComponent twoColors fontStyle={data.fontStyle} onChange={fontStyle => {
                onChange(data.clone(d => {
                    d.fontStyle = fontStyle
                }))
            }}/>

        </Container>
    )
}