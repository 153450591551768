// @flow
import React, { useState } from 'react';
import styled from 'styled-components'

import {
    Divider as MuiDivider,
    Select, MenuItem
} from '@material-ui/core';

import TextField from 'Apps/Signally/Components/InputFields/TextField'

import FontStyleComponent from '../../Utils/FontStyle'
import SpaceStyleComponent from '../../Utils/SpaceStyle'
import {
    LineContainer, InputLabel, InputContainer
} from '../../Utils/Helpers'

import GreenMessageData from '../../../DataObject/Addons/GreenMessage';

export const Icon = '/static/img/signally/icons/hand-leaves.png';

const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`
const Container = styled.div`
    width: 100%;
`

const GreenMessageTexts = GreenMessageData.options

export default ({ greenMessageData, onChange }: { greenMessageData: GreenMessageData, onChange: (data: GreenMessageData) => any }) => {

    const [ selectedOption, setSelectedOption ] = useState(greenMessageData.key || 'custom');
    const [ customText, setCustomText ] = useState(greenMessageData.key === 'custom' ? greenMessageData.text : "");

    return (
        <Container>

            <LineContainer>
                <InputLabel>Type</InputLabel>
                <InputContainer>
                    <Select
                        style={{width: '100%'}}
                        value={selectedOption}
                        onChange={({ target: { value: key }}) => {
                            setSelectedOption(key)
                            onChange(greenMessageData.clone(s => {
                                s.text = key === 'custom' ? customText : GreenMessageTexts[key].text
                                s.key = key;
                            }))
                        }}
                    >
                        {Object.keys(GreenMessageTexts).map(greenMessageTextKey => (
                            <MenuItem key={greenMessageTextKey} value={greenMessageTextKey}>{GreenMessageTexts[greenMessageTextKey].label}</MenuItem>
                        ))}
                        <MenuItem value={"custom"}>{"Custom"}</MenuItem>
                    </Select>
                </InputContainer>
            </LineContainer>

            <TextField
                disabled={selectedOption !== 'custom'}
                value={greenMessageData.text}
                onChange={e => {
                    const text = e.target.value;
                    setCustomText(text)
                    onChange(greenMessageData.clone(s => s.text = text))
                }}
                style={{marginBottom: '10px', width: '100%'}}
                multiline
                rows={10}
            />

            <Divider />

            <SpaceStyleComponent style={greenMessageData.spacingStyle} onChange={spacingStyle => {
                onChange(greenMessageData.clone(s => {
                    s.spacingStyle = spacingStyle
                }))
            }}/>

            <FontStyleComponent fontStyle={greenMessageData.fontStyle} onChange={fontStyle => {
                onChange(greenMessageData.clone(s => {
                    s.fontStyle = fontStyle
                }))
            }}/>
        </Container>
    )
}