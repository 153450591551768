import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import { Edit } from '@material-ui/icons'

import Button from "@knxlab/components/lib/Button";
import { Command } from '@izzistock/models'
import CommandsTable from 'Apps/Stockizy/Components/Commands/List'
import PageList from 'AppCore/Components/CommonPages/PageList/view'
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";

import { FlexRowEnd } from "Apps/Shared/Layouts/FlexBox";


export default () => {

    const navigate = useNavigate();
    const location = useLocation();
    const currentCentre = useCurrentCentre();
    const [ commands, setCommands ] = useState(null);

    let onlyValidation = location.pathname === '/validate-commands';

    const loadData = async ({ centreId }: { centreId: string }) => {
        if (centreId && onlyValidation) {
            setCommands(await Command.getAllByCentreIdWaitingForValidation({ centreId }));
        } else if (centreId) {
            setCommands(await Command.getAllByCentreId({ centreId }));
        }
    }

    useEffect(() => {
        console.log("location", location);
        reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentCentre.id ])

    const reload = () => {
        if (currentCentre.id) {
            loadData({ centreId: currentCentre.id });
        }
    }

    return (
        <PageList
            hasSearch={false}
            title="Liste des commandes"

            header={(
                <FlexRowEnd>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!currentCentre.id}
                        onClick={async () => {
                            if (!currentCentre.id) {
                                alert("Selectionnez un centre pour générer ses commandes");
                                return;
                            }
                            await Command.generateAllForCentre({ centreId: currentCentre.id });
                            reload();
                        }}
                        style={{ marginRight: 10 }}
                    >
                        Générer les commandes
                    </Button>
                </FlexRowEnd>
            )}

            dataList={commands || { data: [] }}
            DataTableComponent={({ data, searchQuery, reload }) => (
                <CommandsTable
                    data={data}
                    actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/command/'+id) }
                    ]}
                />
            )}
        />
    )
}