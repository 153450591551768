import React from 'react';
import styled from 'styled-components';

import {
    Check
} from 'react-feather'

import { Layouts } from '../Layouts'

const Container = styled.div`
    width: 142.857143%;
    transform: scale(0.7);
    transform-origin: top left;
    overflow: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 350px;
`
const LayoutsContainer = styled.div`
    height: 100%;
    display: inline-flex;
    flex-direction: row;
`

const LayoutContainer = styled.div`
    margin-right: 10px;
    width: 400px;
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 9px;
`
const LayoutContent = styled.div``

const ClickableOverlay = styled.div`
    position: absolute;
    top:0; left:0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
`

const IconSelectedContainer = styled.div`
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const IconSelected = () => (
    <IconSelectedContainer>
        <Check size={30} />
    </IconSelectedContainer>
)

export default ({ data, dataPreview, onChange = layoutKey => {} }) => {

    return (
        <Container>
            <LayoutsContainer>
                {Layouts.map(({ key, Component: Layout }, i) => (
                    <LayoutContainer key={key}>
                        {data.layoutKey === key && <IconSelected />}
                        <ClickableOverlay onClick={() => onChange(key)} />
                        <LayoutContent>
                            <Layout data={dataPreview || data} preview />
                        </LayoutContent>
                    </LayoutContainer>
                ))}
            </LayoutsContainer>
        </Container>
    )
}