
const TRADS = {

	'login_or_signup': "Connectez vous ou inscrivez vous",

	'common.skip_step': "Passer cette étape",

	'home.begin': "Démarrer",
	'home.begin_for_me': "Pour moi",
	'home.begin_for_company': "Pour mon entreprise",
	'home.begin_journey': "Créer ma première signature",
	'home.mainSentence': "Enfin un outils simple pour gérer toutes les signatures de mails de votre entreprise",


	'createSignatureCompany.has_team': "Avez-vous des équipes dans votre société ?",
	'createSignatureCompany.login_to_add_member': "Connectez vous pour ajouter des personnes à votre équipe",


	'signature.editor.preview.title': "Ma signature",
	'signature.editor.preview.subject': "Check out my new email signature!",
	'signature.editor.preview.greeting': "Hi David,<br/>Check out this awesome email signature created with Signally.io<br/>Regards,",


	'signature.editor.copybtn': "Copier dans le presse papier",
	'signature.editor.copyandsavebtn': "Enregistrer et installer",
	'signature.editor.changeLayout': "Changer de format",


	'team.create': "Créer une équipe",
	'teammember.create': "Créer un utilisateur"
};

export default key => Object.prototype.hasOwnProperty.call(TRADS, key) ? TRADS[key] : key;
