import { reloadCurrentUser } from ".";
import Constants from "../Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;

/*
	getTeams
*/
export const getTeams = () => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.getTeams({ organisation_id });

		dispatch({
			type: C.SET_TEAMS,
			teams: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createTeam
*/
export const createTeam = ({ team }) => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.createTeam({ organisation_id, team });
		await dispatch(getTeams());

		return result;
	} catch (e) {
		console.error(e);
	}
}


export const updateLocalTeam = ({ team_id, team }) => async (dispatch, getState, { api, ws }) => {
	const teams = JSON.parse(JSON.stringify(getState().teams));

	teams.data.forEach((_team, i) => {
		if (_team.id === team_id) {
			teams.data[i] = {
				...teams.data[i],
				...team
			}
		}
	});

	dispatch({
		type: C.SET_TEAMS,
		teams
	});
}

export const updateTeam = ({ team_id, team }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_teams = {
		...(getState().teams)
	}
	const current_user = getState().current_user;

	dispatch(updateLocalTeam({ team_id, team }));

	try {
		const result = await api.updateTeam({ team_id, team });
		dispatch(getTeams())

		if (current_user.team_id === team_id) {
			dispatch(reloadCurrentUser())
		}

		return result;

	} catch (e) {

		console.error(e);

		dispatch({
			type: C.SET_TEAMS,
			teams: rollback_teams
		});
	}

}

export const deployTeamTemplate = ({ team_id, deploy_message }) => async (dispatch, getState, { api, ws }) => {

	try {
		const result = await api.deployTeamTemplate({ team_id, deploy_message });
		dispatch(getTeams())

		return result;

	} catch (e) {

		console.error(e);
	}

}

export const deleteTeam = ({ team_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteTeam({ team_id });
		await dispatch(getTeams())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}