import React from 'react';
import Select from 'Apps/Signally/Components/InputFields/Select';


export default ({

	title, label, noneLabel, helperText, data = [], getValue = obj => obj.name || "", selected_id, onChange, disableNone = false

}) => (
	<Select
		disableNone={disableNone}
		noneLabel={noneLabel}
		placeholder={helperText}
		label={label || title}
		onChange={onChange}
		value={selected_id}
		data={data.map(({ id, name }) => ({ value: id, label: name}))}
	/>
)







