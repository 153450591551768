// @flow
import AbstractObject from '../AbstractObject';
import FontStyle, { schemaId as FontStyleSchemaId }  from '../FontStyle';
import SpacingStyle, { schemaId as SpacingStyleSchemaId } from '../SpacingStyle';

export const schemaId = "/GreenMessage";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {
        key: { type: "text" },
        text: { type: "text" },
        fontStyle: { '$ref': FontStyleSchemaId },
        spacingStyle: { '$ref': SpacingStyleSchemaId }
    }
}

const NAME = "GreenMessage";
export default class GreenMessage extends AbstractObject {

    static iconPath = "/static/img/signally/icons/hand-leaves.png"

    static options = {
        'green': {
            label: "Eco-responsable",
            text: "Pensez environnement ! N’imprimez cet email que si c’est nécessaire."
        },
        'greenEnglish': {
            label: "Green message",
            text: "Please consider the environment before printing this email."
        },
    }

    key: string
    text: String
    fontStyle: FontStyle = (new FontStyle()).clone(f => f.fontSize = 8)
    spacingStyle: SpacingStyle = new SpacingStyle()

    isEnabled() {
        return !!this.text;
    }

    getName() {
        return NAME;
    }
}
GreenMessage.register({ name: NAME, schema, __class: GreenMessage });
