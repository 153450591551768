import React from 'react';
import { useDispatch } from 'react-redux';

import {
    Avatar,
    Button,
    Grid,
} from "@material-ui/core";

import {
    SidebarFooter,
    SidebarFooterText
} from 'Apps/Shared/Layouts/Components/Sidebar/StyledComponents'
import useCurrentUser from 'Apps/Signally/react-hooks/useCurrentUser';
import { logout } from 'Apps/Signally/Actions';

export default ({ profilPictureUrl }) => {

    const currentUser = useCurrentUser();
    const dispatch = useDispatch();

    return (
        <SidebarFooter>
            <Grid container spacing={2}>
                <Grid item>
                    <Avatar alt="profile picture" src={profilPictureUrl} />
                </Grid>
                <Grid item>
                    <SidebarFooterText variant="body2">
                        {currentUser.getDisplayName() || currentUser.email}
                    </SidebarFooterText>
                    <SidebarFooterText variant="body2">
                        <Button color="primary" variant="text" onClick={() => dispatch(logout())}>déconnexion</Button>
                    </SidebarFooterText>
                </Grid>
            </Grid>
        </SidebarFooter>
    )
}