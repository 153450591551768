import Api from 'Meth/api/request';
import Generated, { checkParam } from './Generated';

export default {
	...Generated,

	/*
		List all folders and files
	*/
	upload: ({ file, onUploadProgress = () => {} } = {}) => {
		checkParam(file, 'file', 'file', true)

		var data = new FormData();
		data.append('file', file);

		return Api.call({
			uri : `media`,
			config: {
				onUploadProgress
			},
			request_method: Api.request_method.POST,
			data
		})
	},


	importCsvUser: ({ file, organisation_id, onUploadProgress = () => {} } = {}) => {
		checkParam(file, 'file', 'file', true)

		var data = new FormData();
		data.append('file', file);

		return Api.call({
			uri : `organisation/${organisation_id}/users/import`,
			config: {
				onUploadProgress
			},
			request_method: Api.request_method.POST,
			data
		})
	},
}