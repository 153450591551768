import initialState from "../initialState";

export default (state = initialState.productCategories, action) => {
	switch (action.type) {
	case 'SET_PRODUCT_CATEGORIES':
		return {
			...state,
			...action.productCategories
		};
	default:
		return state;
	}
}