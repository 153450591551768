// @flow
import { useCurrentUser as knxUseCurrentUser } from '@knxlab/react-hooks';
import { getConfig } from '../config';

export const useCurrentUser = () => {
    const currentUser = knxUseCurrentUser();

    const { auth: authConfig } = getConfig();
    if (authConfig.userClass) {
        const user = new authConfig.userClass()
        user.setData(currentUser.userData || {});
        return user;
    }

    return currentUser;
}