import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.current_user, action) => {
	switch (action.type) {
	case C.SET_CURRENT_USER:
		return action.user || state;
	default:
		return state;
	}
}