import React, { useEffect, useState } from "react";

import Api from "Apps/Signally/Api";
import useTeamContext from "Apps/Signally/react-hooks/useTeamContext";
import DeployBtn from '../Team/DeployBtn';


export default () => {

    const [ selectedTeam ] = useTeamContext();
    const [ team, setTeam ] = useState(null);

    const loadTeam = async () => {
        const team = await Api.getTeam({ team_id: selectedTeam.id })
        setTeam(team);
    }

    useEffect(() => {
		loadTeam();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ selectedTeam.id ])


    return (
        <>
            <h2>{selectedTeam.name}</h2>
            {team && <DeployBtn team={team} onDeployed={loadTeam} />}
        </>
    );

}