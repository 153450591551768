import React from 'react';
import { useNavigate } from 'react-router-dom'

import { Supplier } from '@izzistock/models'
import PageForm from 'AppCore/Components/CommonPages/PageFormWithLoader/index';

export default () => {

    const navigate = useNavigate();

    return (
        <PageForm
            formAttributes={{
                name: { type: 'text', label: "Nom du fournisseur", validators: ['not_empty'] },
                code: { type: 'text', label: "Code", validators: ['not_empty'] },
                email: { type: 'text', label: "Email du fournisseur pour les commandes", validators: ['email', 'not_empty'] }
            }}
            initialData={new Supplier()}
            saveData={async supplier => {
                await supplier.save();
            }}
            onCreated={supplier => {
                navigate('/supplier/' + supplier.id)
            }}
        />
    )
}