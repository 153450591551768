import Constants from "../Constants";
import { displayError } from './display'

const { REDUCER: C } = Constants;


/*
	getTemplates
*/
export const getTemplates = () => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.getTemplates({ organisation_id });

		dispatch({
			type: C.SET_TEMPLATES,
			templates: result
		});

	} catch (e) {
		console.error(e);
	}
}

/*
	createTemplate
*/
export const createTemplate = ({ template }) => async (dispatch, getState, { api, ws }) => {

	const { organisation_id } = getState().Auth;

	try {
		const result = await api.createTemplate({ organisation_id, template });
		await dispatch(getTemplates());

		return result;

	} catch (e) {
		console.error(e);
	}
}


export const updateLocalTemplate = ({ template_id, template }) => async (dispatch, getState, { api, ws }) => {
	const templates = JSON.parse(JSON.stringify(getState().templates));

	templates.data.forEach((_template, i) => {
		if (_template.id === template_id) {
			templates.data[i] = {
				...templates.data[i],
				...template
			}
		}
	});

	dispatch({
		type: C.SET_TEMPLATES,
		templates
	});
}

export const updateTemplate = ({ template_id, template }) => async (dispatch, getState, { api, ws }) => {
	// user

	const rollback_templates = {
		...(getState().templates)
	}

	dispatch(updateLocalTemplate({ template_id, template }));

	try {

		const result = await api.updateTemplate({ template_id, template });
		dispatch(getTemplates())

		return result;

	} catch (e) {

		dispatch({
			type: C.SET_TEMPLATES,
			templates: rollback_templates
		});
	}

}

export const deleteTemplate = ({ template_id }) => async (dispatch, getState, { api, ws }) => {

	try {

		const result = await api.deleteTemplate({ template_id });
		await dispatch(getTemplates())

		return result;

	} catch (e) {
		dispatch(displayError({ error: e }));
		throw e;
	}

}