// @flow
import React, { useState } from 'react';
import classnames from 'classnames';
import { Paper } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import {Collapse} from 'react-collapse';
import {
    ExpandLess,
    ExpandMore
} from '@material-ui/icons'

import {
    TrainingSessionBlocExercice as BlocExercice,
    TrainingSessionBloc as Bloc
} from "@nopanoga/models";

import styled from 'styled-components';
import {  FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';


const BlocContainer = styled.div`

`
const ExerciceContainer = styled.div`
    transition: opacity 1s ease;
    margin-bottom: 10px;
`

type ExerciceListProps = {
    blocsExercicesChecksum?: string,
    exercices: Array<BlocExercice>,
    blocOrder: number,
    renderExerciceComponent?: any => any,
}
const ExerciceList: any = React.memo(({
    blocsExercicesChecksum,
    exercices,
    blocOrder,
    renderExerciceComponent
}: ExerciceListProps) => (exercices || []).map((blocExercice, exerciceOrder)  => (

    <ExerciceContainer key={exerciceOrder} data-order={exerciceOrder} data-blocindex={blocOrder} data-type="blocexercice">
        {renderExerciceComponent && renderExerciceComponent({
            blocExercice: blocExercice,
            blocOrder,
            exerciceOrder
        })}
    </ExerciceContainer>

)), (prev, next) => {
    if (prev.blocsExercicesChecksum && next.blocsExercicesChecksum && prev.blocsExercicesChecksum === next.blocsExercicesChecksum) {
        return true;
    }
    return false;
})

const Padding = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`


const PaperBloc = styled(Paper)`
    padding: 0px;
    width: 100%;
`;
const Header = styled.div`
    border-bottom: 1px solid #E1E1E1;

    transition: border-bottom-color 500ms ease;

    &.closed {
        border-bottom-color: transparent;
    }
`
const ExercicesContainer = styled.div`
    padding: 15px;
    height: auto;
    width: 100%;
`



type Props = {
    blocsExercicesChecksum?: string,
    bloc: Bloc,
    order: number,
    onContainerLoaded: any => any,


    renderExerciceComponent?: any => any,
    renderBlocComponent?: any => any,
}
export default ({
    blocsExercicesChecksum,
    bloc, order,

    onContainerLoaded,

    renderExerciceComponent,
    renderBlocComponent
}: Props) => {

    const [ closed, setClosed ] = useState(false);

    const handleContainerLoaded = container => {
        if (container) {
            onContainerLoaded(container);
        }
    };

    return (
        <BlocContainer data-order={order} data-type="bloc">
            <PaperBloc variant="outlined" className={classnames({closed})}>

                <Header className={classnames({closed})}>
                    <Padding>
                        <FlexRowSpaceBetween>
                            {renderBlocComponent && renderBlocComponent({
                                bloc,
                                order,
                                dragHandlerClass: "drag-bloc-indicator"
                            })}
                            <IconButton
                                variant="contained" color="primary"
                                onClick={() => setClosed(!closed)}
                            >
                                {closed ? <ExpandMore color="secondary" /> : <ExpandLess color="secondary" />}
                            </IconButton>
                        </FlexRowSpaceBetween>
                    </Padding>
                </Header>

                <Collapse isOpened={!closed} initialStyle={{height: 'auto', overflow: 'initial'}}>
                    <ExercicesContainer ref={handleContainerLoaded} data-blocindex={order} style={{minHeight: "100px"}} >
                        <ExerciceList
                            blocsExercicesChecksum={blocsExercicesChecksum}
                            exercices={bloc.exercices}
                            blocOrder={order}
                            renderExerciceComponent={renderExerciceComponent}
                        />
                    </ExercicesContainer>
                </Collapse>


            </PaperBloc>
        </BlocContainer>
    )
};