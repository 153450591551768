import initialState from "../initialState";

export default (state = initialState.suppliers, action) => {
	switch (action.type) {
	case 'SET_SUPPLIERS':
		return {
			...state,
			...action.suppliers
		};
	default:
		return state;
	}
}