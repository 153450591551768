import AbstractObject from './AbstractObject';
import SpacingStyle, { schemaId as SpacingStyleSchemaId } from './SpacingStyle';

import { media as MediaSchema } from './Schemas'
import type { Media as MediaType } from './Types'

export const schemaId = "/Logo";
export const schema = {
    id: schemaId,
    type: "object",
    properties : {

        width: { type: "integer" },
        radius: { type: "integer" },
        url: { type: "string" },
        urlEditable: { type: "boolean" },

        media: MediaSchema,
        mediaId: { type: "string" },

        spacingStyle: { '$ref': SpacingStyleSchemaId }
    }
}

const NAME = "Logo";
export default class Logo extends AbstractObject {

    width: number = 50
    radius: number = 0
    url: string
    urlEditable: boolean = true

    media: MediaType
    mediaId: string

    spacingStyle: SpacingStyle = new SpacingStyle()

    getLayoutData() {
        return {
            width: this.width,
            radius: this.radius,
            spacingStyle: this.spacingStyle.export()
        }
    }

    getStyle() {
        return ({
            width: this.width + "px",
            borderRadius: this.radius + "px",
            ...this.spacingStyle.getSpacingStyle()
        });
    }

    getName() {
        return NAME;
    }
}
Logo.register({ name: NAME, schema, __class: Logo });
