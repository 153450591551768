import initialState from "../initialState";
import Constants from "../../Constants";

const { REDUCER: C } = Constants;


export default (state = initialState.display, action) => {
	switch (action.type) {
	case C.DISPLAY_LOGIN:
		return {
			...state,
			login: action.display
		};

	case C.DISPLAY_LOADING:
		return {
			...state,
			loading: action.display
		};

	case C.DISPLAY_ERROR:
		return {
			...state,
			error: action.display ? {
				error: action.error,
				msg: action.error_msg
			} : null
		};

	case C.DISPLAY_MOBILE_MENU:
		return {
			...state,
			mobileMenu: action.display
		};
	default:
		return state;
	}
}