import React from "react";
import styled from "styled-components";

import Logo from 'Apps/Nopanoga/Assets/logo/pro-white-with-color.png'

const Container = styled.div`
    background: ${props => props.theme.sidebar.background};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
`


export default () => (
    <Container>
        <img src={Logo} style={{width: '112px'}} alt="logo"/>
    </Container>
)