import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Modal, { ModalContent } from 'AppCore/Components/Modal';
import { displayLogin } from 'Apps/Signally/Actions';

import LoginFlow from '../../Auth/loginFlow';


const styles = theme => ({
	paper: {

		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		alignItems: 'center'
	},

	groupList: {

		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',

	},

	groupItem: {
		marginBottom: '20px',
		marginLeft: '10px',
		marginRight: '10px'
	}
});

class LoginModal extends PureComponent {

	onLogged = () => {

		const { onLogged = () => {} } = this.props;
		this.props.displayLogin({ display: false })

		onLogged();
	}

	onClose = () => {
		const { onClose = () => {} } = this.props;
		this.props.displayLogin({ display: false })

		onClose();
	}

	render() {

		return (

			<Modal
				open
				onClose={this.onClose}
			>
				<ModalContent>
					<LoginFlow onLogged={this.onLogged}/>
				</ModalContent>
			</Modal>

		)
	}
}


export default connect(
	state => ({ }),
	{ displayLogin }
)(withStyles(styles)(LoginModal));