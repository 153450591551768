import React from "react";
import PageFormLoader from 'AppCore/Components/CommonPages/PageFormWithLoader/loader';

import { Supplier } from '@izzistock/models';
import { useCurrentCentre } from "Apps/Stockizy/Store/hooks";
import EditCentreData from './EditCentreData';

export default () => {

    const currentCentre = useCurrentCentre();

    if (currentCentre) {
        return <EditCentreData />;
    }

    const getData = async ({ id }) => {
        if (currentCentre) {
            return await Supplier.get({ id, centreId: currentCentre.id });
        }
        return await Supplier.get({ id })
    }

    let formAttributes = {
        name: { type: 'text', label: "Nom du fournisseur", validators: ['not_empty'], disabled: false },
        code: { type: 'text', label: "Code", validators: ['not_empty'], disabled: false },
        email: { type: 'text', label: "Email du fournisseur pour les commandes", validators: ['email', 'not_empty'], disabled: false },
    }

    return (
        <PageFormLoader
            defaultTitle="Modifier un fournisseur"
            getData={getData}
            formAttributes={formAttributes}
        />
    )
};





