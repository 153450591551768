import React from "react";
import { Navigate } from "react-router-dom";

import LayoutDashboard from '../Shared/Layouts/Dashboard'
import LayoutAuth from '../Shared/Layouts/Auth'
import Common from '../Shared/Layouts/Common'

import AuthSignin from '../Shared/Pages/Auth/Signin'
import SidebarFooter from './Layouts/Components/SidebarFooter'
import SidebarHeader from './Layouts/Components/SidebarHeader'

import {
  Mail,
  Users, User,
  Image
} from "react-feather";


import { User as UserModel } from '@izzistock/models';

////////////////////////  PAGES  ////////////////////////
// CENTRE
import CentreList from 'Apps/Stockizy/Pages/Centres/List'
import CentreCreate from 'Apps/Stockizy/Pages/Centres/EditCreate/Create'
import CentreEdit from 'Apps/Stockizy/Pages/Centres/EditCreate/Edit'


// STOCK
import StockList from 'Apps/Stockizy/Pages/Stock/List'

// SUPPLIER
import SupplierList from 'Apps/Stockizy/Pages/Suppliers/List'
import SupplierCreate from 'Apps/Stockizy/Pages/Suppliers/EditCreate/Create'
import SupplierEdit from 'Apps/Stockizy/Pages/Suppliers/EditCreate/Edit'


// PRODUCT CATEGORY
import ProductCategoriesList from 'Apps/Stockizy/Pages/ProductCategory/List'
import ProductCategoryCreate from 'Apps/Stockizy/Pages/ProductCategory/EditCreate/Create'
import ProductCategoryEdit from 'Apps/Stockizy/Pages/ProductCategory/EditCreate/Edit'

// PRODUCT
import ProductList from 'Apps/Stockizy/Pages/Products/List'
import ProductCreate from 'Apps/Stockizy/Pages/Products/EditCreate/Create'
import ProductEdit from 'Apps/Stockizy/Pages/Products/EditCreate/Edit'

// COMMAND
import CommandList from 'Apps/Stockizy/Pages/Commands/List'
import CommandView from 'Apps/Stockizy/Pages/Commands/Edit'

// COMMAND RETURNS
import CommandReturnList from 'Apps/Stockizy/Pages/CommandReturns/List'
import CommandReturnView from 'Apps/Stockizy/Pages/CommandReturns/Edit'

// USER
import UserList from 'Apps/Stockizy/Pages/Users/List'
import UserEdit from 'Apps/Stockizy/Pages/Users/EditCreate/Edit'
import UserCreate from 'Apps/Stockizy/Pages/Users/EditCreate/Create'

// DOCTORS
import PraticienList from 'Apps/Stockizy/Pages/Praticiens/List'
import PraticienEdit from 'Apps/Stockizy/Pages/Praticiens/EditCreate/Edit'
import PraticienCreate from 'Apps/Stockizy/Pages/Praticiens/EditCreate/Create'
////////////////////////  PAGES  ////////////////////////

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}

const checkPermissions = permission => currentUser => {
    const user = new UserModel()
    user.setData(currentUser.userData || {});
    return user.hasPermission(permission);
}

export default {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/home'
    },
    sidebarConfig: {
        SidebarFooterComponent: SidebarFooter,
        SidebarHeaderComponent: SidebarHeader,
    },
    sidebar: [
        { title: 'Centres', path: '/centres', icon: <Mail />, canAccess: checkPermissions('centres') },
        { title: 'Utilisateurs', path: '/users', icon: <Users />, canAccess: checkPermissions('users') },
        { title: 'Fournisseurs', path: '/suppliers', icon: <User />, canAccess: checkPermissions('suppliers') },
        { title: 'Catégories de produit', path: '/product-categories', icon: <Image />, canAccess: checkPermissions('productCategories') },
        { title: 'Produits', path: '/products', icon: <Image />, canAccess: checkPermissions('product_list') },
    ],

    sidebarCentre: [
        { title: 'Praticiens', path: '/praticiens', icon: <Users />, canAccess: checkPermissions('praticiens') },
        { title: 'Utilisateurs', path: '/users', icon: <Users />, canAccess: checkPermissions('users') },
        { title: 'Configuration Fournisseurs', path: '/suppliers', icon: <User />, canAccess: checkPermissions('suppliers') },
        { title: 'Configuration Produits', path: '/products', icon: <Image />, canAccess: checkPermissions('product_list') },
        { title: 'Stock', path: '/stock', icon: <Image />, canAccess: checkPermissions('stock') },
        { title: 'Commandes', path: '/commands', icon: <Image />, canAccess: checkPermissions('commands') },
        { title: 'Commandes à valider', path: '/validate-commands', icon: <Image />, canAccess: checkPermissions('commands') },
        { title: 'Retours', path: '/commandreturns', icon: <Image />, canAccess: checkPermissions('commandreturns') },
        { title: 'Retours à valider', path: '/validate-returns', icon: <Image />, canAccess: checkPermissions('commandreturns') },

    ],


    routes: [

        {
            path: "/",
            element: Common,
            routes: [

                {
                    path: "/auth", element: LayoutAuth,
                    routes : [
                        { path: "", redirectSignin: true },
                        { path: "sign-in", needLogout: false, element: AuthSignin },
                        { path: "onboarding", needLogin: true, element: () => <Navigate to="/" /> }
                    ]
                },

                {
                    path: "/", element: LayoutDashboard,
                    needUser: true,
                    routes : [
                        { path: "", redirectHome: true },
                        {
                            path: "home",
                            element: () => (<div>HOME</div>)
                        },

                        // CENTRES
                        { path: 'centres', element: CentreList },
                        { path: 'centre/create', element: CentreCreate },
                        { path: 'centre/:id', element: CentreEdit },

                        { path: 'stock', element: StockList },

                        // SUPPLIERS
                        { path: 'suppliers', element: SupplierList },
                        { path: 'supplier/create', element: SupplierCreate },
                        { path: 'supplier/:id', element: SupplierEdit },

                        // SUPPLIERS
                        { path: 'product-categories', element: ProductCategoriesList },
                        { path: 'product-categories/create', element: ProductCategoryCreate },
                        { path: 'product-categories/:id', element: ProductCategoryEdit },

                        // PRODUCTS
                        { path: 'products', element: ProductList },
                        { path: 'product/create', element: ProductCreate },
                        { path: 'product/:id', element: ProductEdit },

                        // COMMANDS
                        { path: 'commands', element: CommandList },
                        { path: 'command/:id', element: CommandView },
                        { path: 'validate-commands', element: CommandList },


                        // COMMAND RETURNS
                        { path: 'commandreturns', element: CommandReturnList },
                        { path: 'commandreturn/:id', element: CommandReturnView },
                        { path: 'validate-returns', element: CommandReturnList },

                        // USERS
                        { path: 'users', element: UserList, canAccess: checkPermissions('users') },
                        { path: 'user/create', element: UserCreate },
                        { path: 'user/:id', element: UserEdit },

                        // PRATICIENS
                        { path: 'praticiens', element: PraticienList, canAccess: checkPermissions('praticiens') },
                        { path: 'praticien/create', element: PraticienCreate },
                        { path: 'praticien/:id', element: PraticienEdit },
                    ]
                },
            ]
        },


        {
            path: "*", element: NotFound
        }
    ]
}