import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import uniqid from 'uniqid';
import { Product } from '@izzistock/models';

import useForm from "AppCore/Components/Form/useForm";
import FormFieldBase from "AppCore/Components/Form/formField";
import Button from 'AppCore/Components/Button';

import {
    Title, Divider
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";

import { useProductCategories, useSuppliers } from 'Apps/Stockizy/Store/hooks'

import { getFormAttributes } from './helpers';
import { useSnackbar } from "notistack";
import styled from "styled-components";

const FormField = styled(FormFieldBase)`
    margin-bottom: 10px;
`

export default () => {

    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false)
    const [ changed, setChanged ] = useState(false)

    const [ product, setProduct ] = useState(null);
    const suppliers = useSuppliers();
    const productCategories = useProductCategories();
    const navigate = useNavigate();

    const formConfig = getFormAttributes({ suppliers, productCategories, type: 'edit' });
    const {
        getFormFieldProps,
        hasError,
        checkAllErrors,
        setData: setFormData,
        getData: getFormData,
        getDataAttr
    } = useForm({
        config: formConfig,
        onChange: () => {
            setChanged(true)
        }
    });

    const onClickCancel = () => {
        setFormData(product.export());
    };
    const onClickSave = async () => {
        if (hasError() || checkAllErrors()) {
            console.log("HAS ERROR")
            return false;
        }
        setLoading(true);
        try {
            const product = new Product();
            product.setData(getFormData())
            await product.save();
            setChanged(false);
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setProduct(product)
            navigate('/product/' + product.id)
        } catch (e) {
            alert(e.displayMessage || "une erreur est survenue")
            console.error(e);
        }
        setLoading(false);
    }

    const onClickGenerateCode = (e) => {
        const _uniqid = uniqid();
        setFormData({
            code: _uniqid
        })
        setChanged(false);
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <>

            <FlexRowSpaceBetween>
                <Title>
                    {getDataAttr('name') || "Créer un produit"}
                </Title>

                <div>
                    <Button
                        loading={false} disabled={!changed || loading || hasError()} color="primary"
                        onClick={onClickCancel} style={{marginRight: '10px'}}
                    >{"annuler les modifications".toUpperCase()}</Button>
                    <Button
                        loading={loading} disabled={!changed || loading || hasError()} variant="contained" color="primary"
                        onClick={onClickSave}
                    >{"Enregistrer".toUpperCase()}</Button>
                </div>
            </FlexRowSpaceBetween>


            <br/>
            <Divider />
            <br/>


            <FormField {...getFormFieldProps('categoryId')} />
            <FormField {...getFormFieldProps('name')} />
            <FormField {...getFormFieldProps('code')} />
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={onClickGenerateCode}>Générer un code</a>
            <FormField {...getFormFieldProps('supplierRef')} />
            <FormField {...getFormFieldProps('supplierId')} />
            <FormField {...getFormFieldProps('conditionnement')} />
            <FormField {...getFormFieldProps('needOrdonnance')} />

        </>
    )
};