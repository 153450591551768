// @flow
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import {
	LinearProgress,
	Fab,
	Icon
} from '@material-ui/core';

import FirebaseUpload from 'AppCore/Libs/FirebaseUpload'

import InstagramImport from './InstagramImport'
import DropZone from './DropZone'
import Infos from './DropZoneInfos'

import { loadMedia, isVideo } from './Media/helpers'
import UploadedMedia, { clone as cloneMedia } from './Media';
import CropMedia, { getCroppedImg } from './Media/CropMedia'
import type { MediaType } from './Media'

import type { CropConf } from './Media/CropMedia'
import { ErrorAspectRatio, ErrorFileType, ErrorType } from './errors'
import Style from './style.module.css';
import { constructFirebaseStorageUrl } from '@knxlab/utils';

export { default as ResizableContainer } from './ResizableMediaContainer'

type Props = {

	onFileChanged?: (file: ?MediaType) => any,

	onClear?: any => any,

	infos: string,
	title: string,
	btnLabel: string,
	disabled?: boolean,

	storageRef: any,
	storageDir: string,
	style?: any,

	fileSrc?: string,
	fileType: string,

	media?: MediaType,

	needAspectRatio?: number,
	canCrop?: boolean,
	onError?: (e: ErrorType) => any,

	enableInstagramImport?: boolean
};
type States = {
	progress: number,
	uploading: boolean,
	loading: boolean,
	cropConf?: CropConf,
	media?: MediaType,
	mediaChanged: boolean,
	edit: boolean
}

export default class ImageUploader extends PureComponent<Props, States> {

	static defaultProps = {
		canCrop: true,
		enableInstagramImport: false
	}

	dropZone: any;
	_isMounted: boolean;

	constructor(props: any) {
		super(props);
		this._isMounted = true;
		this.state = {
			uploading: false,
			loading: false,
			progress: 0,

			media: this.props.media || undefined,
			cropConf: undefined,

			edit: false,
			mediaChanged: false
		};
		this.dropZone = null;
	}

	setMedia({ media, ...additionnalState }: { media?: MediaType }, callback?: any => any = () => {}) {
		if (!this._isMounted) {
			return false;
		}

		const { onFileChanged = media => {} } = this.props;
		const { mediaChanged } = this.state;

		this.setState({
			...additionnalState,
			media
		}, callback);

		if (mediaChanged && (!media || !media.local)) {
			onFileChanged(media);
		}
	}

	async componentDidMount() {
		const { media = {}, fileType } = this.props;
		let fileSrc = null

		if (media && media.ref && !media.src && media.type === fileType) {
			const downloadURL = constructFirebaseStorageUrl(media.ref)
			fileSrc = downloadURL;
		}

		if (fileSrc) {
			this.setMedia({
				loading: true,
				media: {
					ref: media.ref,
					width: media.width,
					height: media.height,
					src: fileSrc,
					type: fileType
				},
			});
			const newMedia = await loadMedia(fileSrc, fileType);
			this.setMedia({
				media: {
					...newMedia,
					src: fileSrc,
					type: fileType,
					ref: media.ref,
					width: media.width,
					height: media.height,
				},
				loading: false
			})
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	onDrop = async (acceptedFiles: Array<any>): Promise<void> => {

		const { needAspectRatio, canCrop, onError = (e: Error) => {} } = this.props;

		if (acceptedFiles.length === 0) {
			return;
		}

		const file = acceptedFiles[0];

		if (file.type !== this.props.fileType) {
			onError(new ErrorFileType(file.type))
			return;
		}

		let media = null;

		if (isVideo(file.type)) {
			media = await new Promise(resolve => {
				const mediaSrc = URL.createObjectURL(file);
				const video = document.createElement('video')
				video.addEventListener('loadedmetadata', (event: Event) => {
					resolve({
						src: mediaSrc,
						width: video.videoWidth,
						height: video.videoHeight,
						type: file.type,
						local: true
					})
				})
				video.src = mediaSrc
			})
		} else {
			media = await new Promise(resolve => {
				var reader = new FileReader();
				reader.onload = async ({ target }) => {
					//$FlowFixMe
					const { result: mediaSrc } = target;
					const media = await loadMedia(mediaSrc, file.type);
					resolve({
						src: mediaSrc,
						width: media.width,
						height: media.height,
						type: file.type,
						local: true
					})
				}
				reader.readAsDataURL(file);
			})
		}

		const isValidAspectRatio = this.isAspectRatioValid(media, needAspectRatio)

		if (!isValidAspectRatio && !canCrop) {
			onError(new ErrorAspectRatio("Bad aspect Ratio"))
			return;
		}

		let edit = false;
		if (needAspectRatio) {
			edit = !isValidAspectRatio;
		}

		this.setMedia({
			media,
			edit,
			mediaChanged: true
		}, async () => {
			await this.uploadFile(file, file.type);
		})
	}

	isAspectRatioValid = (media?: { width: number, height: number }, needAspectRatio?: number) => {

		if (!needAspectRatio || !media || !media.width || !media.height) {
			return true;
		}
		let aspectRatio = Math.round((media.width / media.height)*100)/100;
		return aspectRatio === needAspectRatio
	}

	uploadFile = async (file: File | Blob, filetype: string) => {
		const { storageRef, storageDir } = this.props;

		this.setState({
			progress: 1,
			uploading: true
		})
		const firebaseUpload = new FirebaseUpload()
		const { downloadURL, ref } = await firebaseUpload.upload({
			file,
			filetype,
			storageRef: storageRef.ref(storageDir),
			onUploadProgress: (progress, task) => {
				this.setState({ progress });
			}
		})
		this.setState({
			loading: true,
			uploading: false
		})

		const media = await loadMedia(downloadURL, filetype);
		this.setMedia({
			loading: false,
			media: {
				src: media.src,
				ref,
				width: media.width,
				height: media.height,
				type: file.type
			}
		})
	}

	triggerFileSelection = (e: SyntheticEvent<EventTarget, Event>) => {
		this.dropZone.triggerFileSelection();
		e.stopPropagation();
	}

	onClear = (e: SyntheticEvent<EventTarget, Event>) => {
		const { onClear = () => {}, fileSrc } = this.props;

		this.setState({
			cropConf: undefined,
			progress: 0,
			edit: false
		})

		if (!fileSrc) {
			this.setMedia({ media: undefined, mediaChanged: true })
		}

		onClear();

		e.stopPropagation();
	}

	onClickEdit = (e: SyntheticEvent<EventTarget, Event>) => {
		this.setState({ edit: true });
	}

	onClickSave = async (e: SyntheticEvent<EventTarget, Event>) => {

		const { cropConf } = this.state;
		if (!cropConf) {
			throw new Error("Missing cropconf");
		}

		const { media } = this.state;
		if (!media) {
			throw new Error("missing media!");
		}

		if (isVideo(media.type)) {
			this.setState({ edit: false });
			return;
		}

		if (!media || !media.type || !media.height || !media.width || !media.src) {
			throw new Error("Need width and height !")
		}

		const { blob, media: croppedMedia } = await getCroppedImg({
			src: media.src,
			height: media.height,
			width: media.width,
			type: media.type
		}, cropConf)

		const newMedia: MediaType = cloneMedia(croppedMedia);
		newMedia.src = URL.createObjectURL(blob)

		this.setState({
			media: newMedia,
			edit: false,
			mediaChanged: true
		});
		await this.uploadFile(blob, media.type);
	}

	uploadMediaFromUrl = async (url: string, fileType: string) => {

		const blob = await fetch(url).then(r => r.blob())
		const blobUrl = URL.createObjectURL(blob);

		try {
			const newMedia = await loadMedia(blobUrl, fileType);
			this.setState({
				media: {
					src: newMedia.src,
					width: newMedia.width,
					height: newMedia.height,
					local: true,
					type: fileType
				},
				edit: false,
				mediaChanged: true
			});
			await this.uploadFile(blob, fileType);
		} catch (e) {
			console.error(e);
		}
	}



	render() {

		const {
			style = {},
			fileType
		} = this.props;

		const { media } = this.state;

		let renderContent = null;
		if (!media) {
			renderContent = (
				<DropZone
					className={Style.container}
					ref={ref => {this.dropZone = ref} }
					onDrop={this.onDrop}
					disabled={!!media}
					style={style}
					accept={fileType}
				>
					{this.renderContent()}
				</DropZone>
			)
		} else {
			renderContent = (
				<div
					className={classNames(Style.container)}
					style={style}
				>
					{this.renderContent()}
				</div>
			)
		}

		return (
			<React.Fragment>
				{renderContent}
			</React.Fragment>
		)
	}

	renderContent() {

		const {
			infos, title, btnLabel = "Upload a new file",
			disabled = false,
			enableInstagramImport
		} = this.props;

		const { progress, media, edit, uploading, loading } = this.state;

		return (
			<React.Fragment>

				{uploading && <LinearProgress
					className={classNames(Style.progressBar)}
					variant="determinate"
					value={progress}
				/>}

				{media && !uploading && this.renderControls()}

				{ /* IMAGE */ }
				{media && (!edit || uploading || loading) &&
					<UploadedMedia media={media} loading={uploading || loading} />
				}

				{this.renderCropComponent()}

				{ /* INFOS WHEN NO IMAGE */ }
				{!media &&
					<Infos
						onClick={this.triggerFileSelection}
						btnDisabled={disabled}
						btnLabel={btnLabel}
						title={title}
						infos={infos}
						actions={enableInstagramImport ? [
							<InstagramImport
								key={"insta-import"}
								onImportMedia={async media => {

									const { canCrop, needAspectRatio, onError = e => {} } = this.props;
									const mediaInfos = await loadMedia(media.media_url, 'video/mp4')

									if (!this.isAspectRatioValid(mediaInfos, needAspectRatio) && !canCrop) {
										onError(new ErrorAspectRatio("Bad aspect Ratio"))
										return false;
									}
									this.uploadMediaFromUrl(media.media_url, 'video/mp4');
									return true;
								}}
							/>
						] : []}
					/>
				}

			</React.Fragment>
		);
	}

	renderCropComponent() {
		const { media, edit, uploading, loading } = this.state;

		if (uploading || loading || !edit || !media) {
			return null;
		}

		if (!media || !media.width || !media.height || !media.src) {
			return null;
		}
		const cropMedia = {
			src: media.src,
			height: media.height,
			width: media.width,
			type: media.type
		};

		return (
			<CropMedia media={cropMedia} onCropChange={cropConf => this.setState({ cropConf })} />
		)
	}

	renderControls() {

		const { edit, media } = this.state;
		const { needAspectRatio, canCrop } = this.props;

		if (!media) {
			return null;
		}

		const controls: Array<{onClick: any => any, iconName: string}> = [];

		if (edit) {
			controls.push({
				iconName: 'save',
				onClick: this.onClickSave
			})
			controls.push({
				onClick: () => this.setState({ edit: false }),
				iconName: 'cancel'
			})

		} else {

			const mediaAspectRatio = media.height && media.width ? {
				width: media.width,
				height: media.height
			} : null

			if (mediaAspectRatio && canCrop && needAspectRatio && !this.isAspectRatioValid(mediaAspectRatio, needAspectRatio)) {
				controls.push({
					onClick: this.onClickEdit,
					iconName: 'crop'
				})
			}

			controls.push({
				iconName: 'delete',
				onClick: this.onClear
			})
		}

		return (
			<div className={Style.controlsContainer}>
				{controls.map(control => (
					<Fab key={control.iconName} className={Style.icon} size="small" color="primary" aria-label={control.iconName} onClick={control.onClick}>
						<Icon>{control.iconName}</Icon>
					</Fab>
				))}
			</div>
		)
	}

}