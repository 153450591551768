import React from "react";
import PageFormLoader from 'AppCore/Components/CommonPages/PageFormWithLoader/loader';

import { Centre } from '@izzistock/models';

export default () => {

    const getData = async ({ id }) => {
        return await Centre.get({ id })
    }

    return (
        <PageFormLoader
            defaultTitle="Modifier un centre"
            getData={getData}
            formAttributes={{
                name: {
                    type: 'text',
                    label: "Nom",
                    placeholder: "Nom",
                    validators: ['not_empty']
                },
                address: {
                    type: 'text',
                    label: "Adresse",
                    placeholder: "Adresse",
                    validators: ['not_empty']
                },
                create_cmd_hour: {
                    label: 'Heure de création des commandes (0 - 23)',
                    placeholder: "0",
                    type: 'text',
                    transformers: [ 'int' ],
                    validators: ['not_empty']
                }
            }}
        />
    )
};