// @flow
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import CropMedia, { getCroppedImg } from './CropMedia'
import { clone as cloneMedia } from './index';
import { isVideo } from './helpers'
import type { MediaType } from './index'

import { calculateMustContain } from './cropHelpers'

type Props = {
    close: any=> any,
    media: Object,
    aspect?: number,
    initialCropConf?: Object,
    onSaveFile?: ({
        media: MediaType,
        file?: File | Blob,
        cropConf?: Object,
        unchanged?: boolean
    }) => Promise<any>,
    onCancel?: any => any
}

const CropMediaModal = ({ close, media, aspect, initialCropConf = {}, onSaveFile = () => {}, onCancel = () => {} }: Props) => {

    const [ loading: boolean, setLoading ] = useState(false);
    const [ cropConf, setCropConf ] = useState(initialCropConf);

    const onClickSave = async (e: SyntheticEvent<EventTarget, Event>) => {

		if (!cropConf) {
			throw new Error("Missing cropconf");
		}

		if (isVideo(media.type)) {
            onSaveFile({
                media,
                cropConf,
                unchanged: true
            })
            close();
			return;
		}

		if (!media || !media.type || !media.height || !media.width || !media.src) {
			throw new Error("Need width and height !")
		}

        setLoading(true);

		const { blob, media: croppedMedia } = await getCroppedImg({
			src: media.src,
			height: media.height,
			width: media.width,
			type: media.type
		}, cropConf)

		const newMedia: MediaType = cloneMedia(croppedMedia);

        onSaveFile({
            media: newMedia,
            file: blob
        })
        close();
	}

    const onCloseWithoutSave = () => {
        onCancel();
        close()
    }

    const containerWidthHeight = calculateMustContain({
        contentWidth: media.width,
        contentHeight: media.height,
        containerWidth: 600,
        containerHeight: 600
    })

    return (
        <MuiDialog
            fullWidth
            maxWidth='md'
            open
            onClose={!loading ? onCloseWithoutSave : () => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Resize image</DialogTitle>
            <DialogContent style={{position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{position: 'relative', width: containerWidthHeight.width + "px", height: containerWidthHeight.height + "px"}}>
                    <CropMedia
                        media={media}
                        aspect={aspect}
                        initialCropConf={initialCropConf}
                        onCropChange={setCropConf}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {loading ?
                    <ActivityIndicator />:
                    <>
                        <Button onClick={onCloseWithoutSave}>Annuler</Button>
                        <Button disabled={false} onClick={onClickSave}>Valider</Button>
                    </>
                }

            </DialogActions>
        </MuiDialog>

    )
}

export const useOpenModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({ media, aspect, initialCropConf, ...props }: { media: Object, aspect?: number, initialCropConf?: Object }) => openDialogComponent(
        ({ close }) => (
            <CropMediaModal {...props} aspect={aspect} initialCropConf={initialCropConf} close={close} media={media} />
        )
    )
}