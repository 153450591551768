import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import css from './index.module.css';

import Gmail from './InstallInstructions/Gmail';
import Outlook from './InstallInstructions/Outlook';
import AppleMail from './InstallInstructions/AppleMail';
import Yahoo from './InstallInstructions/Yahoo';
import ThunderBird from './InstallInstructions/ThunderBird';
import IphoneIpad from './InstallInstructions/IphoneIpad';
import Outlook365 from './InstallInstructions/365';


export default () => {

	const panels = [
		{ title: "Gmail", render: () => <Gmail /> },
		{ title: "Outlook", render: () => <Outlook /> },
		{ title: "Apple Mail", render: () => <AppleMail /> },
		{ title: "Yahoo", render: () => <Yahoo /> },
		{ title: "Thunderbird", render: () => <ThunderBird /> },
		{ title: "Iphone / Ipad", render: () => <IphoneIpad /> },
		{ title: "Outlook 365 / Office 365", render: () => <Outlook365 /> },
	]

	return (

		<div className={css.installInstructionsContainer}>

			{panels.map(({ title, render }, i) => (
				<Accordion key={i}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={css.heading}>{title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{render()}
					</AccordionDetails>
				</Accordion>
			))}


		</div>
	)
}