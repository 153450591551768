import React from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Api from 'Apps/Signally/Api';

import { updateOrganisation, reloadOrganisation, resetOrganisationPayment } from 'Apps/Signally/Actions';

import Button from 'Apps/Signally/Components/Button'
import Paper from 'Apps/Signally/Components/Paper'
import { PageContainer, Content } from '../Layout';
import Header from '../Layout/Header';

import css from './index.module.css';


const style = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};


class BillingScreen extends React.PureComponent {

	state = {
		saving: false, loading: true, hasError: false
	}

	componentDidMount() {
		this.setState({
			loading: false
		});
	}

	onClickSave = async () => {

		const { organisation: { id: organisation_id = null } = {} } = this.props;

		this.setState({
			loading: true
		})

		const cardElement = this.props.elements.getElement('card');

		const clientSecret = (await Api.getSetupIntentSecretKey({ organisation_id })).secret;

		const { setupIntent, error } = await this.props.stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: cardElement,
				billing_details: {
					name: "Da Quenet"
				},
			},
		});

		if (error) {
			alert(error.message)
		}

		await Api.updatePaymentMethod({
			organisation_id,
			payment: {
				payment_method: setupIntent.payment_method
			}
		});

		await this.props.reloadOrganisation();

		this.setState({
			loading: false,
			change_card: false
		})
	}

	render = () => {

		const { loading, saving, change_card } = this.state;
		const { organisation } = this.props;

		const display_card_form = !organisation.payment_card || change_card

		const amount_due = (organisation.payment_stripe_customer && organisation.payment_stripe_customer.amount_due) || 0;

		return (

			<PageContainer>

				<Header title="Billing" />

				<Content>

					<Paper type="form">


						{display_card_form && <CardElement hidePostalCode className="MyCardElement" style={style} />}
						{display_card_form &&
							<div className={css.actionContainer}>

								{change_card &&
									<Button onClick={() => this.setState({ change_card: false })}>Cancel</Button>
								}
								<Button onClick={this.onClickSave} loading={loading} disabled={loading || saving || this.state.hasError}>Save</Button>
							</div>
						}

						{!display_card_form &&
							<div>
								<div>
									{`**** **** **** ${organisation.payment_card.last4}`}
								</div>
								<div>
									{`expire: ${organisation.payment_card.exp_month}/${organisation.payment_card.exp_year}`}
								</div>
								<Button onClick={() => this.setState({ change_card: true })}>Change</Button>
							</div>
						}


						<Button onClick={() => this.props.resetOrganisationPayment()}>Reset All Payment</Button>

					</Paper>

					<br/>
					<br/>

					<Paper>
						<div>Prochaine facture: {amount_due/100}euros</div>
					</Paper>


					<br/>
					<br/>

					<Paper>
						<div>Historique des factures :</div>
					</Paper>

				</Content>
			</PageContainer>
		);
	}
}


const BillingScreenConnected = connect(
	state => ({
		organisation: state.organisation,
	}),
	{ updateOrganisation, reloadOrganisation, resetOrganisationPayment }
)(withSnackbar(BillingScreen))

const stripePromise = loadStripe("pk_test_FpmkAJb3Iz1t5Cs8mzmiwrJT00ObcDbYx4");
export default () => (
	<Elements stripe={stripePromise}>
		<ElementsConsumer>
			{({elements, stripe}) => (
				<BillingScreenConnected elements={elements} stripe={stripe} />
			)}
		</ElementsConsumer>
	</Elements>
)




