import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";


export default firebaseConfig => {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    if (false && window.location.hostname === 'localhost') {
        firebase.firestore().settings({
            host: "localhost:8080",
            ssl: false
        });
        firebase.functions().useFunctionsEmulator("http://localhost:5001")
    }
}