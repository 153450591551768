import React, { useEffect } from "react";
import { firebaseFunctions } from 'AppCore/Libs/Firebase'
import { Link, Outlet } from 'react-router-dom';
import Nav from '../../Nav';

export default () => {

    useEffect(() => {

        (async () => {
            var retrieveAccessTokenFromCode = firebaseFunctions().httpsCallable('addMessage');
            const result = await retrieveAccessTokenFromCode({
                text: "TEST"
            });

            var retrieveAccessTokenFromCode2 = firebaseFunctions().httpsCallable('addMessage');
            const result2 = await retrieveAccessTokenFromCode2({
                text: "TEST"
            });

            console.log("result", result, result2);
        })()
    })


    return (
        <React.Fragment>
            <Nav />

            <nav>
                <Link to="/billing/invoices">Invoices</Link>{" "}
                <Link to="/billing/team">Team</Link>
            </nav>
            <main>
                <h2>Welcome to the homepage!</h2>
                <p>You can do this, I believe in you.</p>
            </main>
            <Outlet />
        </React.Fragment>
    )
}