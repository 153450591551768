// @flow
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import ExerciceList from './index'
import { Typography } from '@material-ui/core';

type Props = {
    onClose: any => any,
    actions: Array<any>
}
export default ({
    onClose,
    actions = []
}: Props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Mes exercices</Typography>
            </DialogTitle>
            <DialogContent>
                <ExerciceList actions={actions} />
            </DialogContent>
        </Dialog>
    );
}