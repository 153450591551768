// @flow
import React from 'react';
import FontStyle from '../../../DataObject/FontStyle';
import { Link } from './Link'

const _style_text = {
	fontSize: '12pt'
}

type Props = {
	fontStyle: FontStyle,
	color2?: boolean,
	style: Object,
	link?: string,
	bloc?: boolean,
	children: any
}
export const Text = ({ link, bloc = false, fontStyle, color2 = false, style = {}, children }: Props) => {
	const containerStyle = {..._style_text, ...fontStyle.getFontStyle(color2 ? 2 : 1), ...style};
	const content = link ? <Link href={link} style={fontStyle.getFontStyle(color2 ? 2 : 1)} target="_blank" rel="noreferrer">{children}</Link> : children;
	return bloc ? (
		<div style={containerStyle}>
			{content}
		</div>
	) :
	(
		<span style={containerStyle}>
			{content}
		</span>
	)
};

export const TextMultiLine = ({ fontStyle, color2 = false, style = {}, children }: Props) => {
	return (
		<div style={{..._style_text, ...fontStyle.getFontStyle(color2 ? 2 : 1), ...style}}>{children}</div>
	)
};
