import React, { useEffect, useState } from "react";
import moment from 'moment'

import { useProducts, useSuppliers } from 'Apps/Stockizy/Store/hooks'
import { CommandReturn } from '@izzistock/models';
import { ProductList } from '@izzistock/components';

import { useNavigate, useParams } from "react-router-dom";
import { Divider, Paper, Typography } from "@material-ui/core";
import { FlexRowEnd, FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import Button from "AppCore/Components/Button";
import styled from "styled-components";

const ActionButton = styled(Button)`
    margin-right: 10px;
`

export default () => {

    const { id } = useParams();
    const [ command, _setCommand ] = useState(null);
    const [ hasChanged, setHasChanged ] = useState(null);
    const suppliers = useSuppliers();
    const allProducts = useProducts();
    const navigate = useNavigate();

    const setCommand = (command, hasChanged = true) => {
        setHasChanged(hasChanged);
        _setCommand(command);
    }

    const getData = async ({ id }) => {
        return await CommandReturn.get({ id })
    }

    useEffect(() => {
        getData({ id }).then(command => {
            setCommand(command, false);
        });
    }, [ id ])

    if (!command) {
        return null;
    }

    const supplier = (suppliers.data || []).find(_supplier => _supplier.id === command.supplierId);
    const createdAt = command ? moment(command.metadata.createdAt*1000) : null;
    const products = command ? command.getProductsExpanded(allProducts.data || []) : [];

    const onClickDelete = async () => {
        if (!window.confirm("Etes vous sur de vouloir supprimer la retour de commande ?")) {
            return false;
        }
        await CommandReturn.delete({ id })
        navigate("/commands");
    }

    const reloadData = () => getData({ id }).then(command => {
        setCommand(command, false);
    });

    const onClickMarkAsReturned = async () => {
        if (!window.confirm("Etes vous sur de vouloir marqué le retour comme retourné ?")) {
            return false;
        }
        await command.validateReturn();
        await reloadData();
    }

    console.log("command", command);

    return (
        <>

            <FlexRowSpaceBetween>
                <Typography>Retour de commande pour {supplier && supplier.name}</Typography>
                {!!createdAt && `Date de création : ${createdAt.calendar()}` }
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FlexRowSpaceBetween>
                <div>Status : {command.getDisplayStatus()}</div>
                <FlexRowEnd>
                    {command.status === CommandReturn.STATUS_CREATED && <ActionButton color="primary" variant="contained" onClick={onClickMarkAsReturned}>Marqué comme retourné</ActionButton>}
                    {command.status === CommandReturn.STATUS_CREATED && <ActionButton disabled={hasChanged} color="primary" variant="contained" onClick={onClickDelete}>Supprimer</ActionButton>}
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <br/>
            <Divider />
            <br/>

            <FlexRowSpaceBetween>
                <div>Liste des produits</div>
            </FlexRowSpaceBetween>

            <Paper style={{ marginTop: "20px", paddingTop: '20px' }}>
                <ProductList
                    products={products}
                    renderAction={product => {

                        return (
                            <div>
                                {product.payload.qty} en trop
                            </div>
                        )
                    }}
                />
            </Paper>

        </>
    )
};