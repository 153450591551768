import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'


import {
  Grid
} from "@material-ui/core";

import { Exercice } from "@nopanoga/models";
import { useCurrentUser } from '@knxlab/react-hooks';

import Table from './list/table'

import {
    TableHeader
} from 'Apps/Shared/Layouts/Dashboard/Components/Table'

export default ({
    actions,
    headerActions = []
}) => {

    const [ exercices, setExercices ] = useState({ data: [] });
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    const onClickEdit = (index, exercice: Exercice) => {
        navigate(`/exercices/${exercice.id}`)
    }

    useEffect(() => {
        (async () => {
            setExercices(await Exercice.getAll({ owner: currentUser.uid }));
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <TableHeader>
                {headerActions}
            </TableHeader>
            <Grid container>
                <Grid item xs={12}>
                    <Table data={exercices.data} onClickEdit={onClickEdit} actions={actions} />
                </Grid>
            </Grid>
        </div>
    );
}