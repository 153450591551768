// @flow
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";

import { TrainingSession } from "@nopanoga/models"
import LaunchTrainingSession from 'Apps/Nopanoga/Components/TrainingSession/LaunchTrainingSession'



const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;



type Props = {
    id: string
}

const defaultTitle = "Nouvel entrainement";
export default ({ id }: Props) => {

    const [ title ] = useState(defaultTitle)
    const [ trainingSession: TrainingSession ] = React.useState(TrainingSession.getRandomData())

    return (
        <React.Fragment>
            <CssBaseline />
            <GlobalStyle />

            <Helmet title={title} />

            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                <LaunchTrainingSession
                    fullScreen
                    trainingSession={trainingSession}
                />
            </div>

        </React.Fragment>

    )
}
