import React from "react";

import DataTable from 'AppCore/Components/DataTable';
import { useCentres } from "Apps/Stockizy/Store/hooks";

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}) => {

    const centres = useCentres();

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'Email', key: 'email' },
                { title: 'Nom', key: 'displayName' },
                { title: "Centre", 'key': 'centreId' },
                { title: "Actif", 'key': 'isActive' }
            ],
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'isActive') {
                return item.isActive ? "Oui" : "Non";
            }

            if (attr_name === "centreId") {
                const centre = centres.data.find(centre => centre.id === item.centreId);
                return (centre && centre.name) || "";
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}