const BASE_CONFIG = {

	primaryColor: '#FF754C',
	pageBackgroundColor: '#F2F4F7',
	textColor: '#000000',

	app_version: '1.0',
	default_api_url: "https://api.signallydev.com",
	image_url: "https://images.signallydev.com",
	sentry_dsn: "https://ba2851d174e547da9cb3a815ca8a6f47@sentry.io/1296724'"
};


const CONFIG_STAGING = {
	default_api_url: "https://api.signallydev.com",
	image_url: "https://images.signallydev.com"
};

const CONFIG_PROD = {
	default_api_url: "https://api.signally.io",
	image_url: "https://images.signally.io"
};


let CONFIG = BASE_CONFIG;
switch (window.location.hostname) {
	case 'www.signallydev.com':
		CONFIG = {
			...CONFIG,
			...CONFIG_STAGING
		};
		break;
	case 'www.signally.io':
		CONFIG = {
			...CONFIG,
			...CONFIG_PROD
		};
		break;
	case 'localhost':
		CONFIG = {
			...CONFIG,
			...CONFIG_PROD
		};
		break;
	default:
		break;
}

export default CONFIG;