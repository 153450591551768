// @flow
import React from "react";
import styled from 'styled-components'

import SocialIcons from '../../DataObject/Social';

import {
    Slider
} from './Helpers'

const Container = styled.div`
    width: 100%;
`

export default ({ socialIcons, maxWidth = 400, onChange }: { socialIcons: SocialIcons, maxWidth: number, onChange: (socialIcons: ButtonStyle) => any}) => {

    return (
        <Container>
            <Slider
                label={"Forme arrondie"}
                value={socialIcons.radius}
                min={0}
                max={socialIcons.width / 2}
                step={1}
                onChange={value => onChange(socialIcons.clone(s => s.radius = value))}
            />

            <Slider
                label={"Largeur"}
                value={socialIcons.width}
                min={10}
                max={maxWidth}
                step={1}
                onChange={value => onChange(socialIcons.clone(s => {
                    s.radius = (value * s.radius) / s.width
                    s.width = value
                }))}
            />

        </Container>
    )
}