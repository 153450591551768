// @flow
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Switch,
    FormGroup,
} from '@material-ui/core'
import MuscleViewer from 'Apps/Nopanoga/Components/MuscleViewer'

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);



export default () => {

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedSecondaryGroups, setSelectedSecondaryGroups] = useState([]);
    const [sex, setSex] = useState('male');

    return (
        <div>
            <div style={{ display: 'flex', borderTop: '2px solid black', justifyContent: 'space-around', flexDirection: 'column' }}>

                <FormGroup>
                    <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1} style={{ display: 'flex' }}>
                            <Grid item>Female</Grid>
                            <Grid item>
                                <AntSwitch checked={sex === 'male'} onChange={(e, checked) => setSex(checked ? 'male' : 'female')} name="switch_female_male" />
                            </Grid>
                            <Grid item>Male</Grid>
                        </Grid>
                    </Typography>
                </FormGroup>

                <MuscleViewer
                    sex={sex}
                    selectedGroups={selectedGroups}
                    selectedSecondaryGroups={selectedSecondaryGroups}
                    onChangeSelectedGroups={(primary, secondary) => {
                        setSelectedGroups(primary)
                        setSelectedSecondaryGroups(secondary)
                    }}
                />
            </div>


        </div>
    )
}
