import type { Attributes } from 'AppCore/Components/Form/formFields';

export const getFormAttributes = ({ suppliers, productCategories }): Attributes => ({
    name: {
        label: "Nom du produit",
        type: 'text',
        validators: ['not_empty']
    },
    categoryId: {
        label: "Catégorie",
        placeholder: "",
        type: 'select',
        values: productCategories.data.map(productCategory => ({ label: productCategory.name, value: productCategory.id })),
        validators: ['not_empty']
    },
    code: {
        label: "Code Bar / QR CODE",
        type: 'text',
        validators: ['not_empty']
    },
    supplierId: {
        label: "Fournisseur",
        placeholder: "",
        type: 'select',
        values: suppliers.data.map(supplier => ({ label: supplier.name, value: supplier.id })),
        validators: ['not_empty']
    },
    supplierRef: {
        label: "Reference fournisseur",
        placeholder: "reference",
        type: 'text',
        validators: ['not_empty']
    },
    needOrdonnance: {
        label: "A besoin d'une ordonnance",
        type: 'switch'
    },
    conditionnement: {
        label: "Nombre de produits par boite",
        transformers: [ 'int' ],
        type: 'text'
    },
})