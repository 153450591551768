import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Centre } from '@izzistock/models';
import { Supplier, ProductCategory } from '@izzistock/models';
import { Product } from '@izzistock/models';


const selectCurrentCentre = createSelector(
    state => state.currentCentre,
    centreData => {
        if (!centreData) {
            return centreData;
        }
        const centre = new Centre();
        centre.setData(centreData);
        return centre;
    }
)
const selectCentres = createSelector(
    state => state.centres,
    centres => {
        const data = centres.data || [];
        return {
            data: data.map(data => {
                const centre = new Centre();
                centre.setData(data);
                return centre;
            })
        }
    }
)
const selectSuppliers = createSelector(
    state => state.suppliers,
    suppliers => {
        const data = (suppliers && suppliers.data) || [];
        return {
            data: data.map(data => {
                const supplier = new Supplier();
                supplier.setData(data);
                return supplier;
            })
        }
    }
)
const selectProductCategories = createSelector(
    state => state.productCategories,
    productCategories => {
        const data = (productCategories && productCategories.data) || [];
        return {
            data: data.map(data => {
                const productCategory = new ProductCategory();
                productCategory.setData(data);
                return productCategory;
            })
        }
    }
)
const selectProducts = createSelector(
    state => state.products,
    products => {
        const data = (products && products.data) || [];
        return {
            data: data.map(data => {
                const product = new Product();
                product.setData(data);
                return product;
            })
        }
    }
)

export const useCurrentCentre = () => useSelector(selectCurrentCentre);
export const useCentres = () => useSelector(selectCentres);
export const useSuppliers = () => useSelector(selectSuppliers);
export const useProductCategories = () => useSelector(selectProductCategories);
export const useProducts = () => useSelector(selectProducts);