import React from "react";
import { Image as ImageIcon } from '@material-ui/icons'

import Image from 'AppCore/Components/FirebaseImage'
import DataTable from 'AppCore/Components/DataTable';
import { useCentres } from "Apps/Stockizy/Store/hooks";
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";
import { ImageCellContainer } from "Apps/Shared/Layouts/Datatable";

export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
    actions = null
}) => {

    const sortedData = data
    const centres = useCentres();
    const currentUser = useCurrentUser();

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'Photo', key: 'photo' },
                { title: 'Nom', key: 'name' },
                ...(currentUser.isSuperAdmin ? [
                    { title: 'Centre', key: 'centreId'}
                ] : [])
            ],
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'photo') {
                return (
                    <ImageCellContainer>
                        {!item.photo &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.photo &&
                            <Image storageRef={item.photo.ref} style={{width: '100%'}} />
                        }
                    </ImageCellContainer>
                )
            }


            if (attr_name === 'centreId') {
                const centre = centres.data.find(_centre => _centre.id === item.centreId);
                return (centre || { name: "unknown" }).name
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}