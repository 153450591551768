import React from 'react';
import queryString from 'query-string'
import { createGlobalStyle } from 'styled-components';

import EmbededMatterport from '../Components/EmbededMatterport'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
`;


export default () => {

    const {
        c: client,
        m: matterportId,
        logo,
        logoSize,
        bg,
        fontStyle
    } = queryString.parse(window.location.search) || {}

    return (
        <>
            <GlobalStyle />
            <EmbededMatterport
                {...{
                    client, matterportId, logo, bg,
                    logoSize: parseInt(logoSize, 10),
                    fontStyle: JSON.parse(fontStyle)
                }}
            />
        </>
    )
}