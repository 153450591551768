import React from "react";
import { useNavigate } from 'react-router-dom'


import { Edit } from '@material-ui/icons'

import { Centre } from '@izzistock/models'
import CentreTable from 'Apps/Stockizy/Components/Centres/List'
import PageList from 'AppCore/Components/CommonPages/PageList'
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setCurrentCentre } from "Apps/Stockizy/Actions/CurrentCentre";


export default () => {

    const getData = async () => {
        return await Centre.getAll();
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onClickConnectCentre = centre => {
        dispatch(setCurrentCentre(centre))
        navigate('/praticiens')
    }

    return (
        <PageList
            addButton={{
                to: '/centre/create',
                title: 'Créer un centre'
            }}
            getData={getData}
            DataTableComponent={({ data, searchQuery, reload }) => {
                return (
                    <CentreTable
                        data={data.filter(item => {
                            return item.name.toLocaleLowerCase().substr(0, searchQuery.length) === searchQuery.toLocaleLowerCase();
                        })}
                        actions={[
                            { key: 'edit', icon: <Edit />, onClick: id => navigate('/centre/'+id) },
                            centre => (<Button key="connect" color="primary" variant="text" onClick={() => onClickConnectCentre(centre)}>Se connecter</Button>)
                        ]}
                    />
                )
            }}
        />
    )
}