import React from "react";
import {
  Code,
  DollarSign,
  Users,
  BarChart
} from "react-feather";

import LayoutDashboard from '../Shared/Layouts/Dashboard'
import LayoutAuth from '../Shared/Layouts/Auth'

import SidebarHeaderComponent from './Layouts/Components/Sidebar/Header';

//// DASHBOARD
import HomePage from '../Shared/Pages/Home'
import ExperimentsRedux from '../Shared/Pages/Experiments/Redux'
import ExperimentsUpload from '../Shared/Pages/Experiments/Upload'
import ExperimentsMuscleViewer from '../Nopanoga/Pages/Experiments/MuscleViewer'
import ReactNativeExperiment from '../Nopanoga/Pages/Experiments/ReactNative'
import TimePickerExperiment from '../Nopanoga/Pages/Experiments/TimePicker'
import ComponentsExperiment from '../Nopanoga/Pages/Experiments/Components'
import LaunchTrainingSessionExperiment from '../Nopanoga/Pages/Experiments/LaunchTrainingSession'

import ExercicesList from "./Pages/ExercicesList";
import ExerciceEdit from "./Pages/ExerciceEditCreate/Edit";
import ExerciceCreate from "./Pages/ExerciceEditCreate/Create";

import TrainingSessionsList from './Pages/TrainingSessionsList'

import TrainingSessionEdit from "./Pages/TrainingSessionEditCreate/Edit";
import TrainingSessionCreate from "./Pages/TrainingSessionEditCreate/Create";

import TrainingSessionsLaunch from './Pages/TrainingSession/LaunchTrainingSession'
import TrainingProgramsList from './Pages/TrainingProgramsList'

//// AUTH
import AuthSignin from '../Shared/Pages/Auth/Signin'
import AuthOnboarding from './Pages/Auth/Onboarding'

//// OTHERS
import InstagramCallback from 'AppCore/Components/InstagramLoginButton/callbackPage'


//// APP
import LayoutApp from './Pages/App/Layout'
import AppLaunchTrainingSession from './Pages/App/LaunchTrainingSession'




/// CSS
import './Assets/fonts/DIN/stylesheet.css';

import AltereIcon from './Assets/icons/altere';
import ProgramIcon from './Assets/icons/program';
import TrainingSessionIcon from './Assets/icons/training-sessions';


function Invoices() {
  return <h1>Invoices</h1>;
}

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}


export default {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/exercices'
    },

    sidebarConfig: {
        SidebarHeaderComponent
    },

    sidebar: [
        { title: "Mes exercices", path: "/exercices", icon: <AltereIcon /> },
        { title: "Mes séances", path: "/training-sessions", icon: <TrainingSessionIcon /> },
        { title: "Mes programmes", path: "/training-programs", icon: <ProgramIcon /> },
        { title: "Mes coachés", disabled: true, path: "/clients", icon: <Users /> },
        { title: "Mes statistiques", disabled: true, path: "/stats", icon: <BarChart /> },
        {
            title: "Billing",
            disabled: true,
            icon: <DollarSign />,
            children: [
                { title: "Invoices", path: "/billing/invoices" }
            ]
        },
        {
            title: "Experiments",
            disabled: true,
            icon: <Code />,
            children: [
                { title: "Redux", path: "/experiments/redux" },
                { title: "Upload", path: "/experiments/upload" },
                { title: "Muscle Viewer", path: "/experiments/muscle-viewer" },
                {
                    path: '/experiments/react-native', title: "React Native"
                },
                { path: '/experiments/time-picker', title: "Time Picker" },
                { path: '/experiments/launch-training-session', title: "LaunchTrainingSession"},

                { path: '/experiments/components', title: "All Components" }
            ]
        }
    ],
    routes: [

        {
            path: '/instagram-callback', element: InstagramCallback
        },

        {
            path: "/auth", element: LayoutAuth,
            routes : [
                { path: "", redirectSignin: true },
                { path: "sign-in", needLogout: false, element: AuthSignin },
                { path: "onboarding", needLogin: true, element: AuthOnboarding }
            ]
        },

        { path: '/training-session/launch', element: TrainingSessionsLaunch },
        {
            path: '/experiments/react-native', element: ReactNativeExperiment
        },

        {
            path: '/app', element: LayoutApp,
            routes: [
                {
                    path: "training-sessions/:id",
                    element: AppLaunchTrainingSession
                },
            ]
        },


        {
            path: "/", element: LayoutDashboard,
            needUser: true,
            routes : [
                { path: "", redirectHome: true },
                { path: 'exercices', element: ExercicesList },
                { path: 'exercices/new', element: ExerciceCreate },
                { path: 'exercices/:id', element: ExerciceEdit },

                { path: 'training-sessions', element: TrainingSessionsList },
                { path: 'training-sessions/new', element: TrainingSessionCreate },
                { path: 'training-sessions/:id', element: TrainingSessionEdit },

                { path: 'training-programs', element: TrainingProgramsList },
                { path: 'marketplace', redirectHome: true },
                { path: 'clients', redirectHome: true },
                { path: 'stats', redirectHome: true },

                {
                    path: "home",
                    element: HomePage
                },
                {
                    path: "billing",
                    routes: [
                        { path: 'invoices', needUser: true, element: Invoices }
                    ]
                },
                {
                    path: 'experiments',
                    routes: [
                        { path: 'redux', element: ExperimentsRedux },
                        { path: 'upload', element: ExperimentsUpload },
                        { path: 'muscle-viewer', element: ExperimentsMuscleViewer },
                        {
                            path: 'time-picker', element: TimePickerExperiment
                        },
                        { path: "launch-training-session", element: LaunchTrainingSessionExperiment},

                        { path: "components", element: ComponentsExperiment }
                    ]
                }
            ]
        },

        {
            path: "*", element: NotFound
        }
    ]
}