import { Centre } from '@izzistock/models';
import { Supplier, ProductCategory } from '@izzistock/models';
import { User as UserModel } from '@izzistock/models';
import { Product } from '@izzistock/models';

let currentUser = null;
export const onStoreReady = async ({ store }) => {

    currentUser = store.getState().Auth.user;

    store.subscribe(async () => {
        const currentState = store.getState();
        if (!currentUser && currentState.Auth.user) {
            // USER HAS LOGGED
            currentUser = currentState.Auth.user;
            const user = new UserModel()
            user.setData(currentUser.userData || {});
            onLogin({ store, user });
        }

        if (currentUser && (!currentState.Auth || !currentState.Auth.user)) {
            // USER HAS LOGGED OUT
            currentUser = null;
            onLogout({ store });
        }
    })

}


const unsubscribeFunctions = [];
const onLogin = async ({ store, user }: { store: any, user: UserModel }) => {

    console.log("USER ON LOGIN", user)

    unsubscribeFunctions.push(Centre.subscribeAll(centres => {
        store.dispatch({
            type: 'SET_CENTRES',
            centres: {
                data: centres.map(centre => centre.export())
            }
        })
    }));

    unsubscribeFunctions.push(Supplier.subscribeAll(suppliers => {
        store.dispatch({
            type: 'SET_SUPPLIERS',
            suppliers: {
                data: suppliers.map(supplier => supplier.export())
            }
        })
    }));

    unsubscribeFunctions.push(ProductCategory.subscribeAll(productCategories => {
        store.dispatch({
            type: 'SET_PRODUCT_CATEGORIES',
            productCategories: {
                data: productCategories.map(productCategory => productCategory.export())
            }
        })
    }));

    unsubscribeFunctions.push(Product.subscribeAll(products => {
        store.dispatch({
            type: 'SET_PRODUCTS',
            products: {
                data: products.map(product => product.export())
            }
        })
    }));

}

const onLogout = async ({ store }) => {
    console.log("LOGGED OUT => CLEAR ALL")
    unsubscribeFunctions.forEach(unsubscribeFunction => {
        unsubscribeFunction();
    })
    store.dispatch({
        type: 'SET_CENTRES',
        centres: {
            data: []
        }
    })
    store.dispatch({
        type: 'SET_SUPPLIERS',
        suppliers: {
            data: []
        }
    })
}