import React from "react";
import { connect } from "react-redux";

import Selector from 'Apps/Signally/Components/Selector';

export default connect(
	state => ({
		teams: state.teams
	}),
	{}
)(({ teams = {data: []}, ...props }) => {
	return (
		<Selector disableNone {...props} helperText="Select a team" data={teams.data} />
	)
});