import React from "react";
import MaterialPaper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {

	},
	form: {
		width:'100%',
		padding: '20px',
		display: 'flex',
		flexDirection: 'column'
	}
});

const typeProps = classes => ({
	form: {
		elevation: 5,
		className: classes.form
	}
})

const Paper = ({ classes, type, ...props }) => (

	<MaterialPaper
		elevation={1}
		{...(typeProps(classes)[type] || {})}
		{...props}
	/>
)


export default withStyles(styles)(Paper);