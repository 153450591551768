import React from "react";

import DataTable from 'AppCore/Components/DataTable';
import Level from 'Apps/Nopanoga/Components/Level'

/*
    {
        "owner_id": "123",
        "title": "Barbell Squat",
        "description": "Un exercice nécessitant beaucoup de technique",
        "tags": [ "crosstraining", "boxe", "musculation" ],
        "level": 2,
        "equipment": [ "barbell" ],
        "mainMuscleGroup": [ "upper_legs" ],
        "otherMuscleGroup": [ "lower_legs" ]
    }
*/


export default ({
    data = [], searchQuery = '', onClickEdit = () => {},
    sort = null,
}) => {

    const sortedData = data

    if (typeof sort === 'function') {
        sortedData.sort(sort);
    }

    return <DataTable
        data={sortedData}
        config={{
            cells: [
                { title: 'Nom', key: 'title' },
                { title: 'Level', key: 'level' },
                { title: "#Séances", key: "sessions_count"}
            ],
            actions: [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'sessions_count') {
                return (item.sessions && item.sessions.length) || 0
            }

            if (attr_name === "level") {
                return <Level level={item.level} />
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}